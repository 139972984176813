import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { hotjar } from 'react-hotjar';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-credit-cards/es/styles-compiled.css';
import './assets/css/typography.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import 'rc-slider/assets/index.css';
import './assets/css/theme.css';
import 'plyr/dist/plyr.css'

import './i18n';

import App from './App';

// Inicia o rastreio do hotjar
hotjar.initialize(2611985, 6);

ReactDOM.render(
  <React.StrictMode>
    <App />
    <ToastContainer />
  </React.StrictMode>,
  document.getElementById('root')
);

