import React from 'react'
import styles from './backgroundItem.module.css'
import api from '../../services/api'
import Swal from 'sweetalert2'

import { OverlayTrigger, Tooltip } from 'react-bootstrap'

function BackgroundItem(props) {
    const itemType = props.video ? 'video' : 'image';

    const deleteBackground = async () => {

        Swal.fire({
            title: `Remove ${itemType}`,
            text: `Are you sure you want to remove this ${itemType}?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: `Remove`,
            cancelButtonText: `Cancel`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let data = {
                        _id: props.bg_id,
                        key: props.bg_key
                    }

                    let response = await api.delete('/file/remove', { data: data })
                    if (!response.status === 200) {
                        Swal.fire("Oops!", `An error occurred while removing the ${itemType}. Please refresh the page and try again.`, 'warning')
                    } else {
                        props.onUpdate()
                        Swal.fire("Success!", `${itemType} removed successfully.`, 'success')
                    }
                } catch (e) {
                    Swal.fire("Oops!", `An error occurred while removing the ${itemType}. Please refresh the page and try again.`, 'warning')
                }
            } else if (result.isDenied) {
                return false
            }
        })

    }

    return (
        <React.StrictMode>
            <div className={`${styles.bg_item_container} ${props.active ? styles.bg_item_container_active : ''}`}>
                {props.bg_key ? (
                    <OverlayTrigger placement="top" overlay={<Tooltip>Remove {itemType}</Tooltip>}>
                        <div className={`no-select ${styles.bg_item_remove}`} onClick={() => deleteBackground()}>
                            <span>-</span>
                        </div>
                    </OverlayTrigger>
                ) : ('')}
                <div 
                    className={`${props.imageType === 'background' ? (props.active ? styles.bg_item_active : styles.bg_item_card) : (props.active ? styles.logo_item_active : styles.logo_item_card)} ${props.imageType === 'videoclip' ? styles.videoclips_item_card : ''}`} 
                    onClick={() => { props.defineImage() }}
                    style={{ 
                        backgroundImage: `url(${props.src})`,
                        backgroundSize: props.imageType === 'overlay' ? '100% 100%': undefined,
                    }}
                >
                    {props.type === 'uploading' ? (
                        <i className="fas fa-spin fa-circle-notch"></i>
                    ) : ('')}

                    {props.imageType === 'videoclip' && props.type !== 'uploading' && (
                        <video preload="metadata">
                            <source src={`${props.src}#t=2`} type="video/mp4" />
                        </video>
                    )}
                </div>
            </div>
        </React.StrictMode>
    )
}

export default BackgroundItem;