import styled from 'styled-components';
import { Popover } from 'react-bootstrap';

export const Container = styled.div`
  .options_buttons {
    display: grid;
    grid-template-columns: repeat(1fr);
    grid-column-gap: 10px;
  }

  @media (max-width: 768px) {
    .created-at-column {
      display: none;
    }

    table {
      display: table;
      font-size: 11pt;
    }
  }
`;

export const OptionButtons = styled(Popover)`
  button {
      border: 0;
      background: transparent;
      margin: 0 5px;
    }
`;