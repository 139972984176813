export function dummyAudio() {
  let ctx = new AudioContext(), oscillator = ctx.createOscillator();
  let dst = ctx.createMediaStreamDestination();
  let silence = ctx.createGain();
  silence.gain.value = 0;
  oscillator.connect(silence);
  silence.connect(dst);
  oscillator.start();
  return dst.stream.getAudioTracks()[0];
}

export function dummyVideo({width = 640, height = 360} = {}) {
  let canvas = Object.assign(document.createElement("canvas"), {width, height});
  let ctx = canvas.getContext('2d');
  ctx.fillStyle = '#0000';
  let stream = canvas.captureStream();
  return stream.getVideoTracks()[0];
}

export function dummyStream(...args) {
  return new MediaStream([dummyVideo(...args), dummyAudio()]);
}