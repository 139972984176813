import React from 'react';
import styles from './videoComponent.module.css';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Video from '../Video'
import PlayerControls from '../PlayerControls';
import { useTranslation } from 'react-i18next';

function VideoComponent(props) {
	const { t } = useTranslation();
  let descr = null

  if (props.type === "admin") {
    descr = t(`components.VideoComponent.descrYou`)
  } else if (props.file) {
    descr = t(`components.VideoComponent.descrFile`)
  } else if (props.type === "screen") {
    descr = t(`components.VideoComponent.descrScreen`)
  } else if (props.type === "guest" && props.typeVideo === 'screen') {
    descr = t(`components.VideoComponent.descrPresentation`)
  } else {
    descr = t(`components.VideoComponent.descrGuest`)
  }

  let overlayText, overlayClassName, overlayImageClassName;

  if (props.isOnScreen) {
    overlayText = props.page === 'studio' ? t(`components.VideoComponent.removeFromBroadcastText`) : t(`components.VideoComponent.pinnedToScreenText`)
    overlayClassName = styles.unrotate_40
    overlayImageClassName = ''
  } else {
    overlayText = props.page === 'studio' ? t(`components.VideoComponent.addToBroadcastText`) : t(`components.VideoComponent.pinToScreenText`)
    overlayClassName = styles.rotate_40
    overlayImageClassName = 'd-none'
  }

	const showRemoveButton = 
		(props.page === "studio" && props.type !== 'admin') ||
		(props.page === 'meet' && props.file)

  return (
			<React.StrictMode>
				<streamer id={props.id} className={`iq-card ${styles.streamer_card}`} style={{ boxShadow: "none" }}>
					<div id="localMedia">
						<div className={styles.stream_card} onClick={props.addStreamer}>

							<div className={`row align-items-center ${styles.stream_card_content}`}>
								<div className={styles.streamer_fixed}>
									<div>
										<i className={`fas fa-thumbtack ${overlayClassName}`}></i>
										<span className='d-block'>{overlayText}</span>
									</div>
									
									{showRemoveButton && (
										<button
											type="button"
											className={styles.remove_button}
											onClick={props.onRemove}
										>
											<OverlayTrigger
												placement="left"
												overlay={
													<Tooltip>
														{props.file ? t(`components.VideoComponent.tooltipRemoveFileText`) : t(`components.VideoComponent.tooltipRemoveGuestText`)}
													</Tooltip>
												}
											>
												<i className="fas fa-times-circle"></i>
											</OverlayTrigger>
										</button>
									)}
								</div>

								<div className={`col col-6 p-0 ${styles.img_stream_card}`}>
									<div className={`${styles.streamer_img_fixed} ${overlayImageClassName}`}>
										<i className={`fas fa-thumbtack d-block`}></i>
									</div>
									<div className={styles.video_container}>
										<Video aspectRatio srcObject={props.src} muted />
									</div>
								</div>
								<div className="col col-6">
									<div className={styles.stream_card_admin_info}>
										<p className="mb-0">{props.name}</p>
										<label>{descr}</label>
									</div>
								</div>
							</div>

							{props.streamer.videoElement && (
								<PlayerControls 
                  videoElement={props.streamer.videoElement} 
                  compact 
                  dark 
                />
							)}
						</div>
					</div>
				</streamer>
			</React.StrictMode>
		)
}

export default VideoComponent;