import styled from 'styled-components'

export const ColorContainer = styled.div`
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12), 0 2px 5px rgba(0, 0, 0, 0.16);

  display: flex;
  justify-content: center;

  color: transparent;
  -webkit-user-select: none; // Safari        
  -moz-user-select: none; // Firefox
  -ms-user-select: none; // IE10+/Edge
  user-select: none; // Standard
`;