import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

import CreditCardForm from '../../../components/CreditCardForm'
import useCreditCardForm from '../../../hooks/useCreditCardForm';

export default function CreditCardModal({ open, onCardSaved, onClose }) {
  const { t } = useTranslation();
  const creditCardFormData = useCreditCardForm({
    onCardSaved: handleCardSaved,
    onCanceled: handleCanceled,
  });

  async function handleCardSaved(data) {
    await onCardSaved(data);
    creditCardFormData.resetForm();
    onClose();
  }

  function handleCanceled() {
    onClose();
  }

  return (
    <Modal show={open} onHide={creditCardFormData.onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{t(`pages.Billing.CreditCardModal.title`)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreditCardForm {...creditCardFormData} />
      </Modal.Body>
    </Modal>
  )
}