import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  tr {
    border-bottom: 1px solid #eee;
  }

  tbody {
    border-top: 1px solid #eee;

    td.status {
      span {
        padding: 1px 6px;
        border-radius: 4px;
        text-transform: capitalize;
        color: #616161;
        background-color: #f5f5f5;
      }

      span.paid {
        color: #0e6245;
        background-color: #cbf4c9;
      }

      span.uncollectible {
        color: #620e0e;
        background-color: #f4c9c9;
      }
    }

    tr {
      cursor: pointer;
    }
  }
`;

export const NoInvoicesContainer = styled.p`
  font-size: 24px;
  font-weight: 200;
  margin-bottom: 0;
`