import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #0c0c0c;
`;

export const Stage = styled.div`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;

	gap: 1%;
	padding: 2%;
`;

export const Emphasis = styled.div`
  height: 100%;
  flex: 1 0 75%;
`;

export const Mosaic = styled.div`
  flex: 1 0 23%;

  display: flex;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Fullscreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Overlay = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 4%;
  bottom: 4%;
  left: 2.2%;
  right: 2.2%;

  ${({ position }) => position === 'top-left' && css`
    align-items: flex-start;
    justify-content: flex-start;
  `};

  ${({ position }) => position === 'bottom-left' && css`
    align-items: flex-end;
    justify-content: flex-start;
  `};

  ${({ position }) => position === 'top-right' && css`
    align-items: flex-start;
    justify-content: flex-end;
  `};

  ${({ position }) => position === 'bottom-right' && css`
    align-items: flex-end;
    justify-content: flex-end;
  `};
`;

export const Stream = styled(motion.div)`  
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  /* streams in mosaic */
  ${Mosaic} > & {
    margin: 0.5%;
  }

  /* streams in mosaic when there's something in emphasis */
  ${Emphasis} ~ ${Mosaic} > & {
    margin: 2%;
  }

  /* streams when there's only 1 */
  &:first-child:nth-last-child(1) {
    flex: 0 0 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
  }

  /* streams when there's 2, 3 or 4 */
  &:first-child:nth-last-child(2),
  &:first-child:nth-last-child(2) ~ &,
  &:first-child:nth-last-child(3),
  &:first-child:nth-last-child(3) ~ &,
  &:first-child:nth-last-child(4),
  &:first-child:nth-last-child(4) ~ & {
    flex: 0 0 47%;
  }

  /* streams when there's 5 or more */
  flex: 0 0 31%;

  /* streams in mosaic when there's something in emphasis */
  ${Emphasis} ~ ${Mosaic} > & {
    flex: 0 0 100% !important;
    position: relative !important;
  }

  /* streams in emphasis when there's something in mosaic */
  ${Emphasis}:first-child:not(:nth-last-child(1)) > & {
    position: relative;
  }

  /* streams in overlay */
  ${Overlay} > & {
    flex: 0 0 23% !important;
    position: relative !important;
    margin: 0.5% !important;
	  height: auto;
  }
`;

export const Badge = styled.div`    
  position: absolute;
  top: 7%;
  left: 4%;
  padding: calc(1%/2) calc(3%/2);
  border-radius: 4px;
  font-weight: 800;
  font-size: 30%;
  line-height: 1.7;
  color: #fff;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 600;
  background-color: ${({ red }) => red ? '#de3c43' : 'rgb(196 196 196 / 43%)'};
`;

export const Banner = styled.div`    
  position: absolute;
  bottom: 4%;
  left: 0;
  padding: calc(3%/2) calc(6%/2);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-weight: 800;
  font-size: ${({ size }) => 55 - (size / 7.5) + '%'};
  max-width: 75%;
  line-height: 1.3;
  color: #333;
  user-select: none;
  z-index: 110;
  background-color: #ffffffee;

  p {
    font-size: calc(100% + 10px);
  }
`;

export const BackgroundImg = styled(motion.img)`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const OverlayImg = styled(motion.img)`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  object-position: center center;
	z-index: 90;
`;

export const Logo = styled(motion.img)`
  position: absolute;
  bottom: 6%;
  right: 3.2%;
  max-width: 16%;
  max-height: 9%;
  object-fit: contain;
  object-position: right;
	z-index: 500;
`;