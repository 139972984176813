import React from 'react'
import {Link} from 'react-router-dom'
import img from '../../assets/images/error/01.png'

export default function NotFound(){
    return (
        <div className="wrapper">
            <div className="container-fluid p-0">
                <div className="row no-gutters">
                    <div className="col-sm-12 text-center">
                        <div className="iq-error">
                            <img src={img} className="img-fluid " style={{marginTop: 20}} alt=""/>
                            <h2 className="mb-0">Oops! Page not found {':('}</h2>
                            <p>Maybe the page doesn't exist.</p>
                            <Link className="btn btn-primary " to="/"><i className="ri-home-4-line"></i>Go back</Link>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
