import { useTranslation } from 'react-i18next';

import image from '../../assets/images/error/02.png';
import { getAuthenticatedUser } from '../../services/auth';

import { Container } from './styles';

export function Error() {
  const { t } = useTranslation();
  const user = getAuthenticatedUser();

  function handleTryAgainClick() {
    if (user.room_type === 'studio') {
      window.location.href = '/studio';
    }

    if (user.room_type === 'meet') {
      window.location.href = '/meet';
    }
  }

  function handleExitClick() {
    window.location.href = '/logout';
  }

  const invalidUser = 
    !user || 
    !user.room || 
    !user.room_type ||
    typeof user.room !== 'object';

  return (
    <Container>
      <img src={image} className="img-fluid mb-4" alt={t(`pages.Error.errorTitle`)}/>
      <h1>{t(`pages.Error.errorTitle`)}</h1>
      <p>{t(`pages.Error.errorText`)}</p>

      <div className="d-flex">
        {!invalidUser && (
          <button
            type='button'
            className='btn btn-primary'
            onClick={handleTryAgainClick}
          >
            {t(`pages.Error.tryAgainBtn`)}
          </button>
        )}
        <button
          type='button'
          className='btn btn-secondary ml-3'
          onClick={handleExitClick}
        >
          {t(`pages.Error.exitBtn`)}
        </button>
      </div>
    </Container>
  )
}