import { useContext, useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import { getAuthenticatedUser, saveAuthenticatedUser } from '../../services/auth';
import { useAuth } from '../../context/Auth'
import api from '../../services/api'

import SideBar from '../../components/Layout/SideBar/SideBar';
import UploadDropzone from '../../components/UploadDropzone/UploadDropzone';

import Swal from 'sweetalert2';
import { Button, DeleteLogoImageButton, LogoImage } from './styles';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BrandColors } from '../../components/BrandColors';

import { useClientLogo } from '../../hooks/useClientLogo';
import { BrandColorsThemeContext } from '../../context/BrandColorsTheme';
import { useTranslation } from 'react-i18next';
import { Header } from '../../components/Layout/Header';

export default function Settings() {
	const { t } = useTranslation()
	const user = getAuthenticatedUser()

	const { updateLocalStorageClientLogo, clientLogo, deleteClientLogo } = useClientLogo()

	const [name, setName] = useState(user.name)
	const [email, setEmail] = useState(user.email)
	const [error, setError] = useState('')
	const [success, setSuccess] = useState(false)
	const [uploading, setUploading] = useState(false)
	const { setUser, modules } = useAuth()
	const { resetColorToDefault } = useContext(BrandColorsThemeContext)

	const typeCanUseCustom = modules.custom_logo
	const isExternalClient = user.client_type !== '4web'
	const canUseCustomLogo = isExternalClient || typeCanUseCustom

	const history = useHistory();

	useEffect(() => {
		if (error) {
			const timeoutID = setTimeout(() => {
				setError('')
			}, 7000)

			return () => {
				clearTimeout(timeoutID)
			}
		}
	}, [error])

	useEffect(() => {
		if (success) {
			const timeoutID = setTimeout(() => {
				setSuccess(false)
			}, 7000)

			return () => {
				clearTimeout(timeoutID)
			}
		}
	}, [success])

	async function handleSubmit(event) {
		event.preventDefault()
		setError('')
		setSuccess(false)

		if (!name || !email) {
			setError(t(`pages.Settings.errorFieldsAccount`))
			return
		}

		try {
			await api.put(`/users/${user._id}`, {
				name,
				email
			})

			user.name = name;
			user.email = email;
			saveAuthenticatedUser(user);
			setUser(user);
			setSuccess(true)
		} catch (err) {
			if (err.response && (err.response.status === 400)) {
				setError(err.response.data.message)
			} else {
				console.error(err.message);
				setError(t(`pages.Settings.errorSomethingWrong`))
			}
		}
	}

	function uploadingLogo() {
		setUploading(true)
	}

	function handleRequestUpload() {
		if (!canUseCustomLogo) {
			Swal.fire({
				icon: 'warning',
				title: `${t(`pages.Settings.swalForbiddenTitle`)}`,
				html: t(`pages.Settings.swalForbiddenText`),
				showCancelButton: true,
				confirmButtonText: t(`pages.Settings.swalConfirmButtonText`),
				cancelButtonText: t(`pages.Settings.swalCancelButtonText`),
			}).then(async (result) => {
				if (result.isConfirmed) {
					history.push('/plans');
				} else {
					return false;
				}
			});
		}
		return canUseCustomLogo;
	}

	const handleRemoveClientLogo = async () => {
		Swal.fire({
			title: t(`pages.Settings.swalRemoveLogoTitle`),
			text: t(`pages.Settings.swalRemoveLogoText`),
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: t(`pages.Settings.swalRemoveLogoConfirmButtonText`),
			cancelButtonText: t(`pages.Settings.swalRemoveLogoCancelButtonText`),
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let data = {
						_id: clientLogo._id,
						key: clientLogo.key
					}

					const response = await deleteClientLogo(data)
					if (response === 'error') {
						Swal.fire("Oops!", t(`pages.Settings.swalRemoveLogoError`), 'warning')
					} else {
						updateLocalStorageClientLogo()
						Swal.fire(t(`pages.Settings.swalRemoveLogoSuccessTitle`), t(`pages.Settings.swalRemoveLogoSuccessText`), 'success')
					}
				} catch (e) {
					Swal.fire("Oops!", t(`pages.Settings.swalRemoveLogoError`), 'warning')
				}
			} else if (result.isDenied) {
				return false
			}
		})
	}

	function handleUpload() {
		setUploading(false);
	}

	return (
		<>
			{window.location.hostname === 'meet.4web.live' && <Redirect to="meet" />}

			{/* <SideBar page="settings" /> */}
			<Header page="settings" />

			<div className="content-page">
				<div className="container-fluid">
					<div className="row">
                        <div className="col-12">
							<h3>{t(`pages.Settings.title`)}</h3>
                        </div>
                    </div>
					<div className="row">
						<div className="col-12 col-md-6 mt-6 mb-3"
							style={{
								minWidth: '100%',
								display: 'grid',
								gridTemplateColumns: 'repeat(2, 1fr)',
								gridGap: '40px'
							}}
						>
							<div className="iq-card iq-card-block iq-card-stretch pb-3 mt-3">
								<div className="iq-card-header d-flex justify-content-between mt-3">
									<div className="iq-header-title">
										<h4 className="card-title">{t(`pages.Settings.accountTitle`)}</h4>
										<small>{t(`pages.Settings.accountDescription`)}</small>
									</div>
								</div>

								<div className="iq-card-body">
									{error && (
										<div className="alert alert-danger">
											<i
												style={{ fontSize: 18, marginRight: 5 }}
												className="fa fa-info-circle"
											/>
											{error}
										</div>
									)}

									{success && (
										<div className="alert-success d-flex align-items-center mb-3">
											<i
												style={{ fontSize: 18, marginRight: 5 }}
												className="fa fa-info-circle mr-2"
											></i>
											{t(`pages.Settings.updateSuccess`)}
										</div>
									)}

									<form onSubmit={handleSubmit}>
										<div className="form-group mt-0">
											<label className="label-input" style={{ color: 'var(--iq-primary)' }}>* {t(`pages.Settings.name`)}</label>
											<input
												type="text"
												className="form-control mb-0"
												placeholder={t(`pages.Settings.namePlaceholder`)}
												value={name}
												onChange={(e) => setName(e.target.value)}
											/>
										</div>

										<div className="form-group mt-2">
											<label className="label-input" style={{ color: 'var(--iq-primary)' }}>* {t(`pages.Settings.email`)}</label>
											<input
												type="email"
												className="form-control mb-0"
												placeholder={t(`pages.Settings.emailPlaceholder`)}
												value={email}
												onChange={(e) => setEmail(e.target.value)}
											/>
										</div>

										<div className="d-flex align-content-center justify-content-end mt-4">
											<Button className="btn btn-lg btn-primary" type="submit">
												{t(`pages.Settings.saveButtonText`)}
												<span
													style={{ fontSize: 18, marginLeft: 12 }}
													className="fa fa-save"
												/>
											</Button>
										</div>
									</form>
								</div>
							</div>
							{/* logo card */}
							<div className="iq-card iq-card-block iq-card-stretch pb-3 mt-3">
								<div className="iq-card-header d-flex justify-content-between mt-4 pt-1">
									<div className="iq-header-title">
										<h4 className="card-title">{t(`pages.Settings.customLogoTitle`)}</h4>
										<small>{t(`pages.Settings.customLogoDescription`)}</small>
									</div>
								</div>
								<div className="iq-card-body h-100 d-flex justify-content-center align-items-center" style={{ position: 'relative' }}>
									<div className="iq-card-body d-flex justify-content-center align-items-center w-100">
										{clientLogo?.url && (
											<LogoImage style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
												<img className="img-fluid mb-1" src={clientLogo?.url} alt="logo" style={{ height: '200px', width: 'auto', maxHeight: '200px', maxWidth: '200px' }} />
												<div className="logo_image__overlay">
													<DeleteLogoImageButton
														type="button"
														onClick={handleRemoveClientLogo}
													>
														<OverlayTrigger
															placement="top"
															overlay={<Tooltip>{t(`pages.Settings.removeLogoTooltip`)}</Tooltip>}
														>
															<i className="fas fa-times-circle"></i>
														</OverlayTrigger>
													</DeleteLogoImageButton>
												</div>
											</LogoImage>
										)}
										{uploading ? (
											<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
												<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
												<p>{t(`pages.Settings.loadingText`)}</p>
											</div>
										) : (
											!clientLogo?.url && (
												<UploadDropzone
													type="clientLogo"
													cid={user._id}
													eid="1"
													room=""
													onUpload={handleUpload}
													onDrop={uploadingLogo}
													onRequestUpload={handleRequestUpload}
													minHeight={"150px"}
													showLock={!canUseCustomLogo}
												/>
											)
										)}
									</div>
								</div>
							</div>
							{/* brand colors */}
							<div className="iq-card iq-card-block iq-card-stretch pb-3 mt-3">
								<div className="iq-card-header d-flex justify-content-between mt-3">
									<div className="iq-header-title">
										<h4 className="card-title">{t(`pages.Settings.customColorTitle`)}</h4>
											<small>{t(`pages.Settings.customColorDescription`)}</small>
									</div>
								</div>
								<div className="iq-card-body h-100 d-flex justify-content-center align-items-center" style={{position: 'relative'}}>
									<div className="iq-card-body d-flex justify-content-center align-items-center w-100" style={{flexDirection: 'column'}}>
                    <BrandColors />
                    <Button className="btn btn-lg btn-primary align-self-end mt-4" type="button" onClick={resetColorToDefault}>
                      {t(`pages.Settings.resetToDefaultButtonText`)}
                    </Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
