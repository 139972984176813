import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
	width: 100%;
	padding-bottom: ${({ ratio }) => `calc(${ratio} * 100%)`};
	position: relative;

	${({ ratio }) => ratio === 0 && css`
		width: 100%;
		height: 100%;
		padding-bottom: unset;
		position: unset;
	`}
`;

export const Content = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	${({ ratio }) => ratio === 0 && css`
		width: 100%;
		height: 100%;
		position: unset;
		top: unset;
		right: unset;
		bottom: unset;
		left: unset;
	`}
`;
