import React, { useEffect, useState, useRef, useContext } from 'react'
import { useHistory, Link, Redirect } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useMediaQuery } from 'react-responsive'

//internal components
import { Header } from '../../components/Layout/Header'
import BlockUI from '../../components/BlockUI/BlockUI'
import LinkShare from '../../components/LinkShare/LinkShare'
import VideoComponent from '../../components/VideoComponent/VideoComponent'
import CustomizeImages from '../../components/CustomizeImages/CustomizeImages'
import CustomizeOthers from '../../components/CustomizeOthers/CustomizeOthers'
import CustomizeVideoclips from '../../components/CustomizeVideoclips'
import Video from '../../components/Video'
import { PermissionsOverlay } from '../../components/PermissionsOverlay'

import disconnectedImage from '../../assets/images/error/03.png'

import loginSound from '../../assets/sounds/login.mp3';
import logoutSound from '../../assets/sounds/logout.mp3';
import requestSound from '../../assets/sounds/request.mp3';

import { bgSounds } from '../../utils/bgSounds';

//external components
import { Row, Col, Modal, Overlay, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import platform from "platform"
import { FaYoutube, FaTwitch, FaListAlt, FaTrashAlt, FaEllipsisV, FaQuestionCircle } from "react-icons/fa";

//libraries 
import * as mediasoupClient from 'mediasoup-client';
import io from 'socket.io-client';
import axios from 'axios';

// stylesheets
import styles from './studio.module.css'
import 'react-perfect-scrollbar/dist/css/styles.css';

//import logo_white from '../../assets/images/logo/logo-white.png';

import api from '../../services/api';
import { token_decode } from '../../services/auth'

import customRtmpImg from "../../assets/images/custom_rtmp.svg";

import { chat, weblive } from '../../services/firebase/config'
import { getMediaServer } from '../../services/ms_direct'

import { functions } from '../../services/chat/functions'
import firebase from 'firebase'
import Chat from '../../components/Chat'
import Questions from '../../components/Questions'
import { QueryClientProvider, QueryClient } from 'react-query'
import { AccountContext } from '../../context/Accounts'
//import YoutubeAuth from '../../components/YoutubeAuth'
import BigScreen from '../../components/BigScreen'
import { Banners } from './components/Banners'
import { BigScreenLayouts } from './components/BigScreenLayouts'
import { useAuth } from '../../context/Auth'
import { useInternetQuality } from '../../context/InternetQuality'
import { useClientLogo } from '../../hooks/useClientLogo'
import audioTimerLoop from '../../utils/audioTimerLoop'
//import { useForbiddenAlert } from '../../hooks/useForbiddenAlert'
import { dummyAudio, dummyVideo } from '../../utils/dummyTracks'
import { useTranslation } from 'react-i18next'

import mauticPlgEvent from '../../services/plgEvents'

import { 
  Container, 
  Body, 
  Content, 
  BigScreenContainer, 
  RoomTitle, 
  Sidebar, 
  SidebarTabs,
  SidebarContent 
} from './styles';

let fb_chat =
  firebase.apps.find((app) => app.name === '[DEFAULT]') ||
  firebase.initializeApp(chat);
let fb_weblive =
  firebase.apps.find((app) => app.name === 'secondary') ||
  firebase.initializeApp(weblive, 'secondary');
const dbFirebase = fb_chat.database();
const dbWeblive = fb_weblive.database();

var producerTransport = null
var producers = new Map()
var producerLabel = new Map()
var listStreamers = []
var qtdOnScreen = { screen: 0, cam: 0 }
var typeVideo = ''
var streamDisplayName = ''
var streamsAudio = new Map()
var streamStatus = false
var bigScreenAutoAdjustDelayID = null

var p_client = token_decode(localStorage.getItem('auth-user'))
var socket;
var internalSocket;

if (process.env.REACT_APP_ENV === 'local') {
  //socket = io('wss://weblive-dev.4.events:3016', { cors: { origin: window.location.origin, credentials: false } })
  socket = io(p_client.mediaServer, { cors: { origin: window.location.origin, credentials: false } })
  //internalSocket = io(`wss://weblive-dev.4.events:3016/internal`, {cors: { origin: window.location.origin, credentials: false}})
} else if (p_client && typeof p_client !== 'undefined') {
  socket = io(p_client.mediaServer, { cors: { origin: window.location.origin, credentials: false } })
  //internalSocket = io(`${p_client.mediaServer}/internal`, {cors: { origin: window.location.origin, credentials: false}})
}

export default function Studio({ props }) {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery({ minWidth: 1224 });

  if (window.socket) {
    socket = window.socket
    //internalSocket = window.internalSocket
  }

  socket.request = function request(type, data = {}) {
    return new Promise((resolve, reject) => {
      socket.emit(type, data, (data) => {
        if (data.error) {
          reject(data.error)
        } else {
          resolve(data)
        }
      })
    })
  }

  const [blockUI, setBlockUI] = useState(false)
  const [audioOpen, setAudioOpen] = useState(false)
  const [videoOpen, setVideoOpen] = useState(false)
  const [shareScreenController, setShareScreenController] = useState({ shared: false, css: `${styles.btn_studio_room} btn-initial-config` })
  const [showModal, setShowModal] = useState(true)
  const [showModalTips, setShowModalTips] = useState(false)
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [showShareVideoModal, setShowShareVideoModal] = useState([{ status: false, link: '' }])
  const [displayName, setDisplayName] = useState('')
  const [audioSources, setAudioSources] = useState([])
  const [videoSources, setVideoSources] = useState([])
  const [selectVideoSource, setSelectVideoSource] = useState(null)
  const [selectAudioSource, setSelectAudioSource] = useState(null)
  const [selectedVideoStream, setSelectedVideoStream] = useState(null)
  const [testMicController, setTestMicController] = useState(false)
  const [streamers, setStreamers] = useState([])
  const [layoutMode, setLayoutMode] = useState(2)
  const [onScreen, setOnScreen] = useState([])
  const [startingBroadcast, setStartingBroadcast] = useState({ status: 'stopped' })
  const [loadingConnectYoutube, setLoadingConnectYoutube] = useState(false)
  const [showModalGoogle, setShowModalGoogle] = useState(false)
  const [urlAuthGoogle, setUrlAuthGoogle] = useState(null)
  const [account4web, setAccount4web] = useState(true)
  const [showModalAccount, setShowModalAccount] = useState(false)
  const [joinRoomController, setJoinRoomController] = useState(false)
  const [showFooterLayer, setShowFooterLayer] = useState((localStorage.getItem('switchName') ? (localStorage.getItem('switchName') === 'true') : true))
  const [switchOnlyVoice, setSwitchOnlyVoice] = useState((localStorage.getItem('switchOnlyVoice') ? (localStorage.getItem('switchOnlyVoice') === 'true') : false))
  const [countQuestions, setCountQuestions] = useState(0)
  const [unreadPrivateChatCount, setUnreadPrivateChatCount] = useState(0)
  const [loadChatMessages, setLoadChatMessages] = useState(false)
  const [showModalAcceptGuest, setShowModalAcceptGuest] = useState(false)
  const [newGuestName, setNewGuestName] = useState('')
  const [currentSID, setCurrentSID] = useState('')
  const [colorConnection, setColorConnection] = useState('#707070')
  const [messageQualityConnection, setMessageQualityConnection] = useState('')
  const [firstRender, setFirstRender] = useState(true)
  const [backgroundUrl, setBackgroundUrl] = useState(null)
  const [logoUrl, setLogoUrl] = useState(null)
  const [overlayUrl, setOverlayUrl] = useState(null)
  const [videoclipUrl, setVideoclipUrl] = useState(null)
  const [banner, setBanner] = useState(null)
  const [broadcastTitle, setBroadcastTitle] = useState('')
  const [broadcastPrivacy, setBroadcastPrivacy] = useState('')
  const [broadcastTitleError, setBroadcastTitleError] = useState(false)
  const [broadcastPrivacyError, setBroadcastPrivacyError] = useState(false)
  const [isGuestRequest, setIsGuestRequest] = useState(false)
  const [guestLink, setGuestLink] = useState('');
  const [bigScreenLayout, setBigScreenLayout] = useState('presentation');
  const [destination, setDestination] = useState({
    _id: "",
    access_token: "",
    avatar_url: "",
    client_id: "",
    display_name: "",
    id_token: "",
    provider_id: "",
    refresh_token: "",
    type_account: "",
    scope: "",
    rtmp_server_url: "",
    rtmp_stream_key: ""
  })
  const [roomWithoutDestination, setRoomWithoutDestination] = useState(false);
  const [roomWithoutDestinationModal, setRoomWithoutDestinationModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({
    _id: "",
    access_token: "",
    avatar_url: "",
    client_id: "",
    display_name: "",
    provider_id: "",
    refresh_token: "",
    type_account: "",
    scope: "",
  });
  const [hoverImageAccount, setHoverImageAccount] = useState({
    status: false,
    id: "",
  });
  const [twitchCategory, setTwitchCategory] = useState("");
  const [currentBgSound, setCurrentBgSound] = useState(null);
  const [textBtnLiveStarting, setTextBtnLiveStarting] = useState('');
  const [isDisconnected, setIsDisconnected] = useState(false);
  const [activeSidebarTab, setActiveSidebarTab] = useState(isDesktop ? 'people' : null);
  const [isParticipantsTipOpen, setIsParticipantsTipOpen] = useState(false);
  const bgSongRef = useRef(document.createElement('audio'));
  
  const queryClient = new QueryClient()
  const { modules } = useAuth()
  const history = useHistory()

  const notShowQualityConnection = platform.name.toLowerCase().includes('firefox') || platform.name.toLowerCase().includes('safari') || !window.navigator.connection
  const { accounts, hideModal, show_modal, showModalAccounts } = useContext(AccountContext)

  /* CONTEÚDO DO ROOMCLIENT */

  const mediaType = {
    audio: 'audioType',
    video: 'videoType',
    screen: 'screenType',
    screenAudio: 'screenAudioType',
    file: 'fileType',
    fileAudio: 'fileAudioType',
    continuous: 'continuousAudioType',
  }

  const _EVENTS = {
    exitRoom: 'exitRoom',
    openRoom: 'openRoom',
    startVideo: 'startVideo',
    stopVideo: 'stopVideo',
    startAudio: 'startAudio',
    stopAudio: 'stopAudio',
    startScreen: 'startScreen',
    stopScreen: 'stopScreen'
  }

  var client = token_decode(localStorage.getItem('auth-user'))

  if (client.room_type === 'meet') {
    throw new Error('should be on meet')
  }

  let client_id = null
  let event_id = null
  let external_room = null
  var room_id = null
  var room_name = null

  if (client.client_type !== '4web') {

    client_id = client.vp_client_id.toString()
    event_id = client.vp_event_id.toString()
    external_room = typeof client.room === 'string' ? client.room.toString() : client.room.room_id.toString()
    var vp_plan = client.vp_plan?.toString()
    room_id = client_id + event_id + external_room
    room_name = typeof client.room === 'string' ? client.room_name ? client.room_name.toString() : null : client.room.title.toString()

  } else {
    client_id = client._id
    event_id = '1'
    external_room = typeof client.room === 'string' ? client.room.toString() : client.room.room_id.toString()
    room_id = event_id + external_room
    room_name = client.room_name.toString()
  }

  const isRoomOnlyRecord = client.only_record;

  var consumerTransport = null
  var device = null

  var consumers = new Map()
  var _isOpen = false
  var eventListeners = new Map()

  function NewJoin() {

    if (isOpen()) {
      console.log('already connected to a room')
      return false;
    }

    Object.keys(_EVENTS).forEach(function (evt) {
      eventListeners.set(evt, [])
    })

    CreateRoom(room_id).then(async function () {
      await Join(displayName, room_id)
      await InitSockets()
      _isOpen = true
      RoomOpen()
    })

  }

  ////////// INIT /////////

  async function CreateRoom(room_id) {

    await socket.request('createRoom', {
      room_id
    }).catch(err => {
      console.log(err)
    })
  }

  async function Join(name, room_id) {

    socket.request('join', {
      name,
      room_id,
      admin: true
    }).then(async function (e) {
      // console.log(e)
      const data = await socket.request('getRouterRtpCapabilities');
      let thisDevice = await loadDevice(data)
      device = thisDevice
      await initTransports(device)
      socket.emit('getProducers')
      
      new Audio(loginSound).play()
    }).catch(e => {
      console.log(e)
    })

  }

  async function loadDevice(routerRtpCapabilities) {
    let device
    try {
      device = new mediasoupClient.Device();
    } catch (error) {
      if (error.name === 'UnsupportedError') {
        console.error('browser not supported');
      }
      console.error(error)
    }
    await device.load({
      routerRtpCapabilities
    })
    return device

  }
  async function verifyRefreshTokenLocalStorage() {

    let rt = localStorage.getItem('rt')

    if (rt != null) {
      try {
        await api.post('/refresh-token', {
          refresh_token: rt,
          cid: client.vp_client_id,
          email: client.email

        })

        localStorage.removeItem('rt')

      } catch (e) {
        console.error(e)
      }

    }

  }
  async function oauth2() {

    let token_auth = localStorage.getItem('goauth2_token')
    let account = localStorage.getItem('account')

    if (account === '4web') {
      setAccount4web(true)
    } else if (account === null) {
      localStorage.setItem('account', '4web')
      setAccount4web(true)
    } else {
      setAccount4web(false)
    }

    if (!isRoomOnlyRecord && (token_auth === null)) {

      if (client.client_type === '4web') {
        // loadDevices()
        setShowModalGoogle(false)
      } else {

        if (vp_plan && (vp_plan === 'gratuito' || vp_plan === 'trial')) {
          setShowModalGoogle(false)
          setShowModal(true)
        } else {
          setShowModalGoogle(true)
          setShowModal(false)
        }
        
      }
      // try {
      //   let res = await api.get("/goauth2")
      //   let { url, status } = res.data
      //   if (status == 200) {
      //     setUrlAuthGoogle(url)
      //   } else {

      //     console.error('Bad request 401!')
      //   }

      // } catch (e) {
      //   console.log(e)
      // }
    } else {
      // loadDevices()

      setShowModalGoogle(false)
    }



  }

  function handleCloseModalGoogle() {
    // loadDevices()
    setShowModal(true)
    setShowModalGoogle(false)
  }


  function handleSwitchAccount() {

    let token = localStorage.getItem('goauth2_token')
    setAccount4web(!account4web)

    if (account4web == false) {

      localStorage.setItem('account', '4web')

    } else {

      if (token == '' || token == null) {

      }
      localStorage.setItem('account', 'client')
    }

  }

  function handleSetAccount() {
    if (isRoomOnlyRecord) {
      handleStartStream()
      return
    }
  
    if (client.client_type === '4web') {
      if (roomWithoutDestination) {
        setRoomWithoutDestinationModal(true)
        return
      } else {
        handleStartStream()
        return false
      }
    } else if (vp_plan && (vp_plan === 'gratuito' || vp_plan === 'trial')) {
      Swal.fire("Oops!", t(`pages.Studio.upgrade4eventsPlan`), 'warning').then(() => {
        window.location.href = "https://app.4.events/admin/plans/info";
      })
      return false
    }

    if (1 === 1) { //(qtdOnScreen.cam > 0 || qtdOnScreen.screen > 0) {

      setShowModalAccount(true)

    } else {
      Swal.fire("Oops!", t(`pages.Studio.addVideoBeforeStream`), 'warning')
    }
  }

  async function handleStartStream() {
    let externalClientDestination;

    if (client.client_type !== '4web' && !isRoomOnlyRecord) {
      if (!broadcastTitle) {
        setBroadcastTitleError(true);
        return;
      }
  
      if (!account4web) {
        try {
          if (!selectedAccount._id) {
            return toast.error(t(`pages.Studio.toastSelectAnAccountText`), {
              position: "bottom-center",
              autoClose: 3000,
            })
          }
      
          if (selectedAccount.type_account === "youtube" && !broadcastPrivacy) {
            return toast.error(t(`pages.Studio.toastSelectPrivacyText`), {
              position: "bottom-center",
              autoClose: 3000,
            })
          }
      
          if (selectedAccount.type_account === "twitch" && !twitchCategory) {
            return toast.error(t(`pages.Studio.toastSelectCategoryText`), {
              position: "bottom-center",
              autoClose: 3000,
            })
          }
      
          const response = await api.put("api/connect-room-account", {
            destination_id: selectedAccount._id,
            room_id: external_room,
            privacy: broadcastPrivacy,
            twitchCategory,
          });
    
          if (response.status === 200) {
            const destinationResponse = await api.get(`api/destinations/${external_room}`)
            
            if (destinationResponse.data.message === "Destination not found" || !destinationResponse.data.destination) {
              return toast.error(t(`pages.Studio.toastErrorToConnectAccount`));
            }
            
            setDestination(destinationResponse.data.destination)
            setRoomWithoutDestination(false);
            externalClientDestination = destinationResponse.data.destination;
          } else {
            return toast.error(t(`pages.Studio.toastErrorToConnectAccount`));
          }
        } catch (err) {
          console.log(err);
          return toast.error(t(`pages.Studio.toastErrorToConnectAccount`));
        }
      }
  
      setShowModalAccount(false);
    }  

    const canRecord = Boolean(client.client_type === '4web' ? modules.record : true);
    
    if (isRoomOnlyRecord && !canRecord) {
      showForbiddenAlert({
        lockType: 'record',
        broadcastStarted: startingBroadcast.status === 'started' 
      });

      return;
    }

    if (1 === 1) {//(qtdOnScreen.cam > 0 || qtdOnScreen.screen > 0) {

      setLoadingConnectYoutube(true)

      try {
        //Cria um novo servidor de renderização
        let serverRender = null


        // if (process.env.REACT_APP_ENV === 'local') {
        if (1 === 2) {

          serverRender = {
            status: 'active',
            domain: 'weblive-render-dev.4.events'
          }

        } else {

          let i = 0;
          for (i; i <= 3; i++) {

            serverRender = await socket.request('generateWebliveRender', { room: room_id })

            if ((serverRender && serverRender !== undefined) && serverRender.status === 'active') {
              break;
            } else if ((serverRender && serverRender !== undefined) && serverRender.status === 'error') {
              if (i === 3) {
                break;
              } else {
                // toast.info('Still starting, wait...', {
                toast.info(t(`pages.Studio.toastStillStartingText`), {
                  position: "bottom-center",
                  autoClose: 3000,
                });
              }
            }
          }
          
        }
        
        
        if ((serverRender && serverRender !== undefined) && serverRender.status === 'active') {
          internalSocket = await io(`wss://${serverRender.domain}:1630/internal`, { cors: { origin: window.location.origin, credentials: false } })

          internalSocket.request = function request(type, data = {}) {
            return new Promise((resolve, reject) => {
              internalSocket.emit(type, data, (data) => {
                if (data.error) {
                  reject(data.error)
                } else {
                  resolve(data)
                }
              })
            })
          }

          localStorage.setItem('render_data', JSON.stringify(serverRender))
        } else {
          setLoadingConnectYoutube(false)
          Swal.fire("Oops!", t(`pages.Studio.unableToInitializeBroadcast`), 'warning')
          return false
        }

        let renderParams = {
          room_id: room_id,
          room_type: 'studio',
          client_id: client_id,
          mediaServer: client.mediaServer,
          render_type: client.only_record ? 'record' : 'stream',
          rtmp: null,
          broadcast_id: null,
          broadcast_link: null,
          external_room: external_room,
          demo: client.client_type === '4events_lp' ? '4events_lp' : client.client_type === '4web_lp' ? '4web_lp' : false,
          client_type: client.client_type,
          event_id: client.vp_event_id,
          client_email: client.email,
        }
        
        let link_video;

        if (!isRoomOnlyRecord) {

          let data = await localStorage.getItem('goauth2_token') != null ? token_decode(localStorage.getItem('goauth2_token')) : {}

          let account = localStorage.getItem('account')
          let client_type = client.client_type
  
          if (client.client_type === '4web') {
            account = 'client'
          }
  
          data.cid = client_id
          data.event_id = event_id
          data.room = external_room
          data.account = account
          data.client_type = client_type
          data.room_name = room_name
          data.privacy_broadcast = client.privacy_broadcast
          data.only_record = isRoomOnlyRecord

          if (client_type !== '4web') {
            data.room_name = broadcastTitle;
          }

          let dataParamsStartStream = null;

          const streamDestination = client_type === '4web' 
            ? destination 
            : externalClientDestination;
          
          if (account === 'client') {
            dataParamsStartStream = {
              access_token: streamDestination.access_token,
              account,
              cid: client_id,
              client_type,
              event_id,
              // expiry_date: '',
              id_token: streamDestination.id_token,
              privacy_broadcast: broadcastPrivacy,
              // twitchCategory: client.twitchCategory,
              twitchCategory,
              refresh_token: streamDestination.refresh_token,
              room: external_room,
              room_name: data.room_name,
              scope: streamDestination.scope,
              type_account: streamDestination.type_account,
              // token_type: '',
              user: {
                // family_name: '',
                // given_name: '',
                id: streamDestination.provider_id,
                name: streamDestination.display_name,
                picture: streamDestination.avatar_url,
                client_type: client.client_type
              },
            }
          }

          let response = null;

          //iniciando broadcast e pegando os dados
          if (account === 'client') {
            response = await api.post('/start', dataParamsStartStream)
          } else {
            response = await api.post('/start', data)
          }

          console.log('response -> ', response.data)

          if (response.status !== 200) {
            let render_data = await JSON.parse(localStorage.getItem('render_data'))
            let socket_id = null
  
            if (typeof (render_data) !== "undefined" && render_data) {
              socket.request('removeWebliveRender', { socket_id: socket_id, id: render_data.id, dnsRegister: render_data.dnsRegister, domain: render_data.domain })
            }
  
            Swal.fire("Oops!", `There was a problem starting the ${isRoomOnlyRecord ? 'recording' : 'broadcast'}...`, 'warning')
            setStartingBroadcast({ status: 'stopped' })
            streamStatus = false
            setLoadingConnectYoutube(false)
            return
          }
          
          response.data.cid = client_id
          link_video = response.data.link_video;
          renderParams.rtmp = response.data.rtmp_url;
          renderParams.broadcast_id = response.data.internal_broadcast_id;
          renderParams.broadcast_link = link_video;

          localStorage.setItem('broadcast_data', JSON.stringify(response.data))

          //Send PLG Event
          mauticPlgEvent('config', 'Iniciou uma transmissão')
        }

        let openRendererResponse = await internalSocket.request('openRenderer', renderParams)

        let socket_id = openRendererResponse.socket_id;

        updateRenderBigscreen();

        await internalSocket.request('startRender');

        setLoadingConnectYoutube(false)
        setStartingBroadcast({ status: 'started' })
        streamStatus = true

        internalSocket.once('render_crashed', () => {
          handleStopStream(true);
          if (isRoomOnlyRecord) {
            Swal.fire("Oops!", t(`pages.Studio.problemWithRecord`), 'error');
          } else {
            Swal.fire("Oops!", t(`pages.Studio.problemWithBroadcast`), 'error');
          }
        });

        setTimeout(function () {
          transmissionInProgress({
            cid: client.vp_client_id,
            eid: client.vp_event_id,
            rid: external_room,
            eadmin: client.email,
            link: link_video,
            time: '20'
          })
        }, 1200000);

        setTimeout(function () {
          transmissionInProgress({
            cid: client.vp_client_id,
            eid: client.vp_event_id,
            rid: external_room,
            eadmin: client.email,
            link: link_video,
            time: '60'
          })
        }, 3600000);

        setTimeout(function () {
          transmissionInProgress({
            cid: client.vp_client_id,
            eid: client.vp_event_id,
            rid: external_room,
            eadmin: client.email,
            link: link_video,
            time: '180'
          })
        }, 10800000);

        socket.emit('noticeTransmission', {
          room_id: room_id,
          status: 'start',
          onScreenParticipants: onScreen
        })

        setShowShareVideoModal({ status: client.client_type === '4events' && !!link_video, link: link_video })

        localStorage.setItem('socket_id', socket_id)

      } catch (e) {
        if (e.response?.data?.type?.startsWith('limit')) {
          showForbiddenAlert({ 
            lockType: `stream_${e.response.data.type}`,
            broadcastStarted: startingBroadcast.status === 'started'
          });
        } else {
          console.error(e)
          if (e.response?.data?.message === "The user is not enabled for live streaming.") {
            Swal.fire("Oops!", t(`pages.Studio.problemToStartBroadcastAccYT`), 'warning')
          } else {
            Swal.fire("Oops!", t(`pages.Studio.problemToStartBroadcast`), 'warning')
          }
        }

        let render_data = await JSON.parse(localStorage.getItem('render_data'))
        let socket_id = localStorage.getItem('socket_id')

        if (internalSocket) {
          await internalSocket.request('closeRenderer', { room: room_id, socket_id: socket_id })
        }

        if (typeof (render_data) !== "undefined" && render_data) {
          socket.request('removeWebliveRender', { socket_id: socket_id, id: render_data.id, dnsRegister: render_data.dnsRegister, domain: render_data.domain })
        }

        if (e.response?.data?.message === "The user is not enabled for live streaming.") {
          Swal.fire("Oops!", t(`pages.Studio.problemToStartBroadcastAccYT`), 'warning')
        } else {
          Swal.fire("Oops!", t(`pages.Studio.problemToStartBroadcast`), 'warning')
        }
        setStartingBroadcast({ status: 'stopped' })
        streamStatus = false
        setLoadingConnectYoutube(false)
      }
    } else {
      Swal.fire("Oops!", t(`pages.Studio.addVideoBeforeStream`), 'warning')
    }

  }

  async function handleStopStream(silent) {
    setLoadingConnectYoutube(true)

    let socket_id = localStorage.getItem('socket_id')
    let render_data = await JSON.parse(localStorage.getItem('render_data'))

    try {
      if (!isRoomOnlyRecord) {
        let broadcast = localStorage.getItem('broadcast_data')
        let account = localStorage.getItem('account')
        let client_type = client.client_type
  
        if (client.client_type === '4web') {
          account = 'client'
        }
  
        broadcast = JSON.parse(broadcast)
        broadcast.account = account
        broadcast.client_type = client_type
        broadcast.type_account = destination.type_account
        broadcast.provider_id = destination.provider_id
        //parando broadcast
        await api.post('/stop', broadcast)
      }

      setLoadingConnectYoutube(false)
      setStartingBroadcast({ status: 'stopped' })
      streamStatus = false

      if (internalSocket) {
        await internalSocket.request('closeRenderer', { room: room_id, socket_id: socket_id })
      }

      if (typeof (render_data) !== "undefined" && render_data) {
        socket.request('removeWebliveRender', { socket_id: socket_id, id: render_data.id, dnsRegister: render_data.dnsRegister, domain: render_data.domain })
      }

      localStorage.setItem('socket_id', null)
      localStorage.setItem('broadcast_data', null)
      localStorage.removeItem('socket_id')
      localStorage.removeItem('broadcast_data')
      localStorage.removeItem('render_data')

      if (!silent) {
        if(client.only_record) {
          Swal.fire({
            title: "Success!",
            html:  `${t('pages.Studio.recordFinish')}<br><br><a className="btn btn-primary text-center" href="/records">${t('pages.Studio.btnShowRecords')}</a>`,
            icon: 'success'
          })
        } else {
          Swal.fire("Success!", t(`pages.Studio.broadcastFinish`), 'success')
        }
      }
        
      
      socket.emit('noticeTransmission', {
        room_id: room_id,
        status: 'end',
        onScreenParticipants: onScreen
      })

    } catch (e) {

      setLoadingConnectYoutube(false)
      setStartingBroadcast({ status: 'stopped' })
      streamStatus = false

      if (internalSocket) {
        await internalSocket.request('closeRenderer', { room: room_id, socket_id: socket_id })
      }

      if (typeof (render_data) !== "undefined" && render_data) {
        socket.request('removeWebliveRender', { socket_id: socket_id, id: render_data.id, dnsRegister: render_data.dnsRegister, domain: render_data.domain })
      }

      localStorage.setItem('socket_id', null)
      localStorage.setItem('broadcast_data', null)
      localStorage.removeItem('socket_id')
      localStorage.removeItem('broadcast_data')
      localStorage.removeItem('render_data')
    
      if (!silent) {
        Swal.fire("Success!", t(`pages.Studio.broadcastFinish`), 'success')
      }
      socket.emit('noticeTransmission', {
        room_id: room_id,
        status: 'end',
        onScreenParticipants: onScreen
      })
      console.error(e)
    }

    internalSocket = null;
  }

  function updateRenderBigscreen() {
    let background = localStorage.getItem('backgroundUrl')
    let bgActive = localStorage.getItem('switchBackground') ? (localStorage.getItem('switchBackground') === 'true') : true

    let logo = localStorage.getItem('logoUrl')
    let logoActive = localStorage.getItem('switchLogo') ? (localStorage.getItem('switchLogo') === 'true') : true
    
    let overlay = localStorage.getItem('overlayUrl')
    let overlayActive = localStorage.getItem('switchOverlay') ? (localStorage.getItem('switchOverlay') === 'true') : true
    
    const videoclipCurrentTime = document.querySelector('#bigscreen-videoclip')?.plyr?.currentTime || 0;
    
    let this_data = {
      room: room_id,
      background: bgActive ? background : '',
      logo: logoActive ? logo : '',
      overlay: overlayActive ? overlay : '',
      statusNames: showFooterLayer,
      // onlyVoice: switchOnlyVoice,
      layout: bigScreenLayout,
      banner,
      videoclip: videoclipUrl,
      videoclipStartTime: videoclipCurrentTime,
      streamers: [],
      bgSong: {
        song_id: currentBgSound ? currentBgSound?.id : null,
        loop: bgSongRef.current.loop,
        volume: bgSongRef.current.volume,
        paused: bgSongRef.current.paused,
        muted: bgSongRef.current.muted,
        currentTime: bgSongRef.current.currentTime
      }
    }

    onScreen.forEach((data) => {
      this_data.streamers.push({
        producer_id: data.id,
        display: data.display,
        type_video: data.typeVideo,
        group: data.group
      })
    })

    streamsAudio.forEach((data) => {
      let this_video = onScreen.find((stream) => stream.group === data.group)

      if (this_video) {
        this_data.streamers.push({
          producer_id: data.id,
          display: data.display,
          type_video: data.typeVideo,
          group: data.group
        })
      }
    })

    internalSocket.emit('updateBigscreen', this_data)
  }

  async function initTransports(device) {

    // init producerTransport
    {
      const data = await socket.request('createWebRtcTransport', {
        forceTcp: false,
        rtpCapabilities: device.rtpCapabilities,
      })
      if (data.error) {
        console.error(data.error);
        return;
      }

      producerTransport = await device.createSendTransport(data);

      producerTransport.on('connect', async function ({
        dtlsParameters
      }, callback, errback) {
        socket.request('connectTransport', {
          dtlsParameters,
          transport_id: data.id
        })
          .then(callback)
          .catch(errback)
      });

      producerTransport.on('produce', async function ({
        kind,
        rtpParameters
      }, callback, errback) {
        try {
          const {
            producer_id
          } = await socket.request('produce', {
            producerTransportId: producerTransport.id,
            kind,
            rtpParameters,
            display: streamDisplayName,
            typeVideo: kind === 'audio' ? 'audio' : typeVideo,
            group: typeVideo === 'screen' ? `${socket.id}_stream` : `${socket.id}_admin`
          });
          callback({
            id: producer_id
          });
        } catch (err) {
          errback(err);
        }
      })

      producerTransport.on('connectionstatechange', function (state) {
        switch (state) {
          case 'connecting':

            break;

          case 'connected':
            //localVideo.srcObject = stream
            break;

          case 'failed':
            producerTransport.close();
            break;

          default:
            break;
        }
      });
    }

    // init consumerTransport
    {
      const data = await socket.request('createWebRtcTransport', {
        forceTcp: false,
      });
      if (data.error) {
        console.error(data.error);
        return;
      }

      // only one needed
      consumerTransport = device.createRecvTransport(data);
      consumerTransport.on('connect', function ({
        dtlsParameters
      }, callback, errback) {
        socket.request('connectTransport', {
          transport_id: consumerTransport.id,
          dtlsParameters
        })
          .then(callback)
          .catch(errback);
      });

      consumerTransport.on('connectionstatechange', async function (state) {
        switch (state) {
          case 'connecting':
            break;

          case 'connected':
            //remoteVideo.srcObject = await stream;
            //await socket.request('resume');
            break;

          case 'failed':
            consumerTransport.close();
            break;

          default:
            break;
        }
      });
    }

  }

  function InitSockets() {
    socket.on('consumerClosed', function ({
      consumer_id
    }) {
      console.log('closing consumer:', consumer_id)
      RemoveConsumer(consumer_id)
    })

    /**
     * data: [ {
     *  producer_id:
     *  producer_socket_id:
     * }]
     */
    socket.on('newProducers', async function (data) {
      console.log('newProducers teste')
      for (let {
        producer_id, display, typeVideo, group
      } of data) {
        await Consume(producer_id, display, typeVideo, group)
      }
    })

    socket.on('disconnect', function () {
      exit(true)
      setIsDisconnected(true)
    })

    socket.on('guestAccessRequest', function (data) {
      setNewGuestName(data.guestName)
      setCurrentSID(data.guestSocketId)
      setShowModalAcceptGuest(true)
      
      new Audio(requestSound).play();
    })

    socket.on('pendingRequestsResults', (data) => {
      let pendingUsers = data.users

      if (pendingUsers.length === 0) return;

      setNewGuestName(pendingUsers[0].participant_name);
      setCurrentSID(pendingUsers[0].socket_id);
      setShowModalAcceptGuest(true);
    })

  }

  function onAcceptNewGuest() {
    socket.emit('hostResponse', {
      response: 'accept',
      s_id: currentSID,
      room: room_id
    })

    setShowModalAcceptGuest(false)

    checkIfThereArePendingRequests()
  }

  function onRejectNewGuest() {
    socket.emit('hostResponse', {
      response: 'reject',
      s_id: currentSID,
      room: room_id
    })

    setShowModalAcceptGuest(false)

    checkIfThereArePendingRequests()
  }

  function checkIfThereArePendingRequests() {
    socket.emit('pendingRequests', {
      room_id: room_id,
    }, (response) => {
      const users = response;
      users.forEach(user => {
        setNewGuestName(user.participant_name);
        setCurrentSID(user.socket_id);
        setShowModalAcceptGuest(true);
      });
    })
  }

  //////// MAIN FUNCTIONS /////////////

  async function Produce(type, src = null, groupId = null) {

    console.log(type, ' => ',src);

    let mediaConstraints = {}
    let video = false
    let audio = false
    let screen = false
    let screenAudio = false
    let continuous = false
    let file = false
    let fileAudio = false

    switch (type) {
      case mediaType.audio:
        mediaConstraints = {
          audio: {
            deviceId: src,
            sampleRate: 44100,
            echoCancellation: true
          },
          video: false
        }
        audio = true
        break
      case mediaType.video:
        video = true
        typeVideo = 'cam'
        if (src) {
          mediaConstraints = {
            audio: false,
            video: {
              width: { max: 1920, min: 320 },
              height: { max: 1080, min: 200 },
              aspectRatio: { ideal: 1.77778 },
              deviceId: { exact: src }
            }
          }
        } else {
          mediaConstraints = {
            audio: false,
            video: {
              width: { max: 1920, min: 320 },
              height: { max: 1080, min: 200 },
              aspectRatio: { ideal: 1.77778 }
            }
          }
        }
        break
      case mediaType.screen:
        typeVideo = 'screen'
        mediaConstraints = { video: true, audio: true }
        screen = true
        break;
      case mediaType.screenAudio:
        screenAudio = true
        break;
      case mediaType.continuous:
        mediaConstraints = { video: false, audio: true }
        continuous = true
        audio = true
        break;
      case mediaType.file:
        typeVideo = 'screen'
        file = true
        break;
      case mediaType.fileAudio:
        fileAudio = true
        break;
      default:
        return
    }

    // many files (video and audio tracks) can be produced at the same time
    if (!file && !fileAudio) {
      if (producerLabel.has(type)) {
        console.log('producer already exists for this type ' + type)
        return
      }
    }

    try {

      let track, stream;
      let videoElement;

      if (video || audio || screen || continuous) {
        if (video && src === 'dummyVideo') {
          track = dummyVideo();
        } else if (audio && src === 'dummyAudio') {
          track = dummyAudio();
        } else if (continuous) {
          track = dummyAudio();
        } else {
          stream = screen 
            ? await navigator.mediaDevices.getDisplayMedia(mediaConstraints) 
            : await navigator.mediaDevices.getUserMedia(mediaConstraints)

          track = audio ? stream.getAudioTracks()[0] : stream.getVideoTracks()[0]
        }

        if (screen) {
          let screenAudioTrack = stream.getAudioTracks()[0]
          if (screenAudioTrack) {
            Produce(mediaType.screenAudio, screenAudioTrack)
          }
        }

        stream = new MediaStream()
        stream.addTrack(track)

      } else if (file) {
        videoElement = document.createElement('video');
        videoElement.src = src.fileURL;

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        videoElement.addEventListener('loadedmetadata', function () {
          canvas.width = videoElement.videoWidth;
          canvas.height = videoElement.videoHeight;
        });

        function resetVideoPayback() {
          videoElement.currentTime = 0;
          videoElement.pause();
          // if it's on big screen, remove
          setStreamers((prevState) => prevState.map((stream) => {
            if (stream.id === videoElement.producer_id) {
              return {
                ...stream,
                removeFromScreen: true,
              }
            } else {
              return stream
            }
          }));
        }

        // when payback end, go to start and pause
        videoElement.addEventListener('timeupdate', function () {
          // doesn't wait until it actually ends or the stream will be destroyed
          if (!videoElement.paused) {
            if (videoElement.duration - videoElement.currentTime < 0.5) {
              resetVideoPayback()
            }
          }
        });

        videoElement.addEventListener('play', function () {
          if (videoElement.duration - videoElement.currentTime < 0.5) {
            resetVideoPayback()
          }
        });

        const fps = 25;
        const clearInterval = 25;
        let frameCount = 0;

        function frameStep() {
          // clear rect each ${clearInterval} frames
          frameCount++;
          if (frameCount % clearInterval === 0) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
          }
          ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height)
        }

        videoElement.addEventListener('loadeddata', function () {
          videoElement.currentTime = 0;
          if (!videoElement.stopRender) {
            const stopRender = audioTimerLoop(frameStep, 1000 / fps);
            videoElement.stopRender = stopRender;
          }
        });

        const canvasStream = canvas.captureStream(fps);

        let videoStream
        if (videoElement.captureStream) {
          videoStream = videoElement.captureStream();
        } else if (videoElement.mozCaptureStream) {
          videoStream = videoElement.mozCaptureStream();
        } else {
          throw new Error('capture stream not suported');
        }

        // wait for videoStream's audio track
        const videoHasAudio = await new Promise((resolve, reject) => {
          // timeout in 3 seconds
          const timeoutID = setTimeout(() => {
            resolve(false);
          }, 3000);

          videoStream.addEventListener('addtrack', function (event) {
            if (event.track.kind === 'audio') {
              clearTimeout(timeoutID);
              resolve(true);
            }
          })
        })

        groupId = uuidv4();
        
        const [videoTrack] = canvasStream.getVideoTracks();  

        if (videoHasAudio) {
          const [audioTrack] = videoStream.getAudioTracks();   
          await Produce(mediaType.fileAudio, audioTrack, groupId);
        }      

        track = videoTrack;
        stream = new MediaStream();
        stream.addTrack(videoTrack);
      } else {
        track = src
      }

      const params = {
        track
      };

      if (video) {
        params.encodings = [{
          rid: 'r0',
          maxBitrate: 100000,
          //scaleResolutionDownBy: 10.0,
          scalabilityMode: 'L1T3'
        },
        {
          rid: 'r1',
          maxBitrate: 300000,
          scalabilityMode: 'L1T3'
        },
        {
          rid: 'r2',
          maxBitrate: 900000,
          scalabilityMode: 'L1T3'
        }
        ];
        params.codecOptions = {
          videoGoogleStartBitrate: 1000
        };
      }

      if (file) {
        streamDisplayName = src.filename;
      } else {
        streamDisplayName = displayName;
      }

      const producer = await producerTransport.produce(params)

      producers.set(producer.id, producer)

      if (videoElement) {
        videoElement.producer_id = producer.id;
      }

      let elem
      if (video || screen || file) {
        let group;

        if (video) {
          group = `${socket.id}_admin`
        } else if (screen) {
          group = `${socket.id}_screen`
        } else if (file) {
          group = `${socket.id}_file_${groupId}`
        }

        setStreamers(prevState =>
          [...prevState, {
            id: producer.id,
            display: streamDisplayName,
            type: video ? 'admin' : 'screen',
            srcObject: stream,
            status: 'off',
            typeVideo: video ? 'cam' : 'screen',
            group: group,
            file,
            videoElement,
            addOnScreen: Boolean(file),
          }]
        )

        listStreamers = [...listStreamers, {
          id: producer.id,
          type: 'video',
          typeVideo: typeVideo
        }]

        //if produce type screen, define css and sharescreen button true
        if (screen) {
          setShareScreenController({ shared: true, css: 'text-white  btn-initial-config btn-inital-room-pressed d-inline-block' })
        }

        setBlockUI(false)
        checkIfThereArePendingRequests()

        // if producing main video
        if (video) {
          // start monitoring network connection
          dbWeblive.ref('.info/connected').on('value', (snapshot) => {
            if (!snapshot.val()) {
              setIsDisconnected(true);
            }
          })

          // show participants tip on small screens
          if (!isDesktop) {
            setTimeout(() => {
              setIsParticipantsTipOpen(true)
            }, 2000);
          }
        }
      } else {
        if (!continuous) {
          let group;

          if (screenAudio) {
            group = `${socket.id}_screen`
          } else if (fileAudio) {
            group = `${socket.id}_file_${groupId}`
          } else {
            group = `${socket.id}_admin`
          }

          streamsAudio.set(producer.id, {
            id: producer.id,
            display: streamDisplayName,
            typeVideo: 'audio',
            group: group
          })
        }
      }

      producer.on('trackended', () => {
        closeProducer(type, producer.id)
      })

      producer.on('transportclose', () => {
        console.log('producer transport close')
        if (!audio) {
          elem.srcObject.getTracks().forEach(function (track) {
            track.stop()
          })
          elem.parentNode.removeChild(elem)
        }
        producers.delete(producer.id)

      })

      producer.on('close', () => {
        console.log('closing producer')
        if (!audio) {
          elem.srcObject.getTracks().forEach(function (track) {
            track.stop()
          })
          elem.parentNode.removeChild(elem)
        }
        producers.delete(producer.id)

      })

      if (!file && !fileAudio) {
        producerLabel.set(type, producer.id)
      }

      if (type === mediaType.audio && src !== 'dummyAudio') {
        setAudioOpen(true)
      }

      if (type === mediaType.video && src !== 'dummyVideo') {
        setVideoOpen(true)
      }

      switch (type) {
        case mediaType.audio:
          event(_EVENTS.startAudio)
          break
        case mediaType.video:
          event(_EVENTS.startVideo)
          break
        case mediaType.screen:
          event(_EVENTS.startScreen)
          break;
        default:
          return
      }
    } catch (err) {
      if (err.message.toString() === 'Could not start video source' && !audio && !screen) {
        Swal.fire('Oops...', t(`pages.Studio.camProblem`), 'warning')
      } else if (err.message.toString() === 'Permission denied') {
        console.log('Screen sharing canceled')
      } else {
        Swal.fire('Oops...', t(`pages.Studio.camProblem2`), 'warning')
      }

      if (video) {

        setStreamers(prevState =>
          [...prevState, {
            id: 'admin',
            display: displayName,
            type: 'admin',
            srcObject: null,
            status: 'off',
            typeVideo: typeVideo,
            group: `${socket.id}_admin`
          }]
        )

        listStreamers = [...listStreamers, {
          id: 'admin',
          type: 'video',
          typeVideo: typeVideo
        }]

        setBlockUI(false)
      }
    }
  }

  async function Consume(producer_id, display, typeVideo, group) {

    if (!(producerLabel.has(mediaType.video) || producerLabel.has(mediaType.audio))) {
      if (group.indexOf("admin") != -1) {
        Swal.fire({
          icon: 'warning',
          title: '<p>Oops!</p>',
          html: t(`pages.Studio.adminAlreadyOnRoom`)
        }).then(() => {
          window.location.href = "https://app.4web.live/logout";
        })
        return
      }
    }

    //let info = await roomInfo()
    //console.log(display, typeVideo, group)

    getConsumeStream(producer_id).then(async function ({
      consumer,
      stream,
      kind,
    }) {

      consumers.set(consumer.id, consumer)

      let elem

      if (kind === 'video') {
        new Audio(loginSound).play()

        setStreamers(prevState =>
          [...prevState, {
            id: producer_id,
            consumer_id: consumer.id,
            display: display,
            type: 'guest',
            srcObject: stream,
            status: 'off',
            kind: kind,
            typeVideo: typeVideo,
            group: group
          }]
        )

        //console.log(consumer, kind)

      } else {
        elem = document.createElement('audio')
        elem.srcObject = stream
        elem.id = consumer.id
        elem.playsinline = false
        elem.autoplay = true

        //document.getElementById(remoteAudioEl).appendChild(elem)

        streamsAudio.set(producer_id, {
          id: producer_id,
          consumer_id: consumer.id,
          display: display,
          typeVideo: typeVideo,
          group: group
        })
      }

      listStreamers = [...listStreamers, {
        id: producer_id,
        consumer_id: consumer.id,
        type: kind,
        typeVideo: typeVideo
      }]

      consumer.on('trackended', function () {
        RemoveConsumer(consumer.id)
      })
      consumer.on('transportclose', function () {
        RemoveConsumer(consumer.id)
      })

    })
  }

  async function getConsumeStream(producerId) {
    const {
      rtpCapabilities
    } = device
    const data = await socket.request('consume', {
      rtpCapabilities,
      consumerTransportId: consumerTransport.id, // might be 
      producerId
    });

    const {
      id,
      kind,
      rtpParameters
    } = data;

    let codecOptions = {};
    const consumer = await consumerTransport.consume({
      id,
      producerId,
      kind,
      rtpParameters,
      codecOptions,
    })
    const stream = new MediaStream();
    stream.addTrack(consumer.track);
    return {
      consumer,
      stream,
      kind
    }
  }

  function closeProducer(type, producer_id) {

    if (!producer_id) {
      if (!producerLabel.has(type)) {
        console.log('there is no producer for this type ' + type)
        return
      }

      producer_id = producerLabel.get(type);
    }

    socket.emit('producerClosed', {
      producer_id
    })
    producers.get(producer_id).close()
    producers.delete(producer_id)
    producerLabel.delete(type)

    if (type === mediaType.audio) {
      setAudioOpen(false)
    }

    if (type === mediaType.video) {
      setVideoOpen(false)
    }

    if (
      type === mediaType.audio ||
      type === mediaType.screenAudio ||
      type === mediaType.fileAudio
    ) {
      streamsAudio.delete(producer_id)
      return false
    } else if (type === mediaType.screen) {
      if (producerLabel.has(mediaType.screenAudio)) {
        closeProducer(mediaType.screenAudio)
      }
    } else if (type === mediaType.file) {
      const this_streamer = streamers.find(streamer => streamer.id === producer_id);
      if (this_streamer.videoElement.stopRender) {
        this_streamer.videoElement.stopRender()
      }
      this_streamer.videoElement.pause();
      this_streamer.videoElement.currentTime = 0;

      let this_audio;

      streamsAudio.forEach((audioStream) => {
        if (audioStream.group === this_streamer.group) {
          this_audio = audioStream
        }
      })

      if (this_audio) {
        closeProducer(mediaType.fileAudio, this_audio.id);
      }
    }

    setOnScreen(prevState => prevState.filter(streamer => streamer.id !== producer_id))
    setStreamers(prevState => prevState.filter(streamer => streamer.id !== producer_id))

    listStreamers = listStreamers.filter(streamer => streamer.id !== producer_id)

    switch (type) {
      case mediaType.audio:
        event(_EVENTS.stopAudio)
        break
      case mediaType.video:
        event(_EVENTS.stopVideo)
        break
      case mediaType.screen:
        event(_EVENTS.stopScreen)
        break;
      default:
        return
    }

  }

  function pauseProducer(type) {
    if (!producerLabel.has(type)) {
      console.log('there is no producer for this type ' + type)
      if ([mediaType.audio, mediaType.video].includes(type)) {
        const deviceText = type === mediaType.audio ? t(`pages.Studio.micText`) : t(`pages.Studio.cameraText`);
        toast.warn(t(`pages.Studio.toastFailedCameraOrMic`, { deviceText }), {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 8000,
        });
      }
      return false;
    }
    let producer_id = producerLabel.get(type)
    producers.get(producer_id).pause()
    return true;
  }

  function resumeProducer(type) {
    if (!producerLabel.has(type)) {
      console.log('there is no producer for this type ' + type)
      return false;
    }
    let producer_id = producerLabel.get(type)
    producers.get(producer_id).resume()
    return true;
  }

  async function RemoveConsumer(consumer_id) {

    consumers.delete(consumer_id)

    let this_streamer = listStreamers.find((streamer) => streamer.consumer_id === consumer_id)
    let this_type = null

    if (this_streamer) {
      this_type = this_streamer.type
    }

    if (this_type && this_type === "video") {

      if (this_streamer.typeVideo === 'screen') {
        qtdOnScreen.screen--
      } else {
        qtdOnScreen.cam--
      }

      setOnScreen(prevState => prevState.filter(streamer => streamer.consumer_id !== consumer_id))
      setStreamers(prevState => prevState.filter(streamer => streamer.consumer_id !== consumer_id))
    } else {
      streamsAudio.delete(this_streamer.id)
    }

    listStreamers = listStreamers.filter(streamer => streamer.id !== consumer_id)

    new Audio(logoutSound).play()
  }

  function exit(offline = false) {

    let clean = function () {
      _isOpen = false
      consumerTransport.close()
      producerTransport.close()
      socket.off('disconnect')
      socket.off('newProducers')
      socket.off('consumerClosed')
    }

    if (!offline) {
      socket.request('exitRoom').then(e => console.log(e)).catch(e => console.warn(e)).finally(function () {
        clean()
      })
    } else {
      clean()
    }

    event(_EVENTS.exitRoom)

  }

  ///////  HELPERS //////////

  /*async roomInfo() {
      let info = await socket.request('getMyRoomInfo')
      return info
  }*/


  function event(evt) {
    if (eventListeners.has(evt)) {
      eventListeners.get(evt).forEach(callback => callback())
    }
  }

  /*function on(evt, callback) {
    eventListeners.get(evt).push(callback)
  }*/

  //////// GETTERS ////////

  function isOpen() {
    return _isOpen
  }

  /* FIM CONTEÚDO DO ROOMCLIENT */

  function RoomOpen() {
    setTimeout(function () {
      Produce(mediaType.video, selectVideoSource)
      Produce(mediaType.audio, selectAudioSource)
      Produce(mediaType.continuous)

      // if (vp_plan && (vp_plan === 'gratuito' || vp_plan === 'trial')) {
      //   setShowModalTips(false)
      // } else {
      //   setShowModalTips(true)
      // }

      //pausa o audio contínuo usado para não fechar a conexão de audio (necessário pausar para que o admin possa ficar mutado fora da sala)
      setTimeout(function () {
        pauseProducer(mediaType.continuous)
      }, 2000);

    }, 8000);
  }

  //const principalVideoEl = useRef(null)
  const configAudio = useRef(null)
  const playMicBtn = useRef(null)
  const btn_mode_1 = useRef(null)
  const btn_mode_2 = useRef(null)

  function toggleAudio() {
    if (selectAudioSource === 'dummyAudio') {
      const deviceText = t(`components.PermissionsOverlay.microphoneText`);
      const endText = t(`components.PermissionsOverlay.speakToOthersText`);
      toast.warn(t(`components.PermissionsOverlay.toastDenyAccessCameraOrMic`, { deviceText, endText }), {
				position: toast.POSITION.BOTTOM_CENTER,
				autoClose: 8000,
			});
      return
    }

    if (audioOpen) {
      const paused = pauseProducer(mediaType.audio)
      setAudioOpen(!paused)
    } else {
      const resumed = resumeProducer(mediaType.audio)
      setAudioOpen(resumed)
    }
  }

  function toggleVideo() {
    if (selectVideoSource === 'dummyVideo') {
      const deviceText = t(`components.PermissionsOverlay.cameraText`);
      const endText = t(`components.PermissionsOverlay.beSeenByOthersText`);
      toast.warn(t(`components.PermissionsOverlay.toastDenyAccessCameraOrMic`, { deviceText, endText }), {
				position: toast.POSITION.BOTTOM_CENTER,
				autoClose: 8000,
			});
      return
    }

    if (videoOpen) {
      const paused = pauseProducer(mediaType.video)
      setVideoOpen(!paused)
    } else {
      const resumed = resumeProducer(mediaType.video)
      setVideoOpen(resumed)
    }
  }

  function shareScreen() {
    if (!shareScreenController.shared) {
      Produce(mediaType.screen)
      //Send PLG Event
      mauticPlgEvent('config', 'Compartilhou a tela')
    } else {
      closeProducer(mediaType.screen)
      setShareScreenController({ shared: false, css: `${styles.btn_studio_room} btn-initial-config` })
    }

  }

  function joinRoom() {

    if (!joinRoomController) {
      Swal.fire('Oops!', t(`pages.Studio.permissionToWebAndMic`), 'warning')
      return
    }

    if (!displayName) {
      Swal.fire(t(`pages.Studio.displayNameNullTitle`), t(`pages.Studio.displayNameNullText`), 'warning')
      return
    }

    if (testMicController) {
      stopMic()
    }

    setShowModal(false)
    setBlockUI(true)
    NewJoin()

    //Register on 4chat
    let register = {
      user: `${room_id}_4webadmin`,
      room: room_id
    }

    let socket_id = localStorage.getItem('socket_id')

    if (socket_id && socket_id !== "") {
      let b_data = JSON.parse(localStorage.getItem('broadcast_data'))
      let link_video = b_data ? b_data.link_video : null
      setShowShareVideoModal({ status: false, link: link_video })
      setStartingBroadcast({ status: 'started' })
      streamStatus = true
    }
  }

  async function loadDevices() {
    const enumdevices = await navigator.mediaDevices.enumerateDevices();

    const hasCameras = enumdevices.some(device => device.kind === 'videoinput');
    const hasMicrophones = enumdevices.some(device => device.kind === 'audioinput');

    const audioDevices = enumdevices.filter((device) => device.kind === 'audioinput' && device.label);
    const videoDevices = enumdevices.filter((device) => device.kind === 'videoinput' && device.label);

    if (audioDevices.length === 0) {
      const dummyAudioDevice = {
        deviceId: 'dummyAudio',
        groupId: 'dummyAudio',
        kind: 'audioinput',
        label: hasMicrophones 
          ? 'No permission given' 
          : 'No microphones detected',
        selected: true,
      }

      audioDevices.push(dummyAudioDevice);
    }

    let defaultCamId = null; 
    let defaultStream = null;
    let cameraFailed = false;

    const mediaConstraints = {
      audio: false,
      video: {
        width: { max: 1920, min: 320 },
        height: { max: 1080, min: 200 },
        aspectRatio: { ideal: 1.77778 },
      }
    }

    while (videoDevices.length) {
      try {
        defaultStream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
        defaultCamId = defaultStream.getVideoTracks()[0].getSettings().deviceId;
        cameraFailed = false;
        break;
      } catch (err) {
        if (err.name === 'NotReadableError') {
          cameraFailed = true;
          videoDevices.splice(0, 1);
        }
      }
    }

    if (videoDevices.length === 0) {
      const dummyVideoDevice = {
        deviceId: 'dummyVideo',
        groupId: 'dummyVideo',
        kind: 'videoinput',
        label: cameraFailed 
          ? 'All cameras failed' 
          : hasCameras 
          ? 'No permission given' 
          : 'No cameras detected',
        selected: true
      }

      videoDevices.push(dummyVideoDevice);
      defaultStream = new MediaStream([dummyVideo()]);
      defaultCamId = dummyVideoDevice.deviceId;
    }


    const defaultCamIndex = videoDevices.findIndex(
      (device) => device.deviceId === defaultCamId
    )
    videoDevices[defaultCamIndex].selected = true    

    setAudioSources(audioDevices)
    setVideoSources(videoDevices)
    setJoinRoomController(audioDevices.length > 0 && videoDevices.length > 0 ? true : false)

    loadCam(defaultCamId, defaultStream);
    loadMic(audioDevices[0].deviceId);
  }

  //Load user webcam
  async function loadCam(deviceId = null, stream = null) {
    let srcObject;

    setSelectVideoSource(deviceId)

    //Define initial cam for config
    if (!stream) {
      let mediaConstraints = {
        audio: true,
        video: {
          width: { max: 1920, min: 320 },
          height: { max: 1080, min: 200 },
          aspectRatio: { ideal: 1.77778 },
          deviceId: { exact: deviceId }
        }
      }

      srcObject = await navigator.mediaDevices.getUserMedia(mediaConstraints);
    } else {
      srcObject = stream;
    }

    setSelectedVideoStream(srcObject);
  }

  async function loadMic(deviceId) {
    if (testMicController) {
      stopMic()
    }

    setSelectAudioSource(deviceId)
  }

  async function playMic() {
    if (selectAudioSource === 'dummyAudio') {
      return;
    }

    let audio = configAudio.current
    let btn = playMicBtn.current

    let mediaConstraints
    if (selectAudioSource) {
      mediaConstraints = {
        audio: {
          deviceId: { exact: selectAudioSource },
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 4000
        },
        video: false
      }
    } else {
      mediaConstraints = {
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 4000
        },
        video: false
      }
    }

    let stream = await navigator.mediaDevices.getUserMedia(mediaConstraints)

    //CHANGE CSS BUTTON
    btn.innerHTML = '<i class="fa fa-microphone-slash"></i>Stop'
    btn.classList.remove('btn-outline-primary')
    btn.classList.add('btn-danger')

    //DEFINE MEDIASTREAM
    audio.srcObject = stream
    audio.play()

    //CONTROLLER TEST MIC
    setTestMicController(true)

  }

  function stopMic() {
    let btn = playMicBtn.current
    let audio = configAudio.current

    //CHANGE CSS BUTTON
    btn.innerHTML = '<i class="fa fa-microphone"></i>Test microphone'
    btn.classList.remove('btn-danger')
    btn.classList.add('btn-outline-primary')

    //STOP CAPTURE MIC
    let stream = audio.srcObject;
    let tracks = stream.getTracks();

    tracks.forEach(function (track) {
      track.stop();
    });

    audio.srcObject = null;

    //CONTROLLER TEST MIC
    setTestMicController(false)
  }

  function micController() {
    if (!testMicController) {
      playMic()
    } else {
      stopMic()
    }
  }

  function defineLayoutMode(mode) {
    if (mode === layoutMode) {
      return false
    }

    setLayoutMode(mode)

    let btn = null
    let btn_aux = null
    switch (mode) {
      case 1:
        btn = btn_mode_1.current
        btn_aux = btn_mode_2.current
        btn_aux.classList.remove(styles.btn_studio_layout_pressed)
        break
      case 2:
        btn = btn_mode_2.current
        btn_aux = btn_mode_1.current
        btn_aux.classList.remove(styles.btn_studio_layout_pressed)
        break
      default:
        btn = btn_mode_2.current
        btn_aux = btn_mode_1.current
        btn_aux.classList.remove(styles.btn_studio_layout_pressed)
    }

    btn.classList.add(styles.btn_studio_layout_pressed)

  }

  async function handleViewPlans() {
    if (startingBroadcast.status === 'started') {
      await handleStopStream();
    }

    history.push('/plans');

    return true;
  }

  function showForbiddenAlert(lockType) {
    const actionTexts = {
      stream_limit_day: t(`pages.Studio.forbiddenText1`),
      stream_limit_month: t(`pages.Studio.forbiddenText2`),
      screen_participants: t(`pages.Studio.forbiddenText3`),
      logo_required: t(`pages.Studio.forbiddenText4`),
      custom_logo: t(`pages.Studio.forbiddenText5`),
      custom_background: t(`pages.Studio.forbiddenText6`),
      local_video: t(`pages.Studio.forbiddenText7`),
      custom_videoclip: t(`pages.Studio.forbiddenText8`),
      default: t(`pages.Studio.forbiddenTextDefault`),
    }

    const text = actionTexts[lockType] || actionTexts.default;
    
    Swal.fire({
      icon: 'warning',
      title: t(`pages.Studio.forbiddenAlertTitle`),
      html: text,
      showCancelButton: true,
      confirmButtonText: t(`pages.Studio.forbiddenAlertConfirmButton`),
      cancelButtonText: t(`pages.Studio.forbiddenAlertCancelButton`),
    }).then(async (result) => {
      if (result.isConfirmed) {
        await handleViewPlans();
      } else {
        return false;
      }
    });
  }

  function handleNewChatMessage(message) {
    if (message.room.includes('4webInterno')) {
      const isChatHidden = activeSidebarTab !== 'private-chat';
  
      if (isChatHidden) {
        setUnreadPrivateChatCount(prev => prev + 1);
      } else {
        setUnreadPrivateChatCount(0);
      }
    }
  }

  async function addOnScreen(data) {

    if (onScreen.length > 0 && onScreen.find(streamer => streamer.id === data.id)) {

      if (data.typeVideo === 'screen') {
        qtdOnScreen.screen--
      } else {
        qtdOnScreen.cam--
      }

      let this_streamers
      let this_audio = null
      if (data.typeVideo === 'cam') {
        streamsAudio.forEach((stream) => {
          if (stream.group === data.group) {
            this_audio = stream.id
          }
        })

        this_streamers = [{ producer_id: data.id }, { producer_id: this_audio }]
      } else {

        streamsAudio.forEach((stream) => {
          if (stream.group === data.group) {
            this_audio = stream.id
          }
        })

        if (this_audio) {
          this_streamers = [{ producer_id: data.id }, { producer_id: this_audio }]
        } else {
          this_streamers = [{ producer_id: data.id }]
        }

      }

      let this_data = {
        room: room_id,
        streamers: this_streamers
      }

      if (internalSocket) {
        internalSocket.emit('removeFromScreen', this_data)
      }

      if (streamStatus) {
        socket.emit('noticeGuestOnScreen', {
          guestSocketId: data.group,
          status: 'removeFromScreen'
        })
      }

      if (data.file) {
        data.videoElement.pause();
      }

      await setOnScreen(prevState => prevState.filter(streamer => streamer.id !== data.id))
    } else {
      let this_streamer = streamers.find(streamer => streamer.id === data.id)

      if (client.client_type === '4web') {
        if (this_streamer.typeVideo === 'cam') {
          const onScreenParticipants = onScreen.filter(
            (streamer) => streamer.typeVideo === 'cam'
          ).length;
          
          if (onScreenParticipants >= modules.screen_participants) {
            showForbiddenAlert({ 
              lockType: 'screen_participants',
              broadcastStarted: startingBroadcast.status === 'started'
            });

            return false;
          }
        }
      }

      if (this_streamer.typeVideo === 'screen') {
        qtdOnScreen.screen++
      } else {
        qtdOnScreen.cam++
      }

      this_streamer.pos = this_streamer.type === 'screen' ? qtdOnScreen.screen : qtdOnScreen.cam

      let this_audio = null

      streamsAudio.forEach((stream) => {
        if (stream.group === this_streamer.group) {
          this_audio = stream
        }
      })

      let this_data = null

      if (!this_audio) {
        this_data = {
          room: room_id,
          streamers: [
            {
              producer_id: this_streamer.id,
              display: this_streamer.display,
              type_video: this_streamer.typeVideo,
              group: this_streamer.group
            }
          ]
        }
      } else {
        this_data = {
          room: room_id,
          streamers: [
            {
              producer_id: this_streamer.id,
              display: this_streamer.display,
              type_video: this_streamer.typeVideo,
              group: this_streamer.group
            },
            {
              producer_id: this_audio.id,
              display: this_audio.display,
              type_video: this_audio.typeVideo,
              group: this_audio.group
            }
          ]
        }
      }

      if (internalSocket) {
        internalSocket.emit('addOnScreen', this_data)
      }

      if (streamStatus) {
        socket.emit('noticeGuestOnScreen', {
          guestSocketId: this_streamer.group,
          status: 'addOnScreen'
        })
      }

      if (this_streamer.file) {
        this_streamer.videoElement.play();
      }

      await setOnScreen(prevState =>
        [...prevState, this_streamer]
      )
    }

    return true;
  }

  async function defineBackground() {
    let background = localStorage.getItem('backgroundUrl')
    let bgActive = localStorage.getItem('switchBackground') ? (localStorage.getItem('switchBackground') === 'true') : true

    if (background && bgActive) {
      setBackgroundUrl(background);

      //Send PLG Event
      mauticPlgEvent('config', 'Alterou o Background')
    } else {
      setBackgroundUrl(null);
    }

    if (startingBroadcast.status === 'started') {
      let data = {
        room: room_id,
        url: bgActive ? background : ''
      }

      if (internalSocket && bgActive) {
        internalSocket.emit('updateBackground', data)
      }
    }
  }

  async function defineLogo() {
    let logo = localStorage.getItem('logoUrl')
    let logoActive = localStorage.getItem('switchLogo') ? (localStorage.getItem('switchLogo') === 'true') : true

    if (logo && logoActive) {
      setLogoUrl(logo)

      //Send PLG Event
      mauticPlgEvent('config', 'Alterou a logo')
    } else {
      setLogoUrl(null);
    }

    if (startingBroadcast.status === 'started') {
      let data = {
        room: room_id,
        url: logoActive ? logo : ''
      }

      if (internalSocket && logoActive) {
        internalSocket.emit('updateLogo', data)
      }
    }
  }

  async function defineOverlay() {
    let overlay = localStorage.getItem('overlayUrl')
    let overlayActive = localStorage.getItem('switchOverlay') ? (localStorage.getItem('switchOverlay') === 'true') : true

    if (overlay && overlayActive) {
      setOverlayUrl(overlay);
      
      //Send PLG Event
      mauticPlgEvent('config', 'Alterou o overlay')
    } else {
      setOverlayUrl(null);
    }

    if (startingBroadcast.status === 'started') {
      let data = {
        room: room_id,
        url: overlayActive ? overlay : ''
      }

      if (internalSocket && overlayActive) {
        internalSocket.emit('updateOverlay', data)
      }
    }
  }

  async function switchName() {
    let switchName = localStorage.getItem('switchName') ? (localStorage.getItem('switchName') === 'true') : true

    setShowFooterLayer(switchName)

    /*onScreen.forEach((str, i) => {
      if(str.typeVideo !== 'screen'){
        let footerName = streamers_footer.current[str.id]
        if(switchName){
          footerName.classList.remove('d-none')
        }else{
          footerName.classList.add('d-none')
        }
      }
    })*/

    
      //Send PLG Event
      if(switchName){
        mauticPlgEvent('config', 'Exibiu o nome na transmissão')
      } else {
        mauticPlgEvent('config', 'Escondeu o nome na transmissão')
      }

    let data = {
      room: room_id,
      status: switchName
    }

    if (internalSocket) {
      internalSocket.emit('switchName', data)
    }
  }

  async function handleSwitchOnlyVoice() {
    let thisSwitchOnlyVoice = await localStorage.getItem('switchOnlyVoice') ? (localStorage.getItem('switchOnlyVoice') === 'true') : false

    await setSwitchOnlyVoice(thisSwitchOnlyVoice)

    /*onScreen.forEach((str, i) => {
      if(str.typeVideo !== 'screen'){
        let footerName = streamers_footer.current[str.id]
        if(switchName){
          footerName.classList.remove('d-none')
        }else{
          footerName.classList.add('d-none')
        }
      }
    })*/

    let data = {
      room: room_id,
      status: thisSwitchOnlyVoice
    }

    // if (internalSocket) {
    //   internalSocket.emit('switchOnlyVoice', data)
    // }
  }

  async function transmissionInProgress(data) {
    if (streamStatus && internalSocket) {
      internalSocket.emit('transmissionInProgress', data)
    }
  }

  useEffect(() => {
    oauth2()
    verifyRefreshTokenLocalStorage()
    defineBackground()
    defineLogo()
    defineOverlay()
    //loadDevices()
    getMediaServer(fb_weblive, { room: room_id })
    const disconnectedImagePreload = new Image();
    disconnectedImagePreload.src = disconnectedImage;
  }, [showModalAccounts])

  useEffect(() => {
    if (activeSidebarTab === 'private-chat') {
      setUnreadPrivateChatCount(0);
    }
  }, [activeSidebarTab]);

  const { downlink, ms } = useInternetQuality()

  useEffect(() => {
    if (notShowQualityConnection) {
      return
    }

    if (!firstRender) {

      if (downlink === 0 && ms === 0) {
        if (messageQualityConnection === 'No connection') return;

        setColorConnection('#575656')
        setMessageQualityConnection('No connection')

        toast.error(t(`pages.Studio.toastWithoutInternet`), {
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          position: 'bottom-center',
          className: 'no-connection-toast',
          autoClose: 5000
        })

      } else if (ms <= 150) {
        if (messageQualityConnection === 'Stable') return;

        setColorConnection('#36ce59')
        setMessageQualityConnection('Stable')

        toast.success(t(`pages.Studio.toastGoodInternet`), {
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          position: 'bottom-center',
          className: 'good-connection-toast',
          autoClose: 3000
        })
      } else if (ms > 150 && ms < 300) {
        if (messageQualityConnection === 'Weak') return;

        setColorConnection('#ebb134')
        setMessageQualityConnection('Weak')
        toast.warning(t(`pages.Studio.toastWeakInternet`), {
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          position: 'bottom-center',
          autoClose: 3000
        })
      } else if (ms >= 300) {
        if (messageQualityConnection === 'Bad') return;

        setColorConnection('#eb3434')
        setMessageQualityConnection('Bad')
        toast.error(t(`pages.Studio.toastBadInternet`), {
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          position: 'bottom-center',
          autoClose: 3000
        })
      }

      return
    }

    setFirstRender(false)
  }, [downlink, ms, firstRender, messageQualityConnection, notShowQualityConnection, t])

  function handleRemoveStream(event, stream) {
    event.stopPropagation();

    let title, description;

    if (stream.file) {
      title = t(`pages.Studio.removeFileTitle`)
      description = t(`pages.Studio.removeFileText`)
    } else {
      title = t(`pages.Studio.removeGuestTitle`)
      description = t(`pages.Studio.removeGuestText`)
    }

    Swal.fire({
      icon: 'warning',
      title: `<p>${title}</p>`,
      html: description,
      showCancelButton: true,
      confirmButtonText: t(`pages.Studio.buttonConfirmText`),
      cancelButtonText: t(`pages.Studio.buttonCancelText`),
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (stream.file) {
          closeProducer(mediaType.file, stream.id);
        } else {
          const guestSocketId = stream.group;
          socket.emit('removeGuestFromRoom', guestSocketId);
        }
      } else {
        return false;
      }
    });
  }

  function handleTypeBroadcastTitle(event) {
    const title = event.target.value;
    if (title) {
      setBroadcastTitleError(false);
    }
    setBroadcastTitle(title);
  }

  function handleSelectPrivacy(event) {
    const privacy = event.target.value;
    if (privacy) {
      setBroadcastPrivacyError(false);
    }
    setBroadcastPrivacy(privacy);
  }

  async function handleShareVideoFile() {
    if (client.client_type === '4web' && !modules.local_video) {
      showForbiddenAlert('local_video')
      return;
    }

    const sharedVideoFiles = streamers.filter(stream => stream.file);

    if (sharedVideoFiles.length >= 2) {
      const result = await Swal.fire({
        icon: 'warning',
        title: t(`pages.Studio.swalShareVideoFileTitle`),
        html: t(`pages.Studio.swalShareVideoFileText`),
        showCancelButton: true,
        confirmButtonText: t(`pages.Studio.swalShareVideoFileConfirmButton`),
        cancelButtonText: t(`pages.Studio.swalShareVideoFileCancelButton`),
      });

      if (!result.isConfirmed) {
        return;
      }
    }

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'video/*';
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const fileURL = URL.createObjectURL(file);
      const [filename] = file.name.split(/\.[^.]+$/g);
      Produce(mediaType.file, { fileURL, filename });
    }

    //Send PLG Event
    mauticPlgEvent('config', 'Adicionou um local video')
  }

  function checkIfBigScreenNeedsToAutoAdjust(source) {
    if (bigScreenAutoAdjustDelayID) {
      clearTimeout(bigScreenAutoAdjustDelayID);
    }

    const qtdCams = onScreen.filter(stream => stream.typeVideo === 'cam').length
    const qtdScreens = onScreen.filter(stream => stream.typeVideo === 'screen').length
    
    if (qtdCams === 0 && qtdScreens === 0) {
      return;
    }

    if (
      !['solo', 'presentation', 'group', 'fullscreen'].includes(bigScreenLayout)
    ) {
      return;
    }
  
    let newLayout;

    if (qtdCams > 0 && qtdScreens > 0) {
      newLayout = 'presentation';
    } else if (qtdCams === 1) {
      newLayout = 'solo';
    } else if (qtdCams > 1) {
      newLayout = 'group';
    } else {
      newLayout = 'fullscreen';
    }

    if (source === 'user') {
      if (bigScreenLayout === 'group' && qtdCams === 1 && qtdScreens === 0) {
        newLayout = 'solo'
      } else if (bigScreenLayout === 'presentation' && qtdCams === 1 && qtdScreens === 0) {
        newLayout = 'solo'
      } else if (bigScreenLayout === 'presentation' && qtdCams === 0 && qtdScreens > 0) {
        newLayout = 'fullscreen'
      } else if (bigScreenLayout === 'presentation' && qtdCams > 1 && qtdScreens === 0) {
        newLayout = 'group'
      } else {
        return
      }
    }

    let delay = source === 'system' ? 0 : 500;

    if (bigScreenLayout === newLayout) {
      return
    }

    bigScreenAutoAdjustDelayID = setTimeout(() => {
      bigScreenAutoAdjustDelayID = null;
      updateBigScreenLayout(newLayout);
    }, delay);
  }

  function updateBigScreenLayout(newLayout) {
    const oldLayout = bigScreenLayout;

    setBigScreenLayout(newLayout);

    //Send PLG Event
    mauticPlgEvent('config', 'Alterou o layout do telão')

    if (newLayout !== oldLayout && internalSocket) {
      internalSocket.emit('layoutChange', {
        room: room_id,
        layout: newLayout
      });
    }
  }

  function handleOnForbidden(lockType) {
    showForbiddenAlert({
      lockType,
      broadcastStarted: startingBroadcast.status === 'started'
    })
  }

  useEffect(() => {
    api.put('/api/configs', {
      client_id,
      room_id,
      name: 'guest-request',
      value: false
    }).then((response) => {
      setIsGuestRequest(JSON.parse(response.data.data.value))
    }).catch((err) => {
      console.error(err)
    })
  }, [])

  async function handleSwitchGuestRequest() {
    const newValueGuestRequest = !isGuestRequest

    api.put('/api/configs', {
      client_id,
      room_id,
      name: 'guest-request',
      value: newValueGuestRequest
    }).then((response) => {
      setIsGuestRequest(JSON.parse(response.data.data.value))
    }).catch((err) => {
      console.error(err)
    })
  }

  useEffect(() => {
    if (account4web) {
      setBroadcastPrivacy('unlisted');
    }
  }, [account4web])

  useEffect(() => {
    checkIfBigScreenNeedsToAutoAdjust('system');
  }, [onScreen]);

  useEffect(() => {
    checkIfBigScreenNeedsToAutoAdjust('user');
  }, [bigScreenLayout]);

  // automatically add/remove marked streamers to/from big screen
  useEffect(() => {
    const streamsToAddOrRemove = streamers.filter(
      (stream) => stream.addOnScreen || stream.removeFromScreen
    );

    streamsToAddOrRemove.forEach((stream) => {
      const isStreamOnScreen = onScreen.some(item => item.id === stream.id);
      const removeStreamFromScreen = stream.removeFromScreen;
      const addStreamOnScreen = stream.addOnScreen;

      const newStream = {
        ...stream,
        addOnScreen: false,
        removeFromScreen: false,
      };

      setStreamers((prevState) => {
        const newState = [...prevState];
        const index = prevState.findIndex((item) => item.id === stream.id);
        newState[index] = newStream;
        return newState;
      });

      if (
        (isStreamOnScreen && removeStreamFromScreen) ||
        (!isStreamOnScreen && addStreamOnScreen)
      ) {
        addOnScreen(stream);
      }
    });
  }, [streamers]);

  useEffect(() => {
    if (startingBroadcast.status === 'started' && internalSocket) {
      updateRenderBigscreen();
    }
  }, [startingBroadcast]);

  useEffect(() => {
    api.get(`api/destinations/${external_room}`).then(response => {
      if (response.data.message === "Destination not found") {
        setRoomWithoutDestination(true);
        setShowModal(true);
        return;
      }
      setDestination(response.data.destination)

      if (response.data.destination.type_account === 'youtube') {
        setBroadcastPrivacy(response.data.room.privacy);
        return
      } else if (response.data.destination.type_account === 'twitch') {
        setTwitchCategory(response.data.room.twitchCategory);
        return
      }
    }).catch(err => {
      console.error(err)
    })
  }, [external_room])

  useEffect(() => {
    async function generateGuestLink() {
      try {  
        const response = await axios.post('https://weblive-api.4.events:3016/internal-link-sso-guest', {
          room_id: client.room._id,
          client_meeting: client.client_meeting,
          meeting_type: client.meeting_type,
        }, {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_SERVER_API_KEY}` 
          }
        })
        
        const guestLink = `${window.location.origin}/meet/${response.data.short_id}`;
        setGuestLink(guestLink);
      } catch (err) {
        console.log(err);
      }
    }

    generateGuestLink();
  }, [client.room._id, client.client_meeting, client.meeting_type]);

  useEffect(() => {
    const buttonTexts = [
      { text: t(`pages.Studio.liveButtonStartingText1`), seconds: 0 },
      { text: t(`pages.Studio.liveButtonStartingText2`), seconds: 10 },
      { text: t(`pages.Studio.liveButtonStartingText3`), seconds: 20 },
      { text: t(`pages.Studio.liveButtonStartingText4`), seconds: 30 },
      { text: t(`pages.Studio.liveButtonStartingText5`), seconds: 40 },
      { text: t(`pages.Studio.liveButtonStartingText6`), seconds: 60 },
      { text: t(`pages.Studio.liveButtonStartingText7`), seconds: 120 },
      { text: t(`pages.Studio.liveButtonStartingText8`), seconds: 180 },
      { text: t(`pages.Studio.liveButtonStartingText9`), seconds: 250 },
      { text: t(`pages.Studio.liveButtonStartingText10`), seconds: 300 },
      { text: t(`pages.Studio.liveButtonStartingText11`), seconds: 350 },
      { text: t(`pages.Studio.liveButtonStartingText12`), seconds: 400 },
    ];
    
    if (loadingConnectYoutube) {
      const timeouts = buttonTexts.map((textData) => {
        return setTimeout(() => {
          setTextBtnLiveStarting(textData.text);
        }, textData.seconds * 1000);
      });

      return () => {
        timeouts.forEach((timeout) => {
          clearTimeout(timeout);
        });
      }
    } else {
      setTextBtnLiveStarting(buttonTexts[0].text);
    }
  }, [loadingConnectYoutube, t])

  const labelAccount = {
    twitch: {
      backgroundColor: "#9146FF",
      icon: <FaTwitch size={12} color="#fff" />,
    },
    custom_rtmp: {
      backgroundColor: "#ffae00",
      icon: <FaListAlt size={12} color="#2d2d2d" />,
    },
    youtube: {
      backgroundColor: "#FF0000",
      icon: <FaYoutube size={12} color="#fff" />,
    },
  };

  function handleClickAccount(account) {
    if (selectedAccount._id === account._id) {
      setSelectedAccount({});
    } else {
      setSelectedAccount(account);
      if (account.type_account === "twitch") {
        setBroadcastPrivacy("");
        return;
      }
    }
  }

  function handleConnectAccountToRoom() {
    if (!selectedAccount._id) {
      return toast.error(t(`pages.Studio.toastSelectAnAccountText`), {
        position: "bottom-center",
        autoClose: 3000,
      })
    }

    if (selectedAccount.type_account === "youtube" && !broadcastPrivacy) {
      return toast.error(t(`pages.Studio.toastSelectPrivacyText`), {
        position: "bottom-center",
        autoClose: 3000,
      })
    }

    if (selectedAccount.type_account === "twitch" && !twitchCategory) {
      return toast.error(t(`pages.Studio.toastSelectCategoryText`), {
        position: "bottom-center",
        autoClose: 3000,
      })
    }

    Swal.fire({
      icon: "question",
      html: t(`pages.Studio.swalConnectAccountToRoomText`),
      showCancelButton: true,
      confirmButtonText: t(`pages.Studio.buttonConfirmText`),
      cancelButtonText: t(`pages.Studio.buttonCancelText`)
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.put("api/connect-room-account", {
          destination_id: selectedAccount._id,
          room_id: external_room,
          privacy: broadcastPrivacy,
          twitchCategory,
        });

        if (response.status === 200) {
          api.get(`api/destinations/${external_room}`).then(response => {
            if (response.data.message === "Destination not found") {
              setRoomWithoutDestination(true);
              setShowModal(true);
              return;
            }
            setDestination(response.data.destination)
            toast.success('Room connected to a destination', {
              pauseOnFocusLoss: false,
              pauseOnHover: false,
              position: 'bottom-center',
              autoClose: 3000
            })
            if (response.data.destination.type_account === 'youtube') {
              setBroadcastPrivacy(response.data.room.privacy);
              setRoomWithoutDestinationModal(false);
              setRoomWithoutDestination(false);
              return
            } else if (response.data.destination.type_account === 'twitch') {
              setTwitchCategory(response.data.room.twitchCategory);
              setRoomWithoutDestinationModal(false);
              setRoomWithoutDestination(false);
              return
            }
          }).catch(err => {
            console.error(err);
          });
        } else {
          return toast.error(t(`pages.Studio.toastErrorToConnectAccount`));
        }
      } else {
        return false;
      }
    });
  }
  
  function handleSelectTwitchCategory(event) {
    const category = event.target.value;

    setTwitchCategory(category);
  }

  function handleBannerChange(banner) {
    const bannerText = banner?.text ?? null;
    const bannerTitle = banner?.title ?? null;

    // setBanner(bannerText);
    const bannerData = { title: bannerTitle, text: bannerText }
    
    setBanner(bannerData);
    
    //Send PLG Event
    if(bannerText) {
      mauticPlgEvent('config', 'Adicionou um banner ao telão')
    }

    if (startingBroadcast.status === 'started') {
      let data = {
        room: room_id,
        banner: bannerData
      }

      if (internalSocket) {
        internalSocket.emit('updateBanner', data)
      }
    }
  }

  function handlePlayVideoclip(url) {
    const newUrl = videoclipUrl !== url ? url : null;
    
    setVideoclipUrl(newUrl);
    
    //Send PLG Event
    if(url) {
      mauticPlgEvent('config', 'Adicionou um videoclip ao telão')
    }

    if (startingBroadcast.status === 'started') {
      let data = {
        room: room_id,
        videoclip: newUrl
      }

      if (internalSocket) {
        internalSocket.emit('playVideoclip', data)
      }
    }
  }

  function handleVideoclipEnded() {
    setVideoclipUrl(null);
  }

  function handleBannerQuestionChange(questionData) {
    const questionText = questionData?.question ? questionData.question !== banner.title ? questionData.question : null :  null;
    const questionName = questionData?.from_name ? questionData.from_name !== banner.text ? questionData.from_name : null :  null;

    const bannerData = { title: questionText, text: questionName }
    
    setBanner(bannerData)
      
    //Send PLG Event
    mauticPlgEvent('config', 'Adicionou um banner de pergunta ao telão')

    if (startingBroadcast.status === 'started') {
      let data = {
        room: room_id,
        banner: bannerData
      }

      if (internalSocket) {
        internalSocket.emit('updateBanner', data)
      }
    }
  }

  function handleBannerMessageChange(messageData) {
    const messageText = messageData?.text ? messageData.text !== banner.title ? messageData.text : null :  null;
    const messageName = messageData?.display ? messageData.display !== banner.text ? messageData.display : null :  null;
    
    const bannerData = { title: messageText, text: messageName }
    
    setBanner(bannerData)

    if (startingBroadcast.status === 'started') {
      let data = {
        room: room_id,
        banner: bannerData
      }

      if (internalSocket) {
        internalSocket.emit('updateBanner', data)
      }
    }
  }

  useEffect(() => {
    setShowModal(true);
  }, [])
  
  async function cleanChatFirebase() {
    if (!room_id) {
      return;
    }

    const result = await Swal.fire({
      icon: 'warning',
      title: t(`pages.Studio.clearChatText`),
      html: t(`pages.Studio.swalClearChatText`),
      showCancelButton: true,
      confirmButtonText: t(`pages.Studio.swalShareVideoFileConfirmButton`),
      cancelButtonText: t(`pages.Studio.swalShareVideoFileCancelButton`),
    });

    if (result.isConfirmed) {
      const room = functions.setRoom('interno', room_id)
      dbFirebase.ref(`chat_messages_${room}`).remove()
      return;
    } else {
      return;
    }
  }

  function handleSelectBgSong(songData) {
    if (currentBgSound?.id !== songData.id) {
      bgSongRef.current.src = songData.src;
      bgSongRef.current.play();
      setCurrentBgSound(songData);
    } else {
      if (bgSongRef.current.paused) {
        bgSongRef.current.play();
      } else {
        bgSongRef.current.pause();
      }
    }

    let data = {
      room: room_id,
      bgSong: {
        song_id: songData.id,
        loop: bgSongRef.current.loop,
        volume: bgSongRef.current.volume,
        paused: bgSongRef.current.paused,
        muted: bgSongRef.current.muted
      }
    };

    if (internalSocket) {
      internalSocket.emit('updateBgSong', data)
    }
  }


  function handleSoundStateChange() {
    let data = {
      room: room_id,
      bgSong: {
        song_id: currentBgSound?.id,
        loop: bgSongRef.current.loop,
        volume: bgSongRef.current.volume,
        paused: bgSongRef.current.paused,
        muted: bgSongRef.current.muted
      }
    }

    if (internalSocket) {
      internalSocket.emit('updateBgSong', data)
    }
  }

  function handleRefreshPage() {
    window.location.reload();
  }

  function handleTabChange(newTab) {
    setActiveSidebarTab(prevTab => newTab === prevTab ? null : newTab);
  }

  return (

    <>
      <BlockUI blocking={blockUI} title={t(`pages.Studio.blockUIEnteringRoom`)} />

      <Modal show={showModal} backdrop="static" keyboard={false} contentClassName="overflow-hidden">
        <PermissionsOverlay onShouldLoadDevices={loadDevices} />

        <Modal.Header>
          <Modal.Title className="col-12 font-weight-bold text-center" style={{ lineHeight: 1.2 }}>
            {t(`pages.Studio.modalDataTitle`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className="font-md text-center d-block">
            {t(`pages.Studio.whatsYourNameText`)}
          </span>
          <input
            type="text"
            className="form-control mb-0 mt-2 font-md"
            placeholder={t(`pages.Studio.modalPlaceholderName`)}
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                joinRoom()
              }
            }}
          />
          <span className="text-center mt-3 d-block font-md"><b>{t(`pages.Studio.modalTipText`)}: </b>{t(`pages.Studio.modalTipDescription`)}</span>

          <div className={`row my-3 ${styles.container_select_devices}`}>
            <div className={`col-6 ${styles.container_select_devices_col} align-self-center`}>
              <div className="video-wrapper">
                <Video srcObject={selectedVideoStream} aspectRatio muted />
                <audio ref={configAudio} id="config-room-audio"></audio>
              </div>
            </div>
            <div className={`col-6 ${styles.container_select_devices_col}`}>
              <span className="ml-1">
                {t(`pages.Studio.modalSelectCameraText`)}
              </span>
              <select onChange={(e) => loadCam(e.target.value)} className="form-control form-control-sm text-center">
                {videoSources.map((source) => (
                  <option key={source.deviceId} value={source.deviceId} selected={source.selected}>{source.label}</option>
                ))}
              </select>
              <span className="ml-1">{t(`pages.Studio.modalSelectMicText`)}</span>
              <select onChange={(e) => loadMic(e.target.value)} className="form-control form-control-sm text-center">
                {audioSources.map((source) => (
                  <option key={source.deviceId} value={source.deviceId} selected={source.selected}>{source.label}</option>
                ))}
              </select>
              <button ref={playMicBtn} onClick={() => micController()} id="btn-test-mic" className="btn btn-outline-primary text-center col-12 mt-2" type="play"><i className="fa fa-microphone mr-2"></i><span className="txt-test-mic">{t(`pages.Studio.testMicText`)}</span></button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button className="btn btn-primary" onClick={() => joinRoom()}>
            <i className="fa fa-check"></i>{t(`pages.Studio.modalEnterRoomButton`)}
          </button>
        </Modal.Footer>
      </Modal>
      {/* <Modal show={showModalGoogle} backdrop="static" keyboard={false} onHide={handleCloseModalGoogle}>
        <Modal.Header closeButton={client.client_type === '4web' ? false : true}>
          <Modal.Title className=" font-weight-bold ">{t(`pages.Studio.modalGoogleTitle`)}</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <div className="col-12">
            <p>{t(`pages.Studio.modalGoogleDescription`)}</p>
            <p>{t(`pages.Studio.modalGoogleLink`)} <b><a href='https://4.events' target='_blank'>4.events</a></b></p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <YoutubeAuth
            text={t(`pages.Studio.modalGoogleButtonYoutubeText`)}
          />
        </Modal.Footer>
      </Modal> */}

      <Modal show={showModalAccount} backdrop="static" keyboard={false}>
        <Modal.Header >
          <Modal.Title className="col-12 font-weight-bold text-center ">{t(`pages.Studio.modalAddAccountTitle`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 d-flex justify-content-center align-items-center flex-column ">
            <input
              placeholder={t(`pages.Studio.modalAddAccountPlaceholderBroadcastTitle`)}
              className="form-control mt-3"
              type="text"
              value={broadcastTitle}
              onChange={handleTypeBroadcastTitle}
            />
            {broadcastTitleError && (
              <small className="text-danger">{t(`pages.Studio.modalAddAccountErrorTitle`)}</small>
            )}

            <h5 className="mt-3">{t(`pages.Studio.modalAddAccountDescription`)}</h5>
            <p>{t(`pages.Studio.modalAddAccountSubDescription`)}</p>
            <div className="p-2">
              <label className={`${styles.switch}`}>
                <input type="checkbox" onChange={handleSwitchAccount} checked={account4web} />
                <span className={`${styles.slider} ${styles.round}`}></span>
              </label>
            </div>
            {/* {showBtnAuthGoogle && (
              // <a style={{ backgroundColor: '#dfdfdf', color: '#696969' }} className="btn d-flex align-items-center" href={urlAuthGoogle}>
              //   <img className="mr-2" width='25' height='25' src={iconGoogle} /> <b>Log in with google</b>
              // </a>
              <div style={{marginBottom: '10px'}}>
                <YoutubeAuth text={t(`pages.Studio.modalGoogleButtonYoutubeText`)} />
              </div>
            )} */}
            {/* {client.client_type !== '4web' && ( */}
              <div className='text-center' style={{ width: '100%' }}>
                {!account4web && (
                  accounts.length > 0 ? (
                    <>
                      <span>{t('pages.Studio.roomWithoutDestinationDesc')}</span>
                      <div className="accounts_container mt-3 mb-3">
                        {accounts.map((account) => (
                          <OverlayTrigger
                            key={account._id}
                            placement="bottom"
                            overlay={<Tooltip>{account.display_name}</Tooltip>}
                          >
                            <div
                              className={`account_container user-img img-fluid`}
                              onClick={() => handleClickAccount(account)}
                            >
                              <img
                                src={account.type_account === 'custom_rtmp' ? customRtmpImg : account.avatar_url}
                                alt={account.display_name || ''}
                                onMouseEnter={() =>
                                  setHoverImageAccount({
                                    status: true,
                                    id: account._id,
                                  })
                                }
                                onMouseLeave={() =>
                                  setHoverImageAccount({
                                    status: false,
                                    id: account._id,
                                  })
                                }
                                style={{
                                  border:
                                    hoverImageAccount.id === account._id &&
                                      hoverImageAccount.status
                                      ? `2px solid ${labelAccount[account.type_account][
                                      "backgroundColor"
                                      ]
                                      }`
                                      : selectedAccount._id === account._id &&
                                      `2px solid ${labelAccount[account.type_account][
                                      "backgroundColor"
                                      ]
                                      }`,
                                }}
                              />
                              {account.type_account !== 'custom_rtmp' && (
                                <div
                                  className="icon"
                                  style={{
                                    background:
                                      labelAccount[account.type_account]["backgroundColor"],
                                  }}
                                >
                                  {labelAccount[account.type_account]["icon"]}
                                </div>
                              )}
                            </div>
                          </OverlayTrigger>
                        ))}
                      </div>
                      {selectedAccount.type_account === 'youtube' && (
                        <select
                          className="form-control mt-2 mt-3 mb-3"
                          name="privacy"
                          id="privacy"
                          value={broadcastPrivacy}
                          onChange={handleSelectPrivacy}
                          defaultValue=""
                        >
                          <option value="" disabled>
                            {t(`pages.NewRoom.optionDisabledTypeAccount`)}
                          </option>
                          <option value="public">{t(`pages.NewRoom.optionPublicTypeAccount`)}</option>
                          <option value="private">{t(`pages.NewRoom.optionPrivateTypeAccount`)}</option>
                          <option value="unlisted">{t(`pages.NewRoom.optionUnlistedTypeAccount`)}</option>
                        </select>
                      )}
                      {selectedAccount.type_account === 'twitch' && (
                        <select
                          className="form-control mt-2 mt-3 mb-3"
                          name="twitchCategory"
                          id="twitchCategory"
                          value={twitchCategory}
                          onChange={handleSelectTwitchCategory}
                          defaultValue=""
                        >
                          <option value="" disabled>
                            {t(`pages.NewRoom.optionDisabledTwitchCategory`)}
                          </option>
                          <option value={'Talk Shows & Podcasts'}>{t('pages.NewRoom.optionTalkShowsTwitchCategory')}</option>
                          <option value="Just Chatting">{t(`pages.NewRoom.optionJustChattingTwitchCategory`)}</option>
                          <option value={'Fitness & Health'}>{t('pages.NewRoom.optionFitnessHealthTwitchCategory')}</option>
                          <option value="Art">{t('pages.NewRoom.optionArtTwitchCategory')}</option>
                          <option value={'Food & Drink'}>{t('pages.NewRoom.optionFoodDrinkTwitchCategory')}</option>
                          <option value="Politics">{t('pages.NewRoom.optionPoliticsTwitchCategory')}</option>
                          <option value={'Science & Technology'}>{t('pages.NewRoom.optionScienceTechTwitchCategory')}</option>
                          <option value="League of Legends">League of Legends</option>
                          <option value="Valorant">Valorant</option>
                          <option value="Counter-Strike: Global Offensive">Counter-Strike: Global Offensive</option>
                          <option value="Grand Theft Auto V">Grand Theft Auto V</option>
                        </select>
                      )}
                      {/* <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        borderTop: '1px solid #DEE2E6'
                      }}
                      >
                        <button
                          className="btn btn-primary mt-3"
                          type="button"
                          onClick={handleConnectAccountToRoom}
                        >
                          {t('pages.Studio.connectText')}
                        </button>
                      </div> */}
                    </>
                  ) : (
                    <Link to="/destinations">
                      <span>{t('pages.Studio.needADestinationText')}</span>
                    </Link>
                  )
                )}

                {/* <select
                  className="form-control mt-2"
                  name="privacy"
                  id="privacy"
                  value={broadcastPrivacy}
                  onChange={handleSelectPrivacy}
                  defaultValue=""
                >
                  {account4web ? (
                    <option value="unlisted" selected>{t(`pages.NewRoom.optionUnlistedTypeAccount`)}</option>
                  ) : (
                    <>
                      <option value="" disabled>{t(`pages.NewRoom.optionDisabledTypeAccount`)}</option>
                      <option value="public">{t(`pages.NewRoom.optionPublicTypeAccount`)}</option>
                      <option value="private">{t(`pages.NewRoom.optionPrivateTypeAccount`)}</option>
                      <option value="unlisted">{t(`pages.NewRoom.optionUnlistedTypeAccount`)}</option>
                    </>
                  )}
                </select>
                {broadcastPrivacyError && (
                  <small className="text-danger">{t(`pages.Studio.modalAddAccountErrorPrivacy`)}</small>
                )} */}
              </div>
            {/* )} */}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">

          <button className='btn btn-primary' disabled={!account4web && !selectedAccount._id} onClick={handleStartStream}><i className="fas fa-broadcast-tower mr-2"></i>{t(`pages.Studio.startText`)}</button>
          <button className="btn btn-secondary" onClick={() => setShowModalAccount(false)}>{t(`pages.Studio.cancelText`)}</button>
        </Modal.Footer>
      </Modal>

      <Modal show={showInviteModal} onHide={() => setShowInviteModal(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title className="font-weight-bold">{t(`pages.Studio.modalInviteTitle`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className="text-center mt-2 mb-3 d-block font-md">{t(`pages.Studio.modalInviteDescription`)}</span>
          <LinkShare link={guestLink} type='guestLink' />
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 d-flex flex-column align-items-center">
            <span>{t(`pages.Studio.modalInviteText`)}</span>
            <label className={`${styles.switch} mt-2`}>
              <input type="checkbox" onChange={handleSwitchGuestRequest} checked={isGuestRequest} />
              <span className={`${styles.slider} ${styles.round}`}></span>
            </label>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={isDisconnected} size="lg" backdrop="static" centered>
        <Modal.Body>
          <div className={styles.disconnected_modal_container}>
            <img src={disconnectedImage} alt="disconnected" />
            <h2>{t(`pages.Studio.disconnectedTitle`)}</h2>
            <p>{t(`pages.Studio.disconnectedText`)}</p>
            {startingBroadcast.status === 'started' && (
              <span className="text-danger">{t(`pages.Studio.disconnectedTransmission`)}</span>
            )}
            <button 
              type="button"
              className="btn btn-primary mt-4"
              onClick={handleRefreshPage}
            >
              <i className="fas fa-redo mr-2"></i>
              {t(`pages.Studio.refreshPage`)}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showShareVideoModal.status} onHide={() => setShowShareVideoModal({ status: false, link: showShareVideoModal.link })}>
        <Modal.Header>
          {client.client_type !== '4web' ? (
            <Modal.Title className="col-12 font-weight-bold text-center">Sua transmissão iniciou!</Modal.Title>
          ) : (
            <Modal.Title className="font-weight-bold">Video link</Modal.Title>
          )}

        </Modal.Header>
        <Modal.Body>

          {client.client_type === '4web' && (
            <span className="text-center mt-2 mb-3 d-block font-md">{t(`pages.Studio.linkOfBroadcastText`)}</span>
          )}

          <LinkShare link={showShareVideoModal.link} type='videoLink' />

          {client.client_type !== '4web' && (
            <>
              <span className={`text-center mt-3 d-block font-md ${styles.span_danger}`}>ATENÇÃO</span>
              <span className="text-center mt-2 mb-2 d-block font-md">Copie o link acima e cole nos detalhes de sua sala na 4.events para que a transmissão inicie aos participantes, caso contrário a transmissão poderá não iniciar a eles.</span>
              <span className="text-center mb-2 d-block">Veja como:</span>
              <div className={`${styles.tutorial_link_4events}`}></div>
              <button className="col-12 mt-3 text-center btn btn-primary" onClick={() => setShowShareVideoModal({ status: false, link: showShareVideoModal.link })}>
                <i className="fa fa-check"></i>Entendi!
              </button>
            </>
          )}

        </Modal.Body>
      </Modal>

      <Modal show={showModalTips} onHide={() => setShowModalTips(false)} size="lg" centered>
        {/* <Modal.Header className="py-2" closeButton>
          <Modal.Title className=" font-weight-bold ">{t('pages.Studio.modalTipsTitle')}</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="col-12">
            <b className="mb-2 d-block">{t('pages.Studio.modalTips')}</b>
            <p className="mb-2">{t('pages.Studio.modalTip1')}</p>
            <p className="mb-2">{t('pages.Studio.modalTip2')}</p>
            <p className="mb-2">{t('pages.Studio.modalTip3')}</p>
            <p className="mb-2">{t('pages.Studio.modalTip4')}</p>
            <p className="mb-2">{t('pages.Studio.modalTip5')}</p>
            <p className="mb-2">{t('pages.Studio.modalTip6')}</p>
            <p className="mb-2">{t('pages.Studio.modalTip7')}</p>
            <p className="mb-2">{t('pages.Studio.modalTip8')}</p>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showModalAcceptGuest} onHide={onRejectNewGuest} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className=" font-weight-bold ">{t('pages.Studio.modalRequestAccessTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>{newGuestName}</strong> {t('pages.Studio.modalRequestAccessSpan')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onAcceptNewGuest}>
            {t('pages.Studio.modalRequestAccessAcceptButton')}
          </Button>
          <Button variant="secondary" onClick={onRejectNewGuest}>
            {t('pages.Studio.modalRequestAccessRefuseButton')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={roomWithoutDestinationModal} onHide={() => setRoomWithoutDestinationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title className=" font-weight-bold">{t('pages.Studio.roomWithoutDestination')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {accounts.length > 0 ? (
            <>
              <span>{t('pages.Studio.roomWithoutDestinationDesc')}</span>
              <div className="accounts_container mt-3 mb-3">
                {accounts.map((account) => (
                  <OverlayTrigger
                    key={account._id}
                    placement="bottom"
                    overlay={<Tooltip>{account.display_name}</Tooltip>}
                  >
                    <div
                      className={`account_container user-img img-fluid`}
                      onClick={() => handleClickAccount(account)}
                    >
                      <img
                        src={account.type_account === 'custom_rtmp' ? customRtmpImg : account.avatar_url}
                        alt={account.display_name || ''}
                        onMouseEnter={() =>
                          setHoverImageAccount({
                            status: true,
                            id: account._id,
                          })
                        }
                        onMouseLeave={() =>
                          setHoverImageAccount({
                            status: false,
                            id: account._id,
                          })
                        }
                        style={{
                          border:
                            hoverImageAccount.id === account._id &&
                              hoverImageAccount.status
                              ? `2px solid ${labelAccount[account.type_account][
                              "backgroundColor"
                              ]
                              }`
                              : selectedAccount._id === account._id &&
                              `2px solid ${labelAccount[account.type_account][
                              "backgroundColor"
                              ]
                              }`,
                        }}
                      />
                      {account.type_account !== 'custom_rtmp' && (
                        <div
                          className="icon"
                          style={{
                            background:
                              labelAccount[account.type_account]["backgroundColor"],
                          }}
                        >
                          {labelAccount[account.type_account]["icon"]}
                        </div>
                      )}
                    </div>
                  </OverlayTrigger>
                ))}
              </div>
              {selectedAccount.type_account === 'youtube' && (
                <select
                  className="form-control mt-2 mt-3 mb-3"
                  name="privacy"
                  id="privacy"
                  value={broadcastPrivacy}
                  onChange={handleSelectPrivacy}
                  defaultValue=""
                >
                  <option value="" disabled>
                    {t(`pages.NewRoom.optionDisabledTypeAccount`)}
                  </option>
                  <option value="public">{t(`pages.NewRoom.optionPublicTypeAccount`)}</option>
                  <option value="private">{t(`pages.NewRoom.optionPrivateTypeAccount`)}</option>
                  <option value="unlisted">{t(`pages.NewRoom.optionUnlistedTypeAccount`)}</option>
                </select>
              )}
              {selectedAccount.type_account === 'twitch' && (
                <select
                  className="form-control mt-2 mt-3 mb-3"
                  name="twitchCategory"
                  id="twitchCategory"
                  value={twitchCategory}
                  onChange={handleSelectTwitchCategory}
                  defaultValue=""
                >
                  <option value="" disabled>
                    {t(`pages.NewRoom.optionDisabledTwitchCategory`)}
                  </option>
                  <option value={'Talk Shows & Podcasts'}>{t('pages.NewRoom.optionTalkShowsTwitchCategory')}</option>
                  <option value="Just Chatting">{t(`pages.NewRoom.optionJustChattingTwitchCategory`)}</option>
                  <option value={'Fitness & Health'}>{t('pages.NewRoom.optionFitnessHealthTwitchCategory')}</option>
                  <option value="Art">{t('pages.NewRoom.optionArtTwitchCategory')}</option>
                  <option value={'Food & Drink'}>{t('pages.NewRoom.optionFoodDrinkTwitchCategory')}</option>
                  <option value="Politics">{t('pages.NewRoom.optionPoliticsTwitchCategory')}</option>
                  <option value={'Science & Technology'}>{t('pages.NewRoom.optionScienceTechTwitchCategory')}</option>
                  <option value="League of Legends">League of Legends</option>
                  <option value="Valorant">Valorant</option>
                  <option value="Counter-Strike: Global Offensive">Counter-Strike: Global Offensive</option>
                  <option value="Grand Theft Auto V">Grand Theft Auto V</option>
                </select>
              )}
              <div className='text-center' style={{
                display: 'flex',
                justifyContent: 'center',
                borderTop: '1px solid #DEE2E6'
              }}
              >
                <button
                  className="btn btn-primary mt-3"
                  type="button"
                  onClick={handleConnectAccountToRoom}
                >
                  {t('pages.Studio.connectText')}
                </button>
              </div>
            </>
          ) : (
            <Link to="/destinations">
              <span>{t('pages.Studio.needADestinationText')}</span>
            </Link>
          )}
        </Modal.Body>
      </Modal>

      <Container>
        <Header 
          page="studio" 
          broadcastStatus={startingBroadcast.status} 
          stopBroadcast={() => { handleStopStream() }}
        />

        <Body>
          <Content>    
            <BigScreenContainer>
              <RoomTitle>
                <p>{client.client_type === '4events' ? '' : room_name}</p>
              </RoomTitle>

              <BigScreen
                streams={onScreen} 
                live={startingBroadcast.status === 'started'} 
                backgroundUrl={backgroundUrl}
                logoUrl={logoUrl}
                overlayUrl={overlayUrl}
                showNames={showFooterLayer}
                layout={bigScreenLayout}
                banner={banner}
                videoclipUrl={videoclipUrl}
                onVideoclipEnded={handleVideoclipEnded}
              />

              {/*Transmission layouts buttons*/}
              <div className={styles.layout_video_c_footer}>
                <div className="">
                  {startingBroadcast.status == 'stopped' ? (
                    <button style={{ outline: 'none', border: 'none' }} className={`btn btn-primary ml-1`} onClick={handleSetAccount} disabled={loadingConnectYoutube ? true : false}>
                      {loadingConnectYoutube ? (
                        <>
                          <i className="fas fa-spinner fa-spin mr-2"></i>
                          {textBtnLiveStarting}
                        </>
                      ) : (
                        <>
                          <i className={`fas ${isRoomOnlyRecord ? 'fa-video' : 'fa-broadcast-tower'} mr-2`}></i>
                          {isRoomOnlyRecord ? t(`pages.Studio.recordButtonEnterLiveText`) : t(`pages.Studio.liveButtonEnterLiveText`)}
                        </>
                      )}
                    </button>
                  ) : (
                    <>
                      <button style={{ outline: 'none', border: 'none' }} className={`${styles.btn_start_transmission_pressed} ml-1`} onClick={() => handleStopStream()} disabled={loadingConnectYoutube ? true : false}>
                        {loadingConnectYoutube ? (
                          <>
                            <i className="fas fa-spinner fa-spin mr-2"></i>{t(`pages.Studio.liveButtonFinishingText`)}
                          </>
                        ) : (
                          <>
                            <i className={`fas ${isRoomOnlyRecord ? 'fa-video' : 'fa-broadcast-tower'} mr-2`}></i>
                            {isRoomOnlyRecord ? t(`pages.Studio.recordButtonEndLiveText`) : t(`pages.Studio.liveButtonEndLiveText`)}
                          </>
                        )}
                      </button>
                      {destination.type_account !== 'custom_rtmp' && !client.only_record && (
                        <OverlayTrigger placement="top" overlay={<Tooltip>{t(`pages.Studio.tooltipVideoLink`)}</Tooltip>}>
                          <i className={`fas fa-share-alt pointer ${styles.shareTransmission} ml-lg-4 ml-2`} onClick={() => setShowShareVideoModal({ status: true, link: showShareVideoModal.link })}></i>
                        </OverlayTrigger>
                      )}
                    </>
                  )}
                  
                  {vp_plan && (vp_plan === 'gratuito' || vp_plan === 'trial') ? (
                    <i className="ml-2 fa fa-lock"></i>
                  ) : ( <></> ) }
                    
                </div>

                <div>
                  {notShowQualityConnection ? (
                    <OverlayTrigger placement="top" overlay={<Tooltip>{t(`pages.Studio.tooltipQualityConn`)}</Tooltip>}>
                      <i className="fas fa-signal" style={{ color: colorConnection }}></i>
                    </OverlayTrigger>
                  ) : (
                    <OverlayTrigger placement="top" overlay={<Tooltip><strong>{t(`pages.Studio.internetQualityText`)}: </strong>{`${messageQualityConnection}`}</Tooltip>}>
                      <i className="fas fa-signal" style={{ color: colorConnection }}></i>
                    </OverlayTrigger>
                  )}
                  <OverlayTrigger placement="top" overlay={<Tooltip>{t(`pages.Studio.connectionTipsTooltip`)}</Tooltip>}>
                    <button
                      type="button"
                      onClick={() => setShowModalTips(true)}
                      style={{ background: 'none', border: 0, marginLeft: '5px' }}
                    >
                      <FaQuestionCircle color="#393c52" />
                    </button>
                  </OverlayTrigger>
                </div>
              </div>

              <BigScreenLayouts 
                layout={bigScreenLayout}
                onLayoutChange={updateBigScreenLayout}
              />

              {/*Studio footer buttons*/}
              <div className={styles.footer_video_c}>
                {/* Mic button */}
                <OverlayTrigger placement="top"
                  overlay={<Tooltip>{audioOpen ? t(`pages.Studio.tooltipMuteMicText`) : t(`pages.Studio.tooltipUnmuteMicText`)}</Tooltip>}
                >
                  <div 
                    className={`${audioOpen ? `${styles.btn_studio_room} btn-initial-audio` : 'text-white btn-initial-audio btn-inital-room-pressed'}`} 
                    onClick={() => toggleAudio()}
                  >
                    <i className={`${audioOpen ? 'fa fa-microphone btn-initial-icon' : 'fa btn-initial-icon fa-microphone-slash'}`}></i>
                  </div>
                </OverlayTrigger>

                {/* Cam button */}
                <OverlayTrigger placement="top"
                  overlay={<Tooltip>{videoOpen ? t(`pages.Studio.tooltipDisableCamText`) : t(`pages.Studio.tooltipEnableCamText`)}</Tooltip>}
                >
                  <div 
                    className={`btn-initial-video ${videoOpen ? `${styles.btn_studio_room} btn-initial-video` : 'text-white d-inline-block btn-inital-room-pressed'}`} 
                    onClick={() => toggleVideo()}
                  >
                    <i className={videoOpen ? 'fa fa-video btn-initial-icon' : 'fa btn-initial-icon fa-video-slash'}></i>
                  </div>
                </OverlayTrigger>

                {/* Share Screen button */}
                <OverlayTrigger placement="top"
                  overlay={<Tooltip>{shareScreenController.shared ? t(`pages.Studio.tooltipStopShareScreen`) : t(`pages.Studio.tooltipShareScreen`)}</Tooltip>}
                >
                  <div className={shareScreenController.css} onClick={() => shareScreen()}><i className="fa fa-desktop btn-initial-icon"></i></div>
                </OverlayTrigger>

                {/* Local video button */}
                <div className={styles.container_local_video_btn}>
                  <OverlayTrigger placement="top"
                    overlay={<Tooltip>{t(`pages.Studio.tooltipVideoFileText`)}</Tooltip>}
                  >
                    <div className={`${styles.btn_studio_room} btn-initial-config`} onClick={handleShareVideoFile}><i className="fa fa-film btn-initial-icon"></i></div>
                  </OverlayTrigger>
                  
                  {client.client_type === '4web' && !modules.local_video && (
                    <i onClick={handleShareVideoFile} className="fa fa-lock"></i>
                  )}
                </div>

                {/* Invite guest button */}
                <OverlayTrigger placement="top"
                  // overlay={<Tooltip>Invite people</Tooltip>}
                  overlay={<Tooltip>{t(`pages.Studio.tooltipInvitePeopleText`)}</Tooltip>}
                >
                  <div className={`${styles.btn_studio_room} ${styles.btn_studio_invite_guest}`} onClick={() => setShowInviteModal(true)}><i className="fa fa-user-plus btn-initial-icon"></i></div>
                </OverlayTrigger>
              </div>
            </BigScreenContainer>
          </Content>

          <Sidebar>
            <SidebarTabs>
              <OverlayTrigger
                show={isParticipantsTipOpen}
                placement="left"
                rootClose
                onToggle={(show) => !show && setIsParticipantsTipOpen(false)}
                overlay={
                  <Tooltip>{t(`pages.Studio.participantsTabTip`)}</Tooltip>
                }
              >
                <li 
                  className={activeSidebarTab === 'people' ? 'active' : ''}
                  onClick={() => handleTabChange('people')}
                >
                  <i className="ri-group-fill"></i>
                  <span>{t(`pages.Studio.peopleText`)}</span>
                  <div className="floating-badge badge-secondary">{streamers.length}</div>
                </li>
              </OverlayTrigger>
              
              <li 
                className={activeSidebarTab === 'customize' ? 'active' : ''}
                onClick={() => handleTabChange('customize')}
              >
                <i className="ri-palette-fill"></i>
                <span>{t(`pages.Studio.customizeText`)}</span>
              </li>

              <li 
                className={activeSidebarTab === 'banners' ? 'active' : ''}
                onClick={() => handleTabChange('banners')}
              >
                <i className="ri-t-box-fill"></i>
                <span>{t(`pages.Studio.bannersText`)}</span>
              </li>
              
              <li 
                className={activeSidebarTab === 'private-chat' ? 'active' : ''}
                onClick={() => handleTabChange('private-chat')}
              >
                <i className="ri-chat-4-fill"></i>
                <span>{t(`pages.Studio.privateChatText`)}</span>
                {unreadPrivateChatCount > 0 && (
                  <div className="floating-badge">{unreadPrivateChatCount}</div>
                )}
              </li>

              {client.client_type === '4events' && (
                <li 
                  className={activeSidebarTab === 'event-chat' ? 'active' : ''}
                  onClick={() => handleTabChange('event-chat')}
                >
                  <i className="ri-question-answer-fill"></i>
                  <span>{t(`pages.Studio.eventChatText`)}</span>
                </li>
              )}

              {client.client_type === '4events' && (
                <li 
                  className={activeSidebarTab === 'questions' ? 'active' : ''}
                  onClick={() => handleTabChange('questions')}
                >
                  <i className="ri-questionnaire-fill"></i>
                  <span>{t(`pages.Studio.questionsText`)}</span>
                  {countQuestions > 0 && (
                    <div className="floating-badge">{countQuestions}</div>
                  )}
                </li>
              )}
            </SidebarTabs>

            <SidebarContent
              initial="hidden"
              animate={activeSidebarTab ? 'visible' : 'hidden'}
              transition={{ duration: 0.2 }}
              variants={{
                hidden: {
                  opacity: 0,
                  x: '100%',
                  width: 0,
                  borderLeftWidth: 0,
                },
                visible: {
                  opacity: 1,
                  x: 0,
                  width: '450px',
                  borderLeftWidth: '1px',
                }
              }}
            >
              <PerfectScrollbar className={`tab-content ${activeSidebarTab === 'people' ? 'visible' : 'hidden'}`}>
                <div className={`iq-card ${styles.streamers_list}`} style={{ boxShadow: "none" }}>
                  <div id="streamersVideo" className={styles.streamers_video}>
                    {streamers.map((streamer) => {
                      // improve performance (set isOnScreen prop on `streamers` state)
                      const isOnScreen = onScreen.find(
                        (onScreenStreamer) =>
                          onScreenStreamer.id === streamer.id
                      );

                      return (
                        <VideoComponent
                          page="studio"
                          key={streamer.id}
                          id={streamer.id}
                          src={streamer.srcObject}
                          name={streamer.display}
                          type={streamer.type}
                          file={streamer.file}
                          typeVideo={streamer.typeVideo}
                          addStreamer={() => { addOnScreen(streamer) }}
                          isOnScreen={isOnScreen}
                          streamer={streamer}
                          onRemove={(e) => handleRemoveStream(e, streamer)}
                        />
                      )
                    })}
                  </div>

                  <div id="streamersAudio"></div>
                </div>
              </PerfectScrollbar>

              <PerfectScrollbar className={`tab-content ${activeSidebarTab === 'customize' ? 'visible' : 'hidden'}`}>
                <CustomizeImages type="background" cid={client_id} eid={event_id} room={room_id} defineImage={() => { defineBackground() }} onForbidden={showForbiddenAlert} />
                <CustomizeImages type="logo" cid={client_id} eid={event_id} room={room_id} defineImage={() => { defineLogo() }} onForbidden={showForbiddenAlert} />
                <CustomizeImages type="overlay" cid={client_id} eid={event_id} room={room_id} defineImage={() => { defineOverlay() }} onForbidden={showForbiddenAlert} />
                <CustomizeVideoclips cid={client_id} eid={event_id} room={room_id} playingVideoclip={videoclipUrl} onPlayVideoclip={handlePlayVideoclip} onForbidden={showForbiddenAlert} />
                <CustomizeOthers
                  cid={client_id}
                  eid={event_id}
                  room={room_id}
                  switchName={() => { switchName() }}
                  switchOnlyVoice={() => { handleSwitchOnlyVoice() }}
                  songs={bgSounds}
                  songElement={bgSongRef.current}
                  onSelectBgSong={handleSelectBgSong}
                  currentBgSound={currentBgSound}
                  onSoundStateChange={handleSoundStateChange}
                />
              </PerfectScrollbar>

              <PerfectScrollbar className={`tab-content ${activeSidebarTab === 'banners' ? 'visible' : 'hidden'}`}>
                <Banners 
                  clientId={client_id} 
                  roomId={room_id} 
                  onBannerChange={handleBannerChange} 
                />
              </PerfectScrollbar> 

              <div 
                style={{ height: '100%' }}
                className={activeSidebarTab === 'private-chat' ? 'visible' : 'hidden'}
                >
                <Chat
                  display={displayName}
                  room={room_id}
                  cid={client_id}
                  eid={event_id}
                  firebase={dbFirebase}
                  type_room="interno"
                  is_admin={true}
                  disabled={client.client_type === '4web' && !modules.internal_chat}
                  onNewMessage={handleNewChatMessage}
                  onDoubleClick={handleBannerMessageChange}
                  isFromStudio={true}
                  onClearMessages={cleanChatFirebase}
                />

                {(client.client_type === '4web' && !modules.internal_chat) && (
                  <div className={styles.chat_forbidden_overlay}>
                    <p>
                      <i className="fa fa-lock"></i><br />
                      {t(`pages.Studio.chatNotAvailableTitle`)}
                      {startingBroadcast.status === 'started' && (
                        <>
                          <br />
                          <br />
                          {/* <strong>You are live now</strong>. If you leave the studio, your transmission will be stopped */}
                          <strong>{t(`pages.Studio.youAreLiveNowText`)}</strong>. {t(`pages.Studio.youAreLiveNowDescription`)}
                        </>
                      )}
                    </p>
                    <button className="btn btn-primary" onClick={handleViewPlans}>
                      {t(`pages.Studio.viewPlansButtonText`)}
                    </button>
                  </div>
                )}
              </div>

              {client.client_type === '4events' && (
                <div 
                  style={{ height: '100%' }}
                  className={activeSidebarTab === 'event-chat' ? 'visible' : 'hidden'}
                >
                  <Chat
                    display={displayName}
                    room={room_id}
                    cid={client_id}
                    eid={event_id}
                    firebase={dbFirebase}
                    type_room="general"
                    disabled={client.client_type === '4web' && !modules.internal_chat}
                    onNewMessage={handleNewChatMessage}
                    canSendMessage={false}
                    onDoubleClick={handleBannerMessageChange}
                    isFromStudio={true}
                  />
                </div>
              )}

              {client.client_type === '4events' && (
                <QueryClientProvider client={queryClient} >
                  <Questions
                    cid={client_id}
                    eid={event_id}
                    room={external_room}
                    count={(value) => setCountQuestions(value)}
                    onDoubleClick={handleBannerQuestionChange}
                    className={`tab-content ${activeSidebarTab === 'questions' ? 'visible' : 'hidden'}`}
                  />
                </QueryClientProvider>
              )}
            </SidebarContent>
          </Sidebar>
        </Body>
      </Container>
    </>
  )
}