export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function getDateTimeFormated(date) {
  let d = new Date(date);

  // Guarda cada pedaço em uma variável
  let dia = d.getDate().toString().padStart(2, '0');
  let mes = (d.getMonth() + 1).toString().padStart(2, '0');
  let ano = d.getFullYear();
  let hora = d.getHours().toString().padStart(2, '0');
  let min = d.getMinutes().toString().padStart(2, '0');
  let seg = d.getSeconds().toString().padStart(2, '0');

  return `${dia}/${mes}/${ano} às ${hora}:${min}:${seg}`
}