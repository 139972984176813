import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { token_decode } from "../../services/auth";
import api from "../../services/api";
import { toast } from "react-toastify";

export default function CallbackYoutube() {
  let { code } = useParams();

  useEffect(() => {
    const base64 =
      "^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)$";

    let reg = new RegExp(base64);
    let isValid = reg.test(code);

    const client = token_decode(localStorage.getItem("auth-user"));

    let cid = null;

    if (client.client_type === "4web") {
      cid = client._id;
    } else {
      cid = client.vp_client_id;
    }

    let refresh_token = null;

    if (isValid) {
      let user = {};

      // localStorage.setItem("goauth2_token", code);

      code = JSON.parse(atob(code));
      const { user: u } = code;

      localStorage.setItem("rt", code.refresh_token);

      user.access_token = code.access_token;
      user.type_account = "youtube";
      user.refresh_token = code.refresh_token;
      user.id_token = code.id_token;
      user.scope = code.scope;
      user.avatar = u.picture;
      user.display_name = u.given_name;
      user.id = u.id;

      api
        .post("api/destinations/youtube", {
          user,
          type_client: client.type_client,
          client_id: cid,
        })
        .then((res) => {
          if (res.status === 200) {
            window.location.href = "/destinations";
          }
        })
        .catch((err) => {
          console.log(err);
          window.location.href = "/new-room";
          toast.error("Error when logging in with YouTube, try again later");
        });
    }
  }, []);

  return <></>;
}
