import { useState, useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner'

import { getAuthenticatedUser } from '../../../services/auth'
import api from '../../../services/api'

import basicPlanImg from '../../../assets/images/plans/basic-plan.png'
import upPlanImg from '../../../assets/images/plans/up-plan.png'

import { Container, PlanContainer, UpgradeButton } from './styles'
import { useTranslation } from 'react-i18next'

const planImages = {
  'Plan Basic': basicPlanImg,
  'Plan Up': upPlanImg,
}

export default function Plan() {
  const { t } = useTranslation();
  const user = getAuthenticatedUser();
  const [loading, setLoading] = useState(true);
  const [plan, setPlan] = useState(null);
  const planName = plan ? plan.product_name : 'Plan Basic';


  useEffect(() => {
    async function fetchPlan() {
      try {
        const response = await api.get(`/users/${user._id}/plan`);
        setPlan(response.data.plan);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    fetchPlan()
  }, [user._id]);

  if (loading) {
    return (
      <Container>
        <Spinner animation="border" />
      </Container>
    )
  }

  return (
    <Container>
      <PlanContainer>
        <img src={planImages[planName]} alt={planName} />
        <strong>{planName}</strong>
      </PlanContainer>

      <UpgradeButton to="/plans" className="btn-primary">
        {planName === 'Plan Up' ? t(`pages.Billing.Plan.upgradeButtonTextChangePlan`) : t(`pages.Billing.Plan.upgradeButtonTextUpgradePlan`)}
      </UpgradeButton>
    </Container>
  )
}