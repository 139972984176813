import React, { createContext, useState, useEffect } from "react";
import api from "../../services/api";
import { token_decode } from "../../services/auth";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export const AccountContext = createContext({});

export default function AccountProvider({ children }) {
  const { t } = useTranslation();
  const [accounts, setAccounts] = useState([]);
  const [showModalAccounts, setShowModalAccounts] = useState(false);

  const client = token_decode(localStorage.getItem("auth-user"));

  let cid = null;

  if (client.client_type !== "4web") {
    cid = client.vp_client_id;
  } else {
    cid = client._id;
  }

  async function deleteAccount(id) {
    Swal.fire({
      icon: "warning",
      title: t(`context.Accounts.swalDeleteAccountTitle`),
      html: t(`context.Accounts.swalDeleteAccountText`),
      showCancelButton: true,
      confirmButtonText: t(`context.Accounts.swalConfirmButtonText`),
      cancelButtonText: t(`context.Accounts.swalCancelButtonText`),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.delete("api/destinations", {
          params: {
            id,
          },
        });

        if (response.status === 200) {
          return window.location.reload();
        } else {
          return toast.error(t(`context.Accounts.toastErrorDeleteAccount`));
        }
      } else {
        return false;
      }
    });
  }

  function hideModal() {
    setShowModalAccounts(false);
  }

  function show_modal() {
    setShowModalAccounts(false);
  }

  async function verifyAccount() {
    try {
      // const response = await api.get("api/destinations", {
      //   params: {
      //     client_id: cid,
      //   },
      // });

      const response = await api.get(`/user/accounts?id=${cid}&type_client=${client.client_type}`);

      if (response.status === 200) {
        const { data } = response.data;
        setAccounts(data);
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    verifyAccount();
  }, []);

  return (
    <AccountContext.Provider
      value={{
        accounts,
        showModalAccounts,
        deleteAccount,
        hideModal,
        show_modal,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}
