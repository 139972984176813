import React, { useEffect, useState } from 'react'
import img1 from '../../assets/images/login/1.png'
import logo from '../../assets/images/logo/n1-1.png'
import ilustration from '../../assets/images/Ilustration_p.png'
import Steps from '../../components/Steps'
import './style.css'
import { Link, Redirect } from 'react-router-dom'
import api from '../../services/api'
import Swal from 'sweetalert2'
import axios from 'axios'

import { isAuthenticated } from '../../services/auth'
import { useAuth } from '../../context/Auth'
import { useTranslation } from 'react-i18next'

import TagManager from 'react-gtm-module'
import ReCAPTCHA from "react-google-recaptcha-enterprise"

export default function Signup({ history }){

    const tagManagerArgs = {
        gtmId: 'GTM-KQFKWG2'
    }

    TagManager.initialize(tagManagerArgs)

    const { t } = useTranslation()

    const [step, setStep] = useState(1)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [coupon, setCoupon] = useState('')
    const [couponError, setCouponError] = useState('')
    const [next, setNext] = useState(false)
    const [error, setError] = useState({ type: '', message: ''})
    const [code , setCode]  = useState('')
    const [loading, setLoading] = useState(false)
    const [codeVerified , setCodeVerified] = useState(false)
    const [id, setId]   = useState(null)
    const [resend, setResend] = useState(false)
    const [currentCode, setCurrentCode] = useState(null)
    const [loadingLogin, setLoadingLogin]   = useState(false)
    const [captcha, setCaptcha] = useState(null)
    const { setUser } = useAuth()

    async function onChange(value) {
        setCaptcha(value)
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleEmail = (e) => {
        let { value } = e.target

        if(value !== undefined || value !== ""){
            
            setEmail(value)
            
            let valid = validateEmail(value)
            
            if(valid){

                setError({
                    type: 'valid_email',
                    message: ''
                })

                setNext(true)
            }else{
                setError({
                    type: 'invalid_email',
                    message: t(`pages.Signup.errorInvalidEmail`)
                })
                setNext(false)
            }
        }

    }

    const sendPassword = async () => {
        //chamada api para registrar o usuário e enviar senha no email
        try{
            await api.post('/signup', {
                name,
                email,
                coupon,
                captcha
            })
            
            return {
                error: false
            }

        } catch(err) { 
            return {
                error: true,
                type: err.response?.data.type,
                message: err.response?.data.message
            }
        }
        

    }
    const resendPassword = async () => {
        
       
        setResend(true)

        try{
           let response = await api.post('/resend-password',{
                email: email
            })

            

            if(response.status == 200){
                Swal.fire(t(`pages.Signup.swalCodeHasBeenSentTitle`), t(`pages.Signup.swalCodeHasBeenSentText`), 'success')                
            }else{
                Swal.fire('Oops!', t(`pages.Signup.swalErrorCode`), 'error')
            }

           
            setResend(false)
        }catch(err){
            console.error(err.message)
            Swal.fire('Oops!', t(`pages.Signup.swalErrorCode`), 'error')

           
            setResend(false)
        }
    }
    const verifyCode = async () => {
        
        if(code == currentCode){
            setError({
                type: 'invalid_code',
                message: t(`pages.Signup.errorCodeCantBeUsedAgain`)
            })
            return false
        }
        
        if(code !== '' && code !== undefined){
            try{
               let response = await api.post('/verifyCode',{
                    code: code,
                    email: email
                })

                let { data } = response.data
                
                if(data.email && data.token){
                    setError({
                        type: 'valid_code',
                        message: ''
                    })                    
                    setCodeVerified(true)
                    setCurrentCode(code)
                    setId(data.token)

                    return data.token;
                }else{
                    setError({
                        type: 'invalid_code',
                        message: t(`pages.Signup.errorInvalidCode`)
                    })
                }
            }catch(err){
                setError({
                    type: 'invalid_code',
                    message: t(`pages.Signup.errorInvalidCode`)
                })
            }
            
        }else{
            setError({
                type: 'invalid_code',
                message: t(`pages.Signup.errorEnterWithAValidCode`)
            })
        }

        return false;
    }

    
    const handleCode =  (e) => {
        let { value } = e.target

        if(value !== '' || value !== undefined){
            setCode(value)
        }
    }

    const handleNext = async () => {
        

        if(step < 3){
            
            if(step == 2){

                if (!captcha) {
                    Swal.fire('Oops!', t(`pages.Signup.errorCaptcha`), 'warning')
                    return false
                } else {
                    setLoading(true)
                    setNext(false)
                }
                
                let res = await sendPassword()

                if(res.error){
                    switch (res.type) {
                        case 'email':
                            setError({
                                type: 'invalid_email',
                                message: t(`pages.Signup.errorEmailAlreadyExists`)
                            })
                            break;

                        case 'invalid_coupon':
                            setCouponError(t(`pages.Signup.errorInvalidCoupon`));
                            setNext(true);
                            break;

                        case 'expired_coupon':
                            setCouponError(t(`pages.Signup.errorExpiredCoupon`));
                            setNext(true);
                            break;

                        case 'reCAPTCHA':
                            setError({
                                type: 'reCAPTCHA',
                                message: t(`pages.Signup.errorSomethingWrong`)
                            })
                            Swal.fire('Oops!', 'We have identified suspicious behavior on your network, please try again later.', 'warning')
                            break;

                        default:
                            setError({
                                type: 'invalid_email',
                                message: t(`pages.Signup.errorSomethingWrong`)
                            })
                            break;
                    }
                }else{
                    setStep(step + 1)
                    setNext(false)
                }

                setLoading(false)

            }else{
                setStep(step + 1)
                setNext(false)
            }

           
        }
    }

    const handleSubmit = async () => {
    
        if((email !== '' || email !== undefined)){
            setLoadingLogin(true)

            const token = await verifyCode();

            if (!token) {
                setLoadingLogin(false)
                return;
            }

            try{
                
                let response = await api.post('/firstlogin', {
                    email: email,
                    token
                })

                if(response.status == 200){
                    
                    let { data } = response.data
                    
                    let token_auth = await Buffer.from(JSON.stringify(data)).toString('base64')
                    
                    localStorage.setItem('auth-user', token_auth)
                    setUser(data);

                    history.push('/new-room', { firstLogin: true });
                }

                setLoadingLogin(false)

            }catch(err){
                setLoadingLogin(false)
                console.error(err.message)
            }
        }
       
    }

    function handleInputName(event) {
        const text = event.target.value;

        if (!text.trim()) {
            setNext(false)
        } else {
            setNext(true)
        }

        setName(text)
    }

    function handlePressEnter(event) {
        const { key } = event
        if ((next || code) && key === 'Enter') {
            if (step === 1 || step === 2) {
                handleNext();
                return;
            }
            if (step === 3) {
                handleSubmit();
                return;
            }
        }
        return;
    }

    return (
        !isAuthenticated() ? (
        <>
            <section className="sign-in-page">
                <div className="container mt-4">
                    <button className="go-back-btn" onClick={() => window.location.href = 'https://app.4web.live/signin'}>
                        <span  className="fa fa-angle-left"></span> 
                    </button>
                    <div className="row">
                        
                        <Steps
                            steps={2} 
                            titles={[t(`pages.Signup.stepTitleName`), t(`pages.Signup.stepTitleEmail`), t(`pages.Signup.stepTitleEnter`)]}
                            currentStep={step}
                        />
                    </div>
                    
                    <div className="row">                     
                        <div className="iq-card align-self-center card-signup col-16 bg-white">
                            <div className="col-16 align-self-center card-signup-content">                                
                                <div className="sign-in-from mt-5">
                 
                                    {step == 1 && (
                                        <>
                                            <h4>{t(`pages.Signup.pageTitle`)}</h4>
                                            <h6>
                                                {t(`pages.Signup.pageText`)}
                                            </h6>
                                                
                                            <div className="form-group mt-5">
                                                <label className="label-input">* {t(`pages.Signup.whatsYourName`)}</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control mb-0"  
                                                    placeholder={t(`pages.Signup.placeholderFullName`)}
                                                    value={name}
                                                    onChange={handleInputName}
                                                    onKeyUp={handlePressEnter}
                                                />
                                            </div>
                                        </>
                                    )}
                                    {step == 2 && (
                                        <>
                                            <h4>{t(`pages.Signup.whatsYourEmailTitle`)}</h4>
                                            <h6>
                                                {t(`pages.Signup.whatsYourEmailText`)}
                                            </h6>
                                            <div className="form-group mt-5">
                                            
                                                <label className="label-input">* E-mail</label>
                                                <input type="email" 
                                                className={`form-control mb-0  ${error.type == 'invalid_email' ? 'is-invalid' : error.type == 'valid_email' ? 'is-valid' : ''}`}  
                                                placeholder={t(`pages.Signup.placeholderEmail`)}
                                                value={email}
                                                required
                                                onChange={handleEmail}
                                                onKeyUp={handlePressEnter}
                                                />
                                                {error.type == 'invalid_email' && (
                                                    <div className="invalid-feedback">
                                                        {error.message}
                                                    </div>
                                                )}
                                               
                                            </div>
                                            
                                            <div className="form-group mt-2">
                                            
                                                <label className="label-input">{t(`pages.Signup.couponText`)}</label>
                                                <input type="text" 
                                                className={`form-control mb-0  ${couponError ? 'is-invalid' : ''}`}
                                                placeholder={t(`pages.Signup.placeholderCoupon`)}
                                                value={coupon}
                                                required
                                                onChange={(e) => setCoupon(e.target.value)}
                                                />
                                                {couponError && (
                                                    <div className="invalid-feedback">
                                                        {couponError}
                                                    </div>
                                                )}
                                               
                                            </div>

                                            <ReCAPTCHA
                                                sitekey={process.env.REACT_APP_RECAPTCHA}
                                                onChange={onChange}
                                            />
                                        </>
                                    )}
                                    {step == 3 && (
                                        <>
                                            <h4>{t(`pages.Signup.step3Title1`)} {name}, {t(`pages.Signup.step3Title2`)}</h4>
                                            <h6>
                                                {t(`pages.Signup.step3Text`)}
                                            </h6>
                                            <small className="text-primary"><strong>{t(`pages.Signup.note`)}: </strong>{t(`pages.Signup.noteDescription`)}</small>
                                            <div className="form-group mt-3">
                                            
                                                <label className="label-input">* {t(`pages.Signup.accessCodeText`)}</label>
                                                <input type="text" 
                                                    className={`form-control mb-0  ${error.type == 'invalid_code' ? 'is-invalid' : error.type == 'valid_code' ? 'is-valid' : ''}`}
                                                    placeholder={t(`pages.Signup.placeholderAccessCode`)}
                                                    value={code}
                                                    required
                                                    onChange={handleCode}
                                                    onKeyUp={handlePressEnter}
                                                />

                                                    <div>
                                                        
                                                        {!resend ? (<a className="resend-mail" onClick={resendPassword}><u>{t(`pages.Signup.sendNewCodeText`)}</u></a>):( <div className="resend-mail"><span className="fa fa-spin fa-spinner"></span></div> )}
                                                    </div>

                                                    {error.type == 'invalid_code' && (
                                                            
                                                        <div className="invalid-feedback">
                                                            {error.message}
                                                        </div>
                                                            
                                                    )}
                                            </div>
                                           
                                           
                                            
                                        </>
                                        
                                    )}
                                   
                                </div>
                                <div className="step-image">
                                        <img src={ilustration} alt="4weblive"/>
                                </div>
                            </div>
                            <div className="steps-actions">
                                
                                {step == 1 ? (
                                    <div></div>
                                ):(
                                    <button 
                                        type="button" 
                                        className="btn_prev"
                                        onClick={() => setStep(step - 1)}
                                    >
                                        <span style={{fontSize: 18,marginLeft: 5}} className="fa fa-angle-left"></span> {t(`pages.Signup.goBackText`)}
                                    </button>
                                )}

                                {step < 3 ? (
                                    <button 
                                    type="button" 
                                    className={` btn btn-lg btn_next  ${ !next ? 'disabled' : '' }`}
                                    onClick={handleNext}
                                    >
                                        {loading && step == 2 ? ( <span className="fa fa-spin fa-spinner"></span> ):(
                                            <>
                                                {t(`pages.Signup.nextText`)}
                                                <span style={{fontSize: 18,marginLeft: 5}} className="fa fa-angle-right"></span>
                                            </>
                                        )}  
                                       
                                    </button>
                                ):(
                                    <button 
                                    type="button" 
                                    className={` btn btn-lg btn_next  ${ !code || loadingLogin? 'disabled' : '' }`}
                                    onClick={handleSubmit}
                                    >
                                       {loadingLogin ? ( <span className="fa fa-spin fa-spinner"></span> ):(t(`pages.Signup.letsGoButtonText`))}
                                        
                                    </button>
                                )}
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
        ):(
            <Redirect to="/new-room"/>
        )
    )
}