import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function NavButton({ 
  tooltipText, 
  active, 
  className, 
  highlight, 
  children, 
  ...rest 
}) {
  return tooltipText ? (
    <li 
      className={`${className ? className : ''} ${active ? 'active' : ''} ${highlight ? 'highlight' : ''}`} 
      {...rest}
    >
      <OverlayTrigger 
        rootClose
        delay={{ show: 500 }}
        overlay={
          <Tooltip className="tooltip-nav-header">{tooltipText}</Tooltip>
        }
        placement="bottom"
      >
        <button type="button">
          {children}
        </button>
      </OverlayTrigger>            
    </li>
  ) : (
    <li 
      className={`${className ? className : ''} ${active ? 'active' : ''} ${highlight ? 'highlight' : ''}`} 
      {...rest}
    >
      <button type="button">
        {children}
      </button>      
    </li>
  )
}