import React from 'react'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/images/logo/n1-mini.png'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

require('dotenv').config()

export default function Broadcasts({ data }) {
    const { t } = useTranslation()
    let start = new Date(data.started_at)

    let stop = new Date(data.stopped_at)

    start = start.toLocaleString(start)
    stop = data.stopped_at !== null ? stop.toLocaleString(stop) : '-'

    const downloadApiVideo = (uri) => {
        window.location.href = `${process.env.REACT_APP_API_URL}download-video-client?uri=${uri}`

    }

    return (
        <tr>
            <td>
                <div className="account_image_mini_container mr-2" style={{ backgroundImage: `url(${data.picture !== null ? data.picture : logo})` }}></div>
            </td>
            <td>
                <a className=" font-weight-bold" href={data.link} target='_blank'> {data.link}</a>
            </td>
            {data.stopped_at !== null ? (
                <>
                    <td style={{ display: 'none' }}>
                        <button onClick={() => downloadApiVideo(data.link)} className="btn btn-primary">
                            <i className="fa fa-download"></i>Download
                        </button>
                    </td>
                </>
            ) : (
                <>
                    <td style={{ display: 'none' }}>
                        <button className="btn btn-primary">
                            <i className="fa fa-download"></i>Download
                        </button>
                    </td>
                </>
            )}
            <td>
                {data.stopped_at == null ? (
                    <div className="badge badge-pill badge-amber" style={{ width: '100%' }}>
                        {t(`components.Broadcasts.inProgressText`)}
                    </div>
                ) : (
                    <div className="badge badge-pill badge-spring-green" style={{ width: '100%' }}>
                        {t(`components.Broadcasts.finishedText`)}
                    </div>
                )}

            </td>
            <td>{start}</td>
            <td>{stop}</td>
            
            <td className="d-flex justify-content-center">
                {data.record && (
                    <OverlayTrigger overlay={<Tooltip>Download</Tooltip>}>
                        <a href={data.record.url} download className="btn btn-secondary-light btn-light d-flex align-items-center" >
                            <span style={{fontSize: 22}} className="las la-download"></span>
                        </a>
                    </OverlayTrigger>
                )}
            </td>
        </tr>

    )
}