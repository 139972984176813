import React, { useState } from 'react'
import styles from './customizeOthers.module.css'
import ListItemsCustomize from '../ListItemsCustomize/ListItemsCustomize'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useTranslation } from 'react-i18next';

import { SoundsControl } from '../SoundsControl';

function CustomizeOthers(props) {
    const { t } = useTranslation();

    // const [switchOnlyVoice, setSwitchOnlyVoice] = useState(localStorage.getItem('switchOnlyVoice') ? (localStorage.getItem('switchOnlyVoice') === 'true') : false)
    const [switchName, setSwitchName] = useState(localStorage.getItem('switchName') ? (localStorage.getItem('switchName') === 'true') : true)

    const handleSwitchNames = () => {
        setSwitchName(!switchName)
        localStorage.setItem('switchName', !switchName)
        props.switchName()
    }

    // const handleSwitchOnlyVoice = () => {
    //     setSwitchOnlyVoice(!switchOnlyVoice)
    //     localStorage.setItem('switchOnlyVoice', !switchOnlyVoice)
    //     props.switchOnlyVoice()
    // }

    return (
        <React.StrictMode>
            <>
                <div className={`iq-card ${styles.container}`} style={{ boxShadow: "none" }}>
                    <div id="localMedia">
                        <div className={styles.bg_card}>
                            <div className={styles.bg_card_header}>
                                <p className="no-select">{t(`components.CustomizeOthers.bgSounds`)}</p>
                            </div>
                            <div className={styles.bg_card_body}>
                                <PerfectScrollbar className={styles.bg_scrollbar}>
                                    <SoundsControl
                                        songElement={props.songElement}
                                        compact
                                        dark
                                        onSoundStateChange={props.onSoundStateChange}
                                    />
                                    <div className={styles.audio_container}>
                                        {props.songs.map(song => (
                                            <div
                                                className={props.currentBgSound?.id === song.id ? styles.audio_card_selected : styles.audio_card}
                                                key={song.id}
                                                onClick={() => props.onSelectBgSong(song)}
                                            >
                                                <span>{song.name}</span>
                                            </div>
                                        ))}
                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`iq-card ${styles.container}`} style={{ boxShadow: "none" }}>
                    <div id="localMedia">
                        <div className={styles.bg_card}>
                            <div className={styles.bg_card_header}>
                                <p className="no-select">{t(`components.CustomizeOthers.generalTitle`)}</p>
                            </div>
                            <div className={styles.bg_card_body}>
                                <PerfectScrollbar className={styles.bg_scrollbar}>
                                    <ListItemsCustomize title={t(`components.CustomizeOthers.showNamesTitle`)} desc={t(`components.CustomizeOthers.showNamesText`)} func={() => { handleSwitchNames() }} isChecked={switchName} />
                                    {/* <ListItemsCustomize title={t(`components.CustomizeOthers.addVoiceOnlyTitle`)} desc={t(`components.CustomizeOthers.addVoiceOnlyText`)} func={() => { handleSwitchOnlyVoice() }} isChecked={switchOnlyVoice} /> */}
                                </PerfectScrollbar>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </React.StrictMode>
    )
}

export default CustomizeOthers;
