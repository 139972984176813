import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  padding: 16px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    line-height: 1.2;
    margin-bottom: 28px;
  }

  p {
    font-size: 18px;
  }
`;