import React, { useCallback, useEffect, useState } from 'react'
import api from '../../services/api'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../context/Auth'
import io from 'socket.io-client'

import { Container, SpinnerContainer } from './styles'

export function MeetSso() {
	const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { setUser } = useAuth();
  const { short_id } = useParams();

  const verifySSOGuest = useCallback(async () => {
    try {      
      const response = await api.post(`/api/sso-authentication-guest`, {
        short_id
      })
      
      const { error, user } = response.data;
      
      if (error) {
        Swal.fire('Oops...', t(`pages.MeetSso.invalidLink`), 'warning').then((res) => {
          if (res.isConfirmed) {
            return window.location.href = '/'
          }
        })

        setTimeout(() => {
          return window.location.href = '/'
        }, 5000);
      }

      let token_auth = await Buffer.from(JSON.stringify(user)).toString('base64')
      localStorage.setItem('auth-user', token_auth)
      setUser(user);

      if(process.env.REACT_APP_ENV === 'local'){
        window.socket = io(user.mediaServer, {cors: { origin: window.location.origin, credentials: false}})
      } else {
        window.socket = io(user.mediaServer, {cors: { origin: window.location.origin, credentials: false}})
      }
  
      setLoading(false);  
      window.location.href = '/meet' + window.location.search;
    } catch (err) {
      setLoading(false);
      window.location.href = '/'
    }
  }, [t, setUser, short_id])

  useEffect(() => {
    setLoading(true);
    verifySSOGuest();
  }, [verifySSOGuest])

  return (
    <Container>
      {loading ? (
        <SpinnerContainer>
          <div className="spinner-border" role="status">
            <span className="sr-only">...</span>
          </div>
        </SpinnerContainer>
      ) : (
        <></>
      )}
    </Container>
  )
}
