import { useState, useEffect } from "react";

export default function useTemporaryError(defaultValue, timeout = 7000) {
  const [error, setError] = useState(defaultValue);

  useEffect(() => {
    if (error) {
      const timeoutID = setTimeout(() => {
        setError('');
      }, timeout);

      return () => {
        clearTimeout(timeoutID);
      }
    }
  }, [error, timeout]);

  return [error, setError];
}