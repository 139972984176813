import { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';

import CreditCardForm from '../../../components/CreditCardForm';

import api from '../../../services/api'
import { useAuth } from '../../../context/Auth';
import { getAuthenticatedUser } from '../../../services/auth'
import useTemporaryError from '../../../hooks/useTemporaryError';
import useCreditCardForm from '../../../hooks/useCreditCardForm';

import { Container, Button, CreditCardFormContainer } from './styles';

export default function ChangePlanModal({ plan, open, onClose, onSuccess }) {
	const user = getAuthenticatedUser();
	const { reloadModules } = useAuth();

	const [isChangingPlan, setIsChangingPlan] = useState(false);
	const [isLoadingCard, setIsLoadingCard] = useState(true);
	const [clientHasCard, setClientHasCard] = useState(false);
	const [changePlanError, setChangePlanError] = useTemporaryError('');
	const cardNeeded = !clientHasCard && (plan ? plan.id !== 'basic_plan' : true);

	const { 
		onSubmit: submitCreditCard, 
		error: creditCardError, 
		loading: isSubmittingCreditCard, 
		...creditCardFormData
	} = useCreditCardForm();

	const isSubmitting = isSubmittingCreditCard || isChangingPlan;
	const error = creditCardError || changePlanError;

  useEffect(() => {
    async function fetchCard() {
      try {
        const response = await api.get(`/users/${user._id}/card`);
        setClientHasCard(!!response.data.card);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoadingCard(false);
      } 
    }

    fetchCard()
  }, [user._id]);

  function handleClose() {
    if (!isChangingPlan) {
			creditCardFormData.resetForm();
      onClose()
    }
  }

  async function handleConfirm() {
		setChangePlanError('');
		setIsChangingPlan(true);

		try {
			if (cardNeeded) {
				const cardSaved = await submitCreditCard();
				if (!cardSaved) {
					return;
				}
			}

			let newPlan;

			if (plan.id === 'basic_plan') {
				await api.delete(`/users/${user._id}/plan`);
				newPlan = plan;
			} else {
				const newPlanResponse = await api.post(`/users/${user._id}/plan/${plan.id}`);
				newPlan = newPlanResponse.data;
			}
			
			onSuccess(newPlan);
			reloadModules();
			creditCardFormData.resetForm();
			if (cardNeeded) {
				setClientHasCard(true);
			}
		} catch (err) {
      if (err && err.response && err.response.data && err.response.data.type === 'card_error') {
        setChangePlanError(err.response.data.message)
      } else {
        setChangePlanError('Something went wrong. Please try again.');
      }
		} finally {
			setIsChangingPlan(false);
		}
  }

	if (isLoadingCard || !plan) {
		return (
			<Modal show={open} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Confirmation</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container loading>
					<Spinner animation="border" />
				</Container>
			</Modal.Body>
		</Modal>
		)
	}

	return (
		<Modal show={open} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Confirmation</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container>
					{(!cardNeeded && error) && (
						<div className="alert alert-danger">
							<i
								style={{ fontSize: 18, marginRight: 5 }}
								className="fa fa-info-circle"
							/>
							{error}
						</div>
					)}

					{cardNeeded && (
						<CreditCardFormContainer>
							<CreditCardForm 
								{...creditCardFormData} 
								showFooter={false} 
								error={error}
							/>
						</CreditCardFormContainer>
					)}

					<p>
						Are you sure you want to change your plan to <strong>{plan.product_name}</strong>
            {plan.price ? (
              <>
                {' '}
                for{' '}
                <strong>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(plan.price)}
                </strong> per month?
              </>
            ) : '?'}
					</p>

					<footer>
						<Button secondary type="button" onClick={handleClose} disabled={isSubmitting}>
							Cancel
						</Button>
						<Button type="button" className="btn-primary" onClick={handleConfirm} disabled={isSubmitting}>
							{isSubmitting ? (
								<Spinner as="span" animation="border" size="sm" />
							) : (
								'Yes, Change Plan'
							)}
						</Button>
					</footer>
				</Container>
			</Modal.Body>
		</Modal>
	);
}
