import React, { useEffect, useState } from 'react';
import "./BlockUI.css";
import { useTranslation } from 'react-i18next';

const BlockUI = props => {
  const { t } = useTranslation()
  const [tips, setTips] = useState()

  useEffect(() => {
    let atual = 0;
    let textos = [
      t('components.BlockUI.text1'),
      t('components.BlockUI.text2'),
      t('components.BlockUI.text3')
    ]
    setInterval(function() {
      if (atual >= textos.length) atual = 0;
      setTips(textos[atual++])
    }, 3500);
  }, [t])

  if (!props.blocking) {
    return "";
  } else {
    return (
      <div className="block-ui-container">
        <div className="block-ui-overlay" />
        <div className="block-ui-top-message">
          <b>{t('components.BlockUI.tipsText')}:</b>
          {tips}
        </div>
        <div className="block-ui-message-container">
          <div className="block-ui-message">
            <span>{props.title}</span>
            <div className="loading-indicator">
              <svg id="indicator" viewBox="0 0 100 100">
                <circle id="circle" cx="50" cy="50" r="45" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
 
BlockUI.defaultProps = {
  blocking: false,
  title: "Loading"
};
 
export default BlockUI;