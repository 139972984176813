const generatedColors = {}

export function getPersonColor(key) {
  if (generatedColors[key]) {
    return generatedColors[key]
  }

  const forbiddenColors = ['#ff3c5e', '#fdd04c', ' #dfdfdf','#03A9F4']

  let hexadecimal = '0123456789ABCDEF';
  let color

  do {
    color = '#';
    for (let i = 0; i < 6; i++ ) {
      color += hexadecimal[Math.floor(Math.random() * 16)];
    }
  } while (
    luminosity(color) === 0 ||
    forbiddenColors.includes(color)
  );

  setPersonColor(key, color)
  return color;
}

export function setPersonColor(key, color) {
  generatedColors[key] = color;
}

export function luminosity(hex) {
  let r, g, b, longo;
  hex = hex.replace( '#', '' );
  longo = hex.length > 3;

  r = longo ? parseInt(hex.substr(0, 2), 16) : parseInt(hex.substr(0, 1), 16) * 17;
  g = longo ? parseInt(hex.substr(2, 2), 16) : parseInt(hex.substr(1, 1), 16) * 17;
  b = longo ? parseInt(hex.substr(4, 2), 16) : parseInt(hex.substr(2, 1), 16) * 17;

  let lumi = ( r * 299 + g * 587 + b * 114) / 1000;

  if( lumi > 148 ) { return 0; } else {  return 1;}
}