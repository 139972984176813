
import React from 'react'
import { AuthProvider } from './context/Auth'
import { ClientLogoProvider } from './hooks/useClientLogo';
import ErrorBoundary from './components/ErrorBoundary'; 

//css global
import './index.css';

//importando as rotas da aplicação
import Routes from './routes'

//componente principal

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <ClientLogoProvider>
          <Routes/>
        </ClientLogoProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
