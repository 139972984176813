import { cloneElement } from 'react'

import { DropdownContainer } from './styles'

export function Dropdown({ id, toggle, children, dark }) {
  return (
    <div className="dropdown" style={{ userSelect: 'none' }}>
      {toggle && cloneElement(toggle, {
        id, 
        'data-toggle': 'dropdown',
        'aria-expanded': 'false',
      })}

      <DropdownContainer 
        className="dropdown-menu dropdown-menu-right" 
        dark={dark}
      >
        {children}
      </DropdownContainer>
    </div>
  )
}