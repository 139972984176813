import { motion } from 'framer-motion';

import useDimensions from '../../hooks/useDimensions';

export default function ScaleText({ children, ...rest }) {
	const { width, refCallback } = useDimensions();

	return (
		<motion.div style={{ fontSize: width / 12 }} {...rest}>
			<div ref={refCallback} style={{
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
			}} />

			{children}
		</motion.div>
	);
}
