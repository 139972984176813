import React, { createContext, useEffect, useCallback, useState } from "react";

export const BrandColorsThemeContext = createContext({});

let root = document.documentElement

export function BrandColorsThemeProvider({ children }) {
  const [colorTheme, setColorTheme] = useState(() => {
    const color = localStorage.getItem('colorTheme');

    if (color) {
      return color
    }
    return ''
  })

  function luminosity(hex) {
    let r, g, b, longo
    hex = hex.replace('#', '')
    longo = hex.length > 3

    r = longo
      ? parseInt(hex.substr(0, 2), 16)
      : parseInt(hex.substr(0, 1), 16) * 17
    g = longo
      ? parseInt(hex.substr(2, 2), 16)
      : parseInt(hex.substr(1, 1), 16) * 17
    b = longo
      ? parseInt(hex.substr(4, 2), 16)
      : parseInt(hex.substr(2, 1), 16) * 17

    let lumi = (r * 299 + g * 587 + b * 114) / 1000

    if (lumi > 148) {
      return 0
    } else {
      return 1
    }
  }

  const changeColorText = useCallback((color) => {
    if (!color) return
    const lum = luminosity(color)

    root.style.setProperty('--iq-text-button', lum === 1 ? '#fff' : '#000')
  }, [])

  const handleChangeColor = useCallback(({ hex }) => {
    root.style.setProperty('--iq-primary', hex)
    root.style.setProperty('--iq-primary-light', hex)
    localStorage.setItem('colorTheme', hex)
    
    setColorTheme(hex)
    
    changeColorText(hex)
  }, [changeColorText])

  function resetColorToDefault() {
    root.style.setProperty('--iq-primary', '#d82e4c')
    root.style.setProperty('--iq-primary-light', '#ea5532')
    root.style.setProperty('--iq-text-button', '#fff')

    localStorage.removeItem('colorTheme')
  }

  useEffect(() => {
    if (colorTheme) {
      handleChangeColor({ hex: colorTheme });
      changeColorText(colorTheme);
      return;
    } else {
      resetColorToDefault();
      return;
    }
  }, [changeColorText, handleChangeColor, colorTheme])

  return (
    <BrandColorsThemeContext.Provider
      value={{
        handleChangeColor,
        resetColorToDefault,
        color: colorTheme
      }}
    >
      {children}
    </BrandColorsThemeContext.Provider>
  );
}
