import styled, { css } from 'styled-components'

export const Container = styled.div`
  border-radius: 50%;
	width: ${props => props.active ? '52px' : '60px'};
	height: ${props => props.active ? '52px' : '60px'};
	cursor: pointer;
	background: ${props => props.active ? 'var(--iq-primary)' : '#333'};
	color: #fff;
	display: inline-block;
	transition: all ${props => props.secondary ? '0s' : '0.1s'} ease-out 0s;
  position: relative;

  &:hover {
	  background: ${props => props.active ? 'var(--iq-primary)' : '#525252'};
  }

  ${props => props.secondary && css`
    width: 60px;
    height: 60px;
    background: ${props.active ? 'rgba(255, 255, 255, 0.07)' : 'transparent'};
    color: ${props.active ? '#8ab4f8' : '#fff'};

    &:hover {
      background: rgba(255, 255, 255, 0.07);
    }
  `};

  ${props => props.hasText && css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: unset;
    border-radius: 18px;
    padding: 18px;

    i {
      margin-top: 0;
    }

    span {
      margin-left: 8px;
    }
  `}

  ${props => props.disabled && css`
    opacity: 0.7;
    cursor: wait;
    pointer-events: none;
  `}

  .badge {
    align-items: center;
    color: #fff;
    height: 20px;
    background-color: #5f6368;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    min-width: 20px;
    left: 34px;
    position: absolute;
    top: 34px;
    line-height: 1.25rem;
    font-size: .75rem;
    padding: 1px 2px 0 2px;
  }

  .badge-alert {
    background-color: #de5151;
  }
`;