import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;

export const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 150px;
  }

  strong {
    margin-top: 16px;
  }
`;

export const UpgradeButton = styled(Link)`
  width: 150px;
  height: 40px;

  font-size: 14px;
  border-radius: .3rem;
  border: 0;
  /* background-color: #d82e4c; */
  /* color: #fff; */
  transition: 0.3s;
  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: center;


  /* &:hover {
    background-color: #952539;
    color: #fff;
  } */
`;