import React from 'react';

import { Container } from './styles';
import { useTranslation } from 'react-i18next';

export function RemovedFromTheRoom() {
  const { t } = useTranslation();
  function handleClickGoBack() {
    window.history.back();
  }

  return (
    <Container>
      <h2>Oops!</h2>
      <p>{t(`pages.RemovedFromTheRoom.text`)}</p>
      <button
        type='button'
        className='btn btn-primary'
        onClick={handleClickGoBack}
      >
        <i className='fas fa-arrow-left'></i>{t(`pages.RemovedFromTheRoom.buttonText`)}
      </button>
    </Container>
  );
}