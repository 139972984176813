import React from 'react'
import {Route,Redirect} from 'react-router-dom'
import { isAuthenticated, getAuthenticatedUser } from '../../services/auth'

export default function  PrivateRoutes({component: Component,...rest }){
    const user = getAuthenticatedUser();

    return (
        <Route
            {...rest}
            render={props => {
                if (user.room_type === 'meet') {
                    return <Redirect to={{ pathname: "/meet" }} />
                }

                return isAuthenticated() ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                )
            }}
        />
    )

}