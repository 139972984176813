import React, { useContext } from 'react';
import { ColorContainer } from './styles';
// import { ChromePicker } from 'react-color'
import ChromePicker from 'react-color/lib/Google'

import { BrandColorsThemeContext } from '../../context/BrandColorsTheme'

export function BrandColors() {
  const { handleChangeColor, color } = useContext(BrandColorsThemeContext)

	return (
    <ColorContainer>
      <ChromePicker onChange={ handleChangeColor } disableAlpha color={color} />
    </ColorContainer>
	);
}
