import { useEffect, useState } from "react";
import platform from "platform";

export function useInternetQuality() {
  const [ms, setMs] = useState(0)
  const [downlink, setDownlink] = useState(0)
  const [updateState, setUpdateState] = useState(false)

  useEffect(() => {
    if (
    platform.name.toLowerCase().includes('firefox') || 
    platform.name.toLowerCase().includes('safari')  ||
    !window.navigator.connection
    ) {
      return
    }

    if (window.navigator.connection.rtt !== ms || window.navigator.connection.downlink !== downlink) {
      setMs(window.navigator.connection.rtt)
      setDownlink(window.navigator.connection.downlink)
      return
    }

    setTimeout(() => {
      setUpdateState(!updateState)
    }, 5000);
    }, [updateState, ms, downlink])

  return {
     ms,
     downlink
  }
};
