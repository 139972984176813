import BackToTheFuture from "../assets/sounds/bgSounds/Back To The Future - Ofshane.mp3";
import Believe from "../assets/sounds/bgSounds/Believe (Instrumental) - NEFFEX.mp3";
import BlueDreamCheel from "../assets/sounds/bgSounds/Blue Dream - Cheel.mp3";
import ByTheFireplaceTrackTribe from "../assets/sounds/bgSounds/By the Fireplace - TrackTribe.mp3";
import CalvinHarrisJoshPan from "../assets/sounds/bgSounds/Calvin Harris - josh pan.mp3";
import DontWannaLetMyselfDown from "../assets/sounds/bgSounds/Don't Wanna Let Myself Down (Instrumental) - NEFFEX.mp3";
import FractalOfLightChrisHaugen from "../assets/sounds/bgSounds/Fractal of Light - Chris Haugen.mp3";
import GoDownSwinging from "../assets/sounds/bgSounds/Go Down Swinging (Instrumental) - NEFFEX.mp3";
import HolidayForStrings from "../assets/sounds/bgSounds/Holiday For Strings.mp3";
import IcelandicArpeggios from "../assets/sounds/bgSounds/Icelandic Arpeggios - DivKid.mp3";
import PrismBobbyRichards from "../assets/sounds/bgSounds/Prism - Bobby Richards.mp3";
import SymphonyNo5 from "../assets/sounds/bgSounds/Symphony No. 5 (by Beethoven) - Beethoven.mp3";
import TilIHearSay from "../assets/sounds/bgSounds/Til I Hear'em Say (Instrumental) - NEFFEX.mp3";
import TillILetGo from "../assets/sounds/bgSounds/Till I Let Go (Instrumental) - NEFFEX.mp3";
import WeBubbles from "../assets/sounds/bgSounds/We Bubbles - Freedom Trail Studio.mp3";

export const bgSounds = [
  {
    id: '1',
    name: 'Back To The Future - Ofshane',
    src: BackToTheFuture,
  },
  {
    id: '2',
    name: 'Believe (Instrumental) - NEFFEX',
    src: Believe,
  },
  {
    id: '3',
    name: 'Blue Dream - Cheel.mp3',
    src: BlueDreamCheel,
  },
  {
    id: '4',
    name: 'By the Fireplace - TrackTribe',
    src: ByTheFireplaceTrackTribe,
  },
  {
    id: '5',
    name: 'Calvin Harris - josh pan',
    src: CalvinHarrisJoshPan,
  },
  {
    id: '6',
    name: "Don't Wanna Let Myself Down (Instrumental) - NEFFEX",
    src: DontWannaLetMyselfDown,
  },
  {
    id: '7',
    name: 'Fractal of Light - Chris Haugen',
    src: FractalOfLightChrisHaugen,
  },
  {
    id: '8',
    name: 'Go Down Swinging (Instrumental) - NEFFEX',
    src: GoDownSwinging,
  },
  {
    id: '9',
    name: 'Holiday For Strings',
    src: HolidayForStrings,
  },
  {
    id: '10',
    name: 'Icelandic Arpeggios - DivKid',
    src: IcelandicArpeggios,
  },
  {
    id: '11',
    name: 'Prism - Bobby Richards',
    src: PrismBobbyRichards,
  },
  {
    id: '12',
    name: 'Symphony No. 5 (by Beethoven) - Beethoven',
    src: SymphonyNo5,
  },
  {
    id: '13',
    name: "Til I Hear'em Say (Instrumental) - NEFFEX",
    src: TilIHearSay,
  },
  {
    id: '14',
    name: 'Till I Let Go (Instrumental) - NEFFEX',
    src: TillILetGo,
  },
  {
    id: '15',
    name: 'We Bubbles - Freedom Trail Studio',
    src: WeBubbles,
  }
];
