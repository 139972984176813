import styled, { css } from 'styled-components';
import { motion } from 'framer-motion'
import { Emphasis, Mosaic, Overlay, Stream } from '../BigScreen/styles'

export const Container = styled(motion.div)`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	position: relative;
  border-radius: ${props => props.borderRadius ? '6px' : '0'};

	video {
		width: calc(4px + 100%);
		height: calc(4px + 100%);
    max-width: calc(4px + 100%);
    max-height: calc(4px + 100%);
	}
`;

export const DisplayName = styled(motion.div)`
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #1a1a1aba;
  color: #fff;
  padding: ${props => props.responsive ? 'calc(.5% + 0.2rem) calc(1% + 0.3rem)' : '.2rem .6rem'};
  font-size: ${props => props.responsive ? 'calc(8pt + 12%)' : '11pt'};
  border-top-right-radius: 5px;
  font-weight: 700;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;

  ${props => !props.responsive && css`
    /* StreamName when it's not in fullscreen */
    ${Emphasis} ~ ${Mosaic} &,
    ${Emphasis}:first-child:not(:nth-last-child(1)) &,
    ${Stream}:first-child:not(:nth-last-child(1)) &,
    ${Stream}:first-child:not(:nth-last-child(1)) ~ ${Stream} &,
    ${Overlay} & {
      padding: .16rem .3rem;
      font-size: 8pt;
    }
  `}
`;

export const Placeholder = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #444;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    width: 25%;
    height: 44.5%;
    border-radius: 50%;
    background: ${props => props.placeholderColor || '#f55'};
    color: #fff;
    font-size: 95%;
    font-weight: bold;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
`;