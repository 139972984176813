import { memo } from 'react';
import { AnimatePresence } from 'framer-motion';

import ScaleText from '../ScaleText';
import AspectRatio from '../AspectRatio';
import PlayerControls from '../PlayerControls';
import { 
	defaultFadeInOutTransition,
	defaultTransition 
} from '../BigScreen';	

import { 
	Container, 
	DisplayName,
	Placeholder,
} from './styles';

const VideoElement = memo(({ srcObject, ...rest }) => {
	return (
		<video
			ref={(element) => {
				if (element) {
					element.srcObject = srcObject;
				}
			}}
			autoPlay
			webkit-playsinline
			playsInline
			muted
			{...rest}
		/>
	)
});

export default function Video({
	srcObject,
	aspectRatio,
	background = 'solid',
	videoElement,
	displayName,
	displayNameResponsive,
	showPlaceholder,
	placeholderColor,
	borderRadius,
	...rest
}) {
	const initials = 
		displayName
			?.split(' ')
			?.map(name => name.charAt(0))
			?.slice(0, 2)
			?.join('')
			?.toUpperCase() || '';

	return (
		<AspectRatio ratio={aspectRatio ? 9/16 : 0}>
			<Container
				borderRadius={borderRadius}
				animate={{
					backgroundColor: background === 'solid' 
						? '#444' : 'rgba(0, 0, 0, 0)',
				}}
				initial={false}
				layout
				transition={defaultTransition}
			>
				<VideoElement srcObject={srcObject} {...rest} />

				{showPlaceholder && (
					<ScaleText>
						<Placeholder placeholderColor={placeholderColor}>
								<p>
									{initials}
								</p>
						</Placeholder>
					</ScaleText>
				)}

				{videoElement && (
					<PlayerControls videoElement={videoElement} absolute background />
				)}

				<AnimatePresence>
					{displayName && (
						displayNameResponsive ? (
							<ScaleText key={displayName} transition={defaultTransition}>
								<DisplayName
									key={displayName} 								
									transition={defaultTransition}
									responsive
								>
									{displayName}
								</DisplayName>
							</ScaleText>
						) : (
							<DisplayName 
								key={displayName} 								
								transition={defaultTransition}
								layout
							>
								{displayName}
							</DisplayName>
						)
					)}
				</AnimatePresence>
			</Container>
		</AspectRatio>
	);
}
