import { useState, useRef } from 'react';
import { Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LayoutSoloOnImg } from '../../../../assets/images/layout-bigscreen/solo-on.svg';
import { ReactComponent as LayoutSoloOffImg } from '../../../../assets/images/layout-bigscreen/solo-off.svg';
import { ReactComponent as LayoutMosaicOnImg } from '../../../../assets/images/layout-bigscreen/mosaic-on.svg';
import { ReactComponent as LayoutMosaicOffImg } from '../../../../assets/images/layout-bigscreen/mosaic-off.svg';
import { ReactComponent as LayoutGroupOnImg } from '../../../../assets/images/layout-bigscreen/group-on.svg';
import { ReactComponent as LayoutGroupOffImg } from '../../../../assets/images/layout-bigscreen/group-off.svg';
import { ReactComponent as LayoutLeaderOnImg } from '../../../../assets/images/layout-bigscreen/leader-on.svg';
import { ReactComponent as LayoutLeaderOffImg } from '../../../../assets/images/layout-bigscreen/leader-off.svg';
import { ReactComponent as LayoutPresentationOnImg } from '../../../../assets/images/layout-bigscreen/presentation-on.svg';
import { ReactComponent as LayoutPresentationOffImg } from '../../../../assets/images/layout-bigscreen/presentation-off.svg';
import { ReactComponent as LayoutOverlayTopLeftOnImg } from '../../../../assets/images/layout-bigscreen/overlay-top-left-on.svg';
import { ReactComponent as LayoutOverlayTopLeftOffImg } from '../../../../assets/images/layout-bigscreen/overlay-top-left-off.svg';
import { ReactComponent as LayoutOverlayTopRightOnImg } from '../../../../assets/images/layout-bigscreen/overlay-top-right-on.svg';
import { ReactComponent as LayoutOverlayTopRightOffImg } from '../../../../assets/images/layout-bigscreen/overlay-top-right-off.svg';
import { ReactComponent as LayoutOverlayBottomLeftOnImg } from '../../../../assets/images/layout-bigscreen/overlay-bottom-left-on.svg';
import { ReactComponent as LayoutOverlayBottomLeftOffImg } from '../../../../assets/images/layout-bigscreen/overlay-bottom-left-off.svg';
import { ReactComponent as LayoutOverlayBottomRightOnImg } from '../../../../assets/images/layout-bigscreen/overlay-bottom-right-on.svg';
import { ReactComponent as LayoutOverlayBottomRightOffImg } from '../../../../assets/images/layout-bigscreen/overlay-bottom-right-off.svg';
import { ReactComponent as LayoutFullscreenOnImg } from '../../../../assets/images/layout-bigscreen/fullscreen-on.svg';
import { ReactComponent as LayoutFullscreenOffImg } from '../../../../assets/images/layout-bigscreen/fullscreen-off.svg';

import { Container, ContainerPopup } from './styles';

export function BigScreenLayouts({ layout, onLayoutChange }) {
  const [overlayLayoutPopupOpen, setOverlayLayoutPopupOpen] = useState(false);
  const overlayLayoutImgBtnRef = useRef();
  
  const { t } = useTranslation();

  function handleLayoutChange(newLayout) {
    onLayoutChange(newLayout);
    setOverlayLayoutPopupOpen(false);
  }

  return (
    <Container>
      <OverlayTrigger rootClose delay={{ show: 500 }} placement="top" overlay={<Tooltip>{t(`pages.Studio.tooltipLayoutSoloCam`)}</Tooltip>}>
        <div onClick={() => handleLayoutChange('solo')}>
          {layout === 'solo' ? <LayoutSoloOnImg /> : <LayoutSoloOffImg />}
        </div>
      </OverlayTrigger>

      <OverlayTrigger rootClose delay={{ show: 500 }} placement="top" overlay={<Tooltip>{t(`pages.Studio.tooltipLayoutGroupCams`)}</Tooltip>}>
        <div onClick={() => handleLayoutChange('group')}>
          {layout === 'group' ? <LayoutGroupOnImg /> : <LayoutGroupOffImg />}
        </div>
      </OverlayTrigger>

      <OverlayTrigger rootClose delay={{ show: 500 }} placement="top" overlay={<Tooltip>{t(`pages.Studio.tooltipLayoutLeaderCam`)}</Tooltip>}>
        <div onClick={() => handleLayoutChange('leader')}>
          {layout === 'leader' ? <LayoutLeaderOnImg /> : <LayoutLeaderOffImg />}
        </div>
      </OverlayTrigger>

      <OverlayTrigger rootClose delay={{ show: 500 }} placement="top" overlay={<Tooltip>{t(`pages.Studio.tooltipLayoutPresentationWithCams`)}</Tooltip>}>
        <div onClick={() => handleLayoutChange('presentation')}>
          {layout === 'presentation' ? <LayoutPresentationOnImg /> : <LayoutPresentationOffImg />}
        </div>
      </OverlayTrigger>

      <OverlayTrigger rootClose delay={{ show: 500 }} placement="top" overlay={<Tooltip>{t(`pages.Studio.tooltipLayoutMosaic`)}</Tooltip>}>
        <div onClick={() => handleLayoutChange('mosaic')}>
          {layout === 'mosaic' ? <LayoutMosaicOnImg /> : <LayoutMosaicOffImg />}
        </div>
      </OverlayTrigger>

      <Overlay target={overlayLayoutImgBtnRef.current} show={overlayLayoutPopupOpen} placement="top" rootClose delay={{ show: 500 }} onHide={() => setOverlayLayoutPopupOpen(false)}>
        <ContainerPopup className="mb-4">
          <span>{t(`pages.Studio.chooseAnOptionText`)}</span>

          <Container>
            <OverlayTrigger rootClose delay={{ show: 500 }} placement="top" overlay={<Tooltip>{t(`pages.Studio.tooltipLayoutTopLeft`)}</Tooltip>}>
              <div onClick={() => handleLayoutChange('overlay-top-left')}>
                {layout === 'overlay-top-left' ? <LayoutOverlayTopLeftOnImg /> : <LayoutOverlayTopLeftOffImg />}
              </div>
            </OverlayTrigger>

            <OverlayTrigger rootClose delay={{ show: 500 }} placement="top" overlay={<Tooltip>{t(`pages.Studio.tooltipLayoutTopRight`)}</Tooltip>}>
              <div onClick={() => handleLayoutChange('overlay-top-right')}>
                {layout === 'overlay-top-right' ? <LayoutOverlayTopRightOnImg /> : <LayoutOverlayTopRightOffImg />}
              </div>
            </OverlayTrigger>

            <OverlayTrigger rootClose delay={{ show: 500 }} placement="top" overlay={<Tooltip>{t(`pages.Studio.tooltipLayoutBottomLeft`)}</Tooltip>}>
              <div onClick={() => handleLayoutChange('overlay-bottom-left')}>
                {layout === 'overlay-bottom-left' ? <LayoutOverlayBottomLeftOnImg /> : <LayoutOverlayBottomLeftOffImg />}
              </div>
            </OverlayTrigger>

            <OverlayTrigger rootClose delay={{ show: 500 }} placement="top" overlay={<Tooltip>{t(`pages.Studio.tooltipLayoutBottomRight`)}</Tooltip>}>
              <div onClick={() => handleLayoutChange('overlay-bottom-right')}>
                {layout === 'overlay-bottom-right' ? <LayoutOverlayBottomRightOnImg /> : <LayoutOverlayBottomRightOffImg />}
              </div>
            </OverlayTrigger>
          </Container>
        </ContainerPopup>
      </Overlay>

      <OverlayTrigger rootClose delay={{ show: 500 }} placement="top" overlay={<Tooltip>{t(`pages.Studio.tooltipLayoutOverlay`)}</Tooltip>}>
        <div ref={overlayLayoutImgBtnRef} onClick={() => setOverlayLayoutPopupOpen(!overlayLayoutPopupOpen)}>
          {layout === 'overlay-top-left' ? (
            <LayoutOverlayTopLeftOnImg />
          ) : layout === 'overlay-top-right' ? (
            <LayoutOverlayTopRightOnImg />
          ) : layout === 'overlay-bottom-left' ? (
            <LayoutOverlayBottomLeftOnImg />
          ) : layout === 'overlay-bottom-right' ? (
            <LayoutOverlayBottomRightOnImg />
          ) : (
            <LayoutOverlayBottomLeftOffImg />
          )}
        </div>
      </OverlayTrigger>


      <OverlayTrigger rootClose delay={{ show: 500 }} placement="top" overlay={<Tooltip>{t(`pages.Studio.tooltipLayoutFullscreenPresentation`)}</Tooltip>}>
        <div onClick={() => handleLayoutChange('fullscreen')}>
          {layout === 'fullscreen' ? <LayoutFullscreenOnImg /> : <LayoutFullscreenOffImg />}
        </div>
      </OverlayTrigger>
    </Container>
  )
}