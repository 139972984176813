import React from 'react'

import {Redirect} from 'react-router-dom'

import {isAuthenticated} from '../../services/auth'

export default function Auth(){
    
    
    // return isAuthenticated() ? (<Redirect to="/meet"/> ) : (<Redirect to="/signin"/>) 
    return isAuthenticated() ? (<Redirect to="/new-room"/> ) : (<Redirect to="/signin"/>) 
}
