import { useState } from 'react'

import { formatCreditCardNumber, formatCVC, formatExpirationDate } from '../utils/formatCardValues'
import { getAuthenticatedUser } from '../services/auth'
import useTemporaryError from '../hooks/useTemporaryError'
import api from '../services/api'

const DEFAULT_CARD_DATA = {
  cvc: '',
  expiry: '',
  name: '',
  number: '', 
  focused: null
}

export default function useCreditCardForm({ onCardSaved, onCanceled } = {}) {
  const user = getAuthenticatedUser();

  const [cardDraft, setCardDraft] = useState(DEFAULT_CARD_DATA);
  const [error, setError] = useTemporaryError('');
  const [loading, setLoading] = useState(false);

  function resetForm(resetError) {
    setCardDraft(DEFAULT_CARD_DATA);
    if (resetError) setError('');
  }

	function onInputFocus(e) {
		setCardDraft((oldState) => ({ ...oldState, focused: e.target.name }));
	}

	function onInputChange(e) {
		const { name, value } = e.target;
    
    let formattedValue;

    switch (e.target.name) {
      case 'number':
        formattedValue = formatCreditCardNumber(value);
        break;
      case 'expiry':
        formattedValue = formatExpirationDate(value);
        break;
      case 'cvc':
        formattedValue = formatCVC(value);
        break;
      default:
        formattedValue = value;
    }

		setCardDraft((oldState) => ({ ...oldState, [name]: formattedValue }));
	}

  function onCancel() {
    if (!loading) {
      resetForm(true);
      if (onCanceled) {
        onCanceled();
      }
    }
  }

  async function onSubmit() {
    setCardDraft(oldState => ({ ...oldState, focused: null }));
    setError('');
    setLoading(true);

    const { cvc, expiry, name, number } = cardDraft;

    try {
      if (!cvc || !expiry || !name || !number) {
        setError('Please fill out all fields.');
        return;
      }
      
      const [exp_month, exp_year] = expiry.split('/');

      const response = await api.post(`/users/${user._id}/card`, {
        number,
        name,
        exp_month,
        exp_year,
        cvc
      });

      if (onCardSaved) {
        await onCardSaved(response.data);
      }

      return true;
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.type === 'card_error') {
        setError(err.response.data.message)
      } else {
        setError('Something went wrong. Please try again.');
      }

      return false;
    } finally {
      setLoading(false);
    }
  }

  return {
    cardDraft,
    onSubmit,
    onCancel,
    error,
    loading,
    onInputChange,
    onInputFocus,
    resetForm
  }
}