import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../services/api";
import { Modal } from "react-bootstrap";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AccountContext } from "../../context/Accounts";
import { FaYoutube, FaTwitch, FaListAlt } from "react-icons/fa";
import customRtmpImg from "../../assets/images/custom_rtmp.svg";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';

export function RoomModal({ showRoomModal, handleCloseRoomModal, destinationLoaded, modalTitle, reloadRooms }) {
  const { t } = useTranslation();
  const [roomName, setRoomName] = useState('');
  const [privacy, setPrivacy] = useState('');
  const [roomNameError, setRoomNameError] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);
  const [destinationError, setDestinationError] = useState(false);
  const [twitchCategoryError, setTwitchCategoryError] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({
    _id: "",
    access_token: "",
    avatar_url: "",
    client_id: "",
    display_name: "",
    provider_id: "",
    refresh_token: "",
    type_account: "",
    scope: ""
  });
  const [hoverImageAccount, setHoverImageAccount] = useState({
    status: false,
    id: "",
  });
  const [twitchCategory, setTwitchCategory] = useState('')
  const { accounts } = useContext(AccountContext);

  const labelAccount = {
    twitch: {
      backgroundColor: "#9146FF",
      icon: <FaTwitch size={12} color="#fff" />,
    },
    custom_rtmp: {
      backgroundColor: "#ffae00",
      icon: <FaListAlt size={12} color="#2d2d2d" />,
    },
    youtube: {
      backgroundColor: "#FF0000",
      icon: <FaYoutube size={12} color="#fff" />,
    },
  };

  const handleUpdateRoom = async () => {
    let isFormInvalid = false;

    if (!selectedAccount._id) {
      setDestinationError(true);
      isFormInvalid = true;
    }
    
    if (!roomName) {
      setRoomNameError(true);
      isFormInvalid = true;
    }

    if (selectedAccount.type_account === 'youtube' && !!privacy === false) {
      setPrivacyError(true);
      isFormInvalid = true;
    }

    if (selectedAccount.type_account === 'twitch' && !!twitchCategory === false) {
      setTwitchCategoryError(true);
      isFormInvalid = true;
    }

    if (isFormInvalid) {
      return false;
    }

    try {
      let response = await api.put(`room/update`, {
        room_id: destinationLoaded._id,
        roomName,
        privacy: privacy ? privacy : null,
        twitchCategory: twitchCategory ? twitchCategory : null,
        destination_id: selectedAccount._id
      });

      if (response.status === 200 && response.data.message === 'Room updated!') {
        handleCloseRoomModal()
        reloadRooms()
        setRoomName('')
        setPrivacy('')
        setTwitchCategory('')
        setSelectedAccount({})
        return
      } else {
        toast.error(t(`components.RoomModal.toastErrorUpdateRoom`))
      }
    } catch (err) {
      toast.error(t(`components.RoomModal.toastErrorUpdateRoom`))
      console.error(err.message);
    }
  };

  function handleTypeRoomName(event) {
    const roomName = event.target.value;
    if (roomName) {
      setRoomNameError(false);
    }
    setRoomName(roomName);
  }

  function handleSelectPrivacy(event) {
    const privacy = event.target.value;
    if (privacy) {
      setPrivacyError(false);
    }
    setPrivacy(privacy);
  }

  function handleClickAccount(account) {
    if (selectedAccount._id === account._id) {
      return;
      // setSelectedAccount({});
    } else {
      setSelectedAccount(account);
      setDestinationError(false);
    }
  }

  function handleSelectTwitchCategory(event) {
    const category = event.target.value;
    setTwitchCategory(category);
    if (twitchCategoryError) {
      setTwitchCategoryError(false);
    }
    setTwitchCategory(category);
  }

  return (
    <>
      {destinationLoaded?._id && (
        <Modal
          show={showRoomModal}
          onHide={handleCloseRoomModal}
        >
          <Modal.Header closeButton>
            <Modal.Title className=" font-weight-bold ">
              {modalTitle}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {accounts.length > 0 ? (
              <div className="accounts_container">
              {accounts.map((account) => (
                <OverlayTrigger
                  key={account._id}
                  placement="bottom"
                  overlay={<Tooltip>{account.display_name}</Tooltip>}
                >
                  <div
                    className={`account_container user-img img-fluid`}
                    onClick={() => handleClickAccount(account)}
                  >
                    <img
                      src={account.type_account === 'custom_rtmp' ? customRtmpImg : account.avatar_url}
                      alt={account.display_name}
                      onMouseEnter={() =>
                        setHoverImageAccount({
                          status: true,
                          id: account._id,
                        })
                      }
                      onMouseLeave={() =>
                        setHoverImageAccount({
                          status: false,
                          id: account._id,
                        })
                      }
                      style={{
                        border:
                          hoverImageAccount.id === account._id &&
                          hoverImageAccount.status
                            ? `2px solid ${
                                labelAccount[account.type_account][
                                  "backgroundColor"
                                ]
                              }`
                            : selectedAccount._id === account._id &&
                              `2px solid ${
                                labelAccount[account.type_account][
                                  "backgroundColor"
                                ]
                              }`,
                      }}
                    />
                    {account.type_account !== 'custom_rtmp' && (
                      <div
                        className="icon"
                        style={{
                          background:
                            labelAccount[account.type_account]["backgroundColor"],
                        }}
                      >
                        {labelAccount[account.type_account]["icon"]}
                      </div>
                    )}
                  </div>
                </OverlayTrigger>
              ))}
              {destinationError && (
                <small className="text-danger">
                  Select a destionation account
                </small>
              )}
            </div>
            ) : (
              <Link to="/destinations">
                {t(`components.RoomModal.createAccountLinkText`)}
              </Link>
            )}
            <div className="col-12">
              <div className="p-2 row d-flex flex-column">
                <input
                  placeholder={t(`components.RoomModal.broadcastTitlePlaceholder`)}
                  className="form-control mt-2"
                  type="text"
                  value={roomName}
                  maxLength="100"
                  onChange={handleTypeRoomName}
                />
                <div className="d-flex">
                  {roomNameError && (
                    <small className="text-danger">
                      {t(`components.RoomModal.roomNameError`)}
                    </small>
                  )}
                  <small style={{ flex: 1, textAlign: 'end' }}>{roomName?.length ?? 0}/100</small>
                </div>
                {selectedAccount?.type_account === 'youtube' && (
                  <select
                    className="form-control mt-2"
                    name="privacy"
                    id="privacy"
                    value={privacy}
                    onChange={handleSelectPrivacy}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      {t(`components.RoomModal.optionDisabledTypeAccount`)}
                    </option>
                    <option value="public"> {t(`components.RoomModal.optionPublicTypeAccount`)}</option>
                    <option value="private"> {t(`components.RoomModal.optionPrivateTypeAccount`)}</option>
                    <option value="unlisted"> {t(`components.RoomModal.optionUnlistedTypeAccount`)}</option>
                  </select>
                )}
                {privacyError && (
                  <small className="text-danger">
                    {t(`components.RoomModal.errorYoutubePrivacy`)}
                  </small>
                )}
                {selectedAccount?.type_account === 'twitch' && (
                  <select
                    className="form-control mt-2"
                    name="twitchCategory"
                    id="twitchCategory"
                    value={twitchCategory}
                    onChange={handleSelectTwitchCategory}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      {t(`components.RoomModal.optionDisabledTwitchCategory`)}
                    </option>
                    <option value={'Talk Shows & Podcasts'}>{t('components.RoomModal.optionTalkShowsTwitchCategory')}</option>
                    <option value="Just Chatting">{t(`components.RoomModal.optionJustChattingTwitchCategory`)}</option>
                    <option value={'Fitness & Health'}>{t('components.RoomModal.optionFitnessHealthTwitchCategory')}</option>
                    <option value="Art">{t('components.RoomModal.optionArtTwitchCategory')}</option>
                    <option value={'Food & Drink'}>{t('components.RoomModal.optionFoodDrinkTwitchCategory')}</option>
                    <option value="Politics">{t('components.RoomModal.optionPoliticsTwitchCategory')}</option>
                    <option value={'Science & Technology'}>{t('components.RoomModal.optionScienceTechTwitchCategory')}</option>
                    <option value="League of Legends">League of Legends</option>
                    <option value="Valorant">Valorant</option>
                    <option value="Counter-Strike: Global Offensive">Counter-Strike: Global Offensive</option>
                    <option value="Grand Theft Auto V">Grand Theft Auto V</option>
                  </select>
                )}
                {twitchCategoryError && (
                  <small className="text-danger">
                    {t(`components.RoomModal.errorTwitchCategory`)}
                  </small>
                )}
                <button
                  className="btn btn-primary mt-5"
                  onClick={handleUpdateRoom}
                >
                  {t(`components.RoomModal.updateRoomButtonText`)}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}
