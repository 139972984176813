import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 15px;
  padding: 2% 3%;
  margin-top: 30px;
  max-width: 100%;
  gap: 12px;

  > div {
    cursor: pointer;
    transition: all 0.2s ease-out 0s;
  }

  > div:hover {
    opacity: 0.8;
  }

  > div + div {
    margin-left: 8px;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    margin-top: 15px;

    > div {
      width: 80px;
    }
  }

  @media (max-width: 480px) {
    margin-top: 12px;

    > div {
      width: 65px;
    }
  }
`;

export const ContainerPopup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 15px;
  padding-top: 0.5%;
  max-width: 100%;
  width: 22%;
  
  @media (max-width: 980px) {
    width: 50%;
  }

  > div {
    margin: 0;
  }
`;