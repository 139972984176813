/* eslint-disable no-cond-assign */
function getYoutubeThumbnailRes(url, quality) {
  if(url){
    var video_id, result;
    if(result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))
    {
        video_id = result.pop();
    }
    else if(result = url.match(/youtu.be\/(.{11})/))
    {
        video_id = result.pop();
    }

    if(video_id){
        if(typeof quality == "undefined"){
            quality = 'high';
        }
    
        var quality_key = 'maxresdefault'; // Max quality
        if(quality === 'low'){
            quality_key = 'sddefault';
        }else if(quality === 'medium'){
            quality_key = 'mqdefault';
        } else if (quality === 'high') {
            quality_key = 'hqdefault';
        }

        return "http://img.youtube.com/vi/"+video_id+"/"+quality_key+".jpg";
    }
  }
  return false;
}

export function getYoutubeThumbnail(url, quality) {
  const qualities = quality ? [quality] : ['low', 'medium', 'high', 'max'];
  let thumbnail = null;
  while (!thumbnail && qualities.length) {
    thumbnail = getYoutubeThumbnailRes(url, qualities.pop()); 
  }
  return thumbnail;
}

export async function getVimeoThumbnail(url) {
  return new Promise((resolve) => {
    fetch(`https://vimeo.com/api/oembed.json?url=${url}`)
      .then(response => response.json())
      .then((data) => {
        if (!data?.thumbnail_url) {
          resolve(null);
        }
        resolve(data.thumbnail_url);
      })
      .catch((err) => {
        console.error(err);
        resolve(null);
      });
  })
}