import React, { useState, useRef } from 'react';
import { Link, useHistory } from "react-router-dom";
import logo from '../../../assets/images/logo/n1-1.png';
import './SideBar.css';
import Swal from 'sweetalert2';

import { getAuthenticatedUser } from '../../../services/auth';
import { useClientLogo } from '../../../hooks/useClientLogo';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import useContainerDimensions from '../../../hooks/useContainerDimensions';

import USFlagImg from '../../../assets/images/flags/us_flag.svg';
import BRFlagImg from '../../../assets/images/flags/br_flag.svg';

import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useAuth } from "../../../context/Auth";

function Sidebar(props) {
    const [isOpen, setIsOpen] = useState(false);
    const { i18n, t } = useTranslation()
    const user = getAuthenticatedUser()
    const is4webClient = user.client_type === '4web'
    const { clientLogo } = useClientLogo()
    const sidebarRef = useRef(null);
    const dimensions = useContainerDimensions(sidebarRef);
    const { modules } = useAuth();

    const history = useHistory()

    function handleOpenCloseSidebar(e) {
        setIsOpen(e.target.checked);
    }

    function handleNavigation(toPage) {
        if (props.page === 'studio') {
            const text = props.broadcastStatus === 'started' ? t(`components.Sidebar.swalExitFromStudioWhenLiveOn`) : t(`components.Sidebar.swalExitFromStudio`);

            Swal.fire({
                icon: 'warning',
                title: `<p>${t(`components.Sidebar.swalTitle`)}</p>`,
                html: text,
                showCancelButton: true,
                confirmButtonText: t(`components.Sidebar.swalConfirmButton`),
                cancelButtonText: t(`components.Sidebar.swalCancelButton`),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if (props.broadcastStatus === 'started') {
                        await props.stopBroadcast()
                    }

                    if (props.page === 'studio') {
                        window.location.href = toPage
                    } else {
                        history.push(toPage)
                    }
                } else {
                    return false
                }
            })
        } else {
            history.push(toPage)
        }
    }

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('sidebar-main');
        } else {
            document.body.classList.remove('sidebar-main');
        }
    }, [isOpen]);

    useOnClickOutside(sidebarRef, () => {
        setIsOpen(false);
    });

    useEffect(() => {
        if (isOpen) {
            setIsOpen(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dimensions]);

    return (
        <React.StrictMode>
            <div ref={sidebarRef} className="iq-sidebar">
                <div className="d-flex justify-content-between sidebar-logo">
                    {/* {props.page === 'meet' ? (
                        <>
                            <a href="/meet">
                                <img src={logo} className="img-fluid" alt="" />
                            </a>
                        </>
                    ) : ( */}
                    <>
                        <Link to={`/${props.page}`}>
                            <img src={clientLogo?.url ? clientLogo.url : logo} className="img-fluid" alt="" />
                        </Link>
                    </>
                    {/* )} */}
                </div>
                <div id="sidebar-scrollbar" className="sidebar-scrollbar" data-scrollbar="true" style={{ overflow: "hidden", outline: "none" }}>
                <div className="scroll-content">
                    <nav className="iq-sidebar-menu">
                        <ul id="iq-sidebar-toggle" className="iq-menu">
                            {props.page === 'meet' ? (
                                <>
                                    <li className="active">
                                        <a href="/meet">
                                            <i className="ri-home-4-line" />
                                            <span>{t(`components.Sidebar.homeText`)}</span>
                                        </a>
                                    </li>
                                    <li className="flags">
                                        <a className="flags-container iq-waves-effect c-pointer">
                                            {i18n.language ===  'pt-BR' ? (
                                                <button className="flag-image-button" onClick={() => i18n.changeLanguage('pt-BR')}>
                                                    <img src={BRFlagImg} alt="Flag BR" />
                                                </button>
                                            ) : (
                                                <button className="flag-image-button" onClick={() => i18n.changeLanguage('en-US')}>
                                                    <img src={USFlagImg} alt="Flag US" />
                                                </button>
                                            )}
                                            <span>
                                                {i18n.language === 'en-US' ? (
                                                    <button className="flag-image-button" onClick={() => i18n.changeLanguage('pt-BR')}>
                                                        <img src={BRFlagImg} alt="Flag BR" />
                                                    </button>                                                    
                                                ) : (
                                                    <button className="flag-image-button" onClick={() => i18n.changeLanguage('en-US')}>
                                                        <img src={USFlagImg} alt="Flag US" />
                                                    </button>
                                                )}
                                            </span>
                                        </a>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li className={`${!['settings', 'billing', 'destinations', 'support'].includes(props.page) ? 'active' : ''}`}>
                                        <a className="iq-waves-effect c-pointer" onClick={() => handleNavigation('/new-room')}>
                                            <i className="ri-home-4-line" />
                                            <span>{t(`components.Sidebar.homeText`)}</span>
                                        </a>
                                    </li>


                                    <li className={`${props.page === 'settings' ? 'active' : ''}`}>
                                        <a className="iq-waves-effect c-pointer" onClick={() => handleNavigation('/settings')}>
                                            <i className="ri-settings-4-line onboarding-homepage-step-3" />
                                            <span>{t(`components.Sidebar.settings`)}</span>
                                        </a>
                                    </li>
                                    

                                    {is4webClient && (
                                        <li className={`${props.page === 'billing' ? 'active' : ''}`}>
                                            <a className="iq-waves-effect c-pointer" onClick={() => handleNavigation('/billing')}>
                                                <i className="ri-bill-line" />
                                                <span>
                                                    {modules.logo_required ? (
                                                        props.page === 'billing' ? (
                                                            t(`components.Sidebar.billingFree`)
                                                        ) : (
                                                            <b style={{color: '#ff002d'}}>{t(`components.Sidebar.billingFree`)}</b>
                                                        )
                                                    ) : (
                                                        t(`components.Sidebar.billing`)
                                                    )}
                                                </span>
                                            </a>
                                        </li>
                                    )}

                                    
                                    <li className={`${props.page === 'destinations' ? 'active' : ''}`}>
                                        <a className="iq-waves-effect c-pointer" onClick={() => handleNavigation('/destinations')}>
                                            <i className="ri-share-line onboarding-homepage-step-4" />
                                            <span>{t(`components.Sidebar.destinations`)}</span>
                                        </a>
                                    </li>
                                    

                                    {props.onHelpClick && (
                                        <li>
                                            <a className="iq-waves-effect c-pointer" onClick={props.onHelpClick}>
                                                <i className="ri-question-line" />
                                                <span>{t(`components.Sidebar.help`)}</span>
                                            </a>
                                        </li>
                                    )}

                                    {is4webClient && (
                                        <li className={`${props.page === 'support' ? 'active' : ''}`}>
                                            <a className="iq-waves-effect c-pointer" onClick={() => handleNavigation('/support')}>
                                                <i className="ri-chat-3-line" />
                                                <span>{t(`components.Sidebar.support`)}</span>
                                            </a>
                                        </li>
                                    )}

                                    
                                    <li className={`${props.page === 'logout' ? 'active' : ''}`}>
                                        <a className="iq-waves-effect c-pointer" onClick={() => handleNavigation('/logout')}>
                                            <i className="ri-logout-box-line" />
                                            <span>{t(`components.Sidebar.logout`)}</span>
                                        </a>
                                    </li>
                                    

                                    <li className="flags">
                                        <a className="flags-container iq-waves-effect c-pointer">
                                            {i18n.language ===  'pt-BR' ? (
                                                <button className="flag-image-button" onClick={() => i18n.changeLanguage('pt-BR')}>
                                                    <img src={BRFlagImg} alt="Flag BR" />
                                                </button>
                                            ) : (
                                                <button className="flag-image-button" onClick={() => i18n.changeLanguage('en-US')}>
                                                    <img src={USFlagImg} alt="Flag US" />
                                                </button>
                                            )}
                                            <span>
                                                {i18n.language === 'en-US' ? (
                                                    <button className="flag-image-button" onClick={() => i18n.changeLanguage('pt-BR')}>
                                                        <img src={BRFlagImg} alt="Flag BR" />
                                                    </button>                                                    
                                                ) : (
                                                    <button className="flag-image-button" onClick={() => i18n.changeLanguage('en-US')}>
                                                        <img src={USFlagImg} alt="Flag US" />
                                                    </button>
                                                )}
                                            </span>
                                        </a>
                                    </li>
                                </>
                            )}
                        </ul>
                    </nav>
                </div>
                <div className="scrollbar-track scrollbar-track-x" style={{ display: "none" }}><div className="scrollbar-thumb scrollbar-thumb-x" style={{ width: "260px", transform: "translate3d(0px, 0px, 0px)" }}></div></div><div className="scrollbar-track scrollbar-track-y" style={{ display: "block" }}><div className="scrollbar-thumb scrollbar-thumb-y" style={{ height: "62.7479px", transform: "translate3d(0px, 0px, 0px)" }}></div></div></div>
                
                <div className="menu-button-container">
                    <input 
                        id="menu-hamburguer" 
                        type="checkbox" 
                        checked={isOpen} 
                        onChange={handleOpenCloseSidebar} 
                    />

                    <label for="menu-hamburguer">
                        <div className="menu">
                            <span className="hamburguer"></span>
                        </div>
                    </label>

                    <button onClick={handleOpenCloseSidebar}>aoskdasd</button>
                </div>
            </div>
        </React.StrictMode>
    )
}

export default Sidebar;