export function volumeMeter(audioStream, callback) {
  let audioContext = new AudioContext()
  let analyser = audioContext.createAnalyser()
  let microphone = audioContext.createMediaStreamSource(audioStream)
  let javascriptNode = audioContext.createScriptProcessor(2048, 1, 1)

  analyser.smoothingTimeConstant = 0.8
  analyser.fftSize = 1024

  microphone.connect(analyser)
  analyser.connect(javascriptNode)
  javascriptNode.connect(audioContext.destination)

  let volume = 0;

  javascriptNode.onaudioprocess = function () {
    let array = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(array);
    let values = 0;

    let length = array.length;
    for (let i = 0; i < length; i++) {
      values += (array[i]);
    }

    let average = values / length;
    volume = Math.max(average, volume * 0.96);

    callback(Math.round(volume));
  }

  return () => {
    javascriptNode.onaudioprocess = null;
    microphone.disconnect();
    analyser.disconnect();
    javascriptNode.disconnect();
    audioContext.close();
  }
}