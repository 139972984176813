import styled from 'styled-components'

export const Button = styled.button`
    /* background-color: #d82e4c; */
    /* color: #fff; */
    transition: 0.3s;
    display: flex;
    align-items: center;

    /* &:hover {
      background-color: #952539;
      color: #fff;
    } */
`;

export const DeleteLogoImageButton = styled.button`
  cursor: pointer;

  color: #8F8F8F;
  background: none;
  
  border: 0;

  i {
    font-size: 30px;
  }

  &:hover {
    filter: brightness(0.8);
  }
`;

export const LogoImage = styled.div`
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';

  position: relative;

  img {
    display: block;
    width: 100%;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255, 0.60);

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }
`;

export const ColorContainer = styled.div`
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12), 0 2px 5px rgba(0, 0, 0, 0.16);

  width: 100%;

  display: grid;
  place-items: center;

  grid-template-columns: repeat(12, 1fr);
  grid-gap: 4px;
  padding: 15px 5px;
`;