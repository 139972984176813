export default function audioTimerLoop(callback, frequency) {
  var freq = frequency / 1000;      // AudioContext time parameters are in seconds
  var aCtx = new AudioContext();
  // Chrome needs our oscillator node to be attached to the destination
  // So we create a silent Gain Node
  var silence = aCtx.createGain();
  silence.gain.value = 0;
  silence.connect(aCtx.destination);

  onOSCend();

  var stopped = false;       // A flag to know when we'll stop the loop
  function onOSCend() {
    var osc = aCtx.createOscillator();
    osc.onended = onOSCend; // so we can loop
    osc.connect(silence);
    osc.start(0); // start it now
    osc.stop(aCtx.currentTime + freq); // stop it next frame
    callback(aCtx.currentTime); // one frame is done
    if (stopped) {  // user broke the loop
      osc.onended = function() {
        aCtx.close(); // clear the audioContext
        return;
      };
    }
  };
  // return a function to stop our loop
  return function() {
    stopped = true;
  };
}