export const getMediaServer = (fb_weblive, data) => {

    const conn_weblive = fb_weblive.database()

    conn_weblive.ref(`rooms/${data.room}`).get().then(async (snap) => {
        if (snap.val()) {

            let qtd_users = await snap.val().qtd_users

            if (parseInt(qtd_users) > 0) {
                conn_weblive.ref(`rooms/${data.room}`).onDisconnect().update({
                    qtd_users: qtd_users - 1
                })
            }

        }
    })

}

