import { useState, useEffect, useMemo } from 'react';
import Slider from 'rc-slider'

import { Container } from './styles';

export default function PlayerControls({ 
  videoElement, 
  absolute = false,
  compact = false,
  background = false,
	dark = false,
}) {
	const [isPlaying, setIsPlaying] = useState(!videoElement.paused);
	const [currentTime, setCurrentTime] = useState(videoElement.currentTime);
	const [volume, setVolume] = useState(videoElement.volume);
	const [muted, setMuted] = useState(videoElement.muted);

	function handlePlayPauseClick() {
		if (videoElement.paused) {
			videoElement.play();
		} else {
			videoElement.pause();
		}
	}

	function handleSeek(value) {
		setCurrentTime(value);
		videoElement.currentTime = value;
	}

	function handleVolumeChange(value) {
		videoElement.volume = value;
		videoElement.muted = false;
		setMuted(false);
		setVolume(value);
	}

	function handleMuteUnmuteClick() {
		if (muted) {
			videoElement.muted = false;
			setMuted(false);
			if (volume === 0) {
				setVolume(1);
			}
		} else {
			videoElement.muted = true;
			setMuted(true);
		}
	}

	useEffect(() => {
		const onPlay = () => {
			setIsPlaying(true);
		};

		const onPause = () => {
			setIsPlaying(false);
		};

		const onTimeUpdate = () => {
			setCurrentTime(videoElement.currentTime);
		};

		const onVolumeChange = () => {
			setMuted(videoElement.muted);
			setVolume(videoElement.volume);
		};

		videoElement.addEventListener('play', onPlay);
		videoElement.addEventListener('pause', onPause);
		videoElement.addEventListener('timeupdate', onTimeUpdate);
		videoElement.addEventListener('volumechange', onVolumeChange);

		return () => {
			videoElement.removeEventListener('play', onPlay);
			videoElement.removeEventListener('pause', onPause);
			videoElement.removeEventListener('timeupdate', onTimeUpdate);
			videoElement.removeEventListener('volumechange', onVolumeChange);
		};
	}, [videoElement]);

	const formattedTimeLeft = useMemo(() => {
		if (videoElement.duration === Infinity) {
			return '';
		}

		const timeLeft = videoElement.duration - currentTime;

		const hours = String(Math.floor(timeLeft / 3600)).padStart(2, '0');
		const minutes = String(Math.floor((timeLeft % 3600) / 60)).padStart(2, '0');
		const seconds = String(Math.floor(timeLeft % 60)).padStart(2, '0');

		if (hours !== '00') {
			return `${hours}:${minutes}:${seconds}`;
		} else {
			return `${minutes}:${seconds}`;
		}
	}, [videoElement, currentTime]);

	const sliderStyle = useMemo(() => {
		return {
			railStyle: { backgroundColor: dark ? '#0003' : '#fff3', height: compact ? '5px' : '7px' },
			trackStyle: { backgroundColor: '#1aafff', height: compact ? '5px' : '7px' },
			handleStyle: { top: compact ? '5px' : '6px' },
			style: { top: '-1px', cursor: 'pointer' },
		}
	}, [dark, compact]);

	return (
		<Container 
      absolute={absolute} 
      background={background}
      compact={compact}
			dark={dark}
      onClick={(e) => e.stopPropagation()}
    >
			<div className="btn-container" onClick={handlePlayPauseClick}>
				<i className={`fa ${isPlaying ? 'fa-pause' : 'fa-play'} play-pause-btn`}></i>
			</div>

			<Slider
        className="progress-slider"
				max={Math.floor(videoElement.duration !== Infinity ? videoElement.duration : currentTime)}
				value={Math.floor(currentTime)}
				onChange={handleSeek}
				{...sliderStyle}
			/>

			<span className="time-left">{formattedTimeLeft}</span>

			<div className="btn-container" onClick={handleMuteUnmuteClick}>
				<i
					className={`fa ${
						(muted || volume === 0)
							? 'fa-volume-mute'
							: volume > 0.6
							? 'fa-volume-up'
							: volume > 0.3 
							? 'fa-volume-down'
							: 'fa-volume-off'
					} volume-btn`}
				></i>
			</div>

			<Slider
        className="volume-slider"
				max={1}
				step={0.1}
				value={muted ? 0 : volume}
				onChange={handleVolumeChange}
				{...sliderStyle}
			/>
		</Container>
	);
}
