import React from 'react'
import './style.css'

export default function Steps({ steps, titles, currentStep }){
    const lastIndex = titles.length - 1
    return (
        <>
           <div className="card-steps">
                {titles.map((t, index) => (
                    <>
                        <div className="steps">
                            <label className={`step-dot ${currentStep < (index + 1) ? 'dot-disabled' : ''}`}><span>{index + 1}</span></label>
                            <label className="step-name">{t}</label>

                        </div>

                        {index < lastIndex && (
                            <hr className={`progress-line ${currentStep < (index + 1) ? 'progress-line-disabled' : ''}`}></hr>
                        )}
                    
                    </>
                ))}
           </div>
        </>
    )
}