import styled from "styled-components";

export const Container = styled.header`
  width: 100%;
  height: 56px;
  z-index: 999;
  padding: ${({ page }) => page === 'studio' ? '0 80px 0 30px' : '0 30px'};
  background: #fff;
  border-bottom: 1px solid #d6d6d6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex-shrink: 0;

  @media (max-width: 850px) {
    padding: 0 22px;
  }

  @media (max-width: 480px) {
    padding: 0 11px;
  }

  .logo {
    width: 128px;
  }

  nav {
    display: flex;
    align-items: center;

    @media (max-width: 850px) {
      display: none;
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      margin-left: 32px;
    }
    
    li {
      margin-left: 8px;

      button {
        height: 32px;
        min-width: 32px;
        border: 0;
        border-radius: 28px;
        padding: 0 8px;
        background: #eee;
        color: #555;

        transition: all 0.2s ease-in-out;

        i {
          font-size: 18px;
        }

        span {
          font-size: 12px;
          font-weight: bold;
          margin-left: 5px;
        }
      }

      &:hover {
        button {
          background: #ddd;
        }
      }

      &.active {
        button {
          color: var(--iq-primary);
        }
      }

      &.highlight {
        button {
          background: var(--iq-primary);
          opacity: 0.9;
          color: #fff;
        }
      }

      &.highlight:hover {
        button {
          opacity: 0.8;
        }
      }

      &.highlight.active {
        button {
          opacity: 1;
        }
      }
    }
  }

  .languages-container {
    display: flex;
    flex-direction: column;
    padding: 0 8px;
    font-size: 14px;

    button {
      border: 0;
      background: transparent;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        width: 16px;
        margin-right: 8px;
      }
    }

    button + button {
      margin-top: 4px;
      padding-top: 4px;
      border-top: 1px solid #eee;
    }
  }

  .user-dropdown-container {
    display: flex;
    flex-direction: column;
    padding: 0 8px;
    font-size: 14px;

    button {
      border: 0;
      background: transparent;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      i {
        font-size: 16px;
        margin-right: 8px;
      }

      span {
        white-space: nowrap;
      }
    }

    button + button {
      margin-top: 4px;
      padding-top: 4px;
      border-top: 1px solid #eee;
    }
  }
`;

export const ModalLogo = styled.img`
  width: 120px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const MenuButtonContainer = styled.div`
  width: 32px;
  height: 32px;
  margin-left: 16px;
  display: none;

  @media (max-width: 850px) {
    display: block;
  }

  .menu {
    background: #eaeaea;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin: 0;
  }

  .hamburger {
    position: relative;
    display: block;
    background: #555;
    width: 17px;
    height: 2px;
    top: 15px;
    left: 8px;
    transition: 0.3s ease-in-out;
  }

  .hamburger:before,
  .hamburger:after {
    background: #555;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: 0.3s ease-in-out;
  }

  .hamburger:before {
    top: -5px;
  }

  .hamburger:after {
    bottom: -5px;
  }

  input {
    display: none;
  }

  input:checked ~ label .hamburger {
    transform: rotate(45deg);
  }

  input:checked ~ label .hamburger:before {
    transform: rotate(90deg);
    top: 0;
    opacity: 0;
  }

  input:checked ~ label .hamburger:after {
    transform: rotate(90deg);
    bottom: 0;
  }
`;

export const ContainerModal = styled.div`
  font-size: 18px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    li {
      height: 48px;
      display: flex;
      align-items: center;
      margin: 0 -1rem;
      padding: 0 24px;
      transition: background-color 0.1s ease-in-out;
      cursor: pointer;
      color: #555;

      i {
        width: 36px;
      }

      span {
        margin: 0;
        font-weight: 500;
        white-space: nowrap;
      }
    }

    li.active {
      background: #f2f2f2;
      color: var(--iq-primary);
    }

    li + li {
      border-top: 1px solid #eee;
    }

    li:hover {
      background: #efefef;
    }
  }
`;