import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

import logo from '../../../assets/images/logo/n1-1.png';

import { useAuth } from '../../../context/Auth';
import { useClientLogo } from '../../../hooks/useClientLogo';

import { LanguagesDropdown } from './components/LanguagesDropdown';
import { UserDropdown } from './components/UserDropdown';
import { NavButton } from './components/NavButton';

import {
	Container,
	ModalLogo,
	ContainerModal,
	MenuButtonContainer,
} from './styles';

export function Header(props) {
	const [isOpen, setIsOpen] = useState(false);

	const { t } = useTranslation();
	const { modules, user } = useAuth();
	const { clientLogo } = useClientLogo();
	const is4webClient = user.client_type === '4web';
	const history = useHistory();

	function handleNavigation(toPage) {
		if (props.page === 'studio') {
			const text =
				props.broadcastStatus === 'started'
					? t(`components.Sidebar.swalExitFromStudioWhenLiveOn`)
					: t(`components.Sidebar.swalExitFromStudio`);

			Swal.fire({
				icon: 'warning',
				title: `<p>${t(`components.Sidebar.swalTitle`)}</p>`,
				html: text,
				showCancelButton: true,
				confirmButtonText: t(`components.Sidebar.swalConfirmButton`),
				cancelButtonText: t(`components.Sidebar.swalCancelButton`),
			}).then(async (result) => {
				if (result.isConfirmed) {
					if (props.broadcastStatus === 'started') {
						await props.stopBroadcast();
					}

					if (props.page === 'studio') {
						window.location.href = toPage;
					} else {
						history.push(toPage);
					}
				} else {
					return false;
				}
			});
		} else {
			history.push(toPage);
		}
	}

	function handleOpenCloseModalMenu() {
		setIsOpen(!isOpen);
	}

	return (
		<Container page={props.page}>
			<img
				className="logo"
				src={clientLogo?.url ? clientLogo.url : logo}
				alt="Logo do 4web live"
				onClick={() => handleNavigation('/new-room')}
			/>

			<nav>
				<ul>
					<NavButton
						active={props.page === 'new-room'}
						tooltipText={t(`components.Sidebar.homeText`)}
						onClick={() => handleNavigation('/new-room')}
					>
						<i className="ri-home-4-line" />
					</NavButton>

					{is4webClient && (
						<NavButton
							active={props.page === 'billing'}
							tooltipText={!modules.logo_required && t(`components.Sidebar.billing`)}
							onClick={() => handleNavigation('/billing')}
							highlight={modules.logo_required}
						>
							{modules.logo_required ? (
								<i className="ri-bill-fill" />
							) : (
								<i className="ri-bill-line" />
							)}
							{modules.logo_required && (
								<span>{t(`components.Sidebar.billingFree`)}</span>
							)}
						</NavButton>
					)}

					<NavButton
						active={props.page === 'destinations'}
						tooltipText={t(`components.Sidebar.destinations`)}
						onClick={() => handleNavigation('/destinations')}
						className="onboarding-homepage-step-4"
					>
						<i className="ri-share-line" />
					</NavButton>

					{is4webClient && (
						<NavButton
							active={props.page === 'support'}
							tooltipText={t(`components.Sidebar.support`)}
							onClick={() => handleNavigation('/support')}
						>
							<i className="ri-chat-3-line" />
						</NavButton>
					)}

					{props.onHelpClick && (
						<NavButton
							tooltipText={t(`components.Sidebar.help`)}
							onClick={props.onHelpClick}
						>
							<i className="ri-question-line" />
						</NavButton>
					)}

					<NavButton
						active={props.page === 'settings'}
						tooltipText={t(`components.Sidebar.settings`)}
						onClick={() => handleNavigation('/settings')}
						className="onboarding-homepage-step-3"
					>
						<i className="ri-settings-4-line" />
					</NavButton>
				</ul>

				<ul>
					<LanguagesDropdown
						toggle={
							<NavButton tooltipText={t(`components.Sidebar.languages`)}>
								<i className="ri-earth-line" />
							</NavButton>
						}
					/>

					<UserDropdown
						onNavigation={handleNavigation}
						toggle={
							<NavButton>
								<i className="ri-user-fill" />
								<span>{user.name}</span>
							</NavButton>
						}
					/>
				</ul>
			</nav>

			<MenuButtonContainer>
				<input
					id="menu-hamburger"
					type="checkbox"
					checked={isOpen}
					onChange={handleOpenCloseModalMenu}
				/>

				<label for="menu-hamburger">
					<div className="menu">
						<span className="hamburger"></span>
					</div>
				</label>
			</MenuButtonContainer>

			{isOpen && (
				<Modal show={isOpen} onHide={() => setIsOpen(false)}>
					<Modal.Header closeButton>
						<Modal.Title className="text-center">
							<ModalLogo
								src={clientLogo?.url ? clientLogo.url : logo}
								alt="Logo do 4web live"
							/>
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<ContainerModal>
							<ul>
								<li
									className={`${props.page === 'new-room' ? 'active': ''}`}
									onClick={() => handleNavigation('/new-room')}
								>
									<i className="ri-home-4-line" />
									<span>{t(`components.Sidebar.homeText`)}</span>
								</li>

								<li
									className={`onboarding-homepage-step-3 ${
										props.page === 'settings' ? 'active' : ''
									}`}
									onClick={() => handleNavigation('/settings')}
								>
									<i className="ri-settings-4-line" />
									<span>{t(`components.Sidebar.settings`)}</span>
								</li>

								<li
									className={props.page === 'billing' ? 'active' : ''}
									onClick={() => handleNavigation('/billing')}
								>
									<i className="ri-bill-line" />
									<span>
										{modules.logo_required
											? t(`components.Sidebar.billingFree`)
											: t(`components.Sidebar.billing`)}
									</span>
								</li>

								<li
									className={`onboarding-homepage-step-4 ${
										props.page === 'destinations' ? 'active' : ''
									}`}
									onClick={() => handleNavigation('/destinations')}
								>
									<i className="ri-share-line" />
									<span>{t(`components.Sidebar.destinations`)}</span>
								</li>

								{props.onHelpClick && (
									<li
										onClick={() => {
											setIsOpen(false);
											props.onHelpClick();
										}}
									>
										<i className="ri-question-line" />
										<span>{t(`components.Sidebar.help`)}</span>
									</li>
								)}

								{is4webClient && (
									<li
										className={props.page === 'support' ? 'active' : ''}
										onClick={() => handleNavigation('/support')}
									>
										<i className="ri-chat-3-line" />
										<span>{t(`components.Sidebar.support`)}</span>
									</li>
								)}

								<li onClick={() => handleNavigation('/logout')}>
									<i className="ri-logout-box-line" />
									<span>{t(`components.Sidebar.logout`)}</span>
								</li>
							</ul>
						</ContainerModal>
					</Modal.Body>
				</Modal>
			)}
		</Container>
	);
}
