import { useRef, useEffect } from "react";
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import Plyr from 'plyr';

export const Container = styled(motion.div)`
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  z-index: 500;
  pointer-events: none;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  > div {
    flex: 1;
  }
`;

export function VideoclipPlayer({ src, onEnded }) {
  const videoclipRef = useRef();
  const containerRef = useRef();
  const controls = useAnimation();

  useEffect(() => {
    let player;
    let videoclipElement = videoclipRef.current;
    let containerElement = containerRef.current;

		if (videoclipElement) {
			player = new Plyr(videoclipElement, {
				controls: [],
				settings: [],
				disableContextMenu: true,
				clickToPlay: false,
				keyboard: false,
				fullscreen: false,
				autoplay: true,
				playsinline: true,
			});

      if (containerElement) {
        containerElement.plyr = player;
      }

			player.on('ended', () => {
				onEnded();
			})

			player.on('playing', () => {
        controls.start({ opacity: 1 });
			})
      
			player.on('timeupdate', () => {
        const endThreshold = 0.3;

        if (player.duration && (player.currentTime > player.duration - endThreshold)) {
          onEnded();
        }
      })

      const source = {
        src,
      }

      if (src.includes('youtube') || src.includes('youtu.be')) {
        source.provider = 'youtube'
      } else if (src.includes('vimeo')) {
        source.provider = 'vimeo'
      } else {
        source.type = 'video/mp4'
      }

      player.source = {
        type: 'video',
        sources: [source]
      }
		}
		
		return () => {
			if (player) {
				player.destroy();
				player.off('timeupdate');
				player.off('ended');
				player.off('playing');
        if (containerElement) {
          containerElement.plyr = null;
        }
			}
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src])

  return (
    <Container 
      id="bigscreen-videoclip"
      ref={containerRef}
      initial={{
        opacity: 0 
      }}
      animate={controls}
      transition={{
        ease: [0.4, 0, 0.2, 1],
        duration: 0.3
      }}
      exit={{
        opacity: 0
      }}
    >
      <video ref={videoclipRef} />
    </Container>
  )
}