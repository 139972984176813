import React, { useEffect, useState, useRef } from 'react'

//internal components
import Sidebar from '../../components/Layout/SideBar/SideBar'
import BlockUI from '../../components/BlockUI/BlockUI'
import LinkShare from '../../components/LinkShare/LinkShare'
import VideoComponent from '../../components/VideoComponent/VideoComponent'

//external components
import { Container, Row, Col, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Swal from 'sweetalert2'

//libraries 
import * as mediasoupClient from 'mediasoup-client';
import io from 'socket.io-client';
import axios from 'axios';

// stylesheets
import styles from './studio.module.css'
import 'react-perfect-scrollbar/dist/css/styles.css';

import logo_white from '../../assets/images/logo/logo-white.png';

import api from '../../services/api';
import { token_decode } from '../../services/auth'

import iconGoogle from '../../assets/images/icon/google.png'

localStorage.setItem('debug', 0);

const socket = io(process.env.REACT_APP_MEDIA_SERVER, { transports: ['websocket', 'polling'], forceJSONP: true, forceNew: true, timeout: 7000 })
const internalSocket = io(`${process.env.REACT_APP_MEDIA_SERVER}/internal`, { secure: true, reconnection: true, reconnectionDelay: 1000, timeout: 15000, pingTimeout: 15000, pingInterval: 45000 })

socket.request = function request(type, data = {}) {
  return new Promise((resolve, reject) => {
    socket.emit(type, data, (data) => {
      if (data.error) {
        reject(data.error)
      } else {
        resolve(data)
      }
    })
  })
}

var producerTransport = null

var producers = new Map()
var producerLabel = new Map()
var listStreamers = []
var qtdOnScreen = { screen: 0, cam: 0 }
var typeVideo = ''
var streamsAudio = new Map()

export default function StudioDev({ props }) {

  const client = token_decode(localStorage.getItem('auth-user'))

  const [blockUI, setBlockUI] = useState(false)
  const [audioController, setAudioController] = useState({ open: true, css: `${styles.btn_studio_room} btn-initial-audio`, icon: 'fa fa-microphone btn-initial-icon' })
  const [videoController, setVideoController] = useState({ open: true, css: `${styles.btn_studio_room} btn-initial-video`, icon: 'fa fa-video btn-initial-icon' })
  const [shareScreenController, setShareScreenController] = useState({ shared: false, css: `${styles.btn_studio_room} ${styles.btn_studio_inactive} btn-initial-config` })
  const [showModal, setShowModal] = useState(true)
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [showShareVideoModal, setShowShareVideoModal] = useState([{ status: false, link: '' }])
  const [displayName, setDisplayName] = useState('')
  const [audioSources, setAudioSources] = useState([])
  const [videoSources, setVideoSources] = useState([])
  const [selectVideoSource, setSelectVideoSource] = useState(null)
  const [selectAudioSource, setSelectAudioSource] = useState(null)
  const [testMicController, setTestMicController] = useState(false)
  const [streamers, setStreamers] = useState([])
  const [layoutMode, setLayoutMode] = useState(2)
  const [onScreen, setOnScreen] = useState([])
  const [startingBroadcast, setStartingBroadcast] = useState({ status: 'stopped' })
  const [loadingConnectYoutube, setLoadingConnectYoutube] = useState(false)
  const [showModalGoogle, setShowModalGoogle] = useState(false)
  const [urlAuthGoogle, setUrlAuthGoogle] = useState(null)
  const [account4web, setAccount4web] = useState(true)
  const [showModalAccount, setShowModalAccount] = useState(false)
  const [showBtnAuthGoogle, setShowBtnAuthGoogle] = useState(false)

  /* CONTEÚDO DO ROOMCLIENT */

  const mediaType = {
    audio: 'audioType',
    video: 'videoType',
    screen: 'screenType'
  }
  const _EVENTS = {
    exitRoom: 'exitRoom',
    openRoom: 'openRoom',
    startVideo: 'startVideo',
    stopVideo: 'stopVideo',
    startAudio: 'startAudio',
    stopAudio: 'stopAudio',
    startScreen: 'startScreen',
    stopScreen: 'stopScreen'
  }

  const client_id = client.vp_client_id.toString()
  const event_id = client.vp_event_id.toString()
  const external_room = client.room.toString()
  const room_id = parseInt(client_id + event_id + external_room)

  var consumerTransport = null
  var device = null

  var consumers = new Map()
  var _isOpen = false
  var eventListeners = new Map()

  function NewJoin() {

    if (isOpen()) {
      console.log('already connected to a room')
      return false;
    }

    Object.keys(_EVENTS).forEach(function (evt) {
      eventListeners.set(evt, [])
    })

    CreateRoom(room_id).then(async function () {
      await Join(displayName, room_id)
      await InitSockets()
      _isOpen = true
      RoomOpen()
    })

  }

  ////////// INIT /////////

  async function CreateRoom(room_id) {
    await socket.request('createRoom', {
      room_id
    }).catch(err => {
      console.log(err)
    })
  }

  async function Join(name, room_id) {

    socket.request('join', {
      name,
      room_id
    }).then(async function (e) {
      // console.log(e)
      const data = await socket.request('getRouterRtpCapabilities');
      let thisDevice = await loadDevice(data)
      device = thisDevice
      await initTransports(device)
      socket.emit('getProducers')
    }).catch(e => {
      console.log(e)
    })

  }

  async function loadDevice(routerRtpCapabilities) {
    let device
    try {
      device = new mediasoupClient.Device();
    } catch (error) {
      if (error.name === 'UnsupportedError') {
        console.error('browser not supported');
      }
      console.error(error)
    }
    await device.load({
      routerRtpCapabilities
    })
    return device

  }
  async function verifyRefreshTokenLocalStorage() {

    let rt = localStorage.getItem('rt')

    if (rt != null) {
      try {
        await api.post('/refresh-token', {
          refresh_token: rt,
          cid: client.vp_client_id,
          email: client.email

        })

        localStorage.removeItem('rt')

      } catch (e) {
        console.error(e)
      }

    }

  }
  async function oauth2() {

    let token_auth = localStorage.getItem('goauth2_token')
    let account = localStorage.getItem('account')

    if (account == '4web') {
      setAccount4web(true)
    } else if (account == null) {
      localStorage.setItem('account', '4web')
      setAccount4web(true)
    } else {
      setAccount4web(false)
    }

    if (token_auth == null) {

      setShowModal(false)
      setShowModalGoogle(true)

      try {
        let res = await api.get("/goauth2")
        let { url, status } = res.data
        if (status == 200) {
          setUrlAuthGoogle(url)
        } else {

          console.error('Bad request 401!')
        }

      } catch (e) {
        console.log(e)
      }
    } else {
      loadDevices()

      setShowModalGoogle(false)
    }

  }

  function handleCloseModalGoogle() {
    loadDevices()
    setShowModal(true)
    setShowModalGoogle(false)
  }


  function handleSwitchAccount() {

    let token = localStorage.getItem('goauth2_token')
    setAccount4web(!account4web)

    if (account4web == false) {

      localStorage.setItem('account', '4web')
      setShowBtnAuthGoogle(false)

    } else {

      if (token == '' || token == null) {
        setShowBtnAuthGoogle(true)

      }
      localStorage.setItem('account', 'client')
    }

  }


  function handleSetAccount() {
    if (qtdOnScreen.cam > 0 || qtdOnScreen.screen > 0) {

      setShowModalAccount(true)

    } else {
      Swal.fire("Ops!", "Adicione um vídeo antes de iniciar a transmissão!", 'warning')
    }
  }

  async function handleStartStream() {
    if (qtdOnScreen.cam > 0 || qtdOnScreen.screen > 0) {

      setLoadingConnectYoutube(true)

      try {

        let data = localStorage.getItem('goauth2_token') != null ? token_decode(localStorage.getItem('goauth2_token')) : {}

        let account = localStorage.getItem('account')

        data.cid = client.vp_client_id
        data.event_id = client.vp_event_id
        data.room = client.room
        data.account = account


        //iniciando broadcast e pegando os dados

        let response = await api.post('/start', data)
        if (response.status === 200) {
          response.data.cid = client.vp_client_id
          response.data.link_video = `https://youtu.be/${response.data.broadcast_id}`

          localStorage.setItem('broadcast_data', JSON.stringify(response.data))

          let urlData = {
            rtmp: `rtmp://a.rtmp.youtube.com/live2/${response.data.stream_key}`,
            room: room_id
          }

          let url = `https://weblive.4.events:3016?data=${btoa(JSON.stringify(urlData))}`

          let socket_id = await socket.request('startInternalTransmission', url)

          internalSocket.emit('register', room_id)

          let send_link = {
            type: "4web",
            room: room_id,
            link: `https://youtu.be/${response.data.broadcast_id}`
          }

          setTimeout(function () {

            let this_data = {
              room: room_id,
              streamers: [],
            }

            onScreen.forEach((data) => {
              this_data.streamers.push({
                producer_id: data.id,
                display: data.display,
                type_video: data.typeVideo,
                group: data.group
              })
            })

            streamsAudio.forEach((data) => {

              let this_video = onScreen.find((stream) => stream.group === data.group)

              if (this_video) {
                this_data.streamers.push({
                  producer_id: data.id,
                  display: data.display,
                  type_video: data.typeVideo,
                  group: data.group
                })
              }

            })

            internalSocket.emit('startTransmission', this_data)
            setLoadingConnectYoutube(false)
            setStartingBroadcast({ status: 'started' })

            setTimeout(function () {
              axios({
                method: 'post',
                url: `http:${client.site_url}/admin/api`,//'https://4web.live/api',
                data: {
                  type: 'initialize',
                  c: client_id,
                  e: event_id,
                  r: external_room,
                  l: `https://youtu.be/${response.data.broadcast_id}`
                }
              })
                .then(function (response) {
                  console.log(response)
                  console.log('YouTube link sent to transmission')
                })
                .catch(function (error) {
                  console.log('YouTube link sent to transmission: error')
                });

              console.log('YouTube link sent to 4.events')
            }, 15000);

          }, 2000);

          localStorage.setItem('socket_id', socket_id)

          setShowShareVideoModal({ status: showShareVideoModal.status, link: `https://youtu.be/${response.data.broadcast_id}` })
        } else {

          Swal.fire("Ops!", "Houve um problema ao iniciar a transmissão...", 'warning')
          setStartingBroadcast({ status: 'stopped' })
          setLoadingConnectYoutube(false)
        }

      } catch (e) {

        Swal.fire("Ops!", 'Ocorreu um erro ao iniciar. Tente novamente mais tarde.', 'warning')
        setStartingBroadcast({ status: 'stopped' })
        setLoadingConnectYoutube(false)

        console.error(e)
      }
    } else {
      Swal.fire("Ops!", "Adicione um video, antes de iniciar a transmissão!", 'warning')
    }

  }

  async function handleStopStream() {
    setLoadingConnectYoutube(true)

    try {

      let broadcast = localStorage.getItem('broadcast_data')
      let account = localStorage.getItem('account')

      broadcast = JSON.parse(broadcast)
      broadcast.account = account

      //parando broadcast
      let response = await api.post('/stop', broadcast)

      if (response.status === 200) {
        setLoadingConnectYoutube(false)
        setStartingBroadcast({ status: 'stopped' })

        //chamar função para fechar a transmissão
      } else {
        setStartingBroadcast({ status: 'stopped' })
        setLoadingConnectYoutube(false)
      }

      let socket_id = localStorage.getItem('socket_id')

      socket.request('stopInternalTransmission', socket_id)

      localStorage.setItem('socket_id', null)
      localStorage.setItem('broadcast_data', null)
      localStorage.removeItem('socket_id')
      localStorage.removeItem('broadcast_data')

      Swal.fire("Sucesso!", "Transmissão finalizada.", 'success')

    } catch (e) {

      setLoadingConnectYoutube(false)
      setStartingBroadcast({ status: 'stopped' })

      let socket_id = localStorage.getItem('socket_id')

      socket.request('stopInternalTransmission', socket_id)

      localStorage.setItem('socket_id', null)
      localStorage.setItem('broadcast_data', null)
      localStorage.removeItem('socket_id')
      localStorage.removeItem('broadcast_data')

      let { message } = e.response.data
      Swal.fire("Sucesso!", "Transmissão finalizada.", 'success')
      console.error(e)
    }
  }

  async function initTransports(device) {

    // init producerTransport
    {
      const data = await socket.request('createWebRtcTransport', {
        forceTcp: false,
        rtpCapabilities: device.rtpCapabilities,
      })
      if (data.error) {
        console.error(data.error);
        return;
      }

      producerTransport = await device.createSendTransport(data);

      producerTransport.on('connect', async function ({
        dtlsParameters
      }, callback, errback) {
        socket.request('connectTransport', {
          dtlsParameters,
          transport_id: data.id
        })
          .then(callback)
          .catch(errback)
      });

      producerTransport.on('produce', async function ({
        kind,
        rtpParameters
      }, callback, errback) {

        try {
          const {
            producer_id
          } = await socket.request('produce', {
            producerTransportId: producerTransport.id,
            kind,
            rtpParameters,
            display: displayName,
            typeVideo: kind === 'audio' ? 'audio' : typeVideo,
            group: socket.id
          });
          callback({
            id: producer_id
          });
        } catch (err) {
          errback(err);
        }
      })

      producerTransport.on('connectionstatechange', function (state) {
        switch (state) {
          case 'connecting':

            break;

          case 'connected':
            //localVideo.srcObject = stream
            break;

          case 'failed':
            producerTransport.close();
            break;

          default:
            break;
        }
      });
    }

    // init consumerTransport
    {
      const data = await socket.request('createWebRtcTransport', {
        forceTcp: false,
      });
      if (data.error) {
        console.error(data.error);
        return;
      }

      // only one needed
      consumerTransport = device.createRecvTransport(data);
      consumerTransport.on('connect', function ({
        dtlsParameters
      }, callback, errback) {
        socket.request('connectTransport', {
          transport_id: consumerTransport.id,
          dtlsParameters
        })
          .then(callback)
          .catch(errback);
      });

      consumerTransport.on('connectionstatechange', async function (state) {
        switch (state) {
          case 'connecting':
            break;

          case 'connected':
            //remoteVideo.srcObject = await stream;
            //await socket.request('resume');
            break;

          case 'failed':
            consumerTransport.close();
            break;

          default:
            break;
        }
      });
    }

  }

  function InitSockets() {
    socket.on('consumerClosed', function ({
      consumer_id
    }) {
      console.log('closing consumer:', consumer_id)
      RemoveConsumer(consumer_id)
    })

    /**
     * data: [ {
     *  producer_id:
     *  producer_socket_id:
     * }]
     */
    socket.on('newProducers', async function (data) {
      for (let {
        producer_id, display, typeVideo, group
      } of data) {
        await Consume(producer_id, display, typeVideo, group)
      }
    })

    socket.on('disconnect', function () {
      exit(true)
    })

  }

  //////// MAIN FUNCTIONS /////////////

  async function Produce(type, deviceId = null) {

    let mediaConstraints = {}
    let audio = false
    let screen = false

    switch (type) {
      case mediaType.audio:
        mediaConstraints = {
          audio: {
            deviceId: deviceId,
            sampleRate: 44100,
            echoCancellation: true
          },
          video: false
        }
        audio = true
        break
      case mediaType.video:
        typeVideo = 'cam'
        if (deviceId) {
          mediaConstraints = {
            audio: false,
            video: {
              width: { max: 1920, min: 320 },
              height: { max: 1080, min: 200 },
              aspectRatio: { ideal: 1.77778 },
              deviceId: { exact: deviceId }
            }
          }
        } else {
          mediaConstraints = {
            audio: false,
            video: {
              width: { max: 1920, min: 320 },
              height: { max: 1080, min: 200 },
              aspectRatio: { ideal: 1.77778 }
            }
          }
        }
        break
      case mediaType.screen:
        typeVideo = 'screen'
        mediaConstraints = false
        screen = true
        break;
      default:
        return
    }
    /*if (!this.device.canProduce('video') && !audio) {
        console.error('cannot produce video');
        return;
    }*/
    if (producerLabel.has(type)) {
      console.log('producer already exists for this type ' + type)
      return
    }
    let stream;
    try {

      stream = screen ? await navigator.mediaDevices.getDisplayMedia({ video: true, audio: false }) : await navigator.mediaDevices.getUserMedia(mediaConstraints)
      //console.log(navigator.mediaDevices.getSupportedConstraints())

      const track = audio ? stream.getAudioTracks()[0] : stream.getVideoTracks()[0]
      const params = {
        track
      };
      if (!audio && !screen) {
        params.encodings = [{
          rid: 'r0',
          maxBitrate: 100000,
          //scaleResolutionDownBy: 10.0,
          scalabilityMode: 'S1T3'
        },
        {
          rid: 'r1',
          maxBitrate: 300000,
          scalabilityMode: 'S1T3'
        },
        {
          rid: 'r2',
          maxBitrate: 900000,
          scalabilityMode: 'S1T3'
        }
        ];
        params.codecOptions = {
          videoGoogleStartBitrate: 1000
        };

      }

      const producer = await producerTransport.produce(params)

      producers.set(producer.id, producer)

      let elem
      if (!audio) {

        setStreamers(prevState =>
          [...prevState, {
            id: producer.id,
            display: displayName,
            type: !screen ? 'admin' : 'screen',
            srcObject: stream,
            status: 'off',
            typeVideo: !screen ? 'cam' : 'screen',
            group: socket.id
          }]
        )

        listStreamers = [...listStreamers, {
          id: producer.id,
          type: 'video',
          typeVideo: typeVideo
        }]

        //if produce type screen, define css and sharescreen button true
        if (screen) {
          setShareScreenController({ shared: true, css: 'text-white  btn-initial-config btn-inital-room-pressed d-inline-block' })
        }

        setBlockUI(false)

      } else {
        streamsAudio.set(producer.id, {
          id: producer.id,
          display: displayName,
          typeVideo: 'audio',
          group: socket.id
        })
      }

      producer.on('trackended', () => {
        closeProducer(type)
      })

      producer.on('transportclose', () => {
        console.log('producer transport close')
        if (!audio) {
          elem.srcObject.getTracks().forEach(function (track) {
            track.stop()
          })
          elem.parentNode.removeChild(elem)
        }
        producers.delete(producer.id)

      })

      producer.on('close', () => {
        console.log('closing producer')
        if (!audio) {
          elem.srcObject.getTracks().forEach(function (track) {
            track.stop()
          })
          elem.parentNode.removeChild(elem)
        }
        producers.delete(producer.id)

      })

      producerLabel.set(type, producer.id)

      switch (type) {
        case mediaType.audio:
          event(_EVENTS.startAudio)
          break
        case mediaType.video:
          event(_EVENTS.startVideo)
          break
        case mediaType.screen:
          event(_EVENTS.startScreen)
          break;
        default:
          return
      }
    } catch (err) {
      if (err.message.toString() === 'Could not start video source' && !audio && !screen) {
        Swal.fire('Oops...', 'Há um problema com sua câmera. Verifique se ela está conectada corretamente e nenhum outro aplicativo está utilizando a mesma. Caso outro aplicativo esteja utilizando a câmera, é necessário fechá-lo.', 'warning')
      } else {
        Swal.fire('Oops...', 'Ocorreu um erro ao tentar transmitir. Verifique sua câmera e tente novamente.', 'warning')
      }

      if (!audio && !screen) {

        setStreamers(prevState =>
          [...prevState, {
            id: 'admin',
            display: displayName,
            type: 'admin',
            srcObject: null,
            status: 'off',
            typeVideo: typeVideo,
            group: socket.id
          }]
        )

        listStreamers = [...listStreamers, {
          id: 'admin',
          type: 'video',
          typeVideo: typeVideo
        }]

        setBlockUI(false)
      }
    }
  }

  async function Consume(producer_id, display, typeVideo, group) {

    //let info = await roomInfo()
    console.log(display, typeVideo, group)

    getConsumeStream(producer_id).then(async function ({
      consumer,
      stream,
      kind,
    }) {

      consumers.set(consumer.id, consumer)

      let elem

      if (kind === 'video') {

        setStreamers(prevState =>
          [...prevState, {
            id: producer_id,
            consumer_id: consumer.id,
            display: display,
            type: 'guest',
            srcObject: stream,
            status: 'off',
            kind: kind,
            typeVideo: typeVideo,
            group: group
          }]
        )

        //console.log(consumer, kind)

      } else {
        elem = document.createElement('audio')
        elem.srcObject = stream
        elem.id = consumer.id
        elem.playsinline = false
        elem.autoplay = true
        //document.getElementById(remoteAudioEl).appendChild(elem)

        streamsAudio.set(producer_id, {
          id: producer_id,
          consumer_id: consumer.id,
          display: display,
          typeVideo: typeVideo,
          group: group
        })
      }

      listStreamers = [...listStreamers, {
        id: producer_id,
        consumer_id: consumer.id,
        type: kind,
        typeVideo: typeVideo
      }]

      consumer.on('trackended', function () {
        RemoveConsumer(consumer.id)
      })
      consumer.on('transportclose', function () {
        RemoveConsumer(consumer.id)
      })

    })
  }

  async function getConsumeStream(producerId) {
    const {
      rtpCapabilities
    } = device
    const data = await socket.request('consume', {
      rtpCapabilities,
      consumerTransportId: consumerTransport.id, // might be 
      producerId
    });

    const {
      id,
      kind,
      rtpParameters
    } = data;

    let codecOptions = {};
    const consumer = await consumerTransport.consume({
      id,
      producerId,
      kind,
      rtpParameters,
      codecOptions,
    })
    const stream = new MediaStream();
    stream.addTrack(consumer.track);
    return {
      consumer,
      stream,
      kind
    }
  }

  function closeProducer(type) {

    if (!producerLabel.has(type)) {
      console.log('there is no producer for this type ' + type)
      return
    }
    let producer_id = producerLabel.get(type)

    socket.emit('producerClosed', {
      producer_id
    })
    producers.get(producer_id).close()
    producers.delete(producer_id)
    producerLabel.delete(type)

    if (type === "audioType") {
      return false
    }

    setOnScreen(prevState => prevState.filter(streamer => streamer.id !== producer_id))
    setStreamers(prevState => prevState.filter(streamer => streamer.id !== producer_id))

    listStreamers = listStreamers.filter(streamer => streamer.id !== producer_id)

    switch (type) {
      case mediaType.audio:
        event(_EVENTS.stopAudio)
        break
      case mediaType.video:
        event(_EVENTS.stopVideo)
        break
      case mediaType.screen:
        event(_EVENTS.stopScreen)
        break;
      default:
        return
    }

  }

  function pauseProducer(type) {
    if (!producerLabel.has(type)) {
      console.log('there is no producer for this type ' + type)
      return
    }
    let producer_id = producerLabel.get(type)
    producers.get(producer_id).pause()
  }

  function resumeProducer(type) {
    if (!producerLabel.has(type)) {
      console.log('there is no producer for this type ' + type)
      return
    }
    let producer_id = producerLabel.get(type)
    producers.get(producer_id).resume()

  }

  async function RemoveConsumer(consumer_id) {

    consumers.delete(consumer_id)

    let this_streamer = listStreamers.find((streamer) => streamer.consumer_id === consumer_id)
    let this_type = null

    if (this_streamer) {
      this_type = this_streamer.type
    }

    if (this_type && this_type === "video") {

      if(this_streamer.typeVideo === 'screen'){
        qtdOnScreen.screen --
      }else{
        qtdOnScreen.cam --
      }

      setOnScreen(prevState => prevState.filter(streamer => streamer.consumer_id !== consumer_id))
      setStreamers(prevState => prevState.filter(streamer => streamer.consumer_id !== consumer_id))
    } else {
      streamsAudio.delete(this_streamer.id)
    }

    listStreamers = listStreamers.filter(streamer => streamer.id !== consumer_id)

  }

  function exit(offline = false) {

    let clean = function () {
      _isOpen = false
      consumerTransport.close()
      producerTransport.close()
      socket.off('disconnect')
      socket.off('newProducers')
      socket.off('consumerClosed')
    }

    if (!offline) {
      socket.request('exitRoom').then(e => console.log(e)).catch(e => console.warn(e)).finally(function () {
        clean()
      })
    } else {
      clean()
    }

    event(_EVENTS.exitRoom)

  }

  ///////  HELPERS //////////

  /*async roomInfo() {
      let info = await socket.request('getMyRoomInfo')
      return info
  }*/


  function event(evt) {
    if (eventListeners.has(evt)) {
      eventListeners.get(evt).forEach(callback => callback())
    }
  }

  /*function on(evt, callback) {
    eventListeners.get(evt).push(callback)
  }*/

  //////// GETTERS ////////

  function isOpen() {
    return _isOpen
  }

  /* FIM CONTEÚDO DO ROOMCLIENT */

  function RoomOpen() {
    setTimeout(function () {
      Produce(mediaType.video, selectVideoSource)
      Produce(mediaType.audio, selectAudioSource)
    }, 3000);
  }

  //const principalVideoEl = useRef(null)
  const configVideo = useRef(null)
  const configAudio = useRef(null)
  const playMicBtn = useRef(null)
  const btn_mode_1 = useRef(null)
  const btn_mode_2 = useRef(null)
  const streamers_content = useRef(null)
  const streamers_principal = useRef([])
  const container_principal = useRef([])

  function toggleAudio() {

    if (audioController.open) {
      pauseProducer(mediaType.audio)
      setAudioController({ open: false, css: 'text-white btn-initial-audio btn-inital-room-pressed', icon: 'fa btn-initial-icon fa-microphone-slash' })
    } else {
      resumeProducer(mediaType.audio)
      setAudioController({ open: true, css: `${styles.btn_studio_room} btn-initial-audio`, icon: 'fa fa-microphone btn-initial-icon' })
    }

  }

  function toggleVideo() {
    if (videoController.open) {
      pauseProducer(mediaType.video)
      setVideoController({ open: false, css: 'text-white btn-initial-video d-inline-block btn-inital-room-pressed', icon: 'fa btn-initial-icon fa-video-slash' })
    } else {
      resumeProducer(mediaType.video)
      setVideoController({ open: true, css: `${styles.btn_studio_room} btn-initial-video`, icon: 'fa fa-video btn-initial-icon' })
    }
  }

  function shareScreen() {
    if (!shareScreenController.shared) {
      Produce(mediaType.screen)
    } else {
      closeProducer(mediaType.screen)
      setShareScreenController({ shared: false, css: `${styles.btn_studio_room} ${styles.btn_studio_inactive} btn-initial-config` })
    }

  }

  function joinRoom() {
    if (!displayName) {
      Swal.fire('Você não está esquecendo de algo?', 'Digite seu nome para te conhecermos melhor :D', 'warning')
      return
    }
    if (testMicController) {
      stopMic()
    }
    setShowModal(false)
    setBlockUI(true)
    NewJoin()

    //Register on 4chat
    let register = {
      user: `${room_id}_4webadmin`,
      room: room_id
    }

    let socket_id = localStorage.getItem('socket_id')

    if (socket_id && socket_id !== "") {
      let link_video = JSON.parse(localStorage.getItem('broadcast_data')).link_video
      setShowShareVideoModal({ status: false, link: link_video })
      setStartingBroadcast({ status: 'started' })
    }
  }

  async function loadDevices() {

    let mediaConstraints = {
      audio: true,
      video: {
        width: { max: 1920, min: 320 },
        height: { max: 1080, min: 200 },
        aspectRatio: { ideal: 1.77778 },
      }
    }

    let stream = await navigator.mediaDevices.getUserMedia(mediaConstraints)
    let prevCam = stream.getVideoTracks()[0].getSettings().deviceId

    let enumdevices = await navigator.mediaDevices.enumerateDevices()

    loadCam('configVideo', null, stream)

    let audioDevices = enumdevices.filter((device) => device.kind === 'audioinput')
    let videoDevices = enumdevices.filter((device) => device.kind === 'videoinput')

    let camIndex = videoDevices.findIndex((device) => device.deviceId === prevCam)

    videoDevices[camIndex].selected = true

    setAudioSources(audioDevices)
    setVideoSources(videoDevices)

  }

  //Load user webcam
  async function loadCam(ref, deviceId = null, stream = null) {

    setSelectVideoSource(deviceId)

    //Define initial cam for config
    if (!stream) {

      let video = ref === "configVideo" ? configVideo.current : ''
      video.srcObject = null

      let mediaConstraints = {
        audio: true,
        video: {
          width: { max: 1920, min: 320 },
          height: { max: 1080, min: 200 },
          aspectRatio: { ideal: 1.77778 },
          deviceId: { exact: deviceId }
        }
      }

      let stream = await navigator.mediaDevices.getUserMedia(mediaConstraints)
      video.srcObject = stream

    } else {
      let video = ref === "configVideo" ? configVideo.current : ''
      video.srcObject = stream
    }

  }

  async function loadMic(deviceId) {
    if (testMicController) {
      stopMic()
    }

    setSelectAudioSource(deviceId)
  }

  async function playMic() {

    let audio = configAudio.current
    let btn = playMicBtn.current

    let mediaConstraints
    if (selectAudioSource) {
      mediaConstraints = {
        audio: {
          deviceId: { exact: selectAudioSource },
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 4000
        },
        video: false
      }
    } else {
      mediaConstraints = {
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 4000
        },
        video: false
      }
    }

    let stream = await navigator.mediaDevices.getUserMedia(mediaConstraints)

    //CHANGE CSS BUTTON
    btn.innerHTML = '<i class="fa fa-microphone-slash"></i>Parar'
    btn.classList.remove('btn-outline-primary')
    btn.classList.add('btn-danger')

    //DEFINE MEDIASTREAM
    audio.srcObject = stream
    audio.play()

    //CONTROLLER TEST MIC
    setTestMicController(true)

  }

  function stopMic() {
    let btn = playMicBtn.current
    let audio = configAudio.current

    //CHANGE CSS BUTTON
    btn.innerHTML = '<i class="fa fa-microphone"></i>Testar Microfone'
    btn.classList.remove('btn-danger')
    btn.classList.add('btn-outline-primary')

    //STOP CAPTURE MIC
    let stream = audio.srcObject;
    let tracks = stream.getTracks();

    tracks.forEach(function (track) {
      track.stop();
    });

    audio.srcObject = null;

    //CONTROLLER TEST MIC
    setTestMicController(false)
  }

  function micController() {
    if (!testMicController) {
      playMic()
    } else {
      stopMic()
    }
  }

  function defineLayoutMode(mode) {
    if (mode === layoutMode) {
      return false
    }

    setLayoutMode(mode)

    let btn = null
    let btn_aux = null
    switch (mode) {
      case 1:
        btn = btn_mode_1.current
        btn_aux = btn_mode_2.current
        btn_aux.classList.remove(styles.btn_studio_layout_pressed)
        break
      case 2:
        btn = btn_mode_2.current
        btn_aux = btn_mode_1.current
        btn_aux.classList.remove(styles.btn_studio_layout_pressed)
        break
      default:
        btn = btn_mode_2.current
        btn_aux = btn_mode_1.current
        btn_aux.classList.remove(styles.btn_studio_layout_pressed)
    }

    btn.classList.add(styles.btn_studio_layout_pressed)

  }

  async function addOnScreen(data) {

    if (onScreen.length > 0 && onScreen.find(streamer => streamer.id === data.id)) {

      if (data.typeVideo === 'screen') {
        qtdOnScreen.screen--
      } else {
        qtdOnScreen.cam--
      }

      let this_streamers
      let this_audio = null
      if (data.typeVideo === 'cam') {
        streamsAudio.forEach((stream) => {
          if (stream.group === data.group) {
            this_audio = stream.id
          }
        })

        this_streamers = [{ producer_id: data.id }, { producer_id: this_audio }]
      } else {
        this_streamers = [{ producer_id: data.id }]
      }

      let this_data = {
        room: room_id,
        streamers: this_streamers
      }

      internalSocket.emit('removeFromScreen', this_data)

      await setOnScreen(prevState => prevState.filter(streamer => streamer.id !== data.id))

    } else {
      let this_streamer = streamers.find(streamer => streamer.id === data.id)

      if (this_streamer.typeVideo === 'screen') {
        qtdOnScreen.screen++
      } else {
        qtdOnScreen.cam++
      }

      this_streamer.pos = this_streamer.type === 'screen' ? qtdOnScreen.screen : qtdOnScreen.cam

      let this_audio = null

      streamsAudio.forEach((stream) => {
        if (stream.group === this_streamer.group) {
          this_audio = stream
        }
      })

      let this_data = {
        room: room_id,
        streamers: [
          {
            producer_id: this_streamer.id,
            display: this_streamer.display,
            type_video: this_streamer.typeVideo,
            group: this_streamer.group
          },
          {
            producer_id: this_audio.id,
            display: this_audio.display,
            type_video: this_audio.typeVideo,
            group: this_audio.group
          }
        ]
      }

      internalSocket.emit('addOnScreen', this_data)
      await setOnScreen(prevState =>
        [...prevState, this_streamer]
      )
      loadVideo(this_streamer)
    }

    console.log('here')
    console.log(streamers)
    console.log(onScreen)
  }

  function loadVideo(streamer) {
    let video = streamers_principal.current[streamer.id]
    video.srcObject = streamer.srcObject
  }

  function resizeVideos(internal_mode, qtd_cam) {

    let cp_onScreen = onScreen

    let count_cam = 0
    cp_onScreen.forEach((el, i) => {
      if (el.typeVideo === 'cam') {
        count_cam++
        cp_onScreen[i].pos = count_cam
      }
    })

    if (qtdOnScreen.screen === 0 && qtdOnScreen.cam === 0) {
      return false
    }

    let cameraWidth = 0
    let cameraHeight = 0
    let positionX = 0
    let positionY = 0
    let camera_margin = 12
    let camera_percent = 22
    let hTotalCam = 0
    let wTotalCam = 0
    let camTh = 0
    let camTw = 0

    cp_onScreen.forEach((el, i) => {
      let c = streamers_content.current
      let c_width = c.clientWidth
      let video = streamers_principal.current[el.id]

      if (!video) {
        return
      }

      if (el.typeVideo !== 'screen') {

        if (internal_mode === 3) {
          camera_percent = 40
        }

        cameraWidth = c_width * (camera_percent / 100)
        cameraHeight = (video.videoHeight / video.videoWidth) * cameraWidth

        let m = el.pos !== qtd_cam ? 10 : 0

        wTotalCam += (cameraWidth + m)
        hTotalCam += (cameraHeight + m)
      }
    })


    cp_onScreen.forEach((str, i) => {
      let c = streamers_content.current
      let c_width = c.clientWidth
      let c_height = c.clientHeight

      let container = container_principal.current[str.id]
      let video = streamers_principal.current[str.id]

      if (!video || !container) {
        return
      }

      if (internal_mode == 1) {

        container.style.width = '100%'
        video.style.width = '100%'
        container.style.top = '0'
        container.style.left = '0'

      } else if (internal_mode === 2) {

        if (str.typeVideo === 'screen') {

          cameraWidth = c_width * (72 / 100)
          cameraHeight = (video.videoHeight / video.videoWidth) * cameraWidth

          positionX = camera_margin
          positionY = (c_height - cameraHeight) / 2

          container.style.width = `${cameraWidth}px`
          container.style.top = `${positionY}px`
          container.style.left = `${positionX}px`

        } else {

          cameraWidth = c_width * (camera_percent / 100)
          cameraHeight = (video.videoHeight / video.videoWidth) * cameraWidth

          positionX = (c_width - cameraWidth) - camera_margin
          positionY = ((c_height - hTotalCam) / 2) + camTh
          camTh += (cameraHeight + 10)

          container.style.width = `${cameraWidth}px`
          container.style.top = `${positionY}px`
          container.style.left = `${positionX}px`

        }

      } else if (internal_mode === 3) {
        camera_margin = 20
        camera_percent = 40
        cameraWidth = c_width * (camera_percent / 100)
        cameraHeight = (video.videoHeight / video.videoWidth) * cameraWidth

        if (qtd_cam <= 2) {
          positionX = ((c_width - wTotalCam) / 2) + camTw
          camTw += (cameraWidth + 10)

          positionY = (c_height - cameraHeight) / 2
        } else if (qtd_cam <= 4) {

          positionX = ((c_width - ((cameraWidth * 2) + 20)) / 2) + camTw
          positionY = (c_height - cameraHeight) - camera_margin

          switch (str.pos) {
            case 1:
              positionY = camera_margin
              break
            case 2:
              positionY = camera_margin
              break
            case 3:
              camTw = 0
              if (qtd_cam === 3) {
                positionX = ((c_width - cameraWidth + 20) / 2) + camTw
              } else {
                positionX = ((c_width - ((cameraWidth * 2) + 20)) / 2) + camTw
              }
              break
            default:
              positionX = ((c_width - ((cameraWidth * 2) + 20)) / 2) + camTw
          }

          camTw += (cameraWidth + 20)

        }

        container.style.width = `${cameraWidth}px`
        container.style.top = `${positionY}px`
        container.style.left = `${positionX}px`

      } else if (internal_mode === 4) {

        camera_margin = 50
        camera_percent = 30
        cameraWidth = c_width * (camera_percent / 100)
        cameraHeight = (video.videoHeight / video.videoWidth) * cameraWidth

        positionX = ((c_width - ((cameraWidth * 3) + 20)) / 2) + camTw
        positionY = (c_height - cameraHeight) - camera_margin

        switch (str.pos) {
          case 1:
            positionY = camera_margin
            break
          case 2:
            positionY = camera_margin
            break
          case 3:
            positionY = camera_margin
            break
          case 4:
            camTw = 0
            if (qtd_cam === 5) {
              positionX = ((c_width - ((cameraWidth * 2) + 10)) / 2) + camTw
            } else {
              positionX = ((c_width - ((cameraWidth * 3) + 20)) / 2) + camTw
            }
            break
          case 5:
            if (qtd_cam === 5) {
              positionX = ((c_width - ((cameraWidth * 2) + 10)) / 2) + camTw
            } else {
              positionX = ((c_width - ((cameraWidth * 3) + 20)) / 2) + camTw
            }
            break
          case 6:
            positionX = ((c_width - ((cameraWidth * 3) + 20)) / 2) + camTw
            break
          default:

        }

        camTw += (cameraWidth + 10)

        container.style.width = `${cameraWidth}px`
        container.style.top = `${positionY}px`
        container.style.left = `${positionX}px`

      }

    })

  }

  useEffect(() => {
    oauth2()
    verifyRefreshTokenLocalStorage()
    //loadDevices()
  }, [])

  useEffect(() => {
    let qtd_screen = onScreen.filter(streamers => streamers.typeVideo === 'screen').length
    let qtd_cam = onScreen.filter(streamers => streamers.typeVideo !== 'screen').length
    let internal_mode = 0

    if ((qtd_cam === 1 && qtd_screen === 0) || (qtd_cam === 0 && qtd_screen === 1)) {
      internal_mode = 1
    } else if ((qtd_cam > 0 && qtd_screen > 0)) {
      internal_mode = 2
    } else if ((qtd_cam > 1 && qtd_cam <= 4 && qtd_screen === 0)) {
      internal_mode = 3
    } else if ((qtd_cam > 4 && qtd_screen === 0)) {
      internal_mode = 4
    } else {
      internal_mode = 0
    }

    if (internal_mode !== 0) {
      resizeVideos(internal_mode, qtd_cam)
    }

    onScreen.forEach((str, i) => {
      let video = streamers_principal.current[str.id]
      video.addEventListener('resize', () => {
        resizeVideos(internal_mode, qtd_cam)
      })
      window.addEventListener('resize', () => {
        resizeVideos(internal_mode, qtd_cam)
      })
    })

  }, [onScreen])

  return (

    <>
      <BlockUI blocking={blockUI} title="Entrando na sala..." />

      <Modal show={showModal} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title className="col-12 font-weight-bold text-center" style={{ lineHeight: 1.2 }}>Olá, vamos verificar suas informações?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className="font-md text-center d-block">Qual seu nome?</span>
          <input
            type="text"
            className="form-control mb-0 mt-2 font-md"
            placeholder="Digite seu nome..."
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
          <span className="text-center mt-3 d-block font-md"><b>Dica: </b>Utilize seu primeiro nome, nome abreviado ou apelido.</span>

          <div className="row my-3">
            <div className="col-6 align-self-center">
              <div className="video-wrapper">
                <video ref={configVideo} id="video-config" className={`${styles.vid_side} ${styles.bg_black} ${styles.video_config}`} autoPlay={true} playinline="true" muted="muted"></video>
                <audio ref={configAudio} id="config-room-audio"></audio>
              </div>
            </div>
            <div className="col-6">
              <span className="ml-1">Selecione a câmera</span>
              <select onChange={(e) => loadCam('configVideo', e.target.value)} className="form-control form-control-sm text-center">
                {videoSources.map((source) => (
                  <option key={source.deviceId} value={source.deviceId} selected={source.selected}>{source.label}</option>
                ))}
              </select>
              <span className="ml-1">Selecione o microfone</span>
              <select onChange={(e) => loadMic(e.target.value)} className="form-control form-control-sm text-center">
                {audioSources.map((source) => (
                  <option key={source.deviceId} value={source.deviceId} selected={source.selected}>{source.label}</option>
                ))}
              </select>
              <button ref={playMicBtn} onClick={() => micController()} id="btn-test-mic" className="btn btn-outline-primary text-center col-12 mt-2" type="play"><i className="fa fa-microphone mr-2"></i><span className="txt-test-mic">Testar Microfone</span></button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button className="btn btn-primary" onClick={() => joinRoom()}>
            <i className="fa fa-check"></i>Entrar na sala
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModalGoogle} backdrop="static" keyboard={false} onHide={handleCloseModalGoogle}>
        <Modal.Header closeButton>
          <Modal.Title className=" font-weight-bold ">Adicione sua conta do YouTube</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <div className="col-12">
            <p>No 4web.live você pode transmitir diretamente para a sua conta do <b>YouTube</b> ou, se preferir, poderá utilizar nossa própria conta para transmitir sem preocupações. Caso queira utilizar sua conta, basta logar com o botão abaixo.</p>
            <p>Lembrando que nossa conta do YouTube é para uso exclusivo aos cliente da <b><a href='https://4.events' target='_blank'>4.events</a></b></p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <a style={{ backgroundColor: '#dfdfdf', color: '#696969' }} className="btn d-flex align-items-center" href={urlAuthGoogle}>
            <img className="mr-2" width='25' height='25' src={iconGoogle} /> <b>Entrar com o Google</b>
          </a>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalAccount} backdrop="static" keyboard={false}>
        <Modal.Header >
          <Modal.Title className="col-12 font-weight-bold text-center ">Atenção!</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <div className="col-12 d-flex justify-content-center align-items-center flex-column ">
            <h5>Deseja utilizar a nossa conta do YouTube ?</h5>
            <p>Se sim, mantenha esta opção ativada. Caso deseje utilizar sua própria conta, desative-a!</p>
            <div className="p-2">
              <label className={`${styles.switch}`}>
                <input type="checkbox" onChange={handleSwitchAccount} checked={account4web} />
                <span className={`${styles.slider} ${styles.round}`}></span>
              </label>
            </div>
            {showBtnAuthGoogle && (
              <a style={{ backgroundColor: '#dfdfdf', color: '#696969' }} className="btn d-flex align-items-center" href={urlAuthGoogle}>
                <img className="mr-2" width='25' height='25' src={iconGoogle} /> <b>Entrar com o Google</b>
              </a>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">

          <button className='btn btn-primary' disabled={account4web == false && localStorage.getItem('goauth2_token') == null ? true : false} onClick={() => {

            handleStartStream()
            setShowModalAccount(false)

          }}><i className="fas fa-broadcast-tower mr-2"></i>Iniciar</button>
          <button className="btn btn-secondary" onClick={() => setShowModalAccount(false)}>Cancelar</button>
        </Modal.Footer>
      </Modal>

      <Modal show={showInviteModal} onHide={() => setShowInviteModal(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title className="font-weight-bold">Convidar outras pessoas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className="text-center mt-2 mb-3 d-block font-md">Compartilhe o link abaixo para convidar outras pessoas para esta sala.</span>
          <LinkShare type="guest" />
        </Modal.Body>
      </Modal>

      <Modal show={showShareVideoModal.status} onHide={() => setShowShareVideoModal({ status: false, link: showShareVideoModal.link })} centered>
        <Modal.Header closeButton>
          <Modal.Title className="font-weight-bold">Link do vídeo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className="text-center mt-2 mb-3 d-block font-md">Aqui está o link da sua transmissão</span>
          <LinkShare type="video" link={showShareVideoModal.link} />
        </Modal.Body>
      </Modal>
      <div className="wrapper">

        <Sidebar page="studio" />

        <div id="content-page" className="content-page">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Row>
                  <Col lg={9} md={12} className="col-12 pr-4 d-flex justify-content-center">
                    <Col lg={8} md={12} className="col-12">
                      <div className={` ${styles.principal_video_c} d-flex justify-content-center`}>
                        <div className={styles.layoutPreview}>
                          <div className={styles.principal_video_container}>
                            <div className={styles.principal_video_rel}>
                              <div ref={streamers_content} className={styles.streamers_container}>
                                <div className={styles.streamVideo}>
                                  {onScreen.map((streamer, index) => (
                                    <streamp ref={(el) => (container_principal.current[streamer.id] = el)} key={streamer.id} className={`${streamer.type === "screen" ? 'screen' : 'camera'}`} style={streamer.css}>
                                      <video ref={(el) => (streamers_principal.current[streamer.id] = el)} autoPlay></video>
                                    </streamp>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className={styles.principal_video_overlay}>
                              <div className={styles.principal_video_overlay_c}>
                                <div className="d-block">
                                  <img src={logo_white} className={`${styles.overlay_logo} img-fluid`} alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.principal_video_top}>
                            <div className={styles.top_container_badges}>

                              {startingBroadcast.status == 'stopped' ? (
                                <>
                                  <div className={styles.preview_badge}>
                                    PREVIEW
                                  </div>
                                </>
                              ) : (
                                  <>
                                    <div className={styles.live_badge}>
                                      AO VIVO
                                  </div>
                                  </>
                                )}

                            </div>
                          </div>
                        </div>
                      </div>
                      {/*Transmission layouts buttons*/}
                      <div className={styles.layout_video_c_footer}>
                        <div className="">
                          {startingBroadcast.status == 'stopped' ? (
                            <button style={{ outline: 'none', border: 'none' }} className={`${styles.btn_start_transmission} ml-1`} onClick={handleSetAccount}>
                              {loadingConnectYoutube ? (
                                <>
                                  <i className="fas fa-spinner fa-spin mr-2"></i>Iniciando...
                                </>
                              ) : (
                                  <>
                                    <i className="fas fa-broadcast-tower mr-2"></i>Entrar ao vivo
                                  </>
                                )}
                            </button>
                          ) : (
                              <>
                                <button style={{ outline: 'none', border: 'none' }} className={`${styles.btn_start_transmission_pressed} ml-1`} onClick={handleStopStream}>
                                  {loadingConnectYoutube ? (
                                    <>
                                      <i className="fas fa-spinner fa-spin mr-2"></i>Finalizando...
                                  </>
                                  ) : (
                                      <>
                                        <i className="fas fa-broadcast-tower mr-2"></i>Encerrar transmissão
                                    </>
                                    )}
                                </button>
                                <OverlayTrigger placement="top" overlay={<Tooltip>Link do vídeo</Tooltip>}>
                                  <i className={`fas fa-share-alt pointer ${styles.shareTransmission} ml-lg-4 ml-2`} onClick={() => setShowShareVideoModal({ status: true, link: showShareVideoModal.link })}></i>
                                </OverlayTrigger>
                              </>
                            )}

                        </div>

                        {/*<div className={`${styles.layout_video_c} mr-1`}>

                          <OverlayTrigger placement="top"
                            overlay={<Tooltip>modo <b>lado a lado</b></Tooltip>}
                          >
                            <div ref={btn_mode_1} className={styles.btn_studio_layout} onClick={() => defineLayoutMode(1)}>
                              <i className="fa fa-square mr-1"></i>
                              <i className="fa fa-square"></i>
                            </div>
                          </OverlayTrigger>

                          <OverlayTrigger placement="top"
                            overlay={<Tooltip>modo <b>apresentação e miniatura</b></Tooltip>}
                          >
                            <div ref={btn_mode_2} className={`${styles.btn_studio_layout} ${styles.btn_studio_layout_pressed}`} onClick={() => defineLayoutMode(2)}>
                              <i className="fa fa-square mr-1"></i>
                              <i className="fa fa-square" style={{ fontSize: "12pt" }}></i>
                            </div>
                          </OverlayTrigger>
                        </div>*/}
                      </div>
                      {/*Studio footer buttons*/}
                      <div className={styles.footer_video_c}>
                        {/* Mic button */}
                        <OverlayTrigger placement="top"
                          overlay={<Tooltip>{audioController.open ? 'Mutar' : 'Desmutar'}</Tooltip>}
                        >
                          <div className={audioController.css} onClick={() => toggleAudio()}><i className={audioController.icon}></i></div>
                        </OverlayTrigger>

                        {/* Cam button */}
                        <OverlayTrigger placement="top"
                          overlay={<Tooltip>{videoController.open ? 'Desabilitar câmera' : 'Habilitar câmera'}</Tooltip>}
                        >
                          <div className={videoController.css} onClick={() => toggleVideo()}><i className={videoController.icon}></i></div>
                        </OverlayTrigger>

                        {/* Share Screen button */}
                        <OverlayTrigger placement="top"
                          overlay={<Tooltip>{shareScreenController.shared ? 'Parar o compartilhamento' : 'Compartilhar tela'}</Tooltip>}
                        >
                          <div className={shareScreenController.css} onClick={() => shareScreen()}><i className="fa fa-desktop btn-initial-icon"></i></div>
                        </OverlayTrigger>

                      </div>
                    </Col>
                  </Col>
                  <Col lg={3} md={12} className={`${styles.iq_right_fixed} col-12 ml-0 px-0`}>
                    <div className="iq-card" style={{ boxShadow: "none", display: "none" }}>
                      <div className="iq-card-body">
                        <select id="audioSelect">
                        </select>
                        <br />
                        <select id="videoSelect">
                        </select>
                      </div>
                    </div>
                    <div className="container-streams">
                      <PerfectScrollbar>
                        <div className={`iq-card ${styles.streamers_list}`} style={{ boxShadow: "none" }}>
                          {/*Streamers list*/}
                          <div id="streamersVideo" className={styles.streamers_video}>
                            {streamers.map((streamer) => (
                              <VideoComponent key={streamer.id} id={streamer.id} src={streamer.srcObject} name={streamer.display} type={streamer.typeVideo} addStreamer={() => { addOnScreen(streamer) }} />
                            ))}
                          </div>

                          <div id="streamersAudio"></div>

                        </div>
                      </PerfectScrollbar>
                    </div>
                    <div className="container-streams-footer text-center">
                      <button className="btn btn-lg btn-primary mt-4" onClick={() => setShowInviteModal(true)}>
                        <i className="fas fa-share-alt"></i>Convidar participantes
                      </button>
                    </div>

                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

      </div>

    </>
  )
}