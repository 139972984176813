
import React, { useEffect } from 'react'

import {Redirect} from 'react-router-dom'

import {logout} from '../../services/auth'
import { useAuth } from '../../context/Auth'

let root = document.documentElement

export default function Logout (){
    const { setUser } = useAuth();

    logout();
    
    useEffect(() => {
        setUser(null);
        root.style.setProperty('--iq-primary', '#d82e4c')
        root.style.setProperty('--iq-primary-light', '#ea5532')
        root.style.setProperty('--iq-text-button', '#fff')
    }, [setUser]);
    
    return(
        <Redirect to="/signin"/>
    )
}