import React, {  useEffect, useState } from 'react'
import './style.css'
import img1 from '../../assets/images/login/4w1.png'
import img2 from '../../assets/images/login/4w2.png'
import img3 from '../../assets/images/login/4w3.png'
import logo from '../../assets/images/logo/n1-1.png'

import { useAuth } from '../../context/Auth'
import api from '../../services/api'

import { isAuthenticated } from '../../services/auth'
import { Link, Redirect } from 'react-router-dom'
import { Modal,Form } from 'react-bootstrap'

import Swal from 'sweetalert2'
import io from 'socket.io-client'
import { useTranslation } from 'react-i18next'

window.socket = null;
window.internalSocket = null;

export default function Signin({ history }){
    const { t } = useTranslation()

    const [errorMsg,setErrorMsg] = useState(false)
    const [email,setEmail] = useState('')
    const [emailResend,setResendEmail] = useState('')
    const [sendEmail,setSendEmail] = useState(false)
    const [loading,setLoading] = useState(false)
    const [password,setPassword] = useState('')
    const [failedLogin,setFailedLogin] = useState({type: '',message: ''})
    const [contTime,setContTime] = useState(60)
    const [showModal,setShowModal] = useState(false)
    const { setUser } = useAuth()

    //Inicializa o Huggy
    if(window.Huggy && window.Huggy.init){
        window.Huggy.init({
            defaultCountry: '+55',
            uuid: '8109c576-e07f-46ad-8ea0-70adcf95b8be',
            company: "328192",
            contextID: '4c36e52562dc630d4c58853fe937743a',
        })
    }
    
   async function submitEmail(e){
        e.preventDefault()
        
        if(email === undefined || email === ""){
            
            setErrorMsg(true)
            
            setTimeout(()=>{
                setErrorMsg(false)
            },5000)
          
            return false;
        }

        setLoading(true)

        try{
            
            let data = await api.post('/send-password',{
                email: email
            })
            let {status} = data
            
            if(status === 200){
                setSendEmail(true)
                setLoading(false)
            }
            
        }catch(err){

            setFailedLogin({
                type: 'email',
                message: t(`pages.Signin.errorEmailNotFound`)
            })
            setLoading(false)
            setTimeout(()=>{
                setFailedLogin({type: '',message: ''})
            },7000)
              
            return false
        }        
    }

   async function submit(e){
        e.preventDefault()
       
        if(password === undefined || password === "" ){
            setErrorMsg(true)
            setTimeout(()=>{
                setErrorMsg(false)
            },5000)
          return false;
        }

        setLoading(true)
        
        try{
            let res = await api.post('/signin',{
                email:  email,
                password: password,
                type: '4web'
            })
            let { data } = res
            
            if(data.status === 200){
                
                setLoading(false)
                const { user, token } = data.response
                
                let token_auth = await Buffer.from(JSON.stringify(user)).toString('base64')
                localStorage.setItem('auth-user', token_auth)
                localStorage.setItem('auth-token', token)

                api.interceptors.request.use((config) => {
                    config.headers.authorization = `Bearer ${token}`;
                    return config;
                })
                
                setUser(user)

                window.socket = io(data.mediaServer, {cors: { origin: window.location.origin, credentials: false}})

                if(data.room_type.toString() === 'studio'){
                  window.internalSocket = io(`${data.mediaServer}/internal`, {cors: { origin: window.location.origin, credentials: false}})
                }

                history.push('/new-room')
                
            }
            
        }catch(err){
            
            setFailedLogin({
                type: 'login',
                message: t(`pages.Signin.errorPassowrdIncorrect`)
            })

            setTimeout(()=>{
                setFailedLogin({type: '',message: ''})
            },7000)

            setLoading(false)
            
            return false
        }
       

    }


    async function resendEmail(e){
        e.preventDefault()

        if(emailResend === undefined || emailResend === ""){
            
            setFailedLogin({
                type: 'resend',
                message: t(`pages.Signin.errorEmailIsRequired`)

            })
            
            setTimeout(()=>{
                setFailedLogin({type: '',message: ''})
            },5000)
          
            return false;
        }
        setLoading(true)

        try{
            
            let data = await api.post('/resend-password',{
                email: emailResend
            })
            let {status} = data
            
            if(status === 200){
                
                setEmail(emailResend)
                setSendEmail(true)
                setLoading(false)
                setShowModal(false)               
                setContTime(60)

                Swal.fire(t(`pages.Signin.swalEmailHasBeenSentTitle`), t(`pages.Signin.swalEmailHasBeenSentText`), 'success')
            }
            
        }catch(err){
            setLoading(false)
            
           
            Swal.fire('Oops...', t(`pages.Signin.swalSomethingWrong`), 'error')
            
            return false
        }
    }
    
    useEffect(()=>{
       
        setTimeout(()=>{
            if(sendEmail && contTime !== 0){
                setContTime(contTime - 1)
            }
        },1000) 
        
    })
 
    
    return (
        !isAuthenticated () ? (
            <section className="sign-in-page">
        
                <div className="container bg-white p-0">
                    <div className="row no-gutters">
                        <div className="col-sm-6 align-self-center">
                            
                            {
                                sendEmail ? (
                                <div className="sign-in-from">
                                    <h1 className="mb-0">Login</h1>
                                    <p>{t(`pages.Signin.pageDescriptionPassword`)}</p>
                                    {
                                        failedLogin.type === 'login' && (
                                            <div className="alert alert-danger">
                                                <i style={{fontSize: 18,marginRight: 5}}className="fa fa-info-circle"></i> {failedLogin.message}
                                            </div>
                                        )
                                    }
                                    {errorMsg && (
                                        
                                        <div className="alert alert-danger">
                                        <i style={{fontSize: 18,marginRight: 5}}className="fa fa-info-circle"></i>
                                            {t(`pages.Signin.passwordIsRequired`)}
                                        </div>
                                        
                                    )}
                                    {
                                        sendEmail && (
                                            
                                            <div className="alert-success">
                                                <i style={{fontSize: 18,marginRight: 5}}className="fa fa-info-circle"></i>
                                                    {t(`pages.Signin.successWhenSendEmailText1`)} <b>{email}</b> {t(`pages.Signin.successWhenSendEmailText2`)}
                                            </div>
                                        )
                                        
                                    }
                                    <form className="mt-4" onSubmit={submit} >
                                        <div className="form-group">
                                            
                                            <input type="password" 
                                            className={errorMsg ? ("form-control mb-0 input-error"):("form-control mb-0")}  
                                            placeholder={t(`pages.Signin.passwordPlaceholder`)}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            autoComplete="off"
                                            
                                            />
                                        </div>
                                        
                                        <div className="d-flex justify-content-center w-100 p-3">
                                            <button type="submit" className="btn btn-primary float-right btn-signin"> 
                                                <i className={loading && password !== ""  ? ("fa fa-circle-notch fa-spin") : ("las la-sign-in-alt")}></i>
                                                {t(`pages.Signin.enterButtonText`)}
                                            </button>
                                            
                                            <button onClick={()=> setShowModal(true)} disabled={contTime === 0 ? false : true}  type="button"  className={ contTime === 0 ? "btn btn-info ml-2 float-right btn-signin": "btn btn-secondary ml-2 float-right btn-resend btn-signin"}> 
                                                { contTime === 0 ? (
                                                    <i className="las la-paper-plane"></i>
                                                    
                                                    ):(
                                                    <label className="cont-time">{contTime}</label>
                                                    )
                                                }
                                                {t(`pages.Signin.resendEmailText`)}
                                            </button>
                                        </div>
                                    
                                    </form>
                                </div>
                                ):(
                                <div className="sign-in-from">
                                    <h1 className="mb-0">Login</h1>
                                    <p>{t(`pages.Signin.pageDescription`)}</p>
                                    
                                    {errorMsg && (
                                        
                                        <div className="alert alert-danger">
                                            <i style={{fontSize: 18,marginRight: 5}}className="fa fa-info-circle"></i>
                                            {t(`pages.Signin.errorEmailIsRequired`)}
                                        </div>
                                        
                                    )}

                                    {
                                        failedLogin.type === 'email' && (
                                            <div className="alert alert-danger">
                                                <i style={{fontSize: 18,marginRight: 5}}className="fa fa-info-circle"></i> {failedLogin.message}
                                            </div>
                                        )
                                    }

                                    <form className="mt-4" onSubmit={submitEmail}>
                                        <div className="form-group">
                                            
                                            <input type="email" 
                                            className={errorMsg ? ("form-control mb-0 input-error"):("form-control mb-0")}  
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                        
                                        <div className="d-flex justify-content-center w-100 ">
                                            <button type="submit" className="btn btn-primary float-right btn-signin"> 
                                                <i className={loading ? ("fa fa-circle-notch fa-spin") : ("las la-paper-plane")}></i> 
                                                {t(`pages.Signin.sendText`)}
                                            </button>
                                        </div>
                                        <div className="d-flex justify-content-center w-100 mt-5 ">
                                            <Link to='/signup'>
                                                {t(`pages.Signin.notHaveAccountYetText1`)} <strong>{t(`pages.Signin.notHaveAccountYetText2`)}</strong> {t(`pages.Signin.notHaveAccountYetText3`)}
                                            </Link>
                                        </div>
                                    
                                    </form>
                                </div>
                                )
                            }
                            
                        </div>
                        <div className="col-sm-6 text-center">
                            <div className="sign-in-detail text-white">
                                <a className="sign-in-logo mb-5" href="#"><img src={logo} className="img-fluid" /></a>
                                <div className="owl-carousel" data-autoplay="true" data-loop="true" data-nav="false" data-dots="true" data-items="1" data-items-laptop="1" data-items-tab="1" data-items-mobile="1" data-items-mobile-sm="1" data-margin="0">
                                    <div className="item">
                                        <img src={img1} className="img-fluid mb-4" alt="logo"/>
                                        <h4 className="mb-1 text-dark">{t(`pages.Signin.carouselTitle1`)}</h4>
                                        <p className="text-dark">{t(`pages.Signin.carouselText1`)}</p>
                                    </div>
                                    <div className="item">
                                        <img src={img2} className="img-fluid mb-4" alt="logo"/>
                                        <h4 className="mb-1 text-dark">{t(`pages.Signin.carouselTitle2`)}</h4>
                                        <p className="text-dark">{t(`pages.Signin.carouselText2`)}</p>
                                    </div>
                                    <div className="item">
                                        <img src={img3} className="img-fluid mb-4" alt="logo"/>
                                        <h4 className="mb-1 text-dark">{t(`pages.Signin.carouselTitle3`)}</h4>
                                        <p className="text-dark">{t(`pages.Signin.carouselText3`)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton   >
                        
                        <Modal.Title>{t(`pages.Signin.resendEmailText`)}</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={resendEmail}>
                        <Modal.Body>
                            <>
                            {failedLogin.type === 'resend' && (
                                        
                                <div className="alert alert-danger">
                                    <i style={{fontSize: 18,marginRight: 5}}className="fa fa-info-circle"></i> {failedLogin.message}
                                </div>
                                        
                            )}
                            
                            <input type="email" 
                            className={failedLogin.type === 'resend' ? ("form-control mb-0 input-error"):("form-control mb-0")}  
                            placeholder="Email"
                            value={emailResend}
                            onChange={(e) => setResendEmail(e.target.value)}
                            />
                            
                            </>
                        </Modal.Body>

                        <Modal.Footer>
                            <button className="btn btn-secondary" onClick={() => setShowModal(false)}>{t(`pages.Signin.closeButtonText`)}</button>
                            <button className="btn btn-primary" type="submit">
                                <i className={loading && password === "" ? ("fa fa-circle-notch fa-spin") : ("las la-paper-plane")}></i> 
                                {t(`pages.Signin.sendText`)}
                            </button>
                        </Modal.Footer>
                    </Form>
                    
                </Modal>
            </section>

        ):(
            <Redirect to="/new-room"/>
        )
        
       
    )
}
