import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ absolute }) => absolute && css`
    position: absolute;
    bottom: 0;
    left: 0;
  `}

	width: 100%;
  height: ${({ background }) => background ? '52px' : '46px'};
  padding: 0 16px;
  padding-top: ${({ background }) => background ? '8px' : '0px'};
	display: flex;
	align-items: center;
	color: ${({ dark }) => dark ? '#777' : '#fff'};
  font-size: 18px;
  z-index: 105;

  ${({ compact }) => compact && css`
    font-size: 12px;
    height: 28px;
    padding: 0 4px;
  `}

  ${({ background }) => background && css`
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,0) 15%, rgba(0,0,0,0.6558998599439776) 100%);
  `}

  .btn-container {
    padding: 1px 8px;
    cursor: pointer;
    border-radius: 2px;
    transition: all 0.2s;
    margin-right: ${({ compact }) => compact ? '0px' : '8px'};

    &:hover {
      opacity: 0.8;
    }
  }

  .progress-slider {
    flex: 1;
    margin-right: 12px;
    margin-left: ${({ compact }) => compact ? '4px' : '2px'};
  }

  .time-left {
    margin-right: 16px; 
    font-size: 13px;
    cursor: default;
  }

  .volume-btn {
    width: 15px;
  }

  .volume-slider {
    width: 70px;
    margin-right: 12px;
    margin-left: 2px;
  }
`;