import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  flex: 1;
  width: 0;
  height: 100%;

	display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  ${props => props.fullscreen && css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 16px;
    overflow: hidden;
    background: #1b1b1b;
    z-index: 9999999999;
  `}
`;

export const Emphasis = styled(motion.div)`
  flex: 6;
  width: 0;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    width: 100%;
    height: 0;
  }

  @media (max-width: 768px) {
    flex: 4;
  }

  @media (max-width: 480px) {
    flex: 2;
  }

  > div {
    width: 100%;
    height: 100%;
  }
`;


export const Mosaic = styled(motion.div)`
  flex: 2;
  width: 0;
  height: 100%;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;

  @media (max-width: 1024px) {
    width: 100%;
    height: 0;
  }
`;

export const Stream = styled(motion.div)`  
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;