import { Redirect } from 'react-router-dom';
import { getAuthenticatedUser } from '../../services/auth';

import SideBar from '../../components/Layout/SideBar/SideBar';
import { Header } from '../../components/Layout/Header';
import PlanList from './PlanList'
import { useTranslation } from 'react-i18next';

export default function Plans({ history }) {
    const { t } = useTranslation();
	const user = getAuthenticatedUser();
	const is4webClient = user.client_type === '4web';

    const query =  new URLSearchParams(history.location.search);
    const defaultSelectedPlan = query.get('selected');

	return (
		<>
			{window.location.hostname === 'meet.4web.live' && <Redirect to="meet" />}
			{is4webClient || <Redirect to="/new-room" />}

            {/* <SideBar page="billing" /> */}
            <Header page="billing" />

            <div className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <h3>{t(`pages.Plans.title`)}</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <div className="iq-card iq-card-block iq-card-stretch mt-3">
                                <div className="iq-card-body">
                                    <PlanList defaultSelectedPlan={defaultSelectedPlan} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
