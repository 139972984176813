// ARQUIVO DE CONFIGURAÇÃO DAS ROTAS DA APLICAÇÃO

import React from 'react'
//importando o react-router-dom, responsável pelo funcionamento das rotas
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoutes from './components/PrivateRoutes'

//importando as páginas
import Signin from './pages/Signin/index'
import NotFound from './components/NotFound'

import Logout from './components/Logout'
import Auth from './components/Auth'
import AutoLogin from './components/AutoLogin'
import CallbackYoutube from "./components/CallbackYoutube"
import Broadcast from './pages/Broadcast'
import Records from './pages/Records'
import Settings from './pages/Settings'
import Billing from './pages/Billing'
import Plans from './pages/Plans'
import Studio from './pages/Studio'
import { StudioSso } from './pages/StudioSso';
import StudioDev from './pages/StudioDev'
import Meet from './pages/Meet'
import { MeetSso } from './pages/MeetSso'
import Signup from './pages/Signup'
import NewRoom from './pages/NewRoom'
import Support from './pages/Support'
import { Error } from './pages/Error'
import CallbackTwitch from './components/CallbackTwitch'
import AccountProvider from './context/Accounts'
import { BrandColorsThemeProvider } from './context/BrandColorsTheme'
import Destinations from './pages/Destinations'
import { AccessDenied } from './pages/AccessDenied'
import { RemovedFromTheRoom } from './pages/RemovedFromTheRoom'
import { getAuthenticatedUser } from './services/auth';
import mautic from 'mautic-tracking';

//componente de rotas
export default function Routes() {

    const user = getAuthenticatedUser()

    // Inicia o Huggy apenas para clientes 4web
    if (user && user.client_type && user.client_type === '4web') {
        if(window.Huggy.init){
            window.Huggy.init({
                defaultCountry: '+55',
                uuid: '8109c576-e07f-46ad-8ea0-70adcf95b8be',
                company: "328192",
                contextID: '4c36e52562dc630d4c58853fe937743a',
            })
        }
    }
    // Inicia o rastreio do Mautic
    mautic.initialize('https://marketing.4web.live/mtc.js');

    if (user && user.email) {
        mautic.pageView({ firstname: user.name, email: user.email });
    } else {
        mautic.pageView();
    }

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Auth} />
                <Route path="/signin" component={Signin} />
                <Route path="/signup" component={Signup} />
                <Route path="/logout" component={Logout} />
                <Route path="/auto-login/:code" component={AutoLogin} />
                <Route path="/callback-youtube/:code" component={CallbackYoutube} />
                <Route path="/access-denied" component={AccessDenied} />
                <Route path="/removed-from-the-room" component={RemovedFromTheRoom} />
                <Route path="/studio/:short_id" component={StudioSso} />
                <Route path="/meet/:short_id" component={MeetSso} />
                <Route path="/error" component={Error} />
                <Route exact path="/meet" component={Meet} />
                <AccountProvider>
                    <BrandColorsThemeProvider>
                        <PrivateRoutes exact path="/studio" component={Studio} />
                        <PrivateRoutes path="/dev-studio" component={StudioDev} />
                        <PrivateRoutes path="/broadcasts" component={Broadcast} />
                        <PrivateRoutes path="/records" component={Records} />
                        <PrivateRoutes path="/settings" component={Settings} />
                        <PrivateRoutes path="/billing" component={Billing} />
                        <PrivateRoutes path="/plans" component={Plans} />
                        <PrivateRoutes path="/new-room" component={NewRoom} />
                        <PrivateRoutes path="/destinations" component={Destinations} />
                        <PrivateRoutes path="/support" component={Support} />
                        <PrivateRoutes path="/callback-twitch" component={CallbackTwitch} />
                    </BrandColorsThemeProvider>
                </AccountProvider>
                <Route path="*" component={NotFound} />
            </Switch>
        </BrowserRouter>
    )
}
