import { useState, useEffect, useLayoutEffect } from 'react'

export default function useContainerDimensions(myRef, triggerOnEveryRender = false) {
  function getDimensions() {
    return {
      width: myRef.current ? myRef.current.offsetWidth : 0,
      height: myRef.current ? myRef.current.offsetHeight : 0
    }
  }

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    function handleResize() {
      const dimensions = getDimensions()
      myRef.current.dimensionsFromResize = dimensions;
      setDimensions(dimensions)
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myRef]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (triggerOnEveryRender) {
      if (myRef.current) {
        const resizeDimensions = myRef.current.dimensionsFromResize;
        const newDimensions = getDimensions();

        // ignore effects triggered by resize
        if (resizeDimensions) {
          if (resizeDimensions.width === dimensions.width && resizeDimensions.height === dimensions.height) {
            return;
          }
        }
  
        if (newDimensions.width !== dimensions.width || newDimensions.height !== dimensions.height) {
          setDimensions(newDimensions);
        }    
      }
    }
  });


  return dimensions;
};