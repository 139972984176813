import Cards from 'react-credit-cards'
import { Spinner } from 'react-bootstrap'

import { Container, Form, Button } from './styles'
import { useTranslation } from 'react-i18next';

export default function CreditCardForm({
  cardDraft,
  onSubmit,
  onCancel,
  error,
  loading,
  onInputChange,
  onInputFocus,
  showFooter = true,
}) {
  const { t } = useTranslation();
  function handleSubmit(e) {
    e.preventDefault();
    onSubmit();
  }

  return (
    <Container>
      <Cards
        cvc={cardDraft.cvc}
        expiry={cardDraft.expiry}
        focused={cardDraft.focused}
        name={cardDraft.name}
        number={cardDraft.number}
      />

      <Form onSubmit={handleSubmit}>
        {error && (
          <div className="alert alert-danger">
            <i
              style={{ fontSize: 18, marginRight: 5 }}
              className="fa fa-info-circle"
            />
            {error}
          </div>
        )}

        <div className="form-group mt-0">
          <label className="label-input">{t(`components.CreditCardForm.labelNumber`)}</label>
          <input
            className="form-control mb-0"
            type="tel"
            name="number"
            placeholder={t(`components.CreditCardForm.labelNumberPlaceholder`)}
            pattern="[\d| ]{16,22}"
            value={cardDraft.number}
            onChange={onInputChange}
            onFocus={onInputFocus}
          />
        </div>

        <div className="form-group mt-0">
          {/* <label className="label-input">Name</label> */}
          <label className="label-input">{t(`components.CreditCardForm.labelName`)}</label>
          <input
            className="form-control mb-0"
            type="text"
            name="name"
            placeholder={t(`components.CreditCardForm.labelNamePlaceholder`)}
            value={cardDraft.name}
            onChange={onInputChange}
            onFocus={onInputFocus}
          />
        </div>

        <div className="form-group mt-0">
          {/* <label className="label-input">Expiry</label> */}
          <label className="label-input">{t(`components.CreditCardForm.labelExpires`)}</label>
          <input
            className="form-control mb-0"
            type="text"
            name="expiry"
            placeholder={t(`components.CreditCardForm.labelExpiresPlaceholder`)}
            pattern="\d\d/\d\d"
            value={cardDraft.expiry}
            onChange={onInputChange}
            onFocus={onInputFocus}
          />
        </div>

        <div className="form-group mt-0">
          <label className="label-input">CVC</label>
          <input
            className="form-control mb-0"
            type="number"
            name="cvc"
            placeholder="CVC"
            pattern="\d{3,4}"
            value={cardDraft.cvc}
            onChange={onInputChange}
            onFocus={onInputFocus}
          />
        </div>

        {showFooter && (
          <footer>
            <Button secondary type="button" onClick={onCancel} disabled={loading}>
              {t(`components.CreditCardForm.cancelButtonText`)}
            </Button> 
            <Button className="btn-primary" type="submit" disabled={loading}>
              { loading ? (
                <Spinner as="span" animation="border" size="sm" />
              ) : (
                t(`components.CreditCardForm.saveCardButtonText`)
              )}
            </Button>
          </footer>
        )}
      </Form>
    </Container>
  )
}