import { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import { CgCheck } from 'react-icons/cg'
import { GrFormClose } from 'react-icons/gr'
import { useTranslation } from 'react-i18next';

import api from '../../../services/api';
import { getAuthenticatedUser } from '../../../services/auth';

import basicPlanImg from '../../../assets/images/plans/basic-plan.png';
import upPlanImg from '../../../assets/images/plans/up-plan.png';

import ChangePlanModal from '../ChangePlanModal';
import { Container, Plan, PlanHeader, PlanBody, CheckContainer, PlanFooter, Button } from './styles';

const basicPlan = {
	id: "basic_plan",
	product_name: "Plan Basic",
	priceFormatted: "Free",
	price: 0
}

export default function PlanList({ defaultSelectedPlan }) {
	const { t } = useTranslation();
	const user = getAuthenticatedUser();
	const [plans, setPlans] = useState([]);
	const [currentPlan, setCurrentPlan] = useState(null);
	const [loading, setLoading] = useState(true);
	const [selectedPlan, setSelectedPlan] = useState({ plan: null, opened: false });

	useEffect(() => {
		async function fetchData() {
			try {
				const plansPromise = api.get('/api/plans');
				const currentPlanPromise = api.get(`/users/${user._id}/plan`, {
					validateStatus: false
				});

				const [plansResponse, currentPlanResponse] = await Promise.all([
					plansPromise,
					currentPlanPromise,
				]);

				const plans = plansResponse.data.map(plan => {
					const priceFormatted = new Intl.NumberFormat('en-US', {
						style: 'currency',
						currency: 'USD',
					}).format(plan.price) + ' / month';

					return {
						...plan,
						priceFormatted,
					}
				})

				setPlans([basicPlan, ...plans]);

				if (currentPlanResponse.data.plan) {
					setCurrentPlan(currentPlanResponse.data.plan);
				} else {
					setCurrentPlan(basicPlan);
				}

				if (defaultSelectedPlan) {
					const openedPlan = plans.find(plan => plan.product_name === defaultSelectedPlan);
					if (openedPlan) {
						setSelectedPlan({ plan: openedPlan, opened: true });
					}
				}
			} catch (err) {
				console.error(err);
			} finally {
				setLoading(false);
			}
		}

		fetchData();
	}, [user._id]);

	function handleSelectPlan(plan) {
		setSelectedPlan({ plan, opened: true });
	}

	function handleClosePlanConfirmation() {
		setSelectedPlan(prevState => ({ ...prevState, opened: false }));
	}

	function handlePlanChangeSuccessfully(newPlan) {
    toast.success('Plan changed successfully!', {
      position: "bottom-center",
      autoClose: 5000,
    });

		setCurrentPlan(newPlan);
		handleClosePlanConfirmation();
	}

	const plansData = {
		'Plan Basic': {
			image: basicPlanImg,
			description: 'Starter',
			details: {
				[t(`pages.Plans.PlanList.screenSharingText`)]: true,
				[t(`pages.Plans.PlanList.streamAnywhereText`)]: true,
				[t(`pages.Plans.PlanList.2OnScreenParticipantsText`)]: true,
				[t(`pages.Plans.PlanList.logoRequiredText`)]: true,
				[t(`pages.Plans.PlanList.2StreamingPerDayText`)]: true,
				[t(`pages.Plans.PlanList.5StreamingsPerMonthText`)]: true,
				[t(`pages.Plans.PlanList.customLogoText`)]: false,
				[t(`pages.Plans.PlanList.backgroundText`)]: false,
				[t(`pages.Plans.PlanList.customRTMPText`)]: false,
				[t(`pages.Plans.PlanList.internalChatText`)]: false
			}
		},
		'Plan Up': {
			image: upPlanImg,
			description: t(`pages.Plans.PlanList.betterResultsText`),
			details: {
				[t(`pages.Plans.PlanList.screenSharingText`)]: true,
				[t(`pages.Plans.PlanList.streamAnywhereText`)]: true,
				[t(`pages.Plans.PlanList.9OnScreenParticipantsText`)]: true,
				[t(`pages.Plans.PlanList.logoNotRequiredText`)]: true,
				[t(`pages.Plans.PlanList.10StreamingPerDayText`)]: true,
				[t(`pages.Plans.PlanList.30StreamingsPerMonthText`)]: true,
				[t(`pages.Plans.PlanList.customLogoText`)]: true,
				[t(`pages.Plans.PlanList.backgroundText`)]: true,
				[t(`pages.Plans.PlanList.customRTMPText`)]: true,
				[t(`pages.Plans.PlanList.internalChatText`)]: true
			}
		}
	}

	//Lista com os planos expirados
	const expiredPlans = ['prod_KmacgOUri7nrqr']

	if (loading) {
		return (
      <Container>
        <Spinner animation="border" />
      </Container>
		)
	}

	return (
		<>
			<Container>
				{plans.map((plan) => {
					const planSelected = plan.id === currentPlan.id;
					const planData = plansData[plan.product_name];

					//Verifica se o plano ainda está ativo
					if(expiredPlans.indexOf(plan.product_id) > -1) {
						return false
					} else {

						return (
							<Plan key={plan.id}>
								<PlanHeader>
									<img src={planData.image} alt={plan.product_name} />
									<div>
										<h4>{plan.product_name}</h4>
										<small>{planData.description}</small>
									</div>
								</PlanHeader>
	
								<PlanBody>
									<ul>
										{Object.entries(planData.details).map(([detail, active], index) => {
											return (
												<li key={index}>
													<CheckContainer active={active}>
														{active ? (
															<CgCheck color="#2f945c" size="18px" />
														) : (
															<GrFormClose color="#942f2f" size="14px" />
														)}
													</CheckContainer>
													<span>{detail}</span>
												</li>
											);
										})}
									</ul>
								</PlanBody>
	
								<PlanFooter>
									<p>
										<span>${plan.price}</span>
										<small> / {t(`pages.Plans.PlanList.monthText`)}</small>
									</p>
	
									{!planSelected && (
										<Button className="btn-primary" onClick={() => handleSelectPlan(plan)}>
											{t(`pages.Plans.PlanList.chooseText`)}
										</Button>
									)}
								</PlanFooter>
							</Plan>
						);

					}
					
				})}
			</Container>

			<ChangePlanModal
				open={selectedPlan.opened}
				plan={selectedPlan.plan}
				onClose={handleClosePlanConfirmation}
				onSuccess={handlePlanChangeSuccessfully}
			/>
		</>
	);
}
