import { useEffect, useState } from 'react';
import DetectRTC from 'detectrtc';
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';

import { requestCamAndMicPermissions } from '../../utils/permissions';

import { Container } from './styles';

export function PermissionsOverlay({ onShouldLoadDevices, darkMode }) {
	const { t } = useTranslation();
	const [show, setShow] = useState(false);
	const [popupIsOpen, setPopupIsOpen] = useState(false);
	const [permissionsNeeded, setPermissionsNeeded] = useState({
		cam: false,
		mic: false,
	});

	async function handleAllowClick() {
		setPopupIsOpen(true);
		let permissionRequestResult;

		try {
			permissionRequestResult = await requestCamAndMicPermissions();
		} finally {
			setPopupIsOpen(false);
		}

		const camDenied = ['denied', 'blocked'].includes(permissionRequestResult.cam);
		const micDenied = ['denied', 'blocked'].includes(permissionRequestResult.mic);

		if (
			(permissionsNeeded.cam && camDenied) ||
			(permissionsNeeded.mic && micDenied)
		) {
			const deviceText = camDenied && micDenied
				? t(`components.PermissionsOverlay.cameraAndMicText`)
				: camDenied
				? t(`components.PermissionsOverlay.cameraText`)
				: t(`components.PermissionsOverlay.microphoneText`)

			const endText = camDenied && micDenied
				? t(`components.PermissionsOverlay.interactWithOthersText`)
				: camDenied
				? t(`components.PermissionsOverlay.beSeenByOthersText`)
				: t(`components.PermissionsOverlay.speakToOthersText`)

			toast.warn(t(`components.PermissionsOverlay.toastDenyAccessCameraOrMic`, { deviceText, endText }), {
				position: toast.POSITION.BOTTOM_CENTER,
				autoClose: false,
			});
		}

		if (permissionRequestResult.cam === 'failed') {
			toast.error(t(`components.PermissionsOverlay.toastFailedRequestDevice`), {
				position: toast.POSITION.BOTTOM_CENTER,
				autoClose: false,
			})
		}

		setShow(false);
		onShouldLoadDevices?.();
	}

	function handleDenyClick() {
		setShow(false);
		onShouldLoadDevices?.();
	}

	useEffect(() => {
		DetectRTC.load(() => {
			const needCamPermission =
				DetectRTC.hasWebcam && !DetectRTC.isWebsiteHasWebcamPermissions;
			const needMicPermission =
				DetectRTC.hasMicrophone && !DetectRTC.isWebsiteHasMicrophonePermissions;

			if (needCamPermission || needMicPermission) {
				setShow(true);
				setPermissionsNeeded({
					cam: needCamPermission,
					mic: needMicPermission,
				});
			} else {
				onShouldLoadDevices?.();
			}
		});
	}, []);

	if (!show) {
		return null;
	}

	return (
		<Container darkMode={darkMode}>
			<i className="fa fa-video"></i>
			<h1>{t(`components.PermissionsOverlay.title`)}</h1>
			<p>
				{t(`components.PermissionsOverlay.text`)}{' '}
				{permissionsNeeded.cam && permissionsNeeded.mic ? (
					<>
						<b>{t(`components.PermissionsOverlay.cameraText`)}</b> and <b>{t(`components.PermissionsOverlay.microphoneText`)}</b>
					</>
				) : permissionsNeeded.mic ? (
					<b>{t(`components.PermissionsOverlay.microphoneText`)}</b>
				) : (
					<b>{t(`components.PermissionsOverlay.cameraText`)}</b>
				)}
			</p>

			<div>
				{popupIsOpen ? (
					<span>{t(`components.PermissionsOverlay.clickAllowText`)}</span>
				) : (
					<>
						<button className="btn btn-primary" onClick={handleAllowClick}>
							{t(`components.PermissionsOverlay.allowText`)}{' '}
							{permissionsNeeded.cam && permissionsNeeded.mic
								? t(`components.PermissionsOverlay.cameraAndMicText`)
								: permissionsNeeded.mic
								? t(`components.PermissionsOverlay.microphoneText`)
								: t(`components.PermissionsOverlay.cameraText`)}
						</button>
						<button className="btn-deny" onClick={handleDenyClick}>
							{t(`components.PermissionsOverlay.continueWithoutText`)} {permissionsNeeded.cam ? t(`components.PermissionsOverlay.cameraText`) : t(`components.PermissionsOverlay.microphoneText`)}
						</button>
					</>
				)}
			</div>
		</Container>
	);
}
