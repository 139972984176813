const mauticPlgEvent = (name, title) => {
    title = encodeURIComponent('plg_event_' + name + ' - ' + title);
    let url = encodeURIComponent('https://app.4web.live/admin/plg_event/' + name);
    let src = 'https://marketing.4web.live/mtracking.gif?page_url=' + url + '&page_title=' + title;
    let img = document.createElement('img');
    img.style.width  = '1px';
    img.style.height  = '1px';
    img.style.display = 'none';
    img.src = src;
    let body = document.getElementsByTagName('body')[0];
    body.appendChild(img);
}

export default mauticPlgEvent;