import React, { useMemo, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { AnimateSharedLayout } from 'framer-motion'

import Video from '../../../../components/Video';

import { getPersonColor } from '../../../../utils/colors';
import { volumeMeter } from '../../../../utils/volumeMeter';
import useDimensions from '../../../../hooks/useDimensions';
import usePrevious from '../../../../hooks/usePrevious';

import {
	Container,
	Emphasis,
	Mosaic,
	Stream,
} from './styles';

const volumeData = new Map()

export function Conference({
	streams,
	pinnedStreamer,
	layout,
	onSwapStreams,
	fullscreen,
}) {
	const [streamDimensions, setStreamDimensions] = useState({
		width: 0, height: 0, margin: 0, streamCount: 0,
	});

	const {
		width: mosaicWidth,
		height: mosaicHeight,
		refCallback: mosaicRefCallback,
	} = useDimensions();

	const [emphasis, mosaic] = useMemo(() => {
		let mosaic = [];
		let emphasis;

		if (layout === 'automatic') {
			emphasis = streams.find(stream => stream.id === pinnedStreamer);
			mosaic = streams.filter(stream => stream.id !== emphasis?.id);
		}

		if (layout === 'emphasis') {
			const stream =
				streams.find(stream => stream.id === pinnedStreamer) ||
				streams.find(stream => stream.typeVideo === 'screen') ||
				streams[0]

			mosaic = [stream].filter(Boolean);
		}
		return [emphasis, mosaic];
	}, [streams, pinnedStreamer, layout]);

	const mosaicVisible = useMemo(() => {
		return mosaic.slice(0, streamDimensions.streamCount);
	}, [mosaic, streamDimensions.streamCount])

	const prevStreamCount = usePrevious(mosaic.length);

	const updateStreamDimensions = useDebouncedCallback((
		streamCount, availableWidth, availableHeight
	) => {
		const ratio = 9 / 16;
		const marginFixed = 2;
		const marginPercentage = 0.005;
		const minStreamWidth = 220;

		function areaFits(possibleWidth, streamCount) {
			const availWidth = availableWidth;
			const availHeight = availableHeight;
			let totalHeightNeeded;

			const margin = marginFixed + (possibleWidth * marginPercentage);
			const completeWidth = possibleWidth + 2 * margin;

			const streamsPerRow = Math.floor(availWidth / completeWidth);
			const rows = Math.ceil(streamCount / streamsPerRow);
			if (rows > 0) {
				totalHeightNeeded = rows * ((possibleWidth * ratio) + (2 * margin));
			} else {
				totalHeightNeeded = (possibleWidth * ratio) + (2 * margin);
			}
			return totalHeightNeeded < availHeight;
		}

		function calculateOptimalWidth(streamCount) {
			let possibleWidth = 0;
			let lower = 50, upper = 5000;

			while (lower < upper) {
				possibleWidth = Math.floor((upper + lower) / 2);
				if (possibleWidth === lower) {
					break;
				}
	
				const fits = areaFits(possibleWidth, streamCount);
				
				if (fits) {
					lower = possibleWidth;
				} else {
					upper = possibleWidth;
				}
			}

			return possibleWidth;
		}

		let optimalWidth;
		let optimalStreamCount = streamCount;

		optimalWidth = calculateOptimalWidth(streamCount);

		if (optimalWidth < minStreamWidth) {
			let lastOptimalWidth = 0;

			for (let possibleStreamCount = 1; possibleStreamCount <= streamCount; possibleStreamCount++) {
				optimalWidth = calculateOptimalWidth(possibleStreamCount);
	
				if (optimalWidth < minStreamWidth && optimalWidth < lastOptimalWidth) {
					optimalWidth = lastOptimalWidth;
					optimalStreamCount = possibleStreamCount - 1;
					break;
				}

				lastOptimalWidth = optimalWidth;
			}
		}

		const optimalHeight = optimalWidth * ratio;
		const optimalMargin = marginFixed + (optimalWidth * marginPercentage);

		setStreamDimensions({
			width: optimalWidth,
			height: optimalHeight,
			margin: optimalMargin,
			streamCount: optimalStreamCount,
		}); 
	}, 100);

	useEffect(() => {
		updateStreamDimensions(mosaic.length, mosaicWidth, mosaicHeight);
		if (mosaic.length !== prevStreamCount) {
			updateStreamDimensions.flush();
		}
	}, [
		mosaic.length,
		mosaicWidth,
		mosaicHeight,
		updateStreamDimensions,
		prevStreamCount,
	]);

	useEffect(() => {
		streams.forEach(stream => {
			const isVisible =
				!!mosaicVisible.find(mosaicStream => mosaicStream.id === stream.id) ||
				emphasis?.id === stream.id;

			if (volumeData[stream.id]) {
				volumeData[stream.id].isVisible = isVisible;
			} else {
				volumeData[stream.id] = { isVisible };
			}
		})
	}, [streams, mosaicVisible, emphasis]);

	useEffect(() => {
		const config = {
			speakingThreshold: 5, // level of volume that should be considered speaking
			swapTimeout: 10000, // time that a stream cannot be swapped out after it has been swapped in
			silenceTimeout: 3000, // time that a stream must be silent before it can be swapped out
			trySwapInterval: 2000, // interval at which to try to swap if the stream keeps speaking
		}

		const cleanupFunctions = [];

		streams.forEach((streamer) => {
			let lastSwapTryTime = 0;

			function trySwapStream() {
				if (lastSwapTryTime && window.performance.now() - lastSwapTryTime < config.trySwapInterval) {
					return
				}

				lastSwapTryTime = window.performance.now();
				let longerSilentVolumeData;

				streams.forEach(candidateToLeave => {
					const candidateVolumeData = volumeData[candidateToLeave.id];

					if (!candidateVolumeData) {
						return;
					}

					const isSameStreamer = candidateToLeave.id === streamer.id;
					const isPinnedStreamer = pinnedStreamer === candidateToLeave.id;
					const isVisible = candidateVolumeData.isVisible;
					const justBeenSpeaking = candidateVolumeData.isSpeaking || 
						candidateVolumeData.timeInState < config.silenceTimeout;
					const justBeenSwapped = !!candidateVolumeData.lastSwap &&
						candidateVolumeData.lastSwap + config.swapTimeout > window.performance.now();

					if (
						isSameStreamer || 
						isPinnedStreamer || 
						justBeenSpeaking || 
						justBeenSwapped || 
						!isVisible
					) {
						return;
					}

					if (
						!longerSilentVolumeData ||
						candidateVolumeData.timeInState > longerSilentVolumeData.timeInState
					) {
						longerSilentVolumeData = candidateVolumeData;
					}
				})

				if (longerSilentVolumeData) {
					volumeData[streamer.id].lastSwap = window.performance.now();
					volumeData[longerSilentVolumeData.id].lastSwap = window.performance.now();

					onSwapStreams(streamer.id, longerSilentVolumeData.id);
				}
			}

			if (
				streamer.typeVideo === 'cam' && 
				streamer.audioStream &&
				streamer.audioStream instanceof MediaStream
			) {
				let wasSpeaking = !!volumeData[streamer.id]?.isSpeaking;
				let stateStart = volumeData[streamer.id]?.stateStart ||
					window.performance.now();

				let fn;

				try {
					fn = volumeMeter(streamer.audioStream, (volume) => {
						const isSpeaking = volume >= config.speakingThreshold;
						const isVisible = volumeData[streamer.id]?.isVisible;
	
						if (isSpeaking !== wasSpeaking) {
							wasSpeaking = isSpeaking;
							stateStart = window.performance.now();
						}
	
						const timeInState = window.performance.now() - stateStart;
	
						volumeData[streamer.id] = {
							id: streamer.id,
							name: streamer.display,
							volume,
							isSpeaking,
							stateStart,
							timeInState,
							lastSwap: volumeData[streamer.id]?.lastSwap,
							isVisible: volumeData[streamer.id]?.isVisible,
						}
	
						if (isSpeaking && !isVisible) {
							trySwapStream()
						}
					});
	
					cleanupFunctions.push(fn);
				} catch (err) {
					console.error(`error setting up volume meter for ${streamer.display}`, err);
				}
			}
		})

		return () => {
			cleanupFunctions.forEach(fn => fn())
		}
	}, [streams, onSwapStreams, pinnedStreamer]);

	return (
		<AnimateSharedLayout>
			<Container
				data-component="conference"
				transition={transition}
				layout
				fullscreen={fullscreen}
			>
				{emphasis && (
					<Emphasis
						data-component="emphasis"
						style={{
							margin: streamDimensions.margin
						}}
						transition={transition}
						layout
					>
						<Stream
							key={emphasis.id}
							data-component="stream"
							variants={streamVariants}
							initial="hidden"
							animate="visible"
							transition={transition}
							layout
						>
							<Video
								data-component="video"
								srcObject={emphasis.srcObject}
								background={emphasis.file ? 'solid' : 'transparent'}
								videoElement={emphasis.videoElement}
								displayName={emphasis.typeVideo === 'cam' ? emphasis.display : null}
								showPlaceholder={!emphasis.isVideoOpen}
								placeholderColor={getPersonColor(emphasis.id)}
								displayNameResponsive
								borderRadius
							/>
						</Stream>
					</Emphasis>

				)}

				{!!mosaicVisible.length && (
					<Mosaic
						ref={mosaicRefCallback}
						data-component="mosaic"
						transition={transition}
						layout
					>
						{mosaicVisible.map(stream => (
							<Stream
								key={stream.id}
								data-component="stream"
								variants={streamVariants}
								initial="hidden"
								animate="visible"
								transition={transition}
								layout
								style={{
									width: streamDimensions.width,
									height: streamDimensions.height,
									margin: streamDimensions.margin,
								}}
							>
								<Video
									data-component="video"
									aspectRatio
									srcObject={stream.srcObject}
									background="solid"
									videoElement={stream.videoElement}
									displayName={stream.display}
									showPlaceholder={!stream.isVideoOpen}
									placeholderColor={getPersonColor(stream.id)}
									displayNameResponsive
									borderRadius
								/>
							</Stream>
						))}
					</Mosaic>
				)}
			</Container>
		</AnimateSharedLayout>
	);
}

const transition = {
	ease: [0.4, 0, 0.2, 1],
	duration: 0.5,
}

const streamVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
		opacity: 1,
		transition: { ...transition, delay: 0.3 },
	}
}