import React, { useState, useEffect } from 'react'
import dateformat from 'dateformat'
import { token_decode } from '../../services/auth'

import { FaYoutube, FaTwitch, FaListAlt, FaEllipsisV, FaEdit, FaRegTrashAlt } from "react-icons/fa"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import Swal from "sweetalert2"
import { toast } from 'react-toastify'

import { useAuth } from '../../context/Auth'
import { useTranslation } from 'react-i18next'

import api from '../../services/api'

import customRtmpImg from "../../assets/images/custom_rtmp.svg"
import exclamationCircle from '../../assets/images/exclamation_circle.svg'
import recordRoomImg from '../../assets/images/recordRoom.svg'

import { Container, OptionButtons } from './styles'
import { RoomModal } from '../RoomModal'

export default function Rooms({ rooms, reloadRooms }){
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [showRoomModal, setShowRoomModal] = useState(false);
    const [editRoom, setEditRoom] = useState();
    
    const client = token_decode(localStorage.getItem('auth-user'))
    const { setUser } = useAuth()

    const handleEnter = (type, room, room_name, privacy, twitchCategory, only_record) => {
        client.room = room
        client.room_type = type
        client.room_name = room_name
        client.privacy_broadcast = !!privacy ? privacy : 'unlisted'
        client.twitchCategory = twitchCategory
        client.only_record = only_record

        localStorage.setItem('auth-user', btoa(JSON.stringify(client)))
        setUser(client)

        window.location.href = '/studio'
    }

    const labelAccount = {
        twitch: {
          backgroundColor: "#9146FF",
          icon: <FaTwitch size={12} color="#fff" />,
        },
        custom_rtmp: {
          backgroundColor: "#ffae00",
          icon: <FaListAlt size={12} color="#2d2d2d" />,
        },
        youtube: {
          backgroundColor: "#FF0000",
          icon: <FaYoutube size={12} color="#fff" />,
        },
    };

    async function handleDeleteRoom(id) {
        Swal.fire({
            icon: "warning",
            title: t(`components.Rooms.swalDeleteRoomTitle`),
            html: t(`components.Rooms.swalDeleteRoomText`),
            showCancelButton: true,
            confirmButtonText: t(`components.Rooms.swalConfirmButtonText`),
            cancelButtonText: t(`components.Rooms.swalCancelButtonText`),
          }).then(async (result) => {
            if (result.isConfirmed) {
              const response = await api.delete(`rooms/${id}`);
      
              if (response.status === 200) {
                  // return window.location.reload();
                  reloadRooms();
                  return;
              } else {
                return toast.error(t(`components.Rooms.toastErrorDeleteRoom`));
              }
            } else {
              return false;
            }
          });
    }

    useEffect(() => {   
        if(rooms){
            setLoading(false)
        }
    }, [rooms])

    function handleCloseRoomModal() {
        setShowRoomModal(false)
    }

    function handleOpenEditModal(data) {
        setShowRoomModal(true);
        setEditRoom(data);
    }
    
    return (
        <Container className="mb-4 p-3">
            <RoomModal
                handleCloseRoomModal={handleCloseRoomModal}
                showRoomModal={showRoomModal}
                destinationLoaded={editRoom}
                modalTitle={t(`components.Rooms.editModalTitle`)}
                reloadRooms={reloadRooms}
            />
            <div className={`${loading ? 'd-flex justify-content-center align-items-center p-5' : ''} `} style={{ overflow: 'auto', maxHeight: 400 }}>
                {loading ? (
                    <div>
                        <h1>
                            <i className="fa fa-spin fa-spinner"></i>
                        </h1>
                    </div>
                ) : (
                    <div className="table-responsive">
                        <table className="table" style={{ border: '1px solid #dee2e6' }}>
                            <thead className="">
                                <tr style={{ backgroundColor: '#e5e5e5', color: 'var(--iq-primary)' }}>

                                    <th scope="col" style={{ border: '0' }}>{t(`components.Rooms.roomHeader`)}</th>
                                    <th className="created-at-column" scope="col" style={{ border: '0' }}>{t(`components.Rooms.createdAtHeader`)}</th>
                                    <th scope="col" style={{ border: '0' }}></th>

                                </tr>

                            </thead>

                            <tbody className="">
                                {rooms.length === 0 && (
                                    <tr><td><h4>{t(`components.Rooms.thereIsNoRoom`)}</h4></td></tr>
                                )}
                                {rooms.map(r => (
                                    <tr key={r._id}>
                                        <td>
                                            {r.avatar_url || r.type_account === 'custom_rtmp' || r.only_record ? (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'start'
                                                    }}
                                                >
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={<Tooltip>{r.only_record ? t(`components.Rooms.onlyRecordRoom`) : r.display_name}</Tooltip>}
                                                    >
                                                        <div className={`account_container user-img img-fluid`}>

                                                        {r.only_record ? (
                                                            <img
                                                                src={recordRoomImg}
                                                                alt={t(`components.Rooms.onlyRecordRoom`)}
                                                            />
                                                        ) : (
                                                            <img
                                                                src={r.type_account === 'custom_rtmp' ? customRtmpImg : r.avatar_url}
                                                                alt={r.display_name || ''}
                                                                style={{ border: `2px solid ${labelAccount[r.type_account]["backgroundColor"]}`}}
                                                            />
                                                        )}

                                                        {r.type_account !== 'custom_rtmp' && !r.only_record && (
                                                            <div
                                                            className="icon"
                                                            style={{
                                                                background:
                                                                labelAccount[r.type_account]["backgroundColor"],
                                                            }}
                                                            >
                                                            {labelAccount[r.type_account]["icon"]}
                                                            </div>
                                                        )}
                                                        </div>
                                                    </OverlayTrigger>
                                                    <span className="font-weight-light" style={{marginLeft: '10px'}}> {r.title}</span>
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'start'
                                                    }}
                                                >
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={<Tooltip>{t(`components.Rooms.tooltipRoomWithoutDestination`)}</Tooltip>}
                                                    >
                                                        <div className={`account_container user-img img-fluid`}>
                                                        <img
                                                            src={exclamationCircle}
                                                            alt=""
                                                        />
                                                        </div>
                                                    </OverlayTrigger>
                                                    <span className="font-weight-light" style={{marginLeft: '10px'}}> {r.title}</span>
                                                </div>
                                            )}
                                        </td>

                                        <td className="created-at-column">{dateformat(r.created_at, 'mmm dd, hh:MM TT')}</td>
                                        
                                        <td
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <button onClick={() => handleEnter(r.room_type, r, r.title, r.privacy, r.twitchCategory, r.only_record)}  className="btn btn-primary d-flex align-items-center" >
                                                {t(`components.Rooms.enterButton`)} <span style={{fontSize: 22, marginLeft: 2}} className="las la-sign-in-alt"></span>
                                            </button>

                                            {r.last_record && (
                                                <OverlayTrigger placement="top-end" overlay={<Tooltip>Download last transmission</Tooltip>} style>
                                                    <a href={r.last_record.url} download className="btn btn-secondary-light btn-light d-flex align-items-center ml-2" >
                                                        <span style={{fontSize: 22}} className="las la-download"></span>
                                                    </a>
                                                </OverlayTrigger>
                                            )}

                                            <OverlayTrigger
                                                placement="top"
                                                trigger="click"
                                                rootClose
                                                overlay={(
                                                <OptionButtons
                                                    className="options_buttons"
                                                    style={{
                                                        padding: '20px',
                                                        background: '#F1F1F1',
                                                        borderRadius: '8px'
                                                    }}
                                                >
                                                    <button
                                                        type="button"
                                                        onClick={() => handleOpenEditModal(r)}
                                                    >
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip>{t(`components.Rooms.tooltipEditRoomText`)}</Tooltip>}
                                                        >
                                                            <FaEdit size={25} color="#919191" />
                                                        </OverlayTrigger>
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => handleDeleteRoom(r._id)}
                                                    >
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip>{t(`components.Rooms.tooltipDeleteRoomText`)}</Tooltip>}
                                                        >
                                                            <FaRegTrashAlt size={25} color="#919191" />
                                                        </OverlayTrigger>
                                                    </button>
                                                </OptionButtons>
                                                )}>
                                                <button
                                                    type="button"
                                                    style={{
                                                        border: '0',
                                                        background: 'transparent',
                                                        marginLeft: '10px'
                                                    }}
                                                >
                                                    <FaEllipsisV color="#999999" />
                                                </button>
                                            </OverlayTrigger>
                                        </td>
                           
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                )}


            </div>
        </Container>
    )
}
