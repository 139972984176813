import React, { useState, useEffect, useCallback } from 'react'
import dateformat from 'dateformat'
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaTrashAlt, FaEllipsisV } from "react-icons/fa";

import { functions } from '../../services/chat/functions'
import { getPersonColor } from '../../utils/colors'
import usePrevious from '../../hooks/usePrevious'

import styles from './chat.module.css';

import mauticPlgEvent from '../../services/plgEvents'

const { 
    formatNameAvatar,
    setUser,
    setRoom
} = functions

export default function Chat({ className, room, cid, eid, firebase: db, display, type = null, guest = null, type_room = null, disabled, onNewMessage, darkMode, producer_id, canSendMessage = true, onDoubleClick, isFromStudio = false, onClearMessages, is_admin }){
    const { t } = useTranslation();
   
    const [messages, setMessages]       = useState([])
    const [message, setMessage]         = useState('')
    const [loadMore, setLoadMore]       = useState(false)
    const [newMessage, setNewMessage]   = useState(false)
    const [viewMessage, setViewMessage] = useState(false)
    const [chatRef, setChatRef]         = useState(null)
    const prevMessages                  = usePrevious(messages);
    
    const limit_data = 10


    
    room = setRoom(type_room, room)

    const User = setUser(type, cid, eid, room, guest, producer_id)

    const handleMessage = (event) => {
        event.preventDefault()

        if (disabled) {
            return
        }

        if(message !== '' || message !== null){
            let msg =  {
                key: (messages.length - 1) + 1,
                room: room,
                text: message,
                sender: User ,
                display: display,
                avatar: '',
                date: Date.now()
            }

            db.ref(`chat_messages_${room}`).push(msg)
            setMessage('')

            //Send PLG Event
            mauticPlgEvent('config', 'Mensagem enviada pelo chat')
        }
    }

    const handleScroll = (e) => {
        let array = [...messages]

        if (disabled) {
            return
        }
       
        if(e.target.scrollTop === 0){
            db.ref(`chat_messages_${room}`).orderByKey().endBefore(messages[0]?.key).limitToLast(limit_data).once('value', snap => {
               
               snap.forEach( m => {
                   let item = m.val()
                   item.key = m.key

                   if(array.filter(m => m.key === item.key).length === 0){
                        array.unshift(item)
                    }
               })

                if(array.length === messages.length){
                    return false
                }

                setMessages([])
                setMessages(array)

                setLoadMore(true)
             
            })

           
        }

        
      
    }

    const snapshotToArray = (data) => {
        let array = [...messages]

        data.forEach( m => {
            let item = m.val()
            item.key = m.key
            
           

            if(array.filter(m => m.key === item.key).length === 0){
                array.push(item)
            }

        })
       
        return array
    }

    useEffect(() => {
        if (disabled) {
            return
        }

        const ref = db.ref(`chat_messages_${room}`);
       
        ref.limitToLast(limit_data).on('value', snap => {
            if(snap !== null){
                setMessages(snapshotToArray(snap))
                setNewMessage(true)
                //Send PLG Event
                mauticPlgEvent('config', 'Mensagem(s) recebida(s) pelo chat')
            }
        })

        return () => ref.off();
    }, [disabled])


    const useStateRef = (processNode) => {

        const setRef = useCallback(newNode => {
          setChatRef(processNode(newNode));
        }, [processNode]);

        return [chatRef, setRef];
    }

    const [chat_window, setChatWindow ] = useStateRef( node => (node))

    useEffect(() => {
        if (disabled) {
            return
        }
        
        if(loadMore){
            
            chat_window.scrollTop =  document.getElementById(`${messages[9].key}`).offsetTop
            setLoadMore(false)

        }else if(newMessage){
            
            
            chat_window.scrollTop =  chat_window.scrollHeight
            
            setNewMessage(false)
           
        }
      
      
      
    }, [messages,loadMore, newMessage,chat_window])
   
    useEffect(() => {
        // ignore first fetch of messages
        if (!messages || !prevMessages || !prevMessages.length) {
            return;
        }

        const newMessages = 
            messages.filter(m => !prevMessages.find(p => p.key === m.key));

        newMessages.forEach(m => onNewMessage(m));
    }, [messages, prevMessages, onNewMessage]);

    return (
        <>
        {/* {viewMessage && (
                <div class="alert text-white bg-primary" role="alert">
                    <div class="iq-alert-text" onClick={() => {
                        chat_window.current.scrollTop = chat_window.current.scrollHeight
                        setViewMessage(false)
                    }}>Clique para ver novas mensagens</div>
                </div>
            )} */}
        <div style={{ height: type_room === "general" ? '100%' : undefined }} ref={setChatWindow} onScroll={handleScroll} className={`chat-content scroller d-flex flex-column ${darkMode ? styles.chat_content_black: ''} ${className}`}>
            
          
            {messages.map( message => (
                
                <div key={message.key} id={message.key}  className={`chat ${ (!message.sender.includes('4webSpeaker') || message.sender.includes('4webSpeaker')) && message.sender !== User ? 'chat-left' : '' }`}>
                    
                    <div className="chat-user d-flex flex-column">
                        <a className="m-0">
                        { message.avatar !== "" ? (
                                <img src={message.avatar} alt="avatar" className="avatar-40 "/>
                        ): (
                            <div className="avatar-40" style={{
                                backgroundColor: message.sender.includes('4webSpeaker') && (message.sender === User || message.sender !== User) ||  (message.sender.includes('meet') && message.sender === User ) ? '#ff3c5e' 
                                : message.sender.includes('vpadminsx') ? '#fdd04c' 
                                : message.sender === '000' ? '#03A9F4': getPersonColor(message.sender), 
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <h4 className="text-white">{formatNameAvatar(message.display).avatar}</h4>
                            </div>
                        )}
                        
                        </a>
                        <span className="chat-time mt-1">{dateformat(message.date, 'h:MM')}</span>
                    </div>
                    <div className="chat-detail">
                        {isFromStudio ? (
                            <OverlayTrigger
                                key={message.key}
                                placement={message.sender === User ? "left" : "right"}
                                delay={{ show: 250 }}
                                overlay={<Tooltip>{t(`components.Chat.tooltipMessages`)}</Tooltip>}
                            >
                                <div
                                    className={`chat-message ${darkMode ? styles.chat_message_black : ''} ${styles.chat_message_cursor}`}
                                    onDoubleClick={() => onDoubleClick(message)}
                                >
                                    <div className="chat-detail-name">
                                        {message.sender !== User && (
                                            <strong>{formatNameAvatar(message.display).display}</strong>
                                        )}
                                    
                                        {message.sender.includes('vpadminsx') && (
                                            <small> (Admin)</small>
                                        )}

                                        {message.sender.includes('4webSpeaker') && message.sender !== User && (
                                            <small> (Speaker)</small>
                                        )}
                                        
                                    </div>
                                    <p>{message.text}</p>
                                </div>
                            </OverlayTrigger>
                        ) : (
                            <div className={`chat-message ${darkMode ? styles.chat_message_black : ''}`}>
                                <div className="chat-detail-name">
                                    {message.sender !== User && (
                                        <strong>{formatNameAvatar(message.display).display}</strong>
                                    )}
                                
                                    {message.sender.includes('vpadminsx') && (
                                        <small> (Admin)</small>
                                    )}

                                    {message.sender.includes('4webSpeaker') && message.sender !== User && (
                                        <small> (Speaker)</small>
                                    )}
                                    
                                </div>
                                <p>{message.text}</p>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        
        
        </div>
        {canSendMessage && (
            <div className={`chat-footer p-3 bg-white ${darkMode ? styles.chat_footer_black : ''}`}>
            
                <form className="d-flex align-items-center" onSubmit={handleMessage}>
                    {(type_room === 'interno' && !disabled && is_admin) && (
                        <div className="dropup mr-2" style={{ cursor: 'pointer', float: 'right' }}>
                            <span className="text-primary" id="dropdownMenuButton40" data-toggle="dropdown" aria-expanded="false">
                                <FaEllipsisV />
                            </span>
                            <div className="dropdown-menu dropdown-menu-left">
                                <button
                                className="dropdown-item"
                                type="button"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    background: 'none',
                                    border: 0
                                }}
                                onClick={onClearMessages}
                                >
                                    <FaTrashAlt /> <span style={{marginLeft: '5px'}}>{t(`pages.Studio.clearChatText`)}</span>
                                </button>
                            </div>
                        </div>
                    )}

                    <input 
                        type="text" 
                        className="form-control" 
                        placeholder={t(`components.Chat.placeholderTypeAMessage`)}
                        onChange={event => setMessage(event.target.value)}
                        value={message}
                    />
                    {!disabled && (
                        <button type="submit" className="btn btn-primary d-flex align-items-center p-2 ml-3">
                            <i className="fa fa-paper-plane" aria-hidden="true"></i>
                            {!darkMode && (
                                <span className="d-none d-lg-block ml-1">{t(`components.Chat.buttonSendMessage`)}</span>
                            )}
                        </button>
                    )}
                </form>
            </div>
        )}
        </>
    )
}
