import axios from 'axios'

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'https://app.4web.live'
})

api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status && ['No token provided', 'Invalid JWT token.'].includes(error.response.data.message)) {
        localStorage.removeItem('auth-user')
        localStorage.removeItem('auth-token')
        localStorage.removeItem('goauth2_token')
        localStorage.removeItem('account')
        window.location.href = '/';
    } else {
        return Promise.reject(error);
    }
});

export default api
