import React, { useRef } from 'react';
import './LinkShare.css'
import { useTranslation } from 'react-i18next';
import mauticPlgEvent from '../../services/plgEvents'

function LinkShare(props) {
    const { t } = useTranslation();
    
    if(props.type === 'guestLink') {
        //Send PLG Event
        mauticPlgEvent('config', 'Link de convidado do Studio gerado')
    } else {
        mauticPlgEvent('config', 'Link do video no Studio gerado')
    }

    const textArea = useRef(null)
    function copyLink() {
        var copyText = textArea.current;
        copyText.disabled = false
        copyText.select();
        document.execCommand("copy");
        copyText.disabled = true

        if(props.type === 'guestLink') {
            //Send PLG Event
            mauticPlgEvent('config', 'Copiou o link de convidado do Studio')
        } else {
            mauticPlgEvent('config', 'Copiou o link do vídeo')
        }
    }

    return (
        <React.StrictMode>
            <div className="bg-gray rounded px-3 py-2 link-invite-area mb-2">
                <textarea ref={textArea} rows="1" spellCheck="false" disabled={true} id="link-invite" value={props.link} />
            </div>
            <div className="text-center mt-3">
                <span className="btn-copy font-md" onClick={() => copyLink()}><i className="fa fa-clipboard mr-2"></i>{t(`components.LinkShare.clickToCopyText`)}</span>
            </div>
        </React.StrictMode>
    )
}

export default LinkShare;
