import { Redirect } from 'react-router-dom';

import { getAuthenticatedUser } from '../../services/auth';

import SideBar from '../../components/Layout/SideBar/SideBar';
import { Header } from '../../components/Layout/Header';
import CreditCard from './CreditCard';
import Invoices from './Invoices';
import Plan from './Plan';
import { useAuth } from "../../context/Auth";

import { Card } from './styles'
import { useTranslation } from 'react-i18next';
import Promo from "../../components/Promo/Promo";
import { Link } from "react-router-dom";
import promo_banner from "../../assets/images/promo/promo_banner.png";

export default function Billing() {
    const { t } = useTranslation();
    const user = getAuthenticatedUser();
    const is4webClient = user.client_type === '4web';
    const { modules } = useAuth();

    return (
        <>
            {window.location.hostname === 'meet.4web.live' && <Redirect to="meet" />}
            {is4webClient || <Redirect to="/new-room" />}

            {/* <SideBar page="billing" /> */}
            <Header page="billing" />

            <div className="content-page">
                <div className="container-fluid">
                    {/*modules.logo_required && (
                        <>
                            <Promo type="modal"></Promo>
                            <Link to="/plans">
                                <div className="iq-card mb-2 p-2" style={{ marginTop: '-20px'}}>
                                <img src={promo_banner} style={{ width: '100%', borderRadius: '10px'}} />
                                </div>
                            </Link>
                        </>
                    )*/}
                    <div className="row">
                        <div className="col-12">
                            {modules.logo_required ? (
                                <h3>{t(`pages.Billing.titleFree`)}</h3>
                            ) : (
                                <h3>{t(`pages.Billing.title`)}</h3>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 mt-6 mb-2">
                            <Card className="iq-card iq-card-block iq-card-stretch pb-3 mt-3">
                                <div className="iq-card-header d-flex justify-content-between mt-3">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">{t(`pages.Billing.currentPlan`)}</h4>
                                    </div>
                                </div>

                                <div className="iq-card-body h-100">
                                    <Plan />
                                </div>
                            </Card>
                        </div>
                        <div className="col-12 col-md-7 mt-6 mb-2">
                            <Card className="iq-card iq-card-block iq-card-stretch pb-3 mt-3">
                                <div className="iq-card-header d-flex justify-content-between mt-3">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">{t(`pages.Billing.creditCard`)}</h4>
                                    </div>
                                </div>

                                <div className="iq-card-body h-100">
                                    <CreditCard />
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <div className="iq-card iq-card-block iq-card-stretch pb-3 mt-3">
                                <div className="iq-card-header d-flex justify-content-between mt-3">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">{t(`pages.Billing.invoices`)}</h4>
                                    </div>
                                </div>

                                <div className="iq-card-body">
                                    <Invoices />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
