import styled from 'styled-components';
import { motion } from 'framer-motion'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Body = styled.div`
  flex: 1;
  height: 0;
  width: 100%;
  display: flex;
`;

export const Content = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;

  @media (max-width: 768px) {
    padding: 8px;
  }
`;

export const BigScreenContainer = styled.div`
  width: 100%;
  max-width: 98vh;

  @media (max-height: 840px) {
    max-width: 90vh;
  }

  @media (max-height: 720px) {
    max-width: 80vh;
  }

  @media (max-height: 630px) {
    max-width: 70vh;
  }
`;

export const RoomTitle = styled.header`
  font-size: 14pt;
  background: #d7d7d7;
  border-radius: 10px 10px 0 0;
  padding: 0.25rem 1rem;

  p {
    margin: 0;
    font-weight: 700;
    max-width: 55ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: 768px) {
    font-size: 12pt;
  }

  @media (max-width: 480px) {
    font-size: 10pt;
  }
`;

export const Sidebar = styled.aside`
	background: #dfdfdf;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
`;

export const SidebarTabs = styled.ul`
  height: 100%;
	padding: 0 5px;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
	border-left: 1px solid #d6d6d6;
  background: #fff;
  z-index: 999;

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;

    width: 68px;
    height: 68px;

    margin-top: 5px;
    border-radius: 3px;

    cursor: pointer;
    position: relative;

    @media (max-width: 480px) {
      width: 48px;
      height: 48px;
    }

    @media (max-width: 360px) {
      width: 36px;
      height: 36px;
    }

    i {
      font-size: 22px;

      @media (max-width: 360px) {
        font-size: 18px;
      }
    }

    span {
      padding: 0 4px;
      text-align: center;
      margin-top: -4px;
      font-size: 12px;
      font-weight: 400;

      @media (max-width: 768px) {
        font-weight: unset;
      }

      @media (max-width: 480px) {
        display: none;
      }
    }

    .floating-badge {
      align-items: center;
      color: #fff;
      height: 20px;
      background-color: #de5151;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      min-width: 20px;
      right: 11px;
      position: absolute;
      top: 3px;
      line-height: 1.25rem;
      font-size: .75rem;
      padding: 0px 2px 0 2px;
    }
  }

  li:hover {
    background: #eee;
  }

  li.active {
    background: #eee;
    color: var(--iq-primary);
  }

  li.active:hover {
    background: #ddd;
  }
`;

export const SidebarContent = styled(motion.div)`
  width: 450px;
  height: 100%;
  background: #dfdfdf;
	border-left: 1px solid #d6d6d6;
  max-width: calc(100% - 77px);
  z-index: 800;

  @media (max-width: 1024px) {
    position: absolute;
    right: 77px;
    top: 56px;
    height: calc(100% - 56px);
  }

  @media (max-width: 480px) {
    right: 57px;
    max-width: calc(100% - 57px);
  }

  @media (max-width: 360px) {
    right: 45px;
    max-width: calc(100% - 45px);
  }

  > div.hidden {
    display: none !important;
  }

  .tab-content {
    padding: 16px;

    @media (max-width: 1366px) {
      padding: 12px;
    }

    @media (max-width: 480px) {
      padding: 8px;
    }
  }
`;