import { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios'

import { getAuthenticatedUser } from '../../services/auth';
import api from '../../services/api';

export const AuthContext = createContext({});

function loadUserFromSession() {
	const user = getAuthenticatedUser();
	return user || null;
}

export function AuthProvider({ children }) {
	const [user, setUser] = useState(loadUserFromSession);
	const [modules, setModules] = useState({});
	
	let hostId = null;

	if (user) {
		if (user.client_type === 'guest') {
			hostId = user.client_vp_client_id;
		} else {
			hostId = user._id;
		}
	}

	const fetchModules = useCallback(() => {
		if (hostId) {
			let cancelRequest;

			api
				.get(`/users/${hostId}/modules`, {
					cancelToken: new axios.CancelToken((c) => (cancelRequest = c)),
				})
				.then((response) => {
					setModules(response.data);
				})
				.catch(console.error);

			return () => {
        cancelRequest()
      };
		} else {
			setModules({});
		}
	}, [hostId]);

	useEffect(() => {
		return fetchModules();
	}, [fetchModules]);

	return (
		<AuthContext.Provider
			value={{
				user,
				setUser,
				modules,
				reloadModules: fetchModules,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}
