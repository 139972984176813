import { useRef, useState, useCallback } from "react";
import ResizeObserver from "resize-observer-polyfill";

const useDimension = () => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const resizeObserverRef = useRef(null);

  const refCallback = useCallback((node) => {
    if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
    resizeObserverRef.current = new ResizeObserver((entries = []) => {
      entries.forEach((entry) => {
        const { width, height } = entry.contentRect;
        setDimensions({ width, height });
      });
    });
    if (node) resizeObserverRef.current.observe(node);
    return () => {
      if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
    };
  }, []);

  return {
    ...dimensions,
    refCallback,
  };
};

export default useDimension;