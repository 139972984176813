import { 
  useMemo, 
  useRef, 
  useEffect, 
  useCallback, 
  useLayoutEffect
} from 'react';
import ReactDOM from 'react-dom';

export function Portal({ children, toRef, toElement }) {
  const childContainer = useMemo(() => document.createElement('div'), []);
  const target = useRef(null);

  const render = useCallback(() => {
    if (!target.current) {
      target.current = childContainer.firstChild;
    }
    
    let location;
    
    if (toRef) {
      location = toRef.current;
    } else if (toElement) {
      location = toElement;
    }

    if (location) {
      location.appendChild(target.current);
      return () => childContainer.appendChild(target.current);
    }
  }, [toRef, toElement, childContainer])

  useEffect(() => {
    return render();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    return render();
  });

  return (
    ReactDOM.createPortal(children, childContainer)
  )
}

export function PortalIn({ id, children, recalculate }) {
  const childContainer = useMemo(() => document.createElement('div'), []);
  const target = useRef(null);

  const render = useCallback(() => {
    if (!target.current) {
      target.current = childContainer.firstChild;
    }

    if (recalculate) {
      // use recalculate inside render to force this function
      // to run every time recalculate changes
      // no need to actually use it
    }
    
    const placeholder = document.getElementById(id);
    const parent = placeholder?.parentElement;

    if (placeholder && parent) {
      parent.replaceChild(target.current, placeholder);
      return () => {
        parent.replaceChild(placeholder, target.current);
        childContainer.appendChild(target.current);
      }
    }
  }, [id, childContainer, recalculate])

  useEffect(() => {
    return render();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    return render();
  }, [render]);

  return (
    ReactDOM.createPortal(children, childContainer)
  )
}

export function PortalOut({ id }) {
  return (
    <div id={id} />
  )
}