import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios';
import { Redirect, useLocation } from 'react-router-dom';

//internal components
import BlockUI from '../../components/BlockUI/BlockUI'
import LinkShare from '../../components/LinkShare/LinkShare'
import VideoComponent from '../../components/VideoComponent/VideoComponent'
import Video from '../../components/Video'
import { PermissionsOverlay } from '../../components/PermissionsOverlay'
//import VuMeter from '../../components/VuMeter/VuMeter'
import { useInternetQuality } from '../../context/InternetQuality'

import loginSound from '../../assets/sounds/login.mp3';
import logoutSound from '../../assets/sounds/logout.mp3';
import requestSound from '../../assets/sounds/request.mp3';

import { ReactComponent as LayoutPresentationOnImg } from '../../assets/images/layout-bigscreen/presentation-on.svg';
import { ReactComponent as LayoutPresentationOffImg } from '../../assets/images/layout-bigscreen/presentation-off.svg';
import { ReactComponent as LayoutFullscreenOnImg } from '../../assets/images/layout-bigscreen/fullscreen-on.svg';
import { ReactComponent as LayoutFullscreenOffImg } from '../../assets/images/layout-bigscreen/fullscreen-off.svg';

//external components
import { Row, Col, Modal, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import platform from "platform"

//libraries 
import * as mediasoupClient from 'mediasoup-client';
import io from 'socket.io-client';

// stylesheets
import './style.css'
import 'react-perfect-scrollbar/dist/css/styles.css';
import styles from '../Studio/studio.module.css'
import { token_decode } from '../../services/auth'
import Chat from '../../components/Chat'
import firebase from 'firebase'
import { config } from '../../services/firebase/config'
import { functions } from '../../services/chat/functions'
import api from '../../services/api'

import { QueryClientProvider, QueryClient } from 'react-query'
import Questions from '../../components/Questions'
import { useAuth } from '../../context/Auth'
import audioTimerLoop from '../../utils/audioTimerLoop'
import { useTranslation } from 'react-i18next'
import { dummyAudio, dummyVideo } from '../../utils/dummyTracks'
import { Dropdown } from '../../components/Dropdown';

import { Sidebar } from './components/Sidebar'
import { Conference } from './components/Conference';
import { ToolbarButton } from './components/ToolbarButton';

import { 
  Container, 
  Badge,
  Main, 
  BottomToolbar, 
  CenterPane, 
  RightPane, 
  LeftPane,
  SettingsContainer, 
  SettingsItem,
  LayoutsContainer,
  LayoutItem,
} from './styles'

const dbFirebase = firebase.database()

var producerTransport = null
var producerId = null
var producers = new Map()
var producerLabel = new Map()
var typeVideo = ''
var streamDisplayName = ''
var listStreamers = []
var streamsAudio = new Map()
var reconnInterval = false
var _isOpen = false

var p_client = token_decode(localStorage.getItem('auth-user'))
var socket;
var internalSocket;

if(process.env.REACT_APP_ENV === 'local'){
  //socket = io('wss://weblive-dev.4.events:3016', {cors: { origin: window.location.origin, credentials: false}})
  socket = io(p_client.mediaServer, { cors: { origin: window.location.origin, credentials: false } })
} else if (p_client && typeof p_client !== 'undefined') {
  socket = io(p_client.mediaServer, { cors: { origin: window.location.origin, credentials: false } })
}


export default function Meet() {
  const { t } = useTranslation();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  if (window.socket) {
    socket = window.socket
  }

  socket.request = function request(type, data = {}) {
    return new Promise((resolve, reject) => {
      socket.emit(type, data, (data) => {
        if (data.error) {
          reject(data.error)
        } else {
          resolve(data)
        }
      })
    })
  }

  const [blockUI, setBlockUI] = useState(false)
  const [audioOpen, setAudioOpen] = useState(false)
  const [videoOpen, setVideoOpen] = useState(false)
  const [shareScreenController, setShareScreenController] = useState({ shared: false, css: 'text-white btn-initial-room btn-initial-config btn-initial-room-details d-inline-block mx-2' })
  const [showModal, setShowModal] = useState(true)
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [isChangeLayoutModalOpen, setIsChangeLayoutModalOpen] = useState(false)
  const [displayName, setDisplayName] = useState('')
  const [audioSources, setAudioSources] = useState([])
  const [videoSources, setVideoSources] = useState([])
  const [selectVideoSource, setSelectVideoSource] = useState(null)
  const [selectAudioSource, setSelectAudioSource] = useState(null)
  const [selectedVideoStream, setSelectedVideoStream] = useState(null)
  const [testMicController, setTestMicController] = useState(false)
  const [streamers, setStreamers] = useState([])
  const [streamerPrincipal, setStreamerPrincipal] = useState(null)
  const [countQuestions, setCountQuestions] = useState(0)
  const [isLoadingGuestRequest, setIsLoadingGuestRequest] = useState(false)
  const [unreadChatMessagesCount, setUnreadChatMessagesCount] = useState(0)
  const { modules, user } = useAuth()
  const [colorConnection, setColorConnection] = useState('#707070')
  const [messageQualityConnection, setMessageQualityConnection] = useState('')
  const [firstRender, setFirstRender] = useState(true)
  const [isGuestRequest, setIsGuestRequest] = useState(false)
  const [guestLink, setGuestLink] = useState('');
  const [activeSidebarTab, setActiveSidebarTab] = useState(null);
  const [pinnedStreamer, setPinnedStreamer] = useState(null);
  const [conferenceLayout, setConferenceLayout] = useState('automatic');
  const [isRecording, setIsRecording] = useState(false);
  const [isLoadingRecording, setIsLoadingRecording] = useState(false);
  const [textBtnLoadingRecording, setTextBtnLoadingRecording] = useState('');
  const [recordings, setRecordings] = useState([]);
  const [showModalAcceptGuest, setShowModalAcceptGuest] = useState(false);
  const [newGuestName, setNewGuestName] = useState('');
  const [currentSID, setCurrentSID] = useState('');
  const queryClient = new QueryClient()

  const notShowQualityConnection = platform.name.toLowerCase().includes('firefox') || platform.name.toLowerCase().includes('safari')

  /* CONTEÚDO DO ROOMCLIENT */

  const mediaType = {
    audio: 'audioType',
    video: 'videoType',
    screen: 'screenType',
    screenAudio: 'screenAudioType',
    file: 'fileType',
    fileAudio: 'fileAudioType',
  }

  const _EVENTS = {
    exitRoom: 'exitRoom',
    openRoom: 'openRoom',
    startVideo: 'startVideo',
    stopVideo: 'stopVideo',
    startAudio: 'startAudio',
    stopAudio: 'stopAudio',
    startScreen: 'startScreen',
    stopScreen: 'stopScreen'
  }
  
  const isRender = query.get('render_key') === process.env.REACT_APP_RENDER_KEY;

  var client = token_decode(localStorage.getItem('auth-user'))

  if (client.room_type === 'studio') {
    throw new Error('should be on studio')
  }

  const isChatLocked =
    client.type_account === '4web' &&
    client.client_type === 'guest' &&
    !modules.internal_chat;

  let client_id = null 
  let event_id = null 
  let external_room = null 
  let room_id = null 
  let room_name = null

  const client_meeting = client.client_meeting.toString()

  if(client.type_account !== '4web'){

    client_id      =  client.client_vp_client_id.toString()
    event_id       =  client.client_vp_event_id.toString()
    external_room  =  client.client_vp_room.toString()
    room_id        =  client_id + event_id + external_room + client_meeting
    room_name = typeof client.client_vp_room === 'string' ? client.room_name ? client.room_name.toString() : null : client.room.title.toString()

  }else{
    client_id      = client.client_vp_client_id.toString()
    event_id       =  '1'
    external_room  =  client.client_vp_room.toString()
    room_id        =  event_id + external_room + client_meeting
    room_name = client.room_name ? client.room_name.toString() : null
  }
 
  const guest = client.client_id.toString()
  const room_type = client.room_type.toString()

  var consumerTransport = null
  var device = null

  var consumers = new Map()
  var eventListeners = new Map()

  function newJoin() {

    if (isOpen()) {
      console.log('already connected to a room')

      if(reconnInterval){
        reconnInterval = false
        clearInterval(reconnInterval)
        
        toast.info(t(`pages.Meet.toastConnectedText`), {
          position: "top-right",
          autoClose: 3000,
        });
      }
      
      return false;
    }

    Object.keys(_EVENTS).forEach(function (evt) {
      eventListeners.set(evt, [])
    })

    createRoom(room_id).then(async function () {
      await join(displayName, room_id)
      // await initSockets()
      _isOpen = true
      roomOpen()
    })

  }

  function requestGuestEnterRoom() {
    setIsLoadingGuestRequest(true)
    socket.emit('guestAccessRequest', {
      room: room_id,
      s_id: socket.id,
      name: displayName
    })
  }

  ////////// INIT /////////

  async function createRoom(room_id) {
    await socket.request('createRoom', {
      room_id
    }).catch(err => {
      console.log(err)
    })
  }

  async function join(name, room_id) {

    socket.request('join', {
      name,
      room_id,
      admin: user.is_admin ? true : false
    }).then(async function (e) {
      console.log(e)
      const data = await socket.request('getRouterRtpCapabilities');
      let thisDevice = await loadDevice(data)
      device = thisDevice
      await initTransports(device)
      socket.emit('getProducers')
    }).catch(e => {
      console.log(e)
    })

  }

  async function loadDevice(routerRtpCapabilities) {
    let device
    console.log("HERE ->", device)
    try {
      device = new mediasoupClient.Device();
    } catch (error) {
      if (error.name === 'UnsupportedError') {
        console.error('browser not supported');
      }
      console.error(error)
    }
    await device.load({
      routerRtpCapabilities
    })
    return device

  }

  async function initTransports(device) {

    // init producerTransport
    {
      const data = await socket.request('createWebRtcTransport', {
        forceTcp: false,
        rtpCapabilities: device.rtpCapabilities,
      })
      if (data.error) {
        console.error(data.error);
        return;
      }

      producerTransport = await device.createSendTransport(data);

      producerTransport.on('connect', async function ({
        dtlsParameters
      }, callback, errback) {
        socket.request('connectTransport', {
          dtlsParameters,
          transport_id: data.id
        })
          .then(callback)
          .catch(errback)
      });

      producerTransport.on('produce', async function ({
        kind,
        rtpParameters
      }, callback, errback) {
        try {
          const {
            producer_id
          } = await socket.request('produce', {
            producerTransportId: producerTransport.id,
            kind,
            rtpParameters,
            display: streamDisplayName,
            typeVideo: kind === 'audio' ? 'audio' : typeVideo,
            group: typeVideo === 'screen' ? `${socket.id}_stream` : socket.id
          });
          callback({
            id: producer_id
          });
        } catch (err) {
          errback(err);
        }
      })

      producerTransport.on('connectionstatechange', function (state) {
        switch (state) {
          case 'connecting':

            break;

          case 'connected':
            //localVideo.srcObject = stream
            break;

          case 'failed':
            producerTransport.close();
            break;

          default:
            break;
        }
      });
    }

    // init consumerTransport
    {
      const data = await socket.request('createWebRtcTransport', {
        forceTcp: false,
      });
      if (data.error) {
        console.error(data.error);
        return;
      }

      // only one needed
      consumerTransport = device.createRecvTransport(data);
      consumerTransport.on('connect', function ({
        dtlsParameters
      }, callback, errback) {
        socket.request('connectTransport', {
          transport_id: consumerTransport.id,
          dtlsParameters
        })
          .then(callback)
          .catch(errback);
      });

      consumerTransport.on('connectionstatechange', async function (state) {
        switch (state) {
          case 'connecting':
            break;

          case 'connected':
            //remoteVideo.srcObject = await stream;
            //await socket.request('resume');
            break;

          case 'failed':
            consumerTransport.close();
            break;

          default:
            break;
        }
      });
    }

  }

  function initSockets() {
    socket.on('consumerClosed', function ({
      consumer_id
    }) {
      console.log('closing consumer:', consumer_id)
      removeConsumer(consumer_id)
    })

    /**
     * data: [ {
     *  producer_id:
     *  producer_socket_id:
     * }]
     */
    socket.on('newProducers', async function (data) {
      for (let {
        producer_id, display, typeVideo, group
      } of data) {
        await consume(producer_id, display, typeVideo, group)
      }
    })

    socket.on('disconnect', function () {
      //exit(true)
      reconnect()
    })

    socket.on('guestAccessRequest', function (data) {
      if (user.is_admin) {
        setNewGuestName(data.guestName)
        setCurrentSID(data.guestSocketId)
        setShowModalAcceptGuest(true)
        
        new Audio(requestSound).play();
      }
    })

    socket.on('pendingRequestsResults', (data) => {
      if (user.is_admin) {
        let pendingUsers = data.users
  
        if (pendingUsers.length === 0) return;
  
        setNewGuestName(pendingUsers[0].participant_name);
        setCurrentSID(pendingUsers[0].socket_id);
        setShowModalAcceptGuest(true);
      }
    })

    socket.on('noticeTransmission', (data) => {
      // don't show for guests of client 17926
      if (client.client_vp_client_id === 17926) {
        return
      }
      
      if (data.status === 'start') {
        toast.info(
          <div>
            <i className="fas fa-info-circle"></i>
            <span style={{marginLeft: '10px'}}>
              {data.type === 'record' ? t(`pages.Meet.toastRecordingStarted`) : t(`pages.Meet.toastBroadcastStarted`)}
            </span>
          </div>, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          className: 'Toastify__toast Toastify__toast-theme--light Toastify__toast--info'
        });
      }

      if (data.status === 'end') {
        toast.info(
          <div>
            <i className="fas fa-info-circle"></i>
            <span style={{marginLeft: '10px'}}>
              {data.type === 'record' ? t(`pages.Meet.toastRecordingEnded`) : t(`pages.Meet.toastBroadcastEnded`)}
            </span>
          </div>, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          className: 'Toastify__toast Toastify__toast-theme--light Toastify__toast--info'
        });
      }
    })

    socket.on('noticeGuestOnScreen', (data) => {
      // don't show for guests of client 17926
      if (client.client_vp_client_id === 17926) {
        return
      }
      
      if (data.status === 'addOnScreen') {
        toast.info(
          <div>
            <i className="fas fa-info-circle"></i>
            <span style={{marginLeft: '10px'}}>{t(`pages.Meet.toastAddedToTheBroadcast`)}</span>
          </div>, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          className: 'Toastify__toast Toastify__toast-theme--light Toastify__toast--info'
        });
      }

      if (data.status === 'removeFromScreen') {
        toast.info(
          <div>
            <i className="fas fa-info-circle"></i>
            <span style={{marginLeft: '10px'}}>{t(`pages.Meet.toastRemovedFromBroadcast`)}</span>
          </div>, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          className: 'Toastify__toast Toastify__toast-theme--light Toastify__toast--info'
        });
      }
    })
    
    socket.on('hostResponse', (data) => {
      if (data.response === 'accept') {
        setIsLoadingGuestRequest(false)
        setShowModal(false)
        setBlockUI(true)
        newJoin()
        return
      }

      if (data.response === 'reject') {
        setIsLoadingGuestRequest(false)
        setDisplayName('')
        window.location.href = '/access-denied'
        return
      }
    })

    socket.on('removeGuestFromRoom', () => {
      window.location.href = '/removed-from-the-room'
    })

    socket.on('noticeTransmissionStartAndScreen', () => {
      // don't show for guests of client 17926
      if (client.client_vp_client_id === 17926) {
        return
      }
      
      toast.info(
        <div>
          <i className="fas fa-info-circle"></i>
          <span style={{marginLeft: '10px'}}>{t(`pages.Meet.toastAreOnTheBroadcast`)}</span>
        </div>, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        className: 'Toastify__toast Toastify__toast-theme--light Toastify__toast--info'
      });
    })
  }

  //////// MAIN FUNCTIONS /////////////

  async function produce(type, src = null, groupId = null) {

    let mediaConstraints = {}
    let video = false
    let audio = false
    let screen = false
    let screenAudio = false
    let file = false
    let fileAudio = false

    switch (type) {
      case mediaType.audio:
        mediaConstraints = {
          audio: {
            deviceId: src
          },
          video: false
        }
        audio = true
        break
      case mediaType.video:
        video = true
        if (src) {
          mediaConstraints = {
            audio: false,
            video: {
              width: { max: 1920, min: 320 },
              height: { max: 1080, min: 200 },
              aspectRatio: { ideal: 1.77778 },
              deviceId: { exact: src }
            }
          }
        } else {
          mediaConstraints = {
            audio: false,
            video: {
              width: { max: 1920, min: 320 },
              height: { max: 1080, min: 200 },
              aspectRatio: { ideal: 1.77778 }
            }
          }
        }
        typeVideo = 'cam'
        break
      case mediaType.screen:
        mediaConstraints = { video: true, audio: true }
        screen = true
        typeVideo = 'screen'
        break;
      case mediaType.screenAudio:
        screenAudio = true
        break;
      case mediaType.file:
        typeVideo = 'screen'
        file = true
        break;
      case mediaType.fileAudio:
        fileAudio = true
        break;
      default:
        return
    }

    // many files (video and audio tracks) can be produced at the same time
    if (!file && !fileAudio) {
      if (producerLabel.has(type)) {
        console.log('producer already exists for this type ' + type)
        return
      }
    }

    try {

      let track, stream;
      let videoElement;

      if (video || audio || screen) {
        if (video && src === 'dummyVideo') {
          track = dummyVideo();
        } else if (audio && src === 'dummyAudio') {
          track = dummyAudio();
        } else {
          stream = screen 
            ? await navigator.mediaDevices.getDisplayMedia(mediaConstraints) 
            : await navigator.mediaDevices.getUserMedia(mediaConstraints)

          track = audio ? stream.getAudioTracks()[0] : stream.getVideoTracks()[0]
        }

        if (screen) {
          let screenAudioTrack = stream.getAudioTracks()[0]
          if (screenAudioTrack) {
            produce(mediaType.screenAudio, screenAudioTrack)
          }
        }

        stream = new MediaStream()
        stream.addTrack(track)

      } else if (file) {
        videoElement = document.createElement('video');
        videoElement.src = src.fileURL;

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
  
        videoElement.addEventListener('loadedmetadata', function() {
          canvas.width = videoElement.videoWidth;
          canvas.height = videoElement.videoHeight;
        });

        // when payback end, go to start and pause
        videoElement.addEventListener('timeupdate', function() {
          // doesn't wait until it actually ends or the stream will be destroyed
          if (!videoElement.paused) {
            if (videoElement.duration - videoElement.currentTime < 0.5) {
              videoElement.currentTime = 0;
              videoElement.pause();
            }
          }
        });

        videoElement.addEventListener('play', function() {
          if (videoElement.duration - videoElement.currentTime < 0.5) {
            videoElement.currentTime = 0;
            videoElement.pause();
          }
        });
  
        const fps = 25;
        const clearInterval = 25;
        let frameCount = 0;

        function frameStep() {
          // clear rect each ${clearInterval} frames
          frameCount++;
          if (frameCount % clearInterval === 0) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
          }
          ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height)
        }

        videoElement.addEventListener('loadeddata', function() {
          videoElement.currentTime = 0;
          if (!videoElement.stopRender) {
            const stopRender = audioTimerLoop(frameStep, 1000 / fps);
            videoElement.stopRender = stopRender;
          }
        });
  
        const canvasStream = canvas.captureStream(fps);

        let videoStream
        if (videoElement.captureStream) {
          videoStream = videoElement.captureStream();
        } else if (videoElement.mozCaptureStream) {
          videoStream = videoElement.mozCaptureStream();
        } else {
          throw new Error('capture stream not suported');
        }

        // wait for videoStream's audio track
        const videoHasAudio = await new Promise((resolve, reject) => {
          // timeout in 3 seconds
          const timeoutID = setTimeout(() => {
            resolve(false)
          }, 3000);

          videoStream.addEventListener('addtrack', function(event) {
            if (event.track.kind === 'audio') {
              clearTimeout(timeoutID);
              resolve(true);
            }
          })
        })

        groupId = uuidv4();

        const [videoTrack] = canvasStream.getVideoTracks();  
        
        if (videoHasAudio) {
          const [audioTrack] = videoStream.getAudioTracks();   
          await produce(mediaType.fileAudio, audioTrack, groupId);
        }

        track = videoTrack;
        stream = new MediaStream();
        stream.addTrack(videoTrack);
      } else {
        track = src
      }

      const params = {
        track
      };

      if (video) {
        params.encodings = [{
          rid: 'r0',
          maxBitrate: 100000,
          //scaleResolutionDownBy: 10.0,
          scalabilityMode: 'L1T3'
        },
        {
          rid: 'r1',
          maxBitrate: 300000,
          scalabilityMode: 'L1T3'
        },
        {
          rid: 'r2',
          maxBitrate: 900000,
          scalabilityMode: 'L1T3'
        }
        ];
        params.codecOptions = {
          videoGoogleStartBitrate: 1000
        };
      }

      if (file) {
        streamDisplayName = src.filename;
      } else {
        streamDisplayName = displayName;
      }

      const producer = await producerTransport.produce(params)

      producers.set(producer.id, producer)

      let elem
      if (video || screen || file) {
        let group;
        let audioStream;

        if (file) {
          group = `${socket.id}_file_${groupId}`
        } else {
          group = socket.id
        }

        if (video) {
          producerId = producer.id

          streamsAudio.forEach(stream => {
            if (stream.group === group) {
              audioStream = stream
            }
          })
        }

        setStreamers(prevState =>
          [...prevState, {
            id: producer.id,
            display: streamDisplayName,
            type: video ? 'admin' : 'screen',
            srcObject: stream,
            status: 'off',
            typeVideo: video ? 'cam' : 'screen',
            group: group,
            file,
            videoElement,
            addOnScreen: Boolean(file),
            isVideoOpen: (video && src === 'dummyVideo') ? false : true,
            audioStream,
          }]
        )

        listStreamers = [...listStreamers, {
          producer_id: producer.id,
          srcObject: stream
        }]
        
        if ((screen || file) && !pinnedStreamer) {
          setPinnedStreamer(producer.id);
        }

        addPrincipalVideo({ id: producer.id, srcObject: stream, videoElement })

        //if produce type screen, define css and sharescreen button true
        if (screen) {
          setShareScreenController({ shared: true, css: 'text-white  btn-initial-config btn-inital-room-pressed d-inline-block mx-2' })
        }

        setBlockUI(false)

        // if producing main video
        if (type === mediaType.video && user.is_admin) {
          checkIfThereArePendingRequests()
          setShowInviteModal(true)
        }
      } else {
        let group;

        if (fileAudio) {
          group = `${socket.id}_file_${groupId}`
        } else {
          group = socket.id
        }

        streamsAudio.set(producer.id, {
          id: producer.id,
          display: streamDisplayName,
          typeVideo: 'audio',
          stream: stream,
          group: group,
        })

        setStreamers(prevState => prevState.map(streamer => {
          if (streamer.group === group) {
            return {
              ...streamer,
              audioStream: stream
            }
          }
          return streamer
        }))
      }

      producer.on('trackended', () => {
        closeProducer(type, producer.id)
      })

      producer.on('transportclose', () => {
        console.log('producer transport close')
        if (!audio) {
          elem.srcObject.getTracks().forEach(function (track) {
            track.stop()
          })
          elem.parentNode.removeChild(elem)
        }
        producers.delete(producer.id)

      })

      producer.on('close', () => {
        console.log('closing producer')
        if (!audio) {
          elem.srcObject.getTracks().forEach(function (track) {
            track.stop()
          })
          elem.parentNode.removeChild(elem)
        }
        producers.delete(producer.id)

      })

      if (!file && !fileAudio) {
        producerLabel.set(type, producer.id)
      }

      if (type === mediaType.audio && src !== 'dummyAudio') {
        setAudioOpen(true)
      }

      if (type === mediaType.video && src !== 'dummyVideo') {
        setVideoOpen(true)
      }

      switch (type) {
        case mediaType.audio:
          event(_EVENTS.startAudio)
          break
        case mediaType.video:
          event(_EVENTS.startVideo)
          break
        case mediaType.screen:
          event(_EVENTS.startScreen)
          break;
        default:
          return
      }
    } catch (err) {
      console.log(err)
      if (err.message.toString() === 'Could not start video source' && !audio && !screen) {
        Swal.fire('Oops...', 'There is a problem with your camera. Check that it is connected correctly and no other applications are using it. If another application is using the camera, it is necessary to close it.', 'warning')
      } else if (err.message.toString() === 'Permission denied') {
        console.log('Compartilhamento de tela cancelado')
      } else {
        Swal.fire('Oops...', 'An error occurred when starting. Please check your camera and try again.', 'warning')
      }

      if (video) {
        setStreamers(prevState =>
          [...prevState, {
            id: 'admin',
            display: displayName,
            type: 'admin',
            srcObject: null,
            status: 'off',
            typeVideo: typeVideo,
            group: socket.id,
            isVideoOpen: false,
          }]
        )

        setBlockUI(false)
      }
    }
  }

  async function consume(producer_id, display, typeVideo, group) {

    //let info = await roomInfo()
    console.log(typeVideo, display, group)
    getConsumeStream(producer_id).then(function ({
      consumer,
      stream,
      kind,
    }) {

      consumers.set(consumer.id, consumer)

      let elem

      if (kind === 'video') {
        new Audio(loginSound).play()

        let audioStream

        streamsAudio.forEach(streamer => {
          if (streamer.group === group) {
            audioStream = streamer.stream
          }
        })

        setStreamers(prevState =>
          [...prevState, {
            id: producer_id,
            consumer_id: consumer.id,
            display: display,
            type: 'guest',
            srcObject: stream,
            status: 'off',
            kind: kind,
            typeVideo: typeVideo,
            group: group,
            isVideoOpen: true,
            audioStream,
          }]
        )

        listStreamers = [...listStreamers, {
          producer_id: producer_id,
          consumer_id: consumer.id,
          srcObject: stream
        }]
        
        if (typeVideo === 'screen' && !pinnedStreamer) {
          setPinnedStreamer(producer_id);
        }

        setTimeout(function () {
          addPrincipalVideo({ id: producer_id, srcObject: stream })
        }, 1000)

      } else {
        elem = document.createElement('audio')
        elem.srcObject = stream
        elem.id = consumer.id
        elem.playsinline = false
        elem.autoplay = true
        //document.getElementById(remoteAudioEl).appendChild(elem)

        streamsAudio.set(producer_id, {
          id: producer_id,
          consumer_id: consumer.id,
          display: display,
          typeVideo: typeVideo,
          stream: stream,
          group: group
        })

        // add audio stream to streamer object in state with the same group 
        setStreamers(prevState => prevState.map(streamer => {
          if (streamer.group === group) {
            return {
              ...streamer,
              audioStream: stream
            }
          }
          return streamer
        }))
      }

      consumer.on('trackended', function () {
        removeConsumer(consumer.id)
      })
      consumer.on('transportclose', function () {
        removeConsumer(consumer.id)
      })

    })
  }

  async function getConsumeStream(producerId) {
    const {
      rtpCapabilities
    } = device
    const data = await socket.request('consume', {
      rtpCapabilities,
      consumerTransportId: consumerTransport.id, // might be 
      producerId
    });

    const {
      id,
      kind,
      rtpParameters
    } = data;

    let codecOptions = {};
    const consumer = await consumerTransport.consume({
      id,
      producerId,
      kind,
      rtpParameters,
      codecOptions,
    })
    const stream = new MediaStream();
    stream.addTrack(consumer.track);
    return {
      consumer,
      stream,
      kind
    }
  }

  function closeProducer(type, producer_id) {

    if (!producer_id) {
      if (!producerLabel.has(type)) {
        console.log('there is no producer for this type ' + type)
        return
      }

      producer_id = producerLabel.get(type)
    }

    socket.emit('producerClosed', {
      producer_id
    })
    producers.get(producer_id).close()
    producers.delete(producer_id)
    producerLabel.delete(type)

    if (type === mediaType.audio) {
      setAudioOpen(false)
    }

    if (type === mediaType.video) {
      setVideoOpen(false)
    }

    if (
      type === mediaType.audio || 
      type === mediaType.screenAudio ||
      type === mediaType.fileAudio
    ) {
      streamsAudio.delete(producer_id)
      return false
    } else if (type === mediaType.screen) {
      if (producerLabel.has(mediaType.screenAudio)) {
        closeProducer(mediaType.screenAudio)
      }
    } else if (type === mediaType.file) {
      const this_streamer = streamers.find(streamer => streamer.id === producer_id);
      if (this_streamer.videoElement.stopRender) {
        this_streamer.videoElement.stopRender();
      }
      this_streamer.videoElement.pause();
      this_streamer.videoElement.currentTime = 0;
      
      let this_audio;

      streamsAudio.forEach((audioStream) => {
        if (audioStream.group === this_streamer.group) {
          this_audio = audioStream
        }
      }) 

      if (this_audio) {
        closeProducer(mediaType.fileAudio, this_audio.id);
      }
    }

    //Remove streamer from list
    setStreamers(prevState => prevState.filter(streamer => streamer.id !== producer_id))

    listStreamers = listStreamers.filter(streamer => streamer.producer_id !== producer_id)

    if (pinnedStreamer === producer_id) {
      setPinnedStreamer(null);
    }

    //If streamer is on principalVideo, add another video with principal
    if (streamerPrincipal && (streamerPrincipal.id === producer_id)) {
      addPrincipalVideo()
    }

    switch (type) {
      case mediaType.audio:
        event(_EVENTS.stopAudio)
        break
      case mediaType.video:
        event(_EVENTS.stopVideo)
        break
      case mediaType.screen:
        event(_EVENTS.stopScreen)
        break;
      default:
        return
    }

  }

  function pauseProducer(type) {
    if (!producerLabel.has(type)) {
      console.log('there is no producer for this type ' + type)
      if ([mediaType.audio, mediaType.video].includes(type)) {
        const deviceText = type === mediaType.audio ? t(`pages.Studio.micText`) : t(`pages.Studio.cameraText`);
        toast.warn(t(`pages.Studio.toastFailedCameraOrMic`, { deviceText }), {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 8000,
        });
      }
      return false;
    }
    let producer_id = producerLabel.get(type)
    producers.get(producer_id).pause()
    return true;
  }

  function resumeProducer(type) {
    if (!producerLabel.has(type)) {
      console.log('there is no producer for this type ' + type)
      return false;
    }
    let producer_id = producerLabel.get(type)
    producers.get(producer_id).resume()
    return true;
  }

  function removeConsumer(consumer_id) {

    consumers.delete(consumer_id)
    new Audio(logoutSound).play()

    let this_streamer = listStreamers.find(streamer => streamer.consumer_id === consumer_id)

    if (this_streamer) {
      setStreamers(prevState => prevState.filter(streamer => streamer.consumer_id !== consumer_id))

      setPinnedStreamer(prevState => prevState === this_streamer.producer_id ? null : prevState)

      if (streamerPrincipal && (streamerPrincipal.id === this_streamer.producer_id)) {
        addPrincipalVideo()
      }
    }

    let this_audio;

    streamsAudio.forEach((audioStream) => {
      if (audioStream.consumer_id === consumer_id) {
        this_audio = audioStream
      }
    }) 

    if (this_audio) {
      streamsAudio.delete(this_audio.id)
    }
  }

  function exit(offline = false) {

    let clean = function () {
      
      closeProducer(mediaType.video)
      closeProducer(mediaType.audio)

      if(producerLabel.has(mediaType.screen)){
        closeProducer(mediaType.screen)
        setShareScreenController({ shared: false, css: 'text-white btn-initial-room btn-initial-config btn-initial-room-details d-inline-block mx-2' })
      }

      _isOpen = false
      consumerTransport.close()
      producerTransport.close()
      socket.off('disconnect')
      socket.off('newProducers')
      socket.off('consumerClosed')
    }

    if (!offline) {
      socket.request('exitRoom').then(e => console.log(e)).catch(e => console.warn(e)).finally(function () {
        clean()
      })
    } else {
      clean()
    }

    event(_EVENTS.exitRoom)

  }

  function reconnect() {

    toast.warning(t(`pages.Meet.toastReconnecting`), {
      position: "top-right",
      autoClose: 15000,
    });

    exit(true);
    reconnInterval = setInterval(newJoin, 15000);
  }

  ///////  HELPERS //////////

  /*async roomInfo() {
      let info = await socket.request('getMyRoomInfo')
      return info
  }*/


  function event(evt) {
    if (eventListeners.has(evt)) {
      eventListeners.get(evt).forEach(callback => callback())
    }
  }

  /*function on(evt, callback) {
    eventListeners.get(evt).push(callback)
  }*/

  //////// GETTERS ////////

  function isOpen() {
    return _isOpen
  }

  /* FIM CONTEÚDO DO ROOMCLIENT */

  function roomOpen() {
    if (!isRender) {
      setTimeout(function () {
        produce(mediaType.video, selectVideoSource)
        produce(mediaType.audio, selectAudioSource)
      }, 3000);
    } else {
      setBlockUI(false)
    }
  }

  const configAudio = useRef(null)
  const playMicBtn = useRef(null)

  function addPrincipalVideo(streamer) {
    try{
      if (!streamer) {
        streamer = { id: listStreamers[0].producer_id, srcObject: listStreamers[0].srcObject, videoElement: listStreamers[0].videoElement  }
      }else if(streamer && streamer.type === `audio`){
        let this_streamer = listStreamers.find(streamer => streamer.group === streamer.group)
        streamer = { id: this_streamer.producer_id, srcObject: this_streamer.srcObject, videoElement: this_streamer.videoElement }
      }

      if (streamerPrincipal && (streamerPrincipal.id === streamer.id)) {
        return false
      }

      setStreamerPrincipal(streamer);
    }catch(e) {
      //console.log(e)
    }
  }

  function toggleAudio() {
    if (selectAudioSource === 'dummyAudio') {
      const deviceText = t(`components.PermissionsOverlay.microphoneText`);
      const endText = t(`components.PermissionsOverlay.speakToOthersText`);
      toast.warn(t(`components.PermissionsOverlay.toastDenyAccessCameraOrMic`, { deviceText, endText }), {
				position: "top-right",
				autoClose: 8000,
			});
      return
    }

    if (audioOpen) {
      const paused = pauseProducer(mediaType.audio)
      setAudioOpen(!paused);
    } else {
      const resumed = resumeProducer(mediaType.audio)
      setAudioOpen(resumed);
    }
  }

  function toggleVideo() {    
    if (selectVideoSource === 'dummyVideo') {
      const deviceText = t(`components.PermissionsOverlay.cameraText`);
      const endText = t(`components.PermissionsOverlay.beSeenByOthersText`);
      toast.warn(t(`components.PermissionsOverlay.toastDenyAccessCameraOrMic`, { deviceText, endText }), {
				position: "top-right",
				autoClose: 8000,
			});
      return
    }

    let newVideoOpen;

    if (videoOpen) {
      const paused = pauseProducer(mediaType.video);
      newVideoOpen = !paused;
    } else {
      const resumed = resumeProducer(mediaType.video)
      newVideoOpen = resumed;
    }

    setVideoOpen(newVideoOpen);
  
    setStreamers(prevState => prevState.map(streamer => {
      return streamer.id === producerId 
        ? { ...streamer, isVideoOpen: newVideoOpen } 
        : streamer
    }))
  }

  function shareScreen() {
    if (!shareScreenController.shared) {
      produce(mediaType.screen)
    } else {
      closeProducer(mediaType.screen)
      setShareScreenController({ shared: false, css: 'text-white btn-initial-room btn-initial-config btn-initial-room-details d-inline-block mx-2' })
    }

  }

  function joinRoom() {
    if (!displayName && !isRender) {
      Swal.fire("Aren't you forgetting something?", 'Enter your name to get to know you better :D', 'warning')
      return
    }
    if (testMicController) {
      stopMic()
    }
    initSockets()

    // requestGuestEnterRoom()

    // setShowModal(false)
    // setBlockUI(true)
    // newJoin()

    if (client.meeting_type === 'meeting' || isRender || user.is_admin) {
      setShowModal(false)
      setBlockUI(true)
      newJoin()
      return
    }

    if (isGuestRequest) {
      requestGuestEnterRoom()
    } else {
      setShowModal(false)
      setBlockUI(true)
      newJoin()
    }
  }

  useEffect(() => {
    if (isRender) {
      joinRoom()
    }
  }, [])

  async function loadDevices() {
    const enumdevices = await navigator.mediaDevices.enumerateDevices();

    const hasCameras = enumdevices.some(device => device.kind === 'videoinput');
    const hasMicrophones = enumdevices.some(device => device.kind === 'audioinput');

    const audioDevices = enumdevices.filter((device) => device.kind === 'audioinput' && device.label);
    const videoDevices = enumdevices.filter((device) => device.kind === 'videoinput' && device.label);

    if (audioDevices.length === 0) {
      const dummyAudioDevice = {
        deviceId: 'dummyAudio',
        groupId: 'dummyAudio',
        kind: 'audioinput',
        label: hasMicrophones 
          ? 'No permission given' 
          : 'No microphones detected',
        selected: true,
      }

      audioDevices.push(dummyAudioDevice);
    }

    let defaultCamId = null; 
    let defaultStream = null;
    let cameraFailed = false;

    const mediaConstraints = {
      audio: false,
      video: {
        width: { max: 1920, min: 320 },
        height: { max: 1080, min: 200 },
        aspectRatio: { ideal: 1.77778 },
      }
    }

    while (videoDevices.length) {
      try {
        defaultStream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
        defaultCamId = defaultStream.getVideoTracks()[0].getSettings().deviceId;
        cameraFailed = false;
        break;
      } catch (err) {
        if (err.name === 'NotReadableError') {
          cameraFailed = true;
          videoDevices.splice(0, 1);
        }
      }
    }

    if (videoDevices.length === 0) {
      const dummyVideoDevice = {
        deviceId: 'dummyVideo',
        groupId: 'dummyVideo',
        kind: 'videoinput',
        label: cameraFailed 
          ? 'All cameras failed' 
          : hasCameras 
          ? 'No permission given' 
          : 'No cameras detected',
        selected: true
      }

      videoDevices.push(dummyVideoDevice);
      defaultStream = new MediaStream([dummyVideo()]);
      defaultCamId = dummyVideoDevice.deviceId;
    }


    const defaultCamIndex = videoDevices.findIndex(
      (device) => device.deviceId === defaultCamId
    )
    videoDevices[defaultCamIndex].selected = true    

    setAudioSources(audioDevices)
    setVideoSources(videoDevices)

    loadCam(defaultCamId, defaultStream);
    loadMic(audioDevices[0].deviceId);
  }

  //Load user webcam
  async function loadCam(deviceId = null, stream = null) {
    let srcObject;

    setSelectVideoSource(deviceId)

    //Define initial cam for config
    if (!stream) {
      let mediaConstraints = {
        audio: true,
        video: {
          width: { max: 1920, min: 320 },
          height: { max: 1080, min: 200 },
          aspectRatio: { ideal: 1.77778 },
          deviceId: { exact: deviceId }
        }
      }

      srcObject = await navigator.mediaDevices.getUserMedia(mediaConstraints)
    } else {
      srcObject = stream
    }

    setSelectedVideoStream(srcObject)
  }

  async function loadMic(deviceId) {
    if (testMicController) {
      stopMic()
    }

    setSelectAudioSource(deviceId)
  }

  async function playMic() {
    if (selectAudioSource === 'dummyAudio') {
      return
    }

    let audio = configAudio.current
    let btn = playMicBtn.current

    let mediaConstraints
    if (selectAudioSource) {
      mediaConstraints = {
        audio: {
          deviceId: { exact: selectAudioSource },
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 4000
        },
        video: false
      }
    } else {
      mediaConstraints = {
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 4000
        },
        video: false
      }
    }

    let stream = await navigator.mediaDevices.getUserMedia(mediaConstraints)

    //CHANGE CSS BUTTON
    btn.innerHTML = '<i class="fa fa-microphone-slash"></i>Stop'
    btn.classList.add('btn-test-mic-active')

    //DEFINE MEDIASTREAM
    audio.srcObject = stream
    audio.play()

    //CONTROLLER TEST MIC
    setTestMicController(true)

  }

  function stopMic() {
    let btn = playMicBtn.current
    let audio = configAudio.current

    //CHANGE CSS BUTTON
    btn.innerHTML = '<i class="fa fa-microphone"></i>Test microphone'
    btn.classList.remove('btn-test-mic-active')

    //STOP CAPTURE MIC
    let stream = audio.srcObject;
    let tracks = stream.getTracks();

    tracks.forEach(function (track) {
      track.stop();
    });

    audio.srcObject = null;

    //CONTROLLER TEST MIC
    setTestMicController(false)
  }

  function micController() {
    if (!testMicController) {
      playMic()
    } else {
      stopMic()
    }
  }

  function handleNewChatMessage() {
    const isChatVisible = activeSidebarTab === 'chat';

    if (!isChatVisible) {
      setUnreadChatMessagesCount(prev => prev + 1);
    } else {
      setUnreadChatMessagesCount(0);
    }
  }

  function handleRemoveStreamer(stream) {
    let title, description;

    if (stream.file) {
      title = t(`pages.Meet.removeFileTitle`)
      description = t(`pages.Meet.removeFileText`)
    } else {
      title = t(`pages.Meet.removeGuestTitle`)
      description = t(`pages.Meet.removeGuestText`)
    }

    Swal.fire({
      icon: 'warning',
      title: `<p>${title}</p>`,
      html: description,
      showCancelButton: true,
      confirmButtonText: t(`pages.Meet.buttonConfirmText`),
      cancelButtonText: t(`pages.Meet.buttonCancelText`),
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (stream.file) {
          closeProducer(mediaType.file, stream.id);
        } else {
          const guestSocketId = stream.group;
          socket.emit('removeGuestFromRoom', guestSocketId);
        }
      }
    });
  }

  async function handleShareVideoFile() {
    const sharedVideoFiles = streamers.filter(stream => stream.file);

    if (sharedVideoFiles.length >= 2) {
      const result = await Swal.fire({
        icon: 'warning',
        title: '<p>Caution</p>',
        html:
          'Sharing too many video files can slow down your overall performance.<br/><br/>tip: you can remove shared videos that you are not using anymore.',
        showCancelButton: true,
        confirmButtonText: `Ok, continue`,
        cancelButtonText: `Cancel`,
      });

      if (!result.isConfirmed) {
        return;
      }
    }

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'video/*';
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const fileURL = URL.createObjectURL(file);
      const [filename] = file.name.split(/\.[^.]+$/g);
      produce(mediaType.file, { fileURL, filename });
    }
  }

  function handlePinStreamer(streamerId) {
    setPinnedStreamer(prevState => prevState !== streamerId ? streamerId : null);
  }

  function handleActiveSidebarTabChange(newSidebarTab) {
    setActiveSidebarTab(prev => prev !== newSidebarTab ? newSidebarTab : null);
  }

  async function handleStartRecording() {
    setIsLoadingRecording(true);

    try {
      let serverRender = null

      // if (process.env.REACT_APP_ENV === 'local') {
      if (false) {
        serverRender = {
          status: 'active',
          domain: 'localhost'
        }
      } else {
        //Cria um novo servidor de renderização
        let i = 0;

        for (i; i <= 3; i++) {
          serverRender = await socket.request('generateWebliveRender', { room: room_id })

          if (serverRender?.status === 'active') {
            break;
          } else if (serverRender?.status === 'error') {
            if (i === 3) {
              break;
            } else {
              toast.info(t(`pages.Studio.toastStillStartingText`), {
                position: "top-right",
                autoClose: 3000,
              });
            }
          }
        }
      }
      
      if (serverRender?.status === 'active') {
        internalSocket = io(`wss://${serverRender.domain}:1630/internal`, { cors: { origin: window.location.origin, credentials: false } })

        internalSocket.request = function request(type, data = {}) {
          return new Promise((resolve, reject) => {
            internalSocket.emit(type, data, (data) => {
              if (data.error) {
                reject(data.error)
              } else {
                resolve(data)
              }
            })
          })
        }

        localStorage.setItem('render_data', JSON.stringify(serverRender))
      } else {
        Swal.fire("Oops!", t(`pages.Meet.errorStartingRecording`), 'warning')
        return
      }

      const room_short_id = guestLink.split('meet/')[1];

      let renderParams = {
        room_id: room_id,
        room_type: 'meet',
        room_short_id: room_short_id,
        client_id: client_id,
        render_type: 'record',
        external_room: external_room,
        client_type: client.type_account,
        event_id: event_id,
      };

      await internalSocket.request('openRenderer', renderParams);
      await internalSocket.request('startRender');

      internalSocket.once('render_crashed', () => {
        handleStopRecording({ hideAlerts: true });
        Swal.fire("Oops!", t(`pages.Meet.errorWithRecording`), 'error');
      });

      setIsRecording(true);

      socket.emit('noticeTransmission', {
        room_id: room_id,
        status: 'start',
        type: 'record'
      })
    } catch (e) {
      await handleStopRecording({ hideAlerts: true });
      Swal.fire("Oops!", t(`pages.Meet.errorStartingRecording`), 'warning');
    } finally {
      setIsLoadingRecording(false);
    }
  }

  async function handleStopRecording({ hideAlerts } = {}) {
    setIsLoadingRecording(true);

    let recordUrl;
    
    try {
      const { renderData } = await internalSocket?.request('closeRenderer');
      recordUrl = renderData.recordUrl;
      internalSocket?.disconnect();
    } catch (err) {
      console.error('failed to close renderer', err);
    }

    try {
      const renderData = await JSON.parse(localStorage.getItem('render_data'));
  
      if (renderData) {
        socket.request('removeWebliveRender', { 
          socket_id: socket.id, 
          id: renderData.id, 
          dnsRegister: renderData.dnsRegister, 
          domain: renderData.domain 
        })
      }
      
      localStorage.removeItem('render_data');
    } catch (err) {
      console.error('failed to remove weblive render', err);
    }

    setIsLoadingRecording(false);
    setIsRecording(false);
    
    socket.emit('noticeTransmission', {
      room_id: room_id,
      status: 'end',
      type: 'record',
    })
    
    if (!hideAlerts) {
      if (recordUrl) {
        Swal.fire({
          title: "Success!",
          html:  `${t('pages.Meet.recordingFinished')}<br><br><a className="btn btn-primary text-center" href="${recordUrl}">${t('pages.Meet.btnDownloadRecording')}</a>`,
          icon: 'success'
        })
      } else {
        Swal.fire("Success!", t(`pages.Meet.recordingFinished`), 'success');
      }
    }

    setTimeout(() => {
      loadRecordings();
    }, 5000);
  }  

  const handleSwapStreams = useCallback((firstId, secondId) => {
    setStreamers(prevState => {
      const copyArray = [...prevState];
      const firstIndex = copyArray.findIndex(s => s.id === firstId);
      const secondIndex = copyArray.findIndex(s => s.id === secondId);
      [copyArray[firstIndex], copyArray[secondIndex]] =
        [copyArray[secondIndex], copyArray[firstIndex]];
      return copyArray;
    })
  }, [])

  function handleAcceptNewGuest() {
    socket.emit('hostResponse', {
      response: 'accept',
      s_id: currentSID,
      room: room_id
    })

    setShowModalAcceptGuest(false)

    checkIfThereArePendingRequests()
  }

  function handleRejectNewGuest() {
    socket.emit('hostResponse', {
      response: 'reject',
      s_id: currentSID,
      room: room_id
    })

    setShowModalAcceptGuest(false)

    checkIfThereArePendingRequests()
  }

  function checkIfThereArePendingRequests() {
    socket.emit('pendingRequests', {
      room_id: room_id,
    }, (response) => {
      const users = response;
      users.forEach(user => {
        setNewGuestName(user.participant_name);
        setCurrentSID(user.socket_id);
        setShowModalAcceptGuest(true);
      });
    })
  }

  async function handleSwitchGuestRequest() {
    const newValueGuestRequest = !isGuestRequest

    api.put('/api/configs', {
      client_id,
      room_id,
      name: 'guest-request',
      value: newValueGuestRequest
    }).then((response) => {
      setIsGuestRequest(JSON.parse(response.data.data.value))
    }).catch((err) => {
      console.error(err)
    })
  }

  const loadRecordings = useCallback(async () => {
    try {
      let res = await api.post('/records', {
        cid: client_id,
        room_id: external_room,
        room_type: 'meet',
      });
      
      if (res.status === 200) {
        setRecordings(res.data.response);
      }
    } catch (e) {
      console.error(e);
    }
  }, [client_id, external_room]);

  async function handleClearChatMessages() {
    if (!room_id) {
      return;
    }

    const result = await Swal.fire({
      icon: 'warning',
      title: t(`pages.Studio.clearChatText`),
      html: t(`pages.Studio.swalClearChatText`),
      showCancelButton: true,
      confirmButtonText: t(`pages.Studio.swalShareVideoFileConfirmButton`),
      cancelButtonText: t(`pages.Studio.swalShareVideoFileCancelButton`),
    });

    if (result.isConfirmed) {
      const room = functions.setRoom('interno', room_id)
      dbFirebase.ref(`chat_messages_${room}`).remove()
      return;
    } else {
      return;
    }
  }

  useEffect(() => {
    if (user.is_admin) {
      api.put('/api/configs', {
        client_id,
        room_id,
        name: 'guest-request',
        value: false
      }).then((response) => {
        setIsGuestRequest(JSON.parse(response.data.data.value))
      }).catch((err) => {
        console.error(err)
      })
    }
  }, [])

  useEffect(() => {
    const buttonTexts = [
      { text: t(`pages.Studio.liveButtonStartingText1`), seconds: 0 },
      { text: t(`pages.Studio.liveButtonStartingText2`), seconds: 10 },
      { text: t(`pages.Studio.liveButtonStartingText3`), seconds: 20 },
      { text: t(`pages.Studio.liveButtonStartingText4`), seconds: 30 },
      { text: t(`pages.Studio.liveButtonStartingText5`), seconds: 40 },
      { text: t(`pages.Studio.liveButtonStartingText6`), seconds: 60 },
      { text: t(`pages.Studio.liveButtonStartingText7`), seconds: 120 },
      { text: t(`pages.Studio.liveButtonStartingText8`), seconds: 180 },
      { text: t(`pages.Studio.liveButtonStartingText9`), seconds: 250 },
      { text: t(`pages.Studio.liveButtonStartingText10`), seconds: 300 },
      { text: t(`pages.Studio.liveButtonStartingText11`), seconds: 350 },
      { text: t(`pages.Studio.liveButtonStartingText12`), seconds: 400 },
    ];
    
    if (isRecording) {
      const timeouts = buttonTexts.map((textData) => {
        return setTimeout(() => {
          setTextBtnLoadingRecording(textData.text);
        }, textData.seconds * 1000);
      });

      return () => {
        timeouts.forEach((timeout) => {
          clearTimeout(timeout);
        });
      }
    } else {
      setTextBtnLoadingRecording(buttonTexts[0].text);
    } 
  }, [isRecording, t])

  useEffect(() => {
    const isChatVisible = activeSidebarTab === 'chat';

    if (isChatVisible) {
      setUnreadChatMessagesCount(0);
    }
  }, [activeSidebarTab]);

  const { downlink, ms } = useInternetQuality()
  
  useEffect(() => {
    if (notShowQualityConnection) {
      return
    }

    if (!firstRender) {

      if (downlink === 0 && ms === 0) {
        if (messageQualityConnection === 'No connection') return;

        setColorConnection('#575656')
        setMessageQualityConnection('No connection')
      } else if (ms <= 150) {
        if (messageQualityConnection === 'Stable') return;

        setColorConnection('#36ce59')
        setMessageQualityConnection('Stable')
        
        toast.success(t(`pages.Meet.toastGoodInternet`), {
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          position: "top-right",
          className: 'good-connection-toast',
          autoClose: 3000
        })
      } else if (ms > 150 && ms < 300) {
        if (messageQualityConnection === 'Weak') return;

        setColorConnection('#ebb134')
        setMessageQualityConnection('Weak')
        toast.warning(t(`pages.Meet.toastWeakInternet`), {
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          position: "top-right",
          autoClose: 3000
        })
      } else if (ms >= 300) {
        if (messageQualityConnection === 'Bad') return;
        
        setColorConnection('#eb3434')
        setMessageQualityConnection('Bad')
        toast.error(t(`pages.Meet.toastBadInternet`), {
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          position: "top-right",
          autoClose: 3000
        })
      }

      return
    }

    setFirstRender(false)
  }, [downlink, ms, firstRender, messageQualityConnection, notShowQualityConnection, t])

  useEffect(() => {
    if (!user?.is_admin) {
      api.get('/api/configs/find', {
        params: {
          client_id,
          room_id,
          name: 'guest-request'
        }
      }).then((response) => {
        setIsGuestRequest(JSON.parse(response.data.data.value))
      }).catch((err) => {
        console.error(err)
      })
    }
  }, [])

  useEffect(() => {
    async function generateGuestLink() {
      try {    
        const response = await axios.post('https://weblive-api.4.events:3016/internal-link-sso-guest', {
          room_id: client.room._id,
        }, {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_SERVER_API_KEY}` 
          }
        })
        
        const guestLink = `${window.location.origin}/meet/${response.data.short_id}`;
        setGuestLink(guestLink);
      } catch (err) {
        console.log(err);
      }
    }

    generateGuestLink();
  }, [client.room._id, client.client_meeting, client.meeting_type]);

  useEffect(() => {
    if (user.is_admin) {
      loadRecordings();
    }
  }, [loadRecordings, user.is_admin]);

  return (
    <>
      <BlockUI blocking={blockUI} title={t(`pages.Meet.blockUIEnteringRoom`)} />

      <Modal show={showModal} backdrop="static" keyboard={false} contentClassName="overflow-hidden modal-dark">
        <PermissionsOverlay onShouldLoadDevices={loadDevices} darkMode />

        <Modal.Header>
          <Modal.Title className="col-12 font-weight-bold text-center lh-2">{t(`pages.Meet.modalDataTitle`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <span className="font-md text-center d-block">What's your name?</span> */}
          <span className="font-md text-center d-block">{t(`pages.Meet.whatsYourNameText`)}</span>
          <input
            type="text"
            className="form-control mb-0 mt-2 font-md"
            placeholder={t(`pages.Meet.modalPlaceholderName`)}
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                joinRoom()
              }
            }}
          />
          <span className="text-center mt-3 d-block font-md"><b>{t(`pages.Meet.modalTipText`)}: </b>{t(`pages.Meet.modalTipDescription`)}</span>

          <div className="row my-3">
            <div className="col-6 align-self-center">
              <div className="video-wrapper">
                <Video srcObject={selectedVideoStream} aspectRatio muted />
                <audio ref={configAudio} id="config-room-audio"></audio>
              </div>
            </div>
            <div className="col-6">
              <span className="ml-1">{t(`pages.Meet.modalSelectCameraText`)}</span>
              <select onChange={(e) => loadCam(e.target.value)} className="form-control form-control-sm text-center">
                {videoSources.map((source) => (
                  <option key={source.deviceId} value={source.deviceId} selected={source.selected}>{source.label}</option>
                ))}
              </select>
              <span className="ml-1">{t(`pages.Meet.modalSelectMicText`)}</span>
              <select onChange={(e) => loadMic(e.target.value)} className="form-control form-control-sm text-center">
                {audioSources.map((source) => (
                  <option key={source.deviceId} value={source.deviceId} selected={source.selected}>{source.label}</option>
                ))}
              </select>
              <button ref={playMicBtn} onClick={() => micController()} id="btn-test-mic" className="btn btn-primary text-center col-12 mt-2" type="play"><i className="fa fa-microphone mr-2"></i><span className="txt-test-mic">{t(`pages.Meet.testMicText`)}</span></button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          {isLoadingGuestRequest ? (
            <button className="btn btn-primary" type="button" disabled>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span style={{ marginLeft: '10px' }}>{t(`pages.Meet.modalWaitingForPermissionText`)}</span>
            </button>
          ): (
            <button className="btn btn-primary" onClick={() => joinRoom()}>
              <i className="fa fa-check"></i>{t(`pages.Meet.modalEnterRoomButton`)}
            </button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={showInviteModal} onHide={() => setShowInviteModal(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title className="font-weight-bold">{t(`pages.Meet.invitePeopleTitle`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className="text-center mt-2 mb-3 d-block font-md">{t(`pages.Meet.invitePeopleText`)}</span>
          <LinkShare link={guestLink} />
        </Modal.Body>
        {user.is_admin && (
          <Modal.Footer>
            <div className="w-100 d-flex flex-column align-items-center">
              <span>{t(`pages.Studio.modalInviteText`)}</span>
              <label className={`${styles.switch} mt-2`}>
                <input type="checkbox" onChange={handleSwitchGuestRequest} checked={isGuestRequest} />
                <span className={`${styles.slider} ${styles.round}`}></span>
              </label>
            </div>
            <div className='w-100 d-flex flex-column align-items-center mt-2'>
              <button className='btn btn-secondary' onClick={() => setShowInviteModal(false)}>{t(`pages.Meet.invitePeopleBtnClose`)}</button>
            </div>
          </Modal.Footer>
        )}
      </Modal>

      <Modal 
        show={isChangeLayoutModalOpen} 
        contentClassName="modal-dark" 
        onHide={() => setIsChangeLayoutModalOpen(false)} 
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="font-weight-bold">
            {t(`pages.Meet.changeLayoutModalTitle`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className="text-center mt-2 mb-3 d-block font-md">{t(`pages.Meet.changeLayoutModalText`)}</span>
          
          <LayoutsContainer>
            <LayoutItem 
              active={conferenceLayout === 'automatic'}
              onClick={() => setConferenceLayout('automatic')}
            >
              {conferenceLayout === 'automatic' ? (
                <LayoutPresentationOnImg />
              ) : (
                <LayoutPresentationOffImg />
              )}

              <div>
                <p>{t(`pages.Meet.layoutAutomaticText`)}</p>
                <span>{t(`pages.Meet.layoutAutomaticDescription`)}</span>
              </div>
            </LayoutItem>

            <LayoutItem 
              active={conferenceLayout === 'emphasis'}
              onClick={() => setConferenceLayout('emphasis')}
            >
              {conferenceLayout === 'emphasis' ? (
                <LayoutFullscreenOnImg />
              ) : (
                <LayoutFullscreenOffImg />
              )}

              <div>
                <p>{t(`pages.Meet.layoutEmphasisText`)}</p>
                <span>{t(`pages.Meet.layoutEmphasisDescription`)}</span>
              </div>
            </LayoutItem>
          </LayoutsContainer>
        </Modal.Body>
      </Modal>

      <Modal 
        show={showModalAcceptGuest} 
        onHide={handleRejectNewGuest} 
        backdrop="static"
        contentClassName="modal-dark" 
      >
        <Modal.Header closeButton>
          <Modal.Title className=" font-weight-bold ">{t('pages.Studio.modalRequestAccessTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>{newGuestName}</strong> {t('pages.Studio.modalRequestAccessSpan')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAcceptNewGuest}>
            {t('pages.Studio.modalRequestAccessAcceptButton')}
          </Button>
          <Button variant="secondary" onClick={handleRejectNewGuest}>
            {t('pages.Studio.modalRequestAccessRefuseButton')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Container>
        {isRecording && (
          <Badge>
            <div className="circle"></div>
            <span>REC</span>
          </Badge>
        )}

        <Main>
          <Conference 
            streams={streamers} 
            pinnedStreamer={pinnedStreamer} 
            layout={conferenceLayout}
            onSwapStreams={handleSwapStreams}
            fullscreen={isRender}
          />

          <Sidebar 
            activeTab={activeSidebarTab} 
            onClose={() => setActiveSidebarTab(null)} 
            pinnedStreamer={pinnedStreamer}
            onPinStreamerClick={handlePinStreamer}
            streamers={streamers} 
            onRemoveStreamer={handleRemoveStreamer}
            recordings={recordings}
            chatData={{
              display: displayName,
              room: room_id,
              cid: client_id,
              eid: event_id,
              firebase: dbFirebase,
              type: client_meeting == 'undefined' ? null : 'meet',
              guest: guest,
              type_room: "interno",
              is_admin: user.is_admin,
              producer_id: producerId,
              onNewMessage: handleNewChatMessage,
              disabled: isChatLocked,
              onClearMessages: handleClearChatMessages,
            }}
          />
        </Main>

        <BottomToolbar>
          <LeftPane>
            {/*user?.is_admin && (
              <ToolbarButton
                onClick={isRecording ? handleStopRecording : handleStartRecording}
                active={isRecording}
                disabled={isLoadingRecording}
                icon="fas fa-record-vinyl"
                text={
                  isLoadingRecording
                    ? textBtnLoadingRecording
                    : isRecording
                    ? t(`pages.Meet.stopRecordingText`)
                    : t(`pages.Meet.startRecordingText`)
                }
              />
            )*/}
          </LeftPane>

          <CenterPane>
            <ToolbarButton 
              onClick={toggleAudio}
              active={!audioOpen}
              icon={audioOpen ? 'fa fa-microphone' : 'fa fa-microphone-slash'}
              tooltip={audioOpen ? t(`pages.Meet.disableAudioTooltip`) : t(`pages.Meet.enableAudioTooltip`)}
            />

            <ToolbarButton 
              onClick={toggleVideo}
              active={!videoOpen}
              icon={videoOpen ? 'fa fa-video' : 'fa fa-video-slash'}
              tooltip={videoOpen ? t(`pages.Meet.disableVideoTooltip`) : t(`pages.Meet.enableVideoTooltip`)}
            />

            <ToolbarButton
              onClick={shareScreen}
              active={shareScreenController.shared}
              icon="fa fa-desktop"
              tooltip={shareScreenController.shared ? t(`pages.Meet.stopScreenSharingTooltip`) : t(`pages.Meet.startScreenSharingTooltip`)}
            />

            <ToolbarButton
              onClick={handleShareVideoFile}
              icon="fa fa-film"
              tooltip={t(`pages.Meet.shareVideoFileTooltip`)}
            />

            <ToolbarButton
              onClick={() => setShowInviteModal(true)}
              icon="fas fa-share-alt"
              tooltip={t(`pages.Meet.invitePeopleTooltip`)}
            />

            <Dropdown
              id="meet-settings-dropdown"
              dark
              toggle={
                <ToolbarButton 
                  icon="fas fa-ellipsis-v" 
                  tooltip={t(`pages.Meet.settingsTooltip`)}
                />
              }
            >
              <SettingsContainer>
                <SettingsItem onClick={() => setIsChangeLayoutModalOpen(true)}>
                  <i className="fas fa-th-large"></i>
                  <p>{t(`pages.Meet.settingsItemLayout`)}</p>
                </SettingsItem>
              </SettingsContainer>
            </Dropdown>
          </CenterPane>

          <RightPane>
            {user.is_admin && (
              <ToolbarButton
                secondary 
                onClick={() => handleActiveSidebarTabChange('recordings')} 
                active={activeSidebarTab === 'recordings'}
                icon="fas fa-video"
                tooltip={t(`pages.Meet.recordingsTooltip`)}
              />
            )} 

            {!isChatLocked && (
              <ToolbarButton
                secondary 
                onClick={() => handleActiveSidebarTabChange('chat')} 
                active={activeSidebarTab === 'chat'}
                icon="fas fa-comment"
                badge={unreadChatMessagesCount || null}
                badgeAlert
                tooltip={t(`pages.Meet.chatTooltip`)}
              />
            )}

            <ToolbarButton
              secondary 
              onClick={() => handleActiveSidebarTabChange('people')} 
              active={activeSidebarTab === 'people'}
              icon="fas fa-user"
              badge={streamers.length || null}
              tooltip={t(`pages.Meet.peopleTooltip`)}
            />
          </RightPane>
        </BottomToolbar>
      </Container>
    </>
  )
}
