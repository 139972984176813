import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: #1b1b1b;
`;

export const Badge = styled.div`
  align-self: flex-start;
  background-color: #cc281f;
  color: #fff;
  padding: 4px 12px;
  border-radius: 5px;
  margin-top: 16px;
  margin-left: 16px;
  margin-bottom: 4px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  .circle {
    background: #fff;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }
`;

export const Main = styled.div`
  flex: 1;
  height: 0;
  width: 100%;
  padding: 16px 16px 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const BottomToolbar = styled.div`
  width: 100%;
  min-height: 100px;
  flex-shrink: 0;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LeftPane = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  align-items: center;
  justify-content: left;
  text-align: center;

  @media (max-width: 660px) {
    flex: unset;
  }
  
  @media (max-width: 768px) {
    justify-content: center;
    margin-bottom: 8px;
  }
`;

export const CenterPane = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
  gap: 8px;
`;

export const RightPane = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  align-items: center;
  justify-content: right;
  text-align: center;

  @media (max-width: 660px) {
    flex: unset;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const SettingsContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  color: #f3f3f3;
`

export const SettingsItem = styled.li`
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  transition: background-color 0.1s ease-in-out;
  cursor: pointer;

  & + & {
    border-top: 1px solid #333;
  }

  &:hover {
    background: #333;
  }

  i {
    width: 36px;
  }

  p {
    margin: 0;
    font-weight: 500;
    white-space: nowrap;
  }
`

export const LayoutsContainer = styled.ul`
  list-style: none;
  margin-top: 24px;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

export const LayoutItem = styled.li`
  display: flex;
  align-items: center;
  transition: background-color 0.1s ease-in-out;
  padding: 18px 16px;
  margin: 0 -16px;
  cursor: pointer;
  background: ${props => props.active ? '#222' : 'transparent'};

  &:hover {
    background: ${props => props.active ? '#262626' : '#333'};
  }

  > svg {
    width: 120px;
  }

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
      font-weight: 500;
      white-space: nowrap;
    }

    span {
      font-size: 13px;
      color: #999;
    }
  }
`;