import React, { useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion'

import ScaleText from '../ScaleText';
import Video from '../Video';
import AspectRatio from '../AspectRatio';
import { PortalIn, PortalOut } from '../Portal';
import { VideoclipPlayer } from './VideoclipPlayer';

import {
	Container,
	Stage,
	Emphasis,
	Mosaic,
	Fullscreen,
	Overlay,
	Stream,
	Badge,
	Banner,
	OverlayImg,
	BackgroundImg,
	Logo,
} from './styles';

/* -- layouts -- */
// automatic
// solo
// mosaic
// group
// leader
// presentation
// overlay-top-left
// overlay-top-right
// overlay-bottom-left
// overlay-bottom-right
// fullscreen

export const defaultTransition = {
	ease: [0.4, 0, 0.2, 1],
	duration: 0.5,
}

export const defaultFadeInOutTransition = {
	initial: { 
		opacity: 0 
	},
	animate: { 
		opacity: 1 
	},
	transition: {
		...defaultTransition,
		duration: 0.3
	},
	exit: { 
		opacity: 0
	}
}

function BigScreen({
	streams,
	live,
	backgroundUrl,
	logoUrl,
	overlayUrl,
	videoclipUrl,
	onVideoclipEnded,
	showNames = true,
	layout = 'solo',
	banner,
}) {
	const { t } = useTranslation();

	const { mosaic, emphasis, overlay, fullscreen } = useMemo(() => {
		const thereIsScreen = streams.some(stream => stream.typeVideo === 'screen');

		return streams.reduce((bigScreen, stream) => {
				/* SOLO */
				if (layout === 'solo') {
					if (bigScreen.fullscreen.length === 0 && stream.typeVideo === 'cam') {
						bigScreen.fullscreen.push(stream);
					}
				}

				/* MOSAIC */
				if (layout === 'mosaic') {
					if (bigScreen.mosaic.length < 9) {
						bigScreen.mosaic.push(stream);
					}
				}

				/* GROUP */
				if (layout === 'group') {
					if (stream.typeVideo === 'cam') {
						if (bigScreen.mosaic.length < 9) {
							bigScreen.mosaic.push(stream);
						}
					}
				}

				/* LEADER */
				if (layout === 'leader') {
					if (stream.typeVideo === 'cam') {
						if (bigScreen.emphasis.length === 0) {
							bigScreen.emphasis.push(stream);
						} else {
							if (bigScreen.mosaic.length < 4) {
								bigScreen.mosaic.push(stream);
							}
						}
					}
				}

				/* PRESENTATION */
				if (layout === 'presentation') {
					if (stream.typeVideo === 'cam') {
						const camLimit = bigScreen.emphasis.length > 0 ? 4 : 9;

						if (bigScreen.mosaic.length < camLimit) {
							bigScreen.mosaic.push(stream);
						}
					} else {
						if (bigScreen.emphasis.length === 0) {
							bigScreen.emphasis.push(stream);
						}
					}
				}

				/* OVERLAY */
				if (layout.startsWith('overlay')) {
					if (thereIsScreen) {
						if (stream.typeVideo === 'screen') {
							if (bigScreen.fullscreen.length === 0) {
								bigScreen.fullscreen.push(stream);
							}
						} else {
							if (bigScreen.overlay.length < 4) {
								bigScreen.overlay.push(stream);
							}
						}
					} else {
						if (bigScreen.mosaic.length < 9) {
							bigScreen.mosaic.push(stream);
						}
					}
				}

				/* FULLSCREEN */
				if (layout === 'fullscreen') {
					if (bigScreen.fullscreen.length === 0 && stream.typeVideo === 'screen') {
						bigScreen.fullscreen.push(stream);
					}
				}

				/* AUTOMATIC */
				if (layout === 'automatic') {
					if (stream.typeVideo === 'cam') {
						const camLimit = bigScreen.emphasis.length > 0 ? 4 : 9;

						if (bigScreen.mosaic.length < camLimit) {
							bigScreen.mosaic.push(stream);
						}
					} else {
						if (bigScreen.emphasis.length === 0) {
							bigScreen.emphasis.push(stream);
						}
					}
				}

				return bigScreen;
			}, { mosaic: [], emphasis: [], overlay: [], fullscreen: [] }
		);
	}, [streams, layout]);

	// merge bigScreen's arrays into one array
	const bigScreen = useMemo(() => {
		const mapFunction = (location) => (stream) => ({ stream, location }); 

		return [
			...emphasis.map(mapFunction('emphasis')),
			...mosaic.map(mapFunction('mosaic')),
			...fullscreen.map(mapFunction('fullscreen')),
			...overlay.map(mapFunction('overlay')),
		]
	}, [emphasis, mosaic, fullscreen, overlay]);

	return (
		<AspectRatio>
			<Container>
				<AnimatePresence>
					{backgroundUrl && (
						<BackgroundImg 
							key={backgroundUrl}
							src={backgroundUrl} 
							{...defaultFadeInOutTransition}
						/>
					)}
				</AnimatePresence>

				<ScaleText>
					<Badge red={live}>
						{live
							? t(`components.BigScreen.liveText`)
							: t(`components.BigScreen.previewText`)}
					</Badge>
				</ScaleText>

				<AnimateSharedLayout>
					<Stage>
						{bigScreen.map(({ stream, location }) => {
							let background, videoElement, aspectRatio, displayName;

							if (location === 'mosaic' || location === 'overlay') {
								background = 'solid';
								aspectRatio = true;
								videoElement = null;
							} else if (location === 'fullscreen' || location === 'emphasis') {
								background = stream.typeVideo === 'cam' ? 'solid' : 'transparent';
								aspectRatio = stream.typeVideo === 'cam' || Boolean(stream.videoElement);
								videoElement = stream.videoElement;
							}

							displayName =
								stream.typeVideo === 'cam' && showNames ? stream.display : null;

							return (
								<PortalIn 
									key={stream.id} 
									id={`${stream.id}-bigscreen-stream`}
									recalculate={bigScreen}
								>
									<Stream 
										className="stream"
										initial={{ opacity: 0 }}
										animate={{ 
											opacity: 1, 
											transition: {
												...defaultTransition, 
												delay: 0.3,
											} 
										}}
										transition={defaultTransition}
										exit={{ opacity: 0 }}
										layout
									>
										<Video
											srcObject={stream.srcObject}
											background={background}
											videoElement={videoElement}
											aspectRatio={aspectRatio}
											displayName={displayName}
										/>
									</Stream>
								</PortalIn>
							);
						})}

						{emphasis.length > 0 && (
							<Emphasis className="emphasis">
								{emphasis.map(stream => (
									<PortalOut key={stream.id} id={`${stream.id}-bigscreen-stream`} />
								))}
							</Emphasis>
						)}

						{mosaic.length > 0 && (
							<Mosaic className="mosaic">
								{mosaic.map(stream => (
									<PortalOut key={stream.id} id={`${stream.id}-bigscreen-stream`} />
								))}
							</Mosaic>
						)}

						{fullscreen.length > 0 && (
							<Fullscreen className="fullscreen">
								{fullscreen.map(stream => (
									<PortalOut key={stream.id} id={`${stream.id}-bigscreen-stream`} />
								))}
							</Fullscreen>
						)}

						{overlay.length > 0 && (
							<Overlay position={layout.replace('overlay-', '')} className="overlay">
								{overlay.map(stream => (
									<PortalOut key={stream.id} id={`${stream.id}-bigscreen-stream`} />
								))}
							</Overlay>
						)}
					</Stage>
				</AnimateSharedLayout>

				<AnimatePresence>
					{banner?.text && (
						<motion.div 
							key={banner.text}
							{...defaultFadeInOutTransition}
						>
							<ScaleText>
								<Banner size={banner.text.length}>
									{banner?.title && <p>{banner.title}</p>}
									<span>{banner.text}</span>
								</Banner>
							</ScaleText>
						</motion.div>
					)}
				</AnimatePresence>

				<AnimatePresence>
					{overlayUrl && (
						<OverlayImg 
							key={overlayUrl}
							src={overlayUrl} 
							{...defaultFadeInOutTransition}
						/>
					)}
				</AnimatePresence>

				<AnimatePresence>
					{logoUrl && (
						<Logo 
							key={logoUrl}
							src={logoUrl} 
							{...defaultFadeInOutTransition}
						/>
					)}
				</AnimatePresence>

				<AnimatePresence>
					{videoclipUrl && (
						<VideoclipPlayer 
							key={videoclipUrl} 
							src={videoclipUrl} 
							onEnded={onVideoclipEnded} 
						/>
					)}
				</AnimatePresence>
			</Container>
		</AspectRatio>
	);
}

export default memo(BigScreen);