import React from 'react'
import { useTranslation } from 'react-i18next';

import USFlagImg from '../../../../assets/images/flags/us_flag.svg';
import BRFlagImg from '../../../../assets/images/flags/br_flag.svg';

import { Dropdown } from '../../../../components/Dropdown';

export function LanguagesDropdown({ toggle }) {
  const { i18n } = useTranslation();

  return (
    <Dropdown
      id="header-language-dropdown"
      toggle={toggle}
    >
      <div className="languages-container">
        <button
          type="button"
          onClick={() => i18n.changeLanguage('pt-BR')}
        >
          <img src={BRFlagImg} alt="Bandeira do Brasil" />
          <span>Português</span>
        </button>

        <button
          type="button"
          onClick={() => i18n.changeLanguage('en-US')}
        >
          <img src={USFlagImg} alt="Bandeira dos Estados Unidos" />
          <span>English</span>
        </button>
      </div>
    </Dropdown>
  )
}
