import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
`;

export const SpinnerContainer = styled.div`
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    height: 100px;
    width: 100px;
  }
`;