import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth } from '../context/Auth';
import api from '../services/api';

const ClientLogoContext = createContext({});

export function ClientLogoProvider ({ children }) {
  const { user } = useAuth();

  const [clientLogo, setClientLogo] = useState(() => {
    const clientLogoExists = localStorage.getItem('clientLogo');
    
    if (!clientLogoExists) {
      return { url: '' };
    }

    const parsedClientLogo = JSON.parse(clientLogoExists);

    return parsedClientLogo;
  });

  useEffect(() => {
    if (!user?._id) return;
    api.get('client-logo', {
      params: {
        client_id: user._id,
        type: 'clientLogo'
      }
    }).then(response => {
      if (!response.data) {
        setClientLogo({ url: '' });
        return localStorage.removeItem('clientLogo');
      }

      const localStorageLogoUrl = JSON.parse(localStorage.getItem('clientLogo'))?.url

      if (response.data.url === localStorageLogoUrl) {
        return;
      } else {
        localStorage.setItem('clientLogo', JSON.stringify(response.data));
        return setClientLogo(response.data);
      }
    }).catch((err) => {
      return console.error(err);
    });
  }, [user?._id])

  async function onUploadClientLogo({ id, url, key, client_id, type }) {
    const data = { _id: id, url, key, client_id, type };
    localStorage.setItem('clientLogo', JSON.stringify(data));
    setClientLogo(data);
  }

  async function deleteClientLogo(data) {
    try {
      const logoUrl = localStorage.getItem('logoUrl');

      if (logoUrl === clientLogo.url) {
        localStorage.setItem('logoUrl', '');
        localStorage.setItem('logoActive', '');
      }

      const response = await api.delete('/file/remove', { data: data });

      if (response.status !== 200) {
        return 'error';
      }

      setClientLogo({ url: '' });
      localStorage.removeItem('clientLogo');

      return 'deleted';
    } catch (err) {
      return 'error';
    }
  }

  return (
    <ClientLogoContext.Provider
      value={{ clientLogo, onUploadClientLogo, deleteClientLogo }}
    >
      {children}
    </ClientLogoContext.Provider>
  );
};

export function useClientLogo() {
  const context = useContext(ClientLogoContext);
  return context;
}
