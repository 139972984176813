import { useEffect, useState } from 'react'
import Cards from 'react-credit-cards';
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';

import api from '../../../services/api'
import { getAuthenticatedUser } from '../../../services/auth'

import CreditCardModal from '../CreditCardModal'
import { Container, Button } from './styles'
import { useTranslation } from 'react-i18next';

export default function CreditCard() {
  const { t } = useTranslation();
  const user = getAuthenticatedUser();

  const [card, setCard] = useState(null);
  const [loadingCard, setLoadingCard] = useState(true);
  const [isEditingCard, setIsEditingCard] = useState(false);

  function showCardData(data) {
    let numberMock;

    switch (data.brand) {
      case 'American Express':
        numberMock = '**** ****** *****';
        break;
      case 'Diners Club':
        numberMock = '**** ****** ****';
        break;
      default:
        numberMock = '**** **** **** ****';
    }

    const number = numberMock.slice(0, numberMock.length - 4) + data.last4;
    const expiry = `${data.exp_month}/${data.exp_year.toString().slice(2)}`;

    // parse stripe brands to react-credit-cards brands
    const brandParse = {
      'Visa': 'visa',
      'MasterCard': 'mastercard',
      'American Express': 'amex',
      'Discover': 'discover',
      'Diners Club': 'diners',
      'JCB': 'jcb',
      'UnionPay': 'unionpay',
    }

    setCard({
      name: data.name,
      brand: brandParse[data.brand],
      number,
      expiry,
    })
  }

  useEffect(() => {
    async function fetchCard() {
      try {
        const response = await api.get(`/users/${user._id}/card`);
        if (response.data.card) {
          showCardData(response.data.card);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingCard(false);
      }
    }

    fetchCard()
  }, [user._id]);


  function handleOpenEditCard() {
    setIsEditingCard(true);
  }

  function handleCloseEditCard() {
    setIsEditingCard(false)
  }

  async function handleCardSaved(data) {
    toast.success(t(`pages.Billing.CreditCard.toastSuccess`), {
      position: "bottom-center",
      autoClose: 5000,
    });

    showCardData(data);
  }

  if (loadingCard) {
    return (
      <Container>
        <Spinner animation="border" />
      </Container>
    )
  }

  return (
    <>
      <Container>
        {card ? (
          <Cards
            expiry={card.expiry}
            name={card.name}
            number={card.number}
            issuer={card.brand}
            cvc="****"
            preview={true}
          />
        ) : (
          <p>{t(`pages.Billing.CreditCard.noCreditCard`)}</p>
        )}

        <Button className="btn-primary" type="button" onClick={handleOpenEditCard}>{card ? t(`pages.Billing.CreditCard.openButtonEditText`) : t(`pages.Billing.CreditCard.openButtonAddText`)} {t(`pages.Billing.CreditCard.cardText`)}</Button>
      </Container>

      <CreditCardModal
        open={isEditingCard}
        onCardSaved={handleCardSaved}
        onClose={handleCloseEditCard}
      />
    </>
  )
}