import React, { useCallback, useMemo } from "react"
import { useDropzone } from "react-dropzone"
import Swal from 'sweetalert2'
import api from '../../services/api'
import { useClientLogo } from '../../hooks/useClientLogo'
import { useTranslation } from "react-i18next"

export const acceptStyle = {
    color: '#107bd3',
    borderColor: '#2196F3'
};

export const rejectStyle = {
    color: '#e40632',
    borderColor: '#ff1744'
};

function Upload(props) {
    const { t } = useTranslation()
    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '7px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#dfdfdf',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#696969',
        textAlign: 'center',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        cursor: 'pointer',
        minHeight: props.minHeight,
        fontSize: '12pt',
        ...props.dropzoneStyle,
    };

    const { clientLogo, onUploadClientLogo } = useClientLogo()

    const onDrop = useCallback(async (files) => {
        if (props.onRequestUpload) {
            const canUpload = await props.onRequestUpload();
            
            if (!canUpload) {
                return false
            }
        }

        files.map(file => {
            const maxSize = props.maxSize || 2097152;

            if (file.size > maxSize) {
                // Swal.fire("Oops!", "The image is too big! Maximum size allowed: 2 MB.", 'warning')
                Swal.fire("Oops!", `${t(`components.UploadDropzone.swalWarningText`)} ${maxSize / 1024 / 1024} MB.`, 'warning')
                return false
            }

            if (props.type === 'clientLogo') {
                if (clientLogo?.url) {
                    let data = {
                        _id: clientLogo._id,
                        key: clientLogo.key
                    }
                    api.delete('/file/remove', { data: data }).then(() => {
                        localStorage.removeItem('clientLogo')
                    }).catch(err => {
                        console.log(err)
                    })
                }
            }

            props.onDrop()

            let data = new FormData();

            data.append('file', file, file.name)
            data.append('client_id', props.cid)
            data.append('event_id', props.eid)
            data.append('room_id', props.room)
            data.append('type', props.type)

            api.post("/upload", data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: e => {
                    //const progress = parseInt(Math.round((e.loaded * 100) / e.total));
                }
            }).then(response => {
                if (props.type === 'clientLogo') {
                    const { _id, url, key, client_id, type } = response.data;
                    onUploadClientLogo({ id: _id, url, key, client_id, type });
                }
                props.onUpload()
            }).catch((e) => {
                props.onUpload()
                console.log(e)
            })
        })

    })

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        open
    } = useDropzone({
        accept: props.type === 'videoclip' ? ['video/mp4', 'video/webm'] : ["image/jpeg", "image/pjpeg", "image/png", "image/gif"],
        maxFiles: 1,
        onDrop,
        noClick: true,
        noKeyboard: true,
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragReject,
        isDragAccept
    ])

    const renderDragMessage = useCallback(() => {

        if (!isDragActive) {
            if (props.type === 'videoclip') {
                return t(`components.UploadDropzone.defaultMessageVideo`)
            } else {
                return t(`components.UploadDropzone.defaultMessage`)
            }
        }
        
        if (isDragReject) {
            if (props.type === 'videoclip') {
                return <div>{t(`components.UploadDropzone.unsupportedFileVideo`)}</div>
            } else {
                return <div>{t(`components.UploadDropzone.unsupportedFile`)}</div>
            }
        }

        if (isDragAccept) {
            return <div className="mt-3">{t(`components.UploadDropzone.acceptFile`)}</div>
        }

    }, [
        isDragActive,
        isDragReject,
        isDragAccept
    ])

    async function handleDropzoneClick() {
        if (props.onRequestUpload) {
            const canUpload = await props.onRequestUpload();
            
            if (!canUpload) {
                return false
            }
        }
        
        open();
    }

    return (
        <React.StrictMode>
            <div {...getRootProps({ style })} onClick={handleDropzoneClick}>
                {props.showLock && <i className="fa fa-lock"></i>}
                <input {...getInputProps()} />
                {renderDragMessage()}
            </div>
        </React.StrictMode>
    )

}

export default Upload;