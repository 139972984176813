import { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useTranslation } from 'react-i18next';
import { TiPinOutline, TiPin, TiDownload } from 'react-icons/ti'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { formatDistanceToNowStrict, differenceInHours, format } from 'date-fns';
import { ptBR, enUS } from 'date-fns/locale';

import { getPersonColor, setPersonColor } from '../../../../utils/colors'
import { formatBytes } from '../../../../utils/formats';
import { useAuth } from '../../../../context/Auth';
import Chat from '../../../../components/Chat';

import { 
  Container, 
  PeopleContainer, 
  PersonList, 
  PersonItem, 
  RecordingList, 
  RecordingItem,
  NoRecordings 
} from './styles'

const tabs = {
  people: {
    title: 'pages.Meet.sidebarPeopleTitle',
    component: (props) => <PeopleTab {...props} />,
  },
  chat: {
    title: 'pages.Meet.sidebarChatTitle',
    component: (props) => <ChatTab {...props} />,
  },
  recordings: {
    title: 'pages.Meet.sidebarRecordingsTitle',
    component: (props) => <RecordingsTab {...props} />,
  }
}

export function Sidebar({ activeTab, onClose, ...rest }) {
  const { t } = useTranslation();

  const isVisible = (activeTab && tabs[activeTab]);

  return (
    <Container
      initial="hidden"
      animate={isVisible ? 'visible' : 'hidden'}
      variants={sidebarVariants}
      transition={transition}
    >
      <header>
        <h2>{t(tabs[activeTab]?.title)}</h2>

        <button onClick={onClose}>
          <i className="fa fa-times btn-initial-icon"></i>
        </button>
      </header>

      {Object.entries(tabs).map(([key, { component }]) => {
        return (
          <div 
            key={key} 
            style={{ display: key !== activeTab ? 'none' : undefined }}
          >
            {component({ ...rest })}
          </div>
        )
      })}
    </Container>
  )
}

function RecordingsTab({ 
  recordings,
}) {
  const { t, i18n } = useTranslation();
  const [hoveringItem, setHoveringItem] = useState(null);

  function handleDownload(url) {
    window.open(url, '_blank');
  }

  return (
    <PeopleContainer>
      <PerfectScrollbar>
        {recordings.length ? (
          <RecordingList>
            {recordings.map((recording) => {
              const locale = i18n.language === 'pt-BR' ? ptBR : enUS;

              const difference = 
                differenceInHours(new Date(), new Date(recording.created_at));

              let dateFormatted;

              if (difference > 24) {
                dateFormatted = format(
                  new Date(recording.created_at),
                  "dd/MM/yyyy 'às' HH:mm",
                  {
                    locale,
                  }
                );
              } else {
                dateFormatted = formatDistanceToNowStrict(
                  new Date(recording.created_at),
                  {
                    addSuffix: true,
                    locale,
                  }
                );
              }

              const sizeFormatted  = formatBytes(recording.size);

              return (
                <RecordingItem 
                  key={recording.id} 
                  onMouseEnter={() => setHoveringItem(recording.id)}
                  onMouseLeave={() => setHoveringItem(null)}
                >
                  <video controls={hoveringItem === recording.id}>
                    <source src={recording.url} type="video/webm" />
                  </video>

                  <div className="container">
                    <div className="content">
                      <p>{dateFormatted}</p>
                      <span>Tamanho: {sizeFormatted}</span>
                    </div>

                    <div className="buttons">
                      <OverlayTrigger 
                        delay={{ show: 500 }}
                        overlay={<Tooltip>{t(`pages.Meet.sidebarRecordingsDownload`)}</Tooltip>}
                      >
                        <button onClick={() => handleDownload(recording.url)}>
                          <TiDownload />
                        </button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </RecordingItem>
              )
            })}
          </RecordingList>
        ) : (
          <NoRecordings>{t(`pages.Meet.noRecordingsInRoom`)}</NoRecordings>
        )}
      </PerfectScrollbar>
    </PeopleContainer>
  )
}

function PeopleTab({ 
  streamers, 
  onRemoveStreamer, 
  pinnedStreamer, 
  onPinStreamerClick
}) {
	const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <PeopleContainer>
      <PerfectScrollbar>
        <PersonList>
          {streamers.map((streamer) => {
            let descr = '';

            if (streamer.type === "admin") {
              descr = t(`pages.Meet.sidebarPeopleDescrYou`)
            } else if (streamer.file) {
              descr = t(`pages.Meet.sidebarPeopleDescrFile`)
            } else if (streamer.type === "screen") {
              descr = t(`pages.Meet.sidebarPeopleDescrScreen`)
            } else if (streamer.type === "guest" && streamer.typeVideo === 'screen') {
              descr = t(`pages.Meet.sidebarPeopleDescrPresentation`)
            } else {
              descr = t(`pages.Meet.sidebarPeopleDescrGuest`)
            }

            const screen = 
              streamer.file ||
              streamer.type === "screen" || 
              (streamer.type === "guest" && streamer.typeVideo === 'screen')

            const initials =  
              streamer.display
                .split(' ')
                .map(name => name.charAt(0))
                .slice(0, 2)
                .join('')
                .toUpperCase();

            const [colorId] = 
              streamer.group.includes('_file') 
              ? streamer.group.split('_file')
              : streamer.group.split('_stream');

            const color = getPersonColor(colorId || streamer.id);
            setPersonColor(streamer.id, color);

						const showRemoveButton =
							streamer.file ||
							(user.is_admin &&
								streamer.type !== 'admin' &&
								streamer.typeVideo === 'cam');

            return (
              <PersonItem key={streamer.id} color={color}>
                <p className="initials">
                  {screen 
                    ? <i className="fas fa-desktop"></i>
                    : initials
                  }
                </p>

                <div className="content">
                  <p>{streamer.display}</p>
                  <span>{descr}</span>
                </div>

                <div className="buttons">
                  <OverlayTrigger 
                    delay={{ show: 500 }}
                    overlay={
                      <Tooltip>
                        {pinnedStreamer === streamer.id ? (
                          t(`pages.Meet.sidebarPeopleUnpin`)
                        ) : (
                          t(`pages.Meet.sidebarPeoplePin`)
                        )}
                      </Tooltip>
                    }
                  >
                    <button onClick={() => onPinStreamerClick(streamer.id)}>
                      {pinnedStreamer === streamer.id ? (
                        <TiPin />
                      ) : (
                        <TiPinOutline />
                      )}
                    </button>
                  </OverlayTrigger>

                  {showRemoveButton && (
									  <OverlayTrigger
                      delay={{ show: 500 }}
                      overlay={
                        <Tooltip>
                          {streamer.file
                            ? t(`pages.Meet.sidebarPeopleRemoveVideo`)
                            : t(`pages.Meet.sidebarPeopleRemoveGuest`)}
                        </Tooltip>
                      }
                    >
                      <button onClick={() => onRemoveStreamer(streamer)}>
                        <i className="fa fa-times"></i>
                      </button>
                    </OverlayTrigger>
                  )}
                </div>
              </PersonItem>
            )
          })}
        </PersonList>
      </PerfectScrollbar>
    </PeopleContainer>
  )
}

function ChatTab({ chatData }) {
  return (
    <Chat {...chatData} darkMode />
  )
}

const sidebarVariants = {
  hidden: {
    opacity: 0,
    x: '100%',
    width: 0,
    marginLeft: 0,
  },
  visible: {
    opacity: 1,
    x: 0,
    width: '360px',
    marginLeft: '16px',
  },
}

const transition = {
  duration: 0.2,
}