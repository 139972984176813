import React from "react";
import { useHistory } from "react-router-dom";
import { FaListAlt } from "react-icons/fa";

import "./style.css";

export default function CustomRTMP({
  buttonText,
  appId,
  className,
  redirectURI,
  scopes,
  openModal,
}) {
  const history = useHistory();

  function handleClickCustomRTMP() {
    openModal();
  }

  return (
    <div>
      <button
        onClick={handleClickCustomRTMP}
        className={`btn_rtmp ${className}`}
      >
        <FaListAlt />
        <strong>{buttonText}</strong>
      </button>
    </div>
  );
}
