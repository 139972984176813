import React, { useEffect, useState, useContext } from "react";

import Sidebar from "../../components/Layout/SideBar/SideBar";
import { Header } from "../../components/Layout/Header";

import api from "../../services/api";

import { useAuth } from "../../context/Auth";

import "./styles.js";
import { useTranslation } from "react-i18next";


export default function Records() {

    const { user } = useAuth();
    const { t } = useTranslation()

    const [myRecords, setMyRecords] = useState([])
    const [loadingRecords, setLoadingRecords] = useState(true)

    async function getRecords() {
        try {
            let res = await api.post('/records', {
                cid: user.client_type === '4web' ? user._id : user.vp_client_id
            })

            if (res.status === 200) {
                let { response } = res.data

                setLoadingRecords(false)
                setMyRecords(response)
            }
        } catch (e) {
            console.error(e)
        }
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    function getTimeDiference(initial, final) {

        let diff = Math.abs(new Date(final) - new Date(initial)) / 36e5

        return diff

    }

    function getDateTimeFormated(date) {

        let d = new Date(date);

        // Guarda cada pedaço em uma variável
        let dia = d.getDate();
        let mes = d.getMonth() + 1;
        let ano = d.getFullYear();
        let hora = d.getHours();
        let min = d.getMinutes();
        let seg = d.getSeconds();
        let dias = new Array('Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado');

        return `${dia > 9 ? dia : '0' + dia}/${mes > 9 ? mes : '0' + mes}/${ano} as ${hora}:${min}:${seg}`
    }

    useEffect(() => {
        getRecords()
    }, [])

    return (
        <>
            {/* <Sidebar page="broadcast" /> */}
            <Header page="broadcast" />
            <div className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <h3>{t(`pages.Records.title`)}</h3>
                        </div>
                    </div>
                    {myRecords.length === 0 ? (
                        <p>Nenhuma gravação até o momento</p>
                    ) : (
                        myRecords.map((record) => {
                            return (
                                <div key={record._id} className="iq-card mb-4 p-3">
                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', gridTemplateRows: '1fr', gridColumnGap: '20px', gridRowGap: '0px' }}>
                                        <div className="">
                                            <div className="px-2 pb-1 pt-2" style={{ backgroundColor: "#ebebeb", borderRadius: '5px', width: '300px' }}>
                                                <video controls src={record.url}></video>
                                            </div>
                                        </div>
                                        <div className="">
                                            <p className="font-weight-bold mb-1 mt-4">{getDateTimeFormated(record.created_at)}</p>
                                            {record.room_data &&
                                                <span className="d-block mb-2"><i>{record.room_data.title}</i></span>
                                            }
                                            <span className="d-block mb-1">Tamanho: {formatBytes(record.size)}</span>
                                            <a href={record.url} className="btn btn-primary mt-3">Baixar agora</a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    )}
                </div>
            </div>
        </>
    );
}
