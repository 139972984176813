import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { Container } from './styles'

export function ToolbarButton({ 
  onClick, 
  active,
  disabled,
  secondary, 
  badge, 
  badgeAlert, 
  tooltip,
  icon,
  text,
  ...rest
}) {
  const button = (
    <Container 
      onClick={onClick} 
      active={active} 
      disabled={disabled}
      secondary={secondary} 
      hasText={Boolean(text)}
      {...rest}
    >
      {icon && <i className={`${icon} btn-initial-icon`} />}
      {text && <span>{text}</span>}
      {badge && (
        <div className={`badge ${badgeAlert ? 'badge-alert' : ''}`}>
          {badge}
        </div>
      )}
    </Container>
  )

  return tooltip ? (
    <OverlayTrigger rootClose delay={{ show: 500 }} overlay={<Tooltip>{tooltip}</Tooltip>}>
      {button}
    </OverlayTrigger>
  ) : button
}