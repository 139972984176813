import { Wrapper, Content } from './styles'

export default function AspectRatio({ ratio = 9/16, children }) {
  return (
    <Wrapper ratio={ratio}>
      <Content ratio={ratio}>
        {children}
      </Content>
    </Wrapper>
  )
}