import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import io from 'socket.io-client'
import api from '../../services/api'
import { useAuth } from '../../context/Auth'

window.socket = null;
window.internalSocket = null;

export default function AutoLogin(props){
   
   const [loading, setLoading] = useState(true)
   const [url,setUrl] = useState('')
   const { setUser } = useAuth()
   
   const { code } = useParams()
   
    useEffect( () =>{
        
        async function verify(){
            try{
              let response = await api.post(`/login/${code}`)
              
              if(response){
                let { data, token } = response.data

                // if(data.room_type == 'meet' && window.location.hostname == 'app.4web.live' && data.client_meeting !== "undefined"){

                //   window.location.href = `https://meet.4web.live/auto-login/${code}`

                //   return false
                // }
               
                setUrl(data.room_type)
                let token_auth = await Buffer.from(JSON.stringify(data)).toString('base64')
                localStorage.setItem('auth-user', token_auth)
                localStorage.setItem('auth-token', token)
                setUser(data);

                if(data.room_type.toString() === 'studio'){

                  if(process.env.REACT_APP_ENV === 'local'){
                    //window.socket = io('wss://weblive-dev.4.events:3016', {cors: { origin: window.location.origin, credentials: false}})
                    window.socket = io(data.mediaServer, {cors: { origin: window.location.origin, credentials: false}})
                    //window.internalSocket = io(`wss://weblive-dev.4.events:3016/internal`, {cors: { origin: window.location.origin, credentials: false}})
                  } else {
                    window.socket = io(data.mediaServer, {cors: { origin: window.location.origin, credentials: false}})
                    //window.internalSocket = io(`${data.mediaServer}/internal`, {cors: { origin: window.location.origin, credentials: false}})
                  }
              
                } else {
              
                  if(process.env.REACT_APP_ENV !== 'local'){
                    //window.socket = io('wss://weblive-dev.4.events:3016', {cors: { origin: window.location.origin, credentials: false}})
                    window.socket = io(data.mediaServer, {cors: { origin: window.location.origin, credentials: false}})
                  } else {
                    window.socket = io(data.mediaServer, {cors: { origin: window.location.origin, credentials: false}})
                  }
              
                }

                setLoading(false)
                
              }
            
            }catch(err){
                console.log(err)
                return false
            }
           
        }
        verify()
        
    },[])
    
    return !loading  ? (<Redirect to={{pathname: `../${url}`,state:{from: props.location}}}/> ): ( <div></div> )
    
}
