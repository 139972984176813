import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'
import enUS from './locales/en/en-us.json'
import ptBR from './locales/pt/pt-br.json'

const resources = {
  en: {
    translation: enUS,
  },
  pt: {
    translation: ptBR,
  },
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    // lng: "pt",
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
