import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import SideBar from '../../components/Layout/SideBar/SideBar'
import { Header } from '../../components/Layout/Header';
import Broadcasts from '../../components/Broadcasts'
import iconGoogle from '../../assets/images/icon/google.png'
import logo from '../../assets/images/logo/logo-white.png'
import logo2 from '../../assets/images/logo/n1-mini.png'
import api from '../../services/api'
import { token_decode } from '../../services/auth'
import './style.css'
import { useTranslation } from 'react-i18next'

export default function Bradcast() {
    const { t } = useTranslation()
    const [urlAuthGoogle, setUrlAuthGoogle] = useState(null)
    const [broadcasts, setBroadcasts] = useState([])
    const [loadingBroadcasts, setLoadingBroadcasts] = useState(true)
    const [accountType, setAccountType] = useState('')
    const [profileImage, setProfileImage] = useState('')
    const client = token_decode(localStorage.getItem('auth-user'))

    let token = localStorage.getItem('goauth2_token')

    useEffect(() => {
        let account = localStorage.getItem('account')
        let oauth2 = token !== null ? token_decode(token) : { user: { name: '4web', picture: logo2 } }
        async function getBroadcasts() {
            try {
                let res = await api.post('/broadcasts', {
                    cid: client.client_type === '4web' ? client._id : client.vp_client_id,
                    event_id: client.client_type === '4web' ? '1' : client.vp_event_id
                })

                if (res.status === 200) {
                    let { response } = res.data


                    setLoadingBroadcasts(false)
                    setBroadcasts(response)

                }
            } catch (e) {
                console.error(e)
            }
        }
        async function getGoogleUrl() {
            try {
                let res = await api.get('/goauth2')

                if (res.status === 200) {
                    const { url } = res.data

                    setUrlAuthGoogle(url)
                }
            } catch (e) {
                console.error(e)
            }
        }
        getGoogleUrl()
        getBroadcasts()

        if (account === '4web') {
            setAccountType('4web')
            setProfileImage(logo2)
        } else if (account === null) {
            setAccountType('4web')
            setProfileImage(logo2)
        } else {
            setAccountType(oauth2.user.name)
            setProfileImage(oauth2.user.picture)
            if (oauth2.user.name === '4web') localStorage.setItem('account', '4web')
        }
    }, [])



    return (

        <>
            {window.location.hostname == 'meet.4web.live' && (
                <Redirect to="meet" />
            )}
            {/* <SideBar page="broadcast" /> */}
            <Header page="broadcast" />
            <div className="content-page">
                <div className="container-fluid">
                    <div className="row mb-3 ">
                        <div className="col-12">
                            <div className="justify-content-end iq-card p-2 d-none">
                                <a href={client.room_type == 'studio' ? '/studio' : '/new-room?type=studio'} className="btn btn-outline-primary p-2 mr-2"><b>Access Studio</b> <i style={{ fontSize: 20 }} className="ri-base-station-line"></i></a>
                                <a href={client.room_type == 'meet' ? '/meet' : '/new-room?type=meet'} className="btn btn-outline-primary p-2 mr-2 "><b>Access Meet</b> <i style={{ fontSize: 20 }} className="ri-vidicon-line"></i></a>
                                <a href='/new-room' className="btn btn-outline-primary p-2 "><b>New room</b> <i style={{ fontSize: 20 }} className="ri-add-line"></i></a>
                            </div>

                        </div>

                    </div>
                    <div className="row mb-3">
                        <div className={`col-md-6 col-sm-12 ${client.client_type !== '4web' ? '' : 'd-none'}`}>
                            <div className="iq-card iq-card-block iq-card-strech p-2" style={{ height: '100%' }}>

                                <div className="iq-card-body pb-0">
                                    <div className="col-12 ">
                                        <div className="row">
                                            <div className="d-flex align-items-center mb-2">
                                                <div className="account_image_container mr-2" style={{ backgroundImage: `url(${profileImage})` }}></div><h4>{accountType === '4web' ? '4web.live' : accountType}</h4> <span className="ml-2 badge border border-success text-success">ACTIVE</span>
                                            </div>
                                            <div className="d-flex flex-column">
                                                {token === null || accountType === '4web' ? (
                                                    <>
                                                        <p>On 4web.live you can stream directly to your <b>YouTube</b> account or, if you prefer, you can use our own account to stream without worries. If you want to use your account, just login with the button below.</p>
                                                        <p>Remembering that our YouTube account is for the exclusive use of customers of <b><a href='https://4.events' target='_blank'>4.events</a></b></p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <p>Want to stream to another <b>YouTube</b> account? Simply change your account using the button below.</p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="iq-card-footer">
                                    <div className="d-flex  align-items-center justify-content-center" style={{ textAlign: 'justify' }}>
                                        <a onClick={() => {
                                            localStorage.setItem('account', 'client')
                                        }} className="btn my-3 btn-google p-2" href={urlAuthGoogle}>
                                            <img className="mr-2" width='25' height='25' src={iconGoogle} /><b> {token === null || accountType === '4web' ? ('Log in with Google') : ('Switch Google account')}</b>
                                        </a>
                                        {/*<button className="btn btn-primary mt-2 ml-2 p-2" onClick={()=>{
                                                        setAccountType('4web')
                                                        setProfileImage(logo2)
                                                        localStorage.setItem('account','4web')
                                                    }}>
                                                        <b>Conta do 4web.live</b>
                                                    </button>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${client.client_type !== '4web' ? 'col-md-6' : 'col-md-12'} col-sm-12`}>
                            <div className="iq-card iq-card-block iq-card-strech justify-content-center align-items-center" style={{ height: '100%' }}>

                                <div className="iq-card-body text-center">
                                    <div className="rounded-circle iq-card-icon iq-bg-danger mr-2">
                                        <i className="ri-base-station-line"></i>
                                    </div>
                                    <h3 className="my-4"><b>{broadcasts.length}</b> {t(`pages.Broadcast.broadcastsPerformed`)}</h3>
                                </div>
                                <div className="iq-card-footer">
                                    <div className="row d-flex justify-content-center mb-3">
                                        {client.client_type !== '4web' ? (
                                            <a href='/studio' className="btn btn-primary p-2"><b>Access studio</b> <i style={{ fontSize: 20 }} className="ri-login-circle-line"></i></a>
                                        ) : (
                                            <a href='/new-room' className="btn btn-primary p-2"><b>{t(`pages.Broadcast.createRoomButton`)}</b> <i style={{ fontSize: 20 }} className="ri-login-circle-line"></i></a>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-12 mt-6 mb-3">
                            <div className="iq-card iq-card-block iq-card-stretch pb-3" style={{ height: '100%' }}>
                                <div className="iq-card-header d-flex justify-content-between mt-3">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">{t(`pages.Broadcast.broadcastsHistoryTitle`)}</h4>
                                    </div>
                                </div>

                                <div className={`iq-card-body ${loadingBroadcasts ? 'd-flex justify-content-center align-items-center p-5' : ''} `} style={{ overflow: 'auto', maxHeight: 400 }}>
                                    {loadingBroadcasts ? (
                                        <div>
                                            <h1>
                                                <i className="fa fa-spin fa-spinner"></i>
                                            </h1>
                                        </div>
                                    ) : (
                                        <div className="table-responsive">
                                            <table className="table mb-0 table-borderless table-hover">
                                                <thead className="text-center">
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col">Link </th>
                                                        <th style={{ display: 'none' }} scope="col">{t(`pages.Broadcast.downloadRecordingHeader`)}</th>
                                                        <th scope="col">Status </th>
                                                        <th scope="col">{t(`pages.Broadcast.startedAtHeader`)}</th>
                                                        <th scope="col">{t(`pages.Broadcast.finishedAtHeader`)}</th>
                                                        <th scope="col"></th>
                                                    </tr>

                                                </thead>

                                                <tbody className="text-center">
                                                    {broadcasts.length === 0 && (
                                                        <tr><td><h4>{t(`pages.Broadcast.thereIsNoBroadcast`)}</h4></td></tr>
                                                    )}
                                                    {broadcasts.map(b => (
                                                        <Broadcasts key={b._id} data={b} />
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                    )}


                                </div>
                            </div>

                        </div>


                    </div>

                </div>

            </div>

        </>
    )
}
