import React, { useEffect, useState, useRef } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import styles from './listItemsCustomize.module.css';

function ListItemsCustomize(props) {
    return (
        <React.StrictMode>
            <div className={styles.list_item}>
                <div>
                    <label className={`${styles.switch}`}>
                        <input type="checkbox" onChange={props.func} checked={props.isChecked} />
                        <span className={`${styles.slider} ${styles.round}`}></span>
                    </label>
                </div>

                <div>
                    <p className="mb-0">{props.title}
                        <OverlayTrigger overlay={<Tooltip>{props.desc}</Tooltip>}>
                            <i className="fas fa-question-circle ml-1"></i>
                        </OverlayTrigger>
                    </p>
                </div>
            </div>
        </React.StrictMode>
    )
}

export default ListItemsCustomize;