import { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { getAuthenticatedUser } from '../../../services/auth'
import api from '../../../services/api'

import { Container, NoInvoicesContainer } from './styles'
import { useTranslation } from 'react-i18next'

export default function Invoices() {
  const { t } = useTranslation();
  const user = getAuthenticatedUser();

  const [invoices, setInvoices] = useState({
    data: [],
    loading: true,
  });

  useEffect(() => {
    async function fetchInvoices() {
      let data = [];

      try {
        const response = await api(`/users/${user._id}/invoices`)

        data = response.data.map((invoice) => {
          const formattedTotal = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: invoice.currency.toUpperCase(),
          }).format(invoice.total);

          const formattedCreated = new Intl.DateTimeFormat('en-US', {
            dateStyle: 'medium',
          }).format(new Date(invoice.created * 1000));

          const formattedInvoice = {
            ...invoice,
            total: formattedTotal,
            created: formattedCreated
          };

          return formattedInvoice;
        });
      } catch (err) {
        console.error(err);
      } finally {
        setInvoices({ data, loading: false });
      }
    }

    fetchInvoices()
  }, [user._id]);

  if (invoices.loading) {
    return (
      <Container>
        <Spinner animation="border" />
      </Container>
    )
  }

  function handleInvoiceClick(invoice) {
    window.open(invoice.hosted_invoice_url, '_blank').focus();
  }

  return (
    <Container className="table-responsive">
      {invoices.data.length ? (
        <table className="table mb-0 table-borderless table-hover">
          <thead>
            <tr>
              <th className="number">{t(`pages.Billing.Invoices.numberHeader`)}</th>
              <th className="number text-center">{t(`pages.Billing.Invoices.planHeader`)}</th>
              <th className="number text-center">{t(`pages.Billing.Invoices.dateHeader`)}</th>
              <th className="status text-center">Status</th>
              <th className="number text-center">{t(`pages.Billing.Invoices.amountHeader`)}</th>
            </tr>
          </thead>

          <tbody>
            {invoices.data.map(invoice => (
              <tr key={invoice.id} onClick={() => handleInvoiceClick(invoice)}>
                <td className="number">{invoice.number}</td>
                <td className="plan text-center">{invoice.plan}</td>
                <td className="date text-center">{invoice.created}</td>
                <td className="status text-center">
                  <span className={invoice.status}>{invoice.status}</span>
                </td>
                <td className="amount text-center">{invoice.total}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <NoInvoicesContainer>
          {t(`pages.Billing.Invoices.invoicesNotFound`)}
        </NoInvoicesContainer>
      )}
    </Container>
  )
}