import React from "react";

import mauticPlgEvent from '../../services/plgEvents'

import "./style.css";

export default function TwitchAuth({
  buttonText,
  appId,
  className,
  redirectURI,
  scopes,
}) {
  function handleClickTwitchAuth() {
    // window.location.href = `https://id.twitch.tv/oauth2/authorize?client_id=${appId}&redirect_uri=${redirectURI}&response_type=token&scope=${scopes}`;
    mauticPlgEvent('config', 'Novo destino adicionado: Twitch')
    window.location.href = `https://id.twitch.tv/oauth2/authorize?client_id=${appId}&redirect_uri=${redirectURI}&response_type=code&scope=${scopes}`;
  }

  return (
    <div>
      <button
        onClick={handleClickTwitchAuth}
        className={`btn_twitch ${className}`}
      >
        <i className="fab fa-twitch"></i>
        <strong>{buttonText}</strong>
      </button>
    </div>
  );
}
