import Swal from 'sweetalert2';

export function showForbiddenAlert({
	lockType,
	broadcastStarted = false,
	onConfirm = null,
	onCancel = null,
}) {
	const actionTexts = {
		stream_limit_day:
			'You have reached the maximum number of streams per day allowed for your plan, upgrade your plan to increase your limit or wait until tomorrow to create a new stream',
		stream_limit_month:
			'You have reached the maximum number of streams per month allowed for your plan, upgrade your plan to increase your limit or wait until next month to create a new stream',
		screen_participants:
			'You have reached the maximum number of participants on screen allowed for your plan, upgrade your plan to increase your limit and many more features',
		logo_required:
			"You don't have permission to hide the logo, upgrade your plan to unlock this and many more features",
		custom_logo:
			"You don't have permission to upload a custom logo, upgrade your plan to unlock this and many more features",
		custom_background:
			"You don't have permission to upload a custom background, upgrade your plan to unlock this and many more features",
		record: 
			"You don\'t have permission to use recording, upgrade your plan to unlock this and many more features",
		default:
			"You don't have permission to perform this action, upgrade your plan to unlock this and many more features",
	};

	let text = actionTexts[lockType] || actionTexts.default;

	if (broadcastStarted) {
		text += '<br /><br />';
		text +=
			'If you leave the studio now, your transmission will be stopped. Are you sure?';
	}

	Swal.fire({
		icon: 'warning',
		title: '<p>Forbidden</p>',
		html: text,
		showCancelButton: true,
		confirmButtonText: `View Plans`,
		cancelButtonText: `Cancel`,
	}).then(async (result) => {
		let handled = false 

		if (result.isConfirmed) {
      if (onConfirm) {
        handled = await onConfirm();
      }

			if (!handled) {
				window.location.href = '/plans';
			}
		} else {
      if (onCancel) {
        onCancel();
      }

			return false;
		}
	});
}
