import DetectRTC from 'detectrtc';

const loadAsync = new Promise((resolve, reject) => {
  DetectRTC.load(() => {
    resolve();
  });
});

async function requestUserMediaPermissions(constraints) {
  const now = Date.now();
  try {
    const stream = await navigator.mediaDevices.getUserMedia(constraints)
    
    const persisted = (Date.now() - now) < 500;
    
    stream.getTracks().forEach(track => track.stop());
    
    return { 
      status: 'granted', 
      persisted,
    };
  } catch (err) {
    const persisted = (Date.now() - now) < 500;

    let status;
    
    if (err.name === 'NotReadableError') {
      status = 'failed';
    } else if (err.name === 'NotAllowedError') {
      if (persisted) {
        status = 'blocked';
      } else if (err.message.includes('dismissed')) {
        status = 'denied';
      } else if (err.message.includes('denied')) {
        status = 'blocked';
      }
    } else  {
      status = 'denied'
    }

    return { 
      status,
      persisted,
    };
  }
}

export async function requestCamAndMicPermissions() {
  await loadAsync;

  const needsCamPermission = DetectRTC.hasWebcam && !DetectRTC.isWebsiteHasWebcamPermissions;
  const needsMicPermission = DetectRTC.hasMicrophone && !DetectRTC.isWebsiteHasMicrophonePermissions;

  let camStatus = null;
  let micStatus = null;

  if (needsCamPermission && needsMicPermission) {
    const result = await requestUserMediaPermissions({
      audio: needsMicPermission,
      video: needsCamPermission,
    });
  
    if (result.status === 'granted') {
      camStatus = result.status;
      micStatus = result.status;
    }
  }

  if (needsCamPermission && camStatus !== 'granted') {
    const camPermission = await requestUserMediaPermissions({
      audio: false,
      video: true,
    });

    camStatus = camPermission.status;
  }

  if (needsMicPermission && micStatus !== 'granted') {
    const micPermission = await requestUserMediaPermissions({
      audio: true,
      video: false,
    });

    micStatus = micPermission.status;
  }

  return {
    cam: camStatus,
    mic: micStatus,
  }
};