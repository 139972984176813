import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 420px;

  @media (max-width: 840px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Plan = styled.div`
  margin: 48px 0;
  border: 1px solid #efefef;
  border-radius: 8px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);

  & + & {
    margin-left: 48px;
  }

  @media (max-width: 840px) {
    margin: 32px 0;

    & + & {
      margin-left: 0;
    }
  }
`;

export const PlanHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 28px 28px 0px 28px;

  img {
    width: 60px;
    margin-right: 16px;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.1));
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  h4 {
    font-size: 1.8rem;
    line-height: 1.6rem;
    font-weight: bold;
  }

  small {
    font-weight: bold;
    color: #aaa;
  }
`;

export const PlanBody = styled.main`
  margin: 32px 28px 42px 28px;

  ul {
    padding: 0;
    display: block;
    margin-left: 20px;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: bold;
      line-height: 1.3rem;

      + li {
        margin-top: 16px;
      }
    }
  }
`;

export const CheckContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? '#8dfbb0' : '#fb8d8d')};
  margin-right: 24px;
`;

export const PlanFooter = styled.footer`
  height: 140px;
  border-top: 1px solid #efefef;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
  }

  span {
    font-size: 2rem;
    font-weight: bold;
  }

  small {
    font-weight: bold;
    color: #aaa;
  }
`;

export const Button = styled.button`
  width: 120px;
  height: 40px;

  font-size: 14px;
  border-radius: .3rem;
  border: 0;
  /* background-color: #d82e4c;
  color: #fff; */
  transition: 0.3s;
  margin-top: 8px;

  /* &:hover {
    background-color: #952539;
    color: #fff;
  } */

  ${({ selected }) => selected && css`
    cursor: default;
    background-color: #8dfbb0;
    color: #2f945c;

    &:hover {
      background-color: #8dfbb0;
      color: #2f945c;
    }
  `}
`;