import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

import styles from './styles.module.css'


export default function Questions({ eid, cid, room, count, onDoubleClick, className }) {
    const { t } = useTranslation();
    const [total, setTotal] = useState(0);

    const getQuestions = async () => {
        let questions = await axios.get(`https://api.vpeventos.com/transmission/${cid}/${eid}/${room}/questions`)
       

        
    
        return questions
    }

    const {data: result, isLoading, error } = useQuery('questions', getQuestions, {
        refetchInterval: 1000 * 30, // 30 seconds
        refetchOnWindowFocus: true
    })


    if(isLoading){
       return <p>Loading...</p>
    }
    
    const { rows, total_rows } = result.data

    count(total_rows)

  
    return (
        <div className={`scroller ${className}`} style={{ height: '100%', overflowY: 'scroll'}}>
            {!isLoading && (
                <>
                    {rows?.map((question, index) => (
                        <OverlayTrigger
                            key={question.id}
                            placement="left"
                            delay={{ show: 250 }}
                            overlay={<Tooltip>{t(`components.Questions.tooltipQuestions`)}</Tooltip>}
                        >
                            <div key={question.id} className={`col-12 ${styles.question_card}`} onDoubleClick={() => onDoubleClick(question)}>
                                <div className="col-12 m-2">
                                        <h4 style={{color: '#d82e4c'}}><strong>{index + 1}º</strong></h4>   
                                </div>
                                <div className="col-12 m-2 question-person">
                                    <label><strong>{question.from_name}</strong></label>
            
                                    <p>{question.question}</p>
                                </div>
                                <div className="col-12 m-2 question-person">
                                    <span className="fa fa-heart" style={{color: '#d82e4c'}}></span> <strong>{question.vote}</strong> votes
                                </div>
                            </div>
                        </OverlayTrigger>
                    ))}
                </>
            )}
        </div>
    )
}