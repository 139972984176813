import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

	background-color: ${props => props.darkMode ? '#2b2b2b' : '#fff'};
	-webkit-backdrop-filter: blur(2px);
	backdrop-filter: blur(2px);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.5rem;

  i {
    font-size: 50px;
  }

  h1 {
    font-size: 24px;
    font-weight: bold;
	  color: ${props => props.darkMode ? '#eee' : 'unset'};
  }

  p {
    font-size: 20px;
    margin-top: 28px;
    text-align: center;
  }

  div {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    button.btn-deny {
      margin-top: 10px;
      background: none;
      border: none;
      text-decoration: underline;
      color: ${props => props.darkMode ? '#ccc' : 'unset'};
    }

    span {
      font-weight: bold;
    }
  }
`;