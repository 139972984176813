import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 16px;
`;

export const Form = styled.form`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;

    button + button {
      margin-left: 16px;
    }
  }
`;

export const Button = styled.button`
  width: 150px;
  height: 40px;

  font-size: 14px;
  border-radius: .3rem;
  border: 0;
  background-color: ${({ secondary }) => secondary ? 'transparent' : '#d82e4c'};
  color: ${({ secondary }) => secondary ? '#d82e4c' : '#fff'};
  transition: 0.3s;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  :disabled {
    background-color: ${({ secondary }) => secondary ? 'transparent' : '#e69aa7'};
  }

  &:not([disabled]):hover {
    background-color: ${({ secondary }) => secondary ? '#eee' : '#952539'};
  }
`;