import styled from 'styled-components';
import { motion } from 'framer-motion'

export const Container = styled(motion.div)`
  width: 360px;
  max-width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 8px;
  background: #3e3e3e;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-left: 16px;

  @media (max-width: 768px) {
    position: absolute;
    right: 16px;
    top: 16px;
    max-width: calc(100% - 32px);
    height: calc(100% - 32px);
  }

  header {
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 8px;
    flex-shrink: 0;

    h2 {
      font-size: 20px;
      font-weight: bold;
      color: #fff;
    }

    button {
      border: 0;
      background: none;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      width: 48px;
      height: 48px;
      padding: 12px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.2s;

      &:hover {
        background: rgba(255, 255, 255, 0.07);
      }
    }
  }

  > div {
    flex: 1;
    width: 100%;
    height: 0;
    display: flex;
    flex-direction: column;
  }
`;

export const PeopleContainer = styled.div`
  flex: 1;
  height: 0;
  padding: 0 8px;
  margin: 8px 0;
`;

export const PersonList = styled.ul`
  list-style: none;
  padding: 0 16px;
`;

export const PersonItem = styled.li`
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.07);
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & + & {
    margin-top: 12px;
  }

  &:hover {
    .buttons {
      display: flex;
    }
  }

  .initials {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: ${props => props.color};
    font-size: 16pt;
    font-weight: bold;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    i {
      margin-top: 3px;
      margin-left: 1px;
      font-size: 14pt;
    }
  }

  .content {
    flex: 1;
    width: 0;
    margin-left: 16px;
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
      font-size: 12pt;
      line-height: 1.2;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      margin-top: 4px;
      line-height: 1;
      font-size: 10pt;
      font-weight: 200;
      font-style: italic;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .buttons {
    display: none;
    margin-right: -6px;

    button {
      border: 0;
      background: none;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.2s;

      &:hover {
        background: rgba(255, 255, 255, 0.07);
      }
    }
  }
`;

export const RecordingList = styled.ul`
  list-style: none;
  padding: 0 16px;
`;

export const RecordingItem = styled.li`
  width: 100%;
  padding: 16px 16px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.07);
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 12px;
  }

  &:hover {
    .container {
      .buttons {
        display: flex;
      }
    }
  }

  video {
    border-radius: 8px;
  }

  .container {
    height: 36px;
    margin-top: 16px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .content {
      flex: 1;
      width: 0;
      display: flex;
      flex-direction: column;
  
      p {
        margin: 0;
        font-size: 12pt;
        line-height: 1.2;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
  
      span {
        margin-top: 4px;
        line-height: 1;
        font-size: 10pt;
        font-weight: 200;
        font-style: italic;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .buttons {
      display: none;
  
      button {
        border: 0;
        background: none;
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.2s;
  
        &:hover {
          background: rgba(255, 255, 255, 0.07);
        }
      }
    }
  }
`;

export const NoRecordings = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  text-align: center;
`;