import React, { useEffect } from "react";
import api from "../../services/api";
import { token_decode } from "../../services/auth";

export default function CallbackTwitch() {
  const client = token_decode(localStorage.getItem("auth-user"));

  let cid = null;

  if (client.client_type === "4web") {
    cid = client._id;
  } else {
    cid = client.vp_client_id;
  }
  useEffect(() => {
    const login = () => {
      let params = window.location.href;
      let code = params.split("&")[0].split("=")[1];

      api
        .post("api/destinations/twitch", {
          code,
          client_id: cid,
        })
        .then((response) => {
          window.location.href = "/destinations";
        })
        .catch((err) => {
          console.log(err);
          window.location.href = "/destinations";
        });
    };

    login();
  }, [cid, client.type_client]);

  return <></>;
}
