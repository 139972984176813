import React from 'react'
import { useTranslation } from 'react-i18next';

import { Dropdown } from '../../../../components/Dropdown';

export function UserDropdown({ toggle, onNavigation }) {
  const { t } = useTranslation();

  return (
    <Dropdown
      id="header-user-dropdown"
      toggle={toggle}
    >
      <div className="user-dropdown-container">
        <button
          type="button"
          onClick={() => onNavigation('/settings')}
        >
          <i className="ri-profile-line" />
          <span>{t(`components.Sidebar.profile`)}</span>
        </button>

        <button
          type="button"
          onClick={() => onNavigation('/logout')}
        >
          <i className="ri-logout-box-line" />
          <span>{t(`components.Sidebar.logout`)}</span>
        </button>
      </div>
    </Dropdown>
  )
}
