import { useState, useEffect, useMemo } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { BannerList, BannerItem, Overlay, ButtonsContainer } from './styles';

import api from '../../../../services/api';

export function Banners({ clientId, roomId, onBannerChange }) {
  const [banners, setBanners] = useState([]);
  const [selected, setSelected] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [editingText, setEditingText] = useState('');
  const [editingTitle, setEditingTitle] = useState('');

  const selectedBanner = useMemo(
    () => banners.find((banner) => banner._id === selected) || null,
    [banners, selected]
  );

  const addingBanner = editingId === 'new-banner';

  const { t } = useTranslation();

  useEffect(() => {
    async function fetchBanners() {
      try {
        const response = await api.get(`/api/banners/${clientId}/${roomId}`);
        setBanners(response.data.banners);
      } catch (err) {
        console.error('Error fetching banners');
      }
    }

    fetchBanners();
  }, [clientId, roomId]);

  useEffect(() => {
    onBannerChange?.(selectedBanner);
  }, [selectedBanner]);

  function handleBannerClick(banner) {
    if (editingId !== banner._id) {
      setSelected(selected => {
        return selected === banner._id ? null : banner._id;
      })
    }
  }

  function handleEditClick(e, banner) {
    e.stopPropagation();
    setEditingId(banner._id);
    setEditingText(banner.text || '');
    setEditingTitle(banner.title || '');
  }

  async function handleDeleteClick(e, banner) {
    e.stopPropagation();

    try {
      await api.delete(`/api/banners/${banner._id}`);
      setBanners(banners => banners.filter(b => b._id !== banner._id));
    } catch (err) {
      console.error('Error deleting banner');
    }
  }

  async function handleSaveBanner(e) {
    e.preventDefault();

    if (!editingText) {
      return;
    }

    try {
      const response = await api.post(`/api/banners/${clientId}/${roomId}`, {
        id: editingId === 'new-banner' ? null : editingId,
        text: editingText,
        title: editingTitle,
      });

      const banner = response.data.banner;

      let newBanners;

      if (editingId === 'new-banner') {
        newBanners = [...banners, banner];
      } else {
        newBanners = banners.map(item => {
          return item._id === banner._id ? banner : item
        })
      }

      setBanners(newBanners);
    } catch (err) {
      console.error('Error saving banner');
    }

    setEditingId(null);
    setEditingText('');
    setEditingTitle('');
  }

  function handleCancelEditClick() {
    setEditingId(null);
    setEditingText('');
    setEditingTitle('');
  }

  function handleNewBannerClick() {
    if (!addingBanner) {
      setEditingId('new-banner');
      setEditingText('');
    }
  }

  function handleEditingTextChange(e) {
    const newValue = e.target.value;
    setEditingText(newValue.substring(0, 200));
  }

  function handleEditingTitleChange(e) {
    const newValue = e.target.value;
    setEditingTitle(newValue.substring(0, 200));
  }

  return (
    <BannerList>
      {banners.map(banner => (
        <BannerItem
          key={banner._id}
          onClick={() => handleBannerClick(banner)}
          editing={editingId === banner._id}
        >
          {editingId === banner._id ? (
            <EditItemForm
              onSubmit={handleSaveBanner}
              onCancel={handleCancelEditClick}
              textValue={editingText}
              onTextChange={handleEditingTextChange}
              onTitleChange={handleEditingTitleChange}
              titleValue={editingTitle}
            />
          ) : (
            <>
              <div>
                <strong>{banner.title}</strong>
                <p>{banner.text}</p>
              </div>

              <Overlay active={selected === banner._id}>
                <i className='fas fa-thumbtack'></i>
                <span>{selected === banner._id ? 'Hide' : 'Show'}</span>

                <ButtonsContainer>
                  <button type="button" onClick={(e) => handleEditClick(e, banner)}>
                    <OverlayTrigger
                      placement="top-end"
                      overlay={<Tooltip>Edit banner</Tooltip>}
                    >
                      <i className='fas fa-edit'></i>
                    </OverlayTrigger>
                  </button>

                  <button type="button" onClick={(e) => handleDeleteClick(e, banner)}>
                    <OverlayTrigger
                      placement="top-end"
                      overlay={<Tooltip>Delete banner</Tooltip>}
                    >
                      <i className='fas fa-trash'></i>
                    </OverlayTrigger>
                  </button>
                </ButtonsContainer>
              </Overlay>
            </>
          )}
        </BannerItem>
      ))}

      <BannerItem
        editing={addingBanner}
        button={!addingBanner}
        onClick={handleNewBannerClick}
      >
        {addingBanner ? (
          <EditItemForm
            onSubmit={handleSaveBanner}
            onCancel={handleCancelEditClick}
            textValue={editingText}
            onTextChange={handleEditingTextChange}
            onTitleChange={handleEditingTitleChange}
            titleValue={editingTitle}
          />
        ) : (
          <p>
            <i className='fas fa-plus'></i>
            <b>{t(`components.Banners.newBanner`)}</b>
          </p>
        )}
      </BannerItem>
    </BannerList>
  )
}

function EditItemForm({ onSubmit, onCancel, textValue, onTextChange, onTitleChange, titleValue }) {
  const { t } = useTranslation();

  return (
    <form onSubmit={onSubmit}>
      <input
        autoFocus
        type="text"
        className="form-control mb-0 mt-2 font-md"
        value={titleValue}
        onChange={onTitleChange}
        placeholder={t(`components.Banners.placeholderTitle`)}
        maxLength={50}
        rows="3"
      />
      <span>{titleValue.length}/50</span>

      <input
        autoFocus
        type="text"
        className="form-control mb-0 mt-2 font-md"
        value={textValue}
        onChange={onTextChange}
        placeholder={t(`components.Banners.placeholderText`)}
        rows="3"
      />
      <span>{textValue.length}/200</span>

      <div>
        <button
          type="submit"
          className="btn btn-primary"
        >
          <i className="fa fa-check"></i>Save
        </button>

        <button
          type="button"
          className="btn btn-secondary"
          onClick={onCancel}
        >
          <i className="fa fa-times"></i>Cancel
        </button>
      </div>
    </form>
  )
}
