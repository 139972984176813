import React, { useContext, useEffect, useState} from 'react'
import Joyride, { ACTIONS, EVENTS } from 'react-joyride'
import { Link, Redirect, useHistory } from "react-router-dom"
import { token_decode } from '../../services/auth'
import api from '../../services/api'
import './style.css'
import Sidebar from '../../components/Layout/SideBar/SideBar'
import { Header } from '../../components/Layout/Header'
import promo_banner from "../../assets/images/promo/promo_banner.png"
import Promo from "../../components/Promo/Promo"
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import Rooms from '../../components/Rooms'
import { useAuth } from '../../context/Auth'
import { showForbiddenAlert } from '../../utils/forbiddenAlert'

import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { AccountContext } from '../../context/Accounts'
import { useClientLogo } from '../../hooks/useClientLogo'
import { FaYoutube, FaTwitch, FaListAlt } from 'react-icons/fa'
import customRtmpImg from '../../assets/images/custom_rtmp.svg'
import { useTranslation } from 'react-i18next'

export default function NewRoom({ history }) {
  const { t } = useTranslation();
  const [showNewBroadcastModal, setShowNewBroadcastModal] = useState(false);
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [runOnboarding, setRunOnboarding] = useState(false);
  const [onboardingHelpers, setOnboardingHelpers] = useState();

  const [refreshRooms, setRefreshRooms] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [roomName, setRoomName] = useState("");
  const [selected, setSelected] = useState(null);
  const [privacy, setPrivacy] = useState("");
  const [onlyRecord, setOnlyRecord] = useState(false);
  const [roomNameError, setRoomNameError] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);
  const [twitchCategoryError, setTwitchCategoryError] = useState(false);
  const [destinationError, setDestinationError] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({
    _id: "",
    access_token: "",
    avatar_url: "",
    client_id: "",
    display_name: "",
    provider_id: "",
    refresh_token: "",
    type_account: "",
    scope: ""
  });
  const [hoverImageAccount, setHoverImageAccount] = useState({
    status: false,
    id: "",
  });
  const [twitchCategory, setTwitchCategory] = useState("")

  const { modules, setUser } = useAuth();
  const { clientLogo } = useClientLogo();

  const client = token_decode(localStorage.getItem("auth-user"));

  const { accounts } = useContext(AccountContext);

  const canRecord = Boolean(client.client_type === '4web' ? modules.record : true);

  const labelAccount = {
    twitch: {
      backgroundColor: "#9146FF",
      icon: <FaTwitch size={12} color="#fff" />,
    },
    custom_rtmp: {
      backgroundColor: "#ffae00",
      icon: <FaListAlt size={12} color="#2d2d2d" />,
    },
    youtube: {
      backgroundColor: "#FF0000",
      icon: <FaYoutube size={12} color="#fff" />,
    },
  };

  let cid = client._id;
  
  const handleRoom = async () => {
    let isFormInvalid = false;

    // if (!selectedAccount._id) {
    //   setDestinationError(true);
    //   isFormInvalid = true;
    // }
    
    if (roomName === "" || (roomName === undefined && selected !== null)) {
      setRoomNameError(true);
      isFormInvalid = true;
    }

    if (selectedAccount.type_account === 'youtube' && !!privacy === false) {
      setPrivacyError(true);
      isFormInvalid = true;
    }

    if (selectedAccount.type_account === 'twitch' && !!twitchCategory === false) {
      setTwitchCategoryError(true);
      isFormInvalid = true;
    }

    if (isFormInvalid) {
      return false;
    }

    setLoading(true);

    try {
      let response = await api.post(`/new/room`, {
        cid,
        type: selected !== "meet" ? "studio" : "meet",
        roomName,
        privacy: privacy ? privacy : null,
        twitchCategory: twitchCategory ? twitchCategory : null,
        destination_id: selectedAccount._id,
        onlyRecord,
      });

      let { data } = response.data;

      if (response.status === 200) {
        if (data.room_type === "studio") {
          client.room = data.room;
          client.room_type = data.room_type;
          client.room_name = roomName;
          client.privacy_broadcast = privacy;
          client.twitchCategory = twitchCategory;
          client.only_record = onlyRecord;

          localStorage.setItem("auth-user", btoa(JSON.stringify(client)));
          setUser(client);

          setShowNewBroadcastModal(false);
          Swal.fire({
            icon: "success",
            title: t(`pages.NewRoom.swalRoomCreatedTitle`),
            html: t(`pages.NewRoom.swalRoomCreatedText`),
          }).then(async (result) => {
            if (result.isConfirmed) {
              setSelectedAccount({});
              setTwitchCategory('');
              setPrivacy('');
              setRoomName('');
              reloadRooms();
              return;
            } else {
              return false;
            }
          });
        }
      } else {
        Swal.fire(
          t(`pages.NewRoom.swalNotAllowedEnterRoomTitle`),
          t(`pages.NewRoom.swalNotAllowedEnterRoomText`),
          "warning"
        );
      }

      setLoading(false);
    } catch (err) {
      console.error(err.message);

      setLoading(false);
    }
  };

  useEffect(() => {
    api
      .get(`/${cid}/rooms`)
      .then((response) => {
        if (response.status === 200) {
          let { data } = response.data;
          setRooms(data);
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [accounts, refreshRooms, cid]);

  useEffect(() => {
    const onboardingCompleted = sessionStorage.getItem('onboarding-homepage-completed');
    if (history.location.state && history.location.state.firstLogin && !onboardingCompleted) {
      setRunOnboarding(true)
    }  
  }, [history.location])

  if (url !== null) {
    if (url.includes("studio")) {
      return <Redirect to="/studio" />;
    } else {
      window.location.href = url;
    }
  }

  function handleTypeRoomName(event) {
    const roomName = event.target.value;
    if (roomName) {
      setRoomNameError(false);
    }
    setRoomName(roomName);
  }

  function handleSelectPrivacy(event) {
    const privacy = event.target.value;
    if (privacy) {
      setPrivacyError(false);
    }
    setPrivacy(privacy);
  }

  function handleClickAccount(account) {
    if (selectedAccount._id === account._id) {
      setSelectedAccount({});
    } else {
      setSelectedAccount(account);
      setDestinationError(false);
    }
  }

  function handleSelectTwitchCategory(event) {
    const category = event.target.value;
    setTwitchCategory(category);
    if (twitchCategoryError) {
      setTwitchCategoryError(false);
    }
    setTwitchCategory(category);
  }

  function reloadRooms() {
    setRefreshRooms(!refreshRooms)
  }

  function handleOnboardingCallback(data) {
    if (data.action === ACTIONS.CLOSE || data.type === EVENTS.TOUR_END) {
      setRunOnboarding(false);
      sessionStorage.setItem("onboarding-homepage-completed", true);
      onboardingHelpers.reset();
    }
  }

  function handleCheckOnlyRecord(event) {
    setOnlyRecord(event.target.checked)
  }

  function handleNewRecordRoom() {

    if (!showNewBroadcastModal) {
      setShowNewBroadcastModal(true)
    }

    setOnlyRecord(true)
  }

  function handleNewBroadcastRoom() {
    if (!showNewBroadcastModal) {
      setShowNewBroadcastModal(true)
    }

    setOnlyRecord(false)
  }

  function handleOnlyRecordClick() {
    if (!canRecord) {
        showForbiddenAlert({ lockType: 'record' })
    }
  }

  return (
    <>
      {/* <Sidebar page="new-room" onHelpClick={() => setRunOnboarding(true)} /> */}
      <Header page="new-room" onHelpClick={() => setRunOnboarding(true)} />

      <div className="content-page ">
        <div className="container-fluid">
          {/*modules.logo_required && client.client_type === '4web' &&(
            <>
              <Promo type="modal"></Promo>
              <Link to="/plans">
                <div className="iq-card mb-2 p-2" style={{ marginTop: '-20px'}}>
                  <img src={promo_banner} style={{ width: '100%', borderRadius: '10px'}} />
                </div>
              </Link>
            </>
          )*/}
          <div className="iq-card">
            <div className="row px-4 py-3">
              <div className="col-md-12">
                {/*<div className="mb-5">
                  <img
                    width="200"
                    src={clientLogo?.url ? clientLogo.url : logo}
                  />
                </div>*/}
                <div className="mt-2">
                  <h3 style={{ fontWeight: '900', lineHeight: '1.2' }}>{t(`pages.NewRoom.title`)}</h3>
                  {client.client_type === '4web' && (
                    <a
                      onClick={() => handleNewBroadcastRoom()}
                      className={`onboarding-homepage-step-1 btn btn-primary mt-4 col-sm-12 col-md-3`}
                    >
                      {t(`pages.NewRoom.newRoomButton`)}
                      <i style={{ fontSize: 20 }} className="ri-add-line ml-2"></i>
                    </a>
                  )}
                  
                  <a
                    onClick={() => handleNewRecordRoom()}
                    className={`onboarding-homepage-step-2 btn btn-secondary mt-4 col-sm-12 col-md-3 ml-md-2`}
                  >
                    {t(`pages.NewRoom.newRecordRoomButton`)}
                    <i style={{ fontSize: 20 }} className="ri-add-line ml-2"></i>
                  </a>

                  <a
                    href="/broadcasts"
                    className={`btn btn-outline-primary mt-4 col-sm-12 col-md-2 ml-md-2`}
                  >
                    {t(`pages.NewRoom.myBroadcastsButton`)}
                    <i
                      style={{ fontSize: 20 }}
                      className="ri-base-station-line ml-2"
                    ></i>
                  </a>

                  <a
                    href="/records"
                    className={`btn btn-outline-primary mt-4 col-sm-12 col-md-2 ml-md-2`}
                  >
                    {t(`pages.NewRoom.myRecordersButton`)}
                    <i
                      style={{ fontSize: 20 }}
                      className="ri-vidicon-line ml-2"
                    ></i>
                  </a>
                </div>
              </div>
              {/*<div className="col-md-6">

                <div className="mb-5">
                  <img
                    width="200"
                    src={clientLogo?.url ? clientLogo.url : logo}
                  />
                </div>

              </div>*/}
            </div>
            <div className="row px-2 pb-2">
              <div className="col-12">
                <Rooms rooms={rooms} reloadRooms={reloadRooms} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showNewBroadcastModal}
        onHide={() => {
          setShowNewBroadcastModal(false);
          setSelectedAccount({});
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className=" font-weight-bold ">
            {onlyRecord ? t(`pages.NewRoom.NewRecordRoomTitle`) : t(`pages.NewRoom.newBroadcastModalTitle`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!onlyRecord && (accounts.length > 0 ? (
            <div className="accounts_container">
              {accounts.map((account) => (
                <OverlayTrigger
                  key={account._id}
                  placement="bottom"
                  overlay={<Tooltip>{account.display_name}</Tooltip>}
                >
                  <div
                    className={`account_container user-img img-fluid`}
                    onClick={() => handleClickAccount(account)}
                  >
                    <img
                      src={account.type_account === 'custom_rtmp' ? customRtmpImg : account.avatar_url}
                      alt={account.display_name || ''}
                      onMouseEnter={() =>
                        setHoverImageAccount({
                          status: true,
                          id: account._id,
                        })
                      }
                      onMouseLeave={() =>
                        setHoverImageAccount({
                          status: false,
                          id: account._id,
                        })
                      }
                      style={{
                        border:
                          hoverImageAccount.id === account._id &&
                            hoverImageAccount.status
                            ? `2px solid ${labelAccount[account.type_account][
                            "backgroundColor"
                            ]
                            }`
                            : selectedAccount._id === account._id &&
                            `2px solid ${labelAccount[account.type_account][
                            "backgroundColor"
                            ]
                            }`,
                      }}
                    />
                    {account.type_account !== 'custom_rtmp' && (
                      <div
                        className="icon"
                        style={{
                          background:
                            labelAccount[account.type_account]["backgroundColor"],
                        }}
                      >
                        {labelAccount[account.type_account]["icon"]}
                      </div>
                    )}
                  </div>
                </OverlayTrigger>
              ))}
              {destinationError && (
                <small className="text-danger">
                  {t(`pages.NewRoom.destinationError`)}
                </small>
              )}
            </div>
          ) : (
            <Link to="/destinations">
              {t(`pages.NewRoom.createDestinationLink`)}
            </Link>
          ))}
          <div className="col-12">
            <div className="p-2 row d-flex flex-column">
              <input
                placeholder={onlyRecord ? 'Room title' : t(`pages.NewRoom.broadcastTitlePlaceholder`)}
                className="form-control mt-2"
                type="text"
                value={roomName}
                maxLength="100"
                onChange={handleTypeRoomName}
              />
              <div className="d-flex">
                {roomNameError && (
                  <small className="text-danger">
                    {t(`pages.NewRoom.roomNameError`)}
                  </small>
                )}
                <small style={{ flex: 1, textAlign: 'end' }}>{roomName?.length ?? 0}/100</small>
              </div>
              {!onlyRecord && selectedAccount.type_account === 'youtube' && (
                <select
                  className="form-control mt-2"
                  name="privacy"
                  id="privacy"
                  value={privacy}
                  onChange={handleSelectPrivacy}
                  defaultValue=""
                >
                  <option value="" disabled>
                    {t(`pages.NewRoom.optionDisabledTypeAccount`)}
                  </option>
                  <option value="public"> {t(`pages.NewRoom.optionPublicTypeAccount`)}</option>
                  <option value="private"> {t(`pages.NewRoom.optionPrivateTypeAccount`)}</option>
                  <option value="unlisted"> {t(`pages.NewRoom.optionUnlistedTypeAccount`)}</option>
                </select>
              )}
              {privacyError && (
                <small className="text-danger">
                  {t(`pages.NewRoom.errorYoutubePrivacy`)}
                </small>
              )}
              {!onlyRecord && selectedAccount.type_account === 'twitch' && (
                <select
                  className="form-control mt-2"
                  name="twitchCategory"
                  id="twitchCategory"
                  value={twitchCategory}
                  onChange={handleSelectTwitchCategory}
                  defaultValue=""
                >
                  <option value="" disabled>
                    {t(`pages.NewRoom.optionDisabledTwitchCategory`)}
                  </option>
                  <option value={'Talk Shows & Podcasts'}>{t('pages.NewRoom.optionTalkShowsTwitchCategory')}</option>
                  <option value="Just Chatting">{t(`pages.NewRoom.optionJustChattingTwitchCategory`)}</option>
                  <option value={'Fitness & Health'}>{t('pages.NewRoom.optionFitnessHealthTwitchCategory')}</option>
                  <option value="Art">{t('pages.NewRoom.optionArtTwitchCategory')}</option>
                  <option value={'Food & Drink'}>{t('pages.NewRoom.optionFoodDrinkTwitchCategory')}</option>
                  <option value="Politics">{t('pages.NewRoom.optionPoliticsTwitchCategory')}</option>
                  <option value={'Science & Technology'}>{t('pages.NewRoom.optionScienceTechTwitchCategory')}</option>
                  <option value="League of Legends">League of Legends</option>
                  <option value="Valorant">Valorant</option>
                  <option value="Counter-Strike: Global Offensive">Counter-Strike: Global Offensive</option>
                  <option value="Grand Theft Auto V">Grand Theft Auto V</option>
                </select>
              )}
              {client.client_type === '4web' && (
                <div className="d-flex justify-content-between mt-3">
                  <span>{t(`pages.NewRoom.onlyRecordRoom`)} <b>{t(`pages.NewRoom.onlyRecordRoomPt2`)}</b></span>

                  <label className="switch" onClick={handleOnlyRecordClick}>
                    <input 
                      type="checkbox" 
                      id="only-record" 
                      name="only-record" 
                      checked={onlyRecord}
                      disabled={!canRecord}
                      onChange={handleCheckOnlyRecord}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              )}           
              {twitchCategoryError && (
                <small className="text-danger">
                  {t(`pages.NewRoom.errorTwitchCategory`)}
                </small>
              )}
              <button
                className="btn btn-primary mt-4"
                onClick={() => handleRoom()}
              >
                {loading ? (
                  <span
                    style={{ fontSize: 20 }}
                    className="fa fa-spin fa-spinner"
                  ></span>
                ) : (
                  t(`pages.NewRoom.createRoomButton`)
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Joyride 
        callback={handleOnboardingCallback}
        run={runOnboarding}
        continuous 
        hideBackButton
        getHelpers={setOnboardingHelpers}
        showProgress
        steps={[
          client.client_type === "4web"
            ? {
                title: t(`pages.NewRoom.helpStepTitle`),
                target: '.onboarding-homepage-step-1',
                content: t(`pages.NewRoom.helpStep1Content`),
                disableBeacon: true,
              } 
            : null,
          {
            title: t(`pages.NewRoom.helpStepTitle`),
            target: '.onboarding-homepage-step-2',
            content: t(`pages.NewRoom.helpStep2Content`),
            disableBeacon: true,
          },
          {
            title: t(`pages.NewRoom.helpStepTitle`),
            target: '.onboarding-homepage-step-3',
            content: t(`pages.NewRoom.helpStep3Content`),
            disableBeacon: true,
            placement: 'right-start'
          },
          {
            title: t(`pages.NewRoom.helpStepTitle`),
            target: '.onboarding-homepage-step-4',
            content: t(`pages.NewRoom.helpStep4Content`),
            disableBeacon: true,
            placement: 'right-start'
          },
          {
            title: t(`pages.NewRoom.helpStepTitle`),
            target: 'body',
            content: t(`pages.NewRoom.helpStep5Content`),
            disableBeacon: true,
            placement: 'center'
          },
        ].filter(Boolean)} 
        locale={{
          last: 'Ok'
        }}
        styles={{
          options: {
            zIndex: 9999,
          },
          tooltipFooter: {
            marginTop: 0
          },
          buttonNext: {
            background: 'var(--iq-primary)',
          }
        }}
        floaterProps={{
          disableAnimation: true
        }}
      />
    </>
  );
}
