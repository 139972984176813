import React, { useCallback, useEffect, useState } from 'react'
import api from '../../services/api'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../context/Auth'
import io from 'socket.io-client'
import { token_decode } from '../../services/auth'

import { Container, SpinnerContainer } from './styles'

export function StudioSso() {
	const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { setUser } = useAuth();
  const { short_id } = useParams();

  const verifySSOClient = useCallback(async () => {
    try {      
      const response = await api.post(`/api/sso-authentication`, {
        short_id
      })

      const { message, user, room } = response.data;
      
      if (message === 'token has been expired') {
        Swal.fire('Oops...', t(`pages.Sso.linkIsExpiredText`), 'warning').then((res) => {
          if (res.isConfirmed) {
            return window.location.href = '/'
          }
        })

        setTimeout(() => {
          return window.location.href = '/'
        }, 5000);
      }

      if (message === 'token not found') {
        Swal.fire('Oops...', t(`pages.Sso.invalidLinkText`), 'warning').then((res) => {
          if (res.isConfirmed) {
            return window.location.href = '/'
          }
        })
        
        setTimeout(() => {
          return window.location.href = '/'
        }, 5000);
      }

      const signinRequest = await api.post('/signin',{
          email:  user.email,
          password: user.code,
          type: user.type
      })
      
      const { data } = signinRequest
      
      setLoading(false);

      if (data.status === 200) {
          
          setLoading(false)
          const { user, token } = data.response
          
          let token_auth = await Buffer.from(JSON.stringify(user)).toString('base64')
          localStorage.setItem('auth-user', token_auth)
          localStorage.setItem('auth-token', token)
          setUser(user)

          window.socket = io(data.mediaServer, {cors: { origin: window.location.origin, credentials: false}})
          
          /*if(data.room_type?.toString() === 'studio'){
            window.internalSocket = io(`${data.mediaServer}/internal`, {cors: { origin: window.location.origin, credentials: false}})
          }*/

          const client = token_decode(localStorage.getItem('auth-user'))
          client.room = room
          client.room_name = room.room_name
          client.privacy_broadcast = !!room.privacy ? room.privacy : 'unlisted'
          client.twitchCategory = room.twitchCategory
          client.only_record = room.only_record

          localStorage.setItem('auth-user', btoa(JSON.stringify(client)))
          setUser(client)

          window.location.href = '/studio'
      }
  
    } catch (err) {
      console.log(err.response);
      console.log('sso', err);
      setLoading(false);
    }
  }, [t, setUser])

  useEffect(() => {
    setLoading(true);
    verifySSOClient();
  }, [verifySSOClient])

  return (
    <Container>
      {loading ? (
        <SpinnerContainer>
          <div className="spinner-border" role="status">
            <span className="sr-only">...</span>
          </div>
        </SpinnerContainer>
      ) : (
        <></>
      )}
    </Container>
  )
}
