import styled, { css } from 'styled-components';

export const BannerList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
`;

export const BannerItem = styled.li`
	width: 100%;
	min-height: 60px;
	padding: 10px 20px;
	border-radius: 8px;

	display: flex;
	align-items: center;
	justify-content: ${({ button }) => (button ? 'center' : 'flex-start')};

	font-size: 12pt;
	line-height: 1.2;
	user-select: none;
	overflow: hidden;

	position: relative;
  cursor: pointer;	
	background-color: #f5f5f5;
  transition: all 0.5s ease-in-out 0s;

	& + & {
		margin-top: 16px;

		@media (max-width: 1366px) {
			margin-top: 12px;
		}

		@media (max-width: 480px) {
			margin-top: 8px;
		}
	}

	&:last-child {
		margin-bottom: 80px;
	}

	p {
		margin: 0;
	}

	${({ button }) => button && css`
		b {
			margin-left: 10px;
		}

		&:hover {
			background-color: #eaeaea;
		}
	`}

	${({ editing }) => editing && css`
		flex-direction: column;
		align-items: flex-end;
	`}

	form {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		div {
			margin-top: 8px;
		}

		button {
			margin-left: 8px;
		}
	}
`;

export const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(245 245 245 / 95%);
	transition: all 0.1s ease-in-out 0s;
	opacity: ${({ active }) => (active ? 1 : 0)};
	z-index: 5;

	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		opacity: 1;

		span {
			display: block;
		}
	}

	> i {
		margin-right: 8px;
		transition: all 0.2s ease-in-out 0s;
		
		${({ active }) => active && `
			color: #8f8f8f;
			transform: rotate(40deg);
		`}
	}

	span {
		display: none;
	}
`;

export const ButtonsContainer = styled.div`
	display: none;
	position: absolute;
	top: 8px;
	right: 4px;

	button {
		color: #8f8f8f;
		background: none;
		border: 0;

		&:hover {
			filter: brightness(0.9);
		}
	}

	${Overlay}:hover & {
		display: block;
	}
`;