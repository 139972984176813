import React from "react";

import api from "../../services/api";

import mauticPlgEvent from '../../services/plgEvents'

import "./style.css";
export default function YoutubeAuth({
  text,
  // appId,
  className,
  // redirectURI,
  // scopes,
}) {
  async function handleAuth() {
    try {
      let res = await api.get("/goauth2");
      let { url, status } = res.data;
      if (status === 200) {
        mauticPlgEvent('config', 'Novo destino adicionado: Youtube')
        window.location.href = url;
      } else {
        console.error("Bad request 401!");
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div>
      <button onClick={handleAuth} className={`btn_youtube ${className}`}>
        <i className="fab fa-youtube"></i>
        <strong>{text}</strong>
      </button>
    </div>
  );
}
