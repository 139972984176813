export  const functions = {
    randomColor(){
        let hexadecimal = '0123456789ABCDEF';
        let color = '#';
      
        for (let i = 0; i < 6; i++ ) {
          color += hexadecimal[Math.floor(Math.random() * 16)];
        }
      
        let lum = functions.luminosity(color);

        if(lum === 0){
            color = functions.randomColor();
            return color;
          }else{
            return color;
          }
    },
    formatNameAvatar(name){
        let d_name = name.split(" ");
        let avatar = null;
        let display = null;

        if(d_name.length > 1){
            avatar  = d_name[0].substr(0, 1) + d_name[d_name.length-1].substr(0, 1);
            display = d_name[0] + ' ' +  d_name[d_name.length-1];
        }else{
            avatar  = d_name[0].substr(0, 1);
            display = d_name[0];
        }

        return { avatar, display }
    },
    luminosity(hex){
        let r, g, b, longo;
        hex = hex.replace( '#', '' );
        longo = hex.length > 3;
     
        r = longo ? parseInt(hex.substr(0, 2), 16) : parseInt(hex.substr(0, 1), 16) * 17;
        g = longo ? parseInt(hex.substr(2, 2), 16) : parseInt(hex.substr(1, 1), 16) * 17;
        b = longo ? parseInt(hex.substr(4, 2), 16) : parseInt(hex.substr(2, 1), 16) * 17;
     
        let lumi = ( r * 299 + g * 587 + b * 114) / 1000;
     
        if( lumi > 148 ) { return 0; } else {  return 1;}
    },
    setRoom(type_room, room){
        return type_room === 'interno' ? `${room}_4webInterno` : room;        
    },
    setUser(type, cid, eid, room_id, guest = null, producer_id){
        let user = null


        if(type === "meet" && guest !== null){
            if (producer_id) {
                user = producer_id
            } else {
                user = `${guest}_meet`
            }
        }else{
            if(guest !== null){
                

                user  = `${guest + '4webSpeaker'}` 
            }else{
               

                user  = `${room_id + '4webSpeaker'}` 
            }
           
        }

        return user
    }
}