import { Component } from 'react';
import axios from 'axios'
import StackTrace from "stacktrace-js";

import { getAuthenticatedUser } from '../../services/auth';

class ErrorBoundary extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    }
  }
  
  async componentDidCatch(error) {
    if (error.message === 'should be on studio') {
      window.location.href = '/studio'
      return;
    } else if (error.message === 'should be on meet') {
      window.location.href = '/meet'
      return;
    }

    if (process.env.REACT_APP_ENV === 'production') {
      try {
        this.setState({ hasError: true });
  
        const client = getAuthenticatedUser();
  
        const stackframes = await StackTrace.fromError(error);
    
        var stringifiedStack = stackframes.slice(0, 8).map(function(sf) {
            return sf.toString();
        }).join('\n');
        
        await axios.post('https://weblive-api.4.events:3016/log-error', {
          client,
          message: error.toString(),
          stack: stringifiedStack,
        }, {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_SERVER_API_KEY}` 
          }
        });
      } finally {
        window.location.href = '/error';
      }
    } else {
      console.error('WOULD LOG ERROR TO DISCORD', error);
      window.location.href = '/error';
    }
  }
  
  render() {
    if (this.state.hasError) {
      return (
        <div 
          style={{ height: '100vh' }}
          className="d-flex justify-content-center align-items-center"
        >
          <div className="spinner-border" role="status">
            <span className="sr-only">...</span>
          </div>
        </div>
      )
    }

    return this.props.children;
  }  
}

export default ErrorBoundary;