import React, { useEffect, useState, useRef, useCallback } from 'react';
import styles from './customizeVideoclips.module.css';
import api from '../../services/api'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useAuth } from '../../context/Auth'
import { Modal, Button } from 'react-bootstrap'

import BackgroundItem from '../BackgroundItem/BackgroundItem'
import UploadDropzone from '../UploadDropzone/UploadDropzone'	
import { useTranslation } from 'react-i18next';
import { getVimeoThumbnail, getYoutubeThumbnail } from '../../utils/thumbnails';

function CustomizeVideoclips({ 
	cid, 
	eid, 
	room, 
	playingVideoclip, 
	onPlayVideoclip, 
	onForbidden 
}) {
	const [images, setImages] = useState([])
	const [uploading, setUploading] = useState(false)
	const [showAddVideoclip, setShowAddVideoclip] = useState(false) 
	const [externalLink, setExternalLink] = useState('')
	const [externalLinkInvalid, setExternalLinkInvalid] = useState(false)

	const { t } = useTranslation();
	const { user, modules } = useAuth();
	const scrollbarRef = useRef()

	const isExternalClient = user.client_type !== '4web'
	const canUseCustomVideoclip = isExternalClient || modules.custom_videoclips

	const loadVideoclips = useCallback(async (scroll) => {
		if (scroll) {
			scrollTop()
			setUploading(false)
		}

		let clientVideos = []

		if (canUseCustomVideoclip) {
			const response = await api.get('/videoclips', {
				params: {
					client_id: cid,
					event_id: eid,
					room_id: room
				}
			});

			if (Array.isArray(response.data)) {
				clientVideos = response.data;
			}
		}

		let defaultVideos = [
			{ _id: '5', url: 'https://vp2uploads.s3.amazonaws.com/4weblive/undefined/backgrounds/d6e15e60a3c9698556a6643d3302f57a-videoclip-01.mp4' },
			{ _id: '4', url: 'https://vp2uploads.s3.amazonaws.com/4weblive/undefined/backgrounds/84f2fa4e64c46db6558f37bd03e49308-videoclip-02.mp4' },
			{ _id: '3', url: 'https://vp2uploads.s3.amazonaws.com/4weblive/undefined/backgrounds/0434dfeabf5df72f6578adc1d7f4a1c4-videoclip-03.mp4' },
			{ _id: '2', url: 'https://vp2uploads.s3.amazonaws.com/4weblive/undefined/backgrounds/646956289cb00d94df899dd1e8fcd958-videoclip-04.mp4' },
			{ _id: '1', url: 'https://vp2uploads.s3.amazonaws.com/4weblive/undefined/backgrounds/c13468995bd4b67fb588d54f35db8a2c-videoclip-10.mp4' },
		]

		const images = clientVideos.concat(defaultVideos);

		for (let i = 0; i < images.length; i++) {
			const image = images[i]
			image.thumb = 
				image.url.includes('youtube') || image.url.includes('youtu.be') 
					? getYoutubeThumbnail(image.url) 
					: image.url.includes('vimeo') 
					? await getVimeoThumbnail(image.url) 
					: null
		}

		setImages(images)
	}, [cid, eid, room, canUseCustomVideoclip]);

	const uploadingVideo = () => {
		scrollTop()
		setUploading(true)
		setShowAddVideoclip(false)
	}

	const scrollTop = () => {
		if (scrollbarRef.current) {
			scrollbarRef.current.scrollTop = 0
		}
	}

	const handleAddVideoclipClick = () => {
		if (!canUseCustomVideoclip) {
			onForbidden('custom_videoclip');
			return false;
		}

		setShowAddVideoclip('choose-type');
		setExternalLink('');
	}

	async function handleSaveExternalLink() {
		setExternalLinkInvalid(false);

		const youtubeRegex = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/;
		const vimeoRegex = /(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

		if (!externalLink.match(youtubeRegex) && !externalLink.match(vimeoRegex)) {
			setExternalLinkInvalid(true);
			return;
		}

		setShowAddVideoclip(false);
		uploadingVideo()

		await api.post('/videoclips', {
			client_id: cid,
			event_id: eid,
			room_id: room,
			videoclip_url: externalLink
		})

		loadVideoclips(true)
	}

	useEffect(() => {
		loadVideoclips()
	}, [loadVideoclips])

	return (
		<React.StrictMode>
			<Modal show={Boolean(showAddVideoclip)} backdrop="static" centered onHide={() => setShowAddVideoclip(false)}>
        <Modal.Header closeButton>
          <Modal.Title className=" font-weight-bold ">{t('components.CustomizeVideoclips.TitleAddVideoclip')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
					{showAddVideoclip === 'choose-type' ? (
						<div className="d-flex flex-align-center justify-content-center py-3">
							<button 
								type="button"
								className="btn btn-primary"
								onClick={() => setShowAddVideoclip('local-video')}
							>
								<i className="fas fa-upload mr-2"></i>
								{t(`components.CustomizeVideoclips.btnAddLocalVideo`)}
							</button>

							<button 
								type="button"
								className="btn btn-primary ml-5"
								onClick={() => setShowAddVideoclip('external-link')}
							>
								<i className="fas fa-link mr-2"></i>
								{t(`components.CustomizeVideoclips.btnAddExternalLink`)}
							</button>
						</div>
					) : showAddVideoclip === 'local-video' ? (
						<div className={styles.bg_upload}>
							{!uploading && (
								<UploadDropzone
									type="videoclip"
									cid={cid}
									eid={eid}
									room={room}
									onDrop={() => { uploadingVideo() }}
									onUpload={() => { loadVideoclips(true) }}
									minHeight={"66px"}
									showLock={!canUseCustomVideoclip}
									maxSize={20 * 1024 * 1024} // 20MB
									dropzoneStyle={{
										padding: '28px'
									}}
								/>
							)}
						</div>
					) : showAddVideoclip === 'external-link' ? (
						<>
							<span className="font-md text-center d-block">
								{t(`components.CustomizeVideoclips.TextAddLinkModal`)}
							</span>

							<input
								type="text"
								className="form-control mb-0 mt-2 font-md"
								placeholder={t(`components.CustomizeVideoclips.externalLinkPlaceholder`)}
								value={externalLink}
								onChange={(e) => setExternalLink(e.target.value)}
							/>

							{externalLinkInvalid && (
								<span className="text-danger font-md">
									{t(`components.CustomizeVideoclips.externalLinkInvalid`)}
								</span>
							)}
						</>
					) : null}
        </Modal.Body>
        <Modal.Footer>
					{showAddVideoclip === 'external-link' && (
						<Button variant="primary" onClick={handleSaveExternalLink}>
							{t('components.CustomizeVideoclips.btnSaveLink')}
						</Button>
					)}
          <Button variant="secondary" onClick={() => setShowAddVideoclip(false)}>
            {t('components.CustomizeVideoclips.btnCancel')}
          </Button>
        </Modal.Footer>
      </Modal>

			<div className={`iq-card ${styles.container}`} style={{ boxShadow: "none" }}>
				<div id="localMedia">
					<div className={styles.bg_card}>
						<div className={styles.bg_card_header}>
							<div></div>
							
							<p>{t(`components.CustomizeImages.titleVideoclips`)}</p>

							<OverlayTrigger placement="top" overlay={<Tooltip>{t(`components.CustomizeImages.tooltipAddVideoclips`)}</Tooltip>}>
								<i className="fas fa-question-circle"></i>
							</OverlayTrigger>
						</div>
						<div className={styles.bg_card_body}>
							<PerfectScrollbar className={styles.bg_scrollbar} containerRef={el => (scrollbarRef.current = el)}>
								<div className={styles.bg_list}>

									{uploading ? (
										<BackgroundItem
											imageType="videoclip"
											key={0}
											src={''}
											type="uploading"
										/>
									) : ('')}

									{images.map((bg) => (
										<BackgroundItem
											video
											imageType={bg.thumb ? "overlay" : 'videoclip'}
											key={bg._id}
											active={playingVideoclip === bg.url}
											bg_id={bg._id}
											bg_key={bg.key}
											src={bg.thumb || bg.url}
											type="uploaded"
											onUpdate={() => { loadVideoclips(false) }}
											defineImage={() => { onPlayVideoclip(bg.url) }}
										/>
									))}

								</div>
							</PerfectScrollbar>

							<div className="d-flex flex-align-center justify-content-center mt-3 pb-3">
								{!uploading && (
									<button 
										type="button"
										className="btn btn-primary"
										onClick={handleAddVideoclipClick}
									>
										<i className="fas fa-video mr-2"></i>
										{t(`components.CustomizeVideoclips.btnAddVideoclip`)}
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.StrictMode>
	)
}

export default CustomizeVideoclips;