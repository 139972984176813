import React, { useState } from "react";
import Sidebar from "../../components/Layout/SideBar/SideBar";
import { Header } from "../../components/Layout/Header";
import api from "../../services/api";
import Swal from 'sweetalert2'
import Promo from "../../components/Promo/Promo";
import { Link, Redirect } from "react-router-dom";
import promo_banner from "../../assets/images/promo/promo_banner.png";
import { Button } from './styles';

import { useAuth } from "../../context/Auth";

import "./styles.js";
import { useTranslation } from "react-i18next";

var suppSomOne = Math.floor(Math.random() * 10 + 1)
var suppSomTwo = Math.floor(Math.random() * 10 + 1)

export default function Support() {

    const { user, modules } = useAuth();
    const { t } = useTranslation()

    const [subject, setSubject] = useState('')
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [somRes, setSomRes] = useState(0)
    const [redirect, setRedirect] = useState(false)
    const [awaitingSend, setAwaitingSend] = useState(false)

    async function handleSubmit(event) {
        event.preventDefault()
        if ((suppSomOne + suppSomTwo !== parseInt(somRes))) {
            Swal.fire('Oops...', t(`pages.Support.swalErrorSum`), 'warning')
            return false
        } else if (!subject || !title || !message) {
            Swal.fire('Oops...', t(`pages.Support.swalVerifyFields`), 'warning')
            return false
        }

        try {

            setAwaitingSend(true)

            let data = await api.post('/api/support/message', {
                subject: subject,
                title: title,
                message: message,
                user: {
                    type: user.client_type ? user.client_type : '',
                    email: user.email ? user.email : '',
                    name: user.name ? user.name : '',
                    id: user._id ? user._id : ''
                }
            })

            let { status } = data

            if (status === 200) {
                Swal.fire(t(`pages.Signin.swalEmailHasBeenSentTitle`), t(`pages.Support.swalSendMessageSuccess`), 'success')
            } else {
                Swal.fire('Oops...', t(`pages.Support.swalSendMessageError`), 'warning')
            }

            suppSomOne = Math.floor(Math.random() * 10 + 1)
            suppSomTwo = Math.floor(Math.random() * 10 + 1)

            setRedirect(true)

        } catch (err) {

            Swal.fire('Oops...', t(`pages.Support.swalSendMessageError`), 'warning')
            setAwaitingSend(false)

            return false
        }
    }

    return (
        <>
            {redirect ? (<Redirect to='/new-room' />) : (<></>)}
            {/* <Sidebar page="support" /> */}
            <Header page="support" />

            <div className="content-page">
                <div className="container-fluid">
                    {/*modules.logo_required && (
                        <>
                            <Promo type="modal"></Promo>
                            <Link to="/plans">
                                <div className="iq-card mb-2 p-2" style={{ marginTop: '-20px' }}>
                                    <img src={promo_banner} style={{ width: '100%', borderRadius: '10px' }} />
                                </div>
                            </Link>
                        </>
                    )*/}
                    <div className="row">
                        <div className="col-12 mb-3">
                            <h3>{t(`pages.Support.title`)}</h3>
                        </div>
                    </div>
                    <div className="iq-card mb-4 p-3">
                        <div className="row mt-4">
                            <div className="col-md-12 col-12 col-sm-12">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group mt-0">
                                        <label className="label-input" style={{ color: 'var(--iq-primary)' }}>* {t(`pages.Support.subject`)}</label>
                                        <select className="form-control mb-0" required={true} onChange={(e) => setSubject(e.target.value)}>
                                            <option selected="" disabled="">{t(`pages.Support.subjectPlaceholder`)}</option>
                                            <option value={t(`pages.Support.technicalProblems`)}>{t(`pages.Support.technicalProblems`)}</option>
                                            <option value={t(`pages.Support.financial`)}>{t(`pages.Support.financial`)}</option>
                                            <option value={t(`pages.Support.help`)}>{t(`pages.Support.help`)}</option>
                                            <option value={t(`pages.Support.others`)}>{t(`pages.Support.others`)}</option>
                                        </select>
                                    </div>

                                    <div className="form-group mt-2">
                                        <label className="label-input" style={{ color: 'var(--iq-primary)' }}>* {t(`pages.Support.messageTitle`)}</label>
                                        <input
                                            required={true}
                                            type="text"
                                            className="form-control mb-0"
                                            placeholder={t(`pages.Support.messageTitlePlaceholder`)}
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </div>

                                    <div className="form-group mt-2">
                                        <label className="label-input" style={{ color: 'var(--iq-primary)' }}>* {t(`pages.Support.message`)}</label>
                                        <textarea required={true} className="form-control" rows={4} onChange={(e) => setMessage(e.target.value)}></textarea>
                                    </div>

                                    <div className="form-group mt-2">
                                        <label className="label-input" style={{ color: 'var(--iq-primary)', display: 'block' }}>* {t(`pages.Support.labelSum`)}</label>

                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control mb-0 text-center"
                                            value={suppSomOne}
                                            onChange={(e) => setTitle(e.target.value)}
                                            style={{ width: '60px', display: 'inline' }}
                                        />

                                        <p className="font-weight-bold mx-3" style={{ display: 'inline' }}>+</p>

                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control mb-0 text-center"
                                            value={suppSomTwo}
                                            onChange={(e) => setTitle(e.target.value)}
                                            style={{ width: '60px', display: 'inline' }}
                                        />

                                        <p className="font-weight-bold mx-3" style={{ display: 'inline' }}>=</p>

                                        <input
                                            required={true}
                                            type="text"
                                            className="form-control mb-0"
                                            onChange={(e) => setSomRes(e.target.value)}
                                            style={{ width: '60px', display: 'inline' }}
                                        />

                                    </div>

                                    <div className="d-flex align-content-center justify-content-end mt-4">
                                        {awaitingSend ? (
                                            <Button disabled={true} className="btn btn-lg btn-primary" type="submit">
                                                <span
                                                    style={{ fontSize: 18 }}
                                                    className="fa fa-spin fa-spinner"
                                                />
                                            </Button>
                                        ) : (
                                            <Button className="btn btn-lg btn-primary" type="submit">
                                                {t(`components.Chat.buttonSendMessage`)}
                                                <span
                                                    style={{ fontSize: 18, marginLeft: 12 }}
                                                    className="fa fa-paper-plane"
                                                />
                                            </Button>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
