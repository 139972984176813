import React, { useState, useContext } from "react";

import Sidebar from "../../components/Layout/SideBar/SideBar";
import { Header } from "../../components/Layout/Header";
import TwitchAuth from "../../components/TwitchAuth";
import YoutubeAuth from "../../components/YoutubeAuth";
import CustomRTMP from "../../components/CustomRTMP";
import { token_decode } from "../../services/auth";
import { AccountContext } from "../../context/Accounts";
import { FaRegTrashAlt, FaTwitch, FaYoutube, FaListAlt } from "react-icons/fa";
import api from "../../services/api";
import { toast } from "react-toastify";
import customRtmpImg from "../../assets/images/custom_rtmp.svg";

import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import { useAuth } from "../../context/Auth";
import Promo from "../../components/Promo/Promo";
import { Link } from "react-router-dom";
import promo_banner from "../../assets/images/promo/promo_banner.png";

import mauticPlgEvent from '../../services/plgEvents'

import "./styles.css";
import { useTranslation } from "react-i18next";

export default function Destinations() {
  const { t } = useTranslation();
  const { accounts, deleteAccount } = useContext(AccountContext);
  const [showModalAddAccount, setShowModalAddAccount] = useState(false);
  const [showModalRTMP, setShowModalRTMP] = useState(false);
  const [serverUrlRTMP, setServerUrlRTMP] = useState("");
  const [streamKeyRTMP, setStreamKeyRTMP] = useState("");
  const [nicknameRTMP, setNicknameRTMP] = useState("");
  const [serverUrlRTMPError, setServerUrlRTMPError] = useState(false);
  const [streamKeyRTMPError, setStreamKeyRTMPError] = useState(false);
  const [nicknameRTMPError, setNicknameRTMPError] = useState(false);

  const client = token_decode(localStorage.getItem("auth-user"));

  const { modules } = useAuth();

  let cid = null;

  if (client.client_type !== "4web") {
    cid = client.vp_client_id;
  } else {
    cid = client._id;
  }

  const labelAccount = {
    twitch: {
      class: "twitch_label",
      icon: <FaTwitch size={14} color="#fff" />,
    },
    custom_rtmp: {
      class: "custom_rtmp_label",
      icon: <FaListAlt size={14} color="#fff" />,
    },
    youtube: {
      class: "youtube_label",
      icon: <FaYoutube size={14} color="#fff" />,
    },
  };

  function handleOpenModal() {
    setShowModalAddAccount(false);
    setShowModalRTMP(true);
  }

  function handleCreateCustomRTMP() {
    let isFormInvalid = false;

    if (!serverUrlRTMP) {
      setServerUrlRTMPError(true);
      isFormInvalid = true;
    }

    if (!streamKeyRTMP) {
      setStreamKeyRTMPError(true);
      isFormInvalid = true;
    }

    if (!nicknameRTMP) {
      setNicknameRTMPError(true);
      isFormInvalid = true;
    }

    if (isFormInvalid) {
      return;
    }

    api
      .post("api/destinations/custom-rtmp", {
        client_id: cid,
        type_account: "custom_rtmp",
        display_name: nicknameRTMP,
        rtmp_stream_key: streamKeyRTMP,
        rtmp_server_url: serverUrlRTMP,
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = "/destinations";
        }
        mauticPlgEvent('config', 'Novo destino adicionado: Custom RTMP')
      })
      .catch((err) => {
        console.log(err);
        window.location.href = "/new-room";
        toast.error(t(`pages.Destinations.toastErrorText`));
      });
  }

  function handleTypeRtmpServerUrl(event) {
    const rtmpServerUrl = event.target.value;
    if (rtmpServerUrl) {
      setServerUrlRTMPError(false);
    }
    setServerUrlRTMP(rtmpServerUrl);
  }

  function handleTypeStreamKeyRtmp(event) {
    const streamKey = event.target.value;
    if (streamKey) {
      setStreamKeyRTMPError(false);
    }
    setStreamKeyRTMP(streamKey);
  }

  function handleTypeNicknameRtmp(event) {
    const nickname = event.target.value;
    if (nickname) {
      setNicknameRTMPError(false);
    }
    setNicknameRTMP(nickname);
  }

  return (
    <>
      {/* <Sidebar page="destinations" /> */}
      <Header page="destinations" />
      <Modal
        show={showModalAddAccount}
        onHide={() => setShowModalAddAccount(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className=" font-weight-bold ">
            {t(`pages.Destinations.modalAddAccountTitle`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <strong>{t(`pages.Destinations.optionsText`)}</strong>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridGap: "10px",
                marginTop: "20px",
              }}
            >
              <TwitchAuth
                buttonText={t(`pages.Destinations.buttonTextTwitch`)}
                appId={process.env.REACT_APP_TWITCH_APP_ID}
                redirectURI="https://app.4web.live/callback-twitch"
                // redirectURI="http://localhost:3000/callback-twitch"
                scopes="channel:read:editors channel:read:stream_key user:read:email channel:manage:broadcast channel:manage:videos"
              />
              <YoutubeAuth text={t(`pages.Destinations.buttonTextYoutube`)} />
              <CustomRTMP
                buttonText={t(`pages.Destinations.buttonTextRTMP`)}
                openModal={handleOpenModal}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showModalRTMP} onHide={() => setShowModalRTMP(false)}>
        <Modal.Header closeButton>
          <Modal.Title className=" font-weight-bold ">
            {t(`pages.Destinations.addCustomRTMPText`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <input
              placeholder="RTMP server URL"
              className="form-control mt-2"
              type="text"
              value={serverUrlRTMP}
              onChange={handleTypeRtmpServerUrl}
            />
            {serverUrlRTMPError && (
              <small className="text-danger">
                {t(`pages.Destinations.errorRTMPUrlServer`)}
              </small>
            )}
            <input
              placeholder={t(`pages.Destinations.placeholderRTMPStreamKey`)}
              className="form-control mt-2"
              type="text"
              value={streamKeyRTMP}
              onChange={handleTypeStreamKeyRtmp}
            />
            {streamKeyRTMPError && (
              <small className="text-danger">
                {t(`pages.Destinations.errorRTMPStreamKey`)}
              </small>
            )}
            <input
              placeholder={t(`pages.Destinations.placeholderRTMPNickname`)}
              className="form-control mt-2"
              type="text"
              value={nicknameRTMP}
              onChange={handleTypeNicknameRtmp}
            />
            {nicknameRTMPError && (
              <small className="text-danger">
                {t(`pages.Destinations.errorRTMPNickname`)}
              </small>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCreateCustomRTMP}>
            {t(`pages.Destinations.createButtonText`)}
          </Button>
          <Button variant="secondary" onClick={() => setShowModalRTMP(false)}>
            {t(`pages.Destinations.cancelButtonText`)}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="content-page">
        <div className="container-fluid">
          {/*modules.logo_required && client.client_type === '4web' &&(
            <>
            <Promo type="modal"></Promo>
            <Link to="/plans">
              <div className="iq-card mb-2 p-2" style={{ marginTop: '-20px'}}>
                <img src={promo_banner} style={{ width: '100%', borderRadius: '10px'}} />
              </div>
            </Link>
            </>
          )*/}
          <div className="iq-card mb-4 p-3">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3>{t(`pages.Destinations.title`)}</h3>
              <p className="mb-0 mt-2 text-center px-5">{t(`pages.Destinations.newDestinationDescript`)}</p>
              <div className="row d-flex justify-content-center mb-3">
                <button
                  type="button"
                  className="btn btn-primary p-2 mt-5"
                  onClick={() => setShowModalAddAccount(true)}
                >
                  <b>{t(`pages.Destinations.description`)}</b>{" "}
                  <i
                    style={{ fontSize: 20 }}
                    className="ri-login-circle-line"
                  ></i>
                </button>
              </div>
            </div>

            <div className="row mt-4 col-md-12">
              <ul className=" mt-2 list-group list-group-flush">
                {accounts.length > 0 && <h5>{t(`pages.Destinations.activeAccountsText`)}</h5>}
                {accounts.map((account) => (
                  <li
                    key={account._id}
                    id={`account_${account._id}`}
                    className="list-group-item d-flex align-items-center justify-content-between "
                  >
                    <OverlayTrigger
                      key={account._id}
                      placement="top"
                      overlay={<Tooltip>{account.display_name}</Tooltip>}
                    >
                      <div className="user-img img-fluid">
                        <div>
                          <a className={`btn_select_account_destination`}>
                            <img
                              src={account.type_account === 'custom_rtmp' ? customRtmpImg : account.avatar_url}
                              alt={account.display_name || ''}
                              className={`rounded-circle avatar-50`}
                            />
                          </a>
                        </div>
                        <div>
                          <label
                            className={
                              `${labelAccount[account.type_account]["class"]} d-flex align-items-center justify-content-center`
                            }
                          >
                            {labelAccount[account.type_account]["icon"]}
                          </label>
                        </div>
                      </div>
                    </OverlayTrigger>
                    <label className="ml-5">
                      <b>{account.display_name}</b>
                    </label>
                    <button
                      id={account._id}
                      onClick={() => deleteAccount(account._id)}
                      className="ml-5 btn btn-light"
                    >
                      <FaRegTrashAlt />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
