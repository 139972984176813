import React, { useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from 'use-debounce'
import { Form } from 'react-bootstrap';
import Slider from 'rc-slider';
import { Container } from './styles';

export function SoundsControl({
  songElement,
  absolute = false,
  compact = false,
  background = false,
  dark = false,
  onSoundStateChange,
}) {
  const [volume, setVolume] = useState(songElement?.volume);
  const [muted, setMuted] = useState(songElement?.muted);
  const [isPlaying, setIsPlaying] = useState(!songElement?.paused);
  const [isLoop, setIsLoop] = useState(!songElement?.loop);

  const sliderStyle = useMemo(() => {
    return {
      railStyle: { backgroundColor: '#0003', height: '5px' },
      trackStyle: { backgroundColor: '#1aafff', height: '5px' },
      handleStyle: { top: '5px' },
      style: { top: '-1px', cursor: 'pointer' },
    }
  }, []);

  const onSoundStateChangeDebounced = useDebouncedCallback(() => {
    onSoundStateChange();
  }, 300);

  function handlePlayPauseClick() {
    if (songElement.paused) {
      songElement.play();
    } else {
      songElement.pause();
    }
    onSoundStateChangeDebounced();
  }

  function handleVolumeChange(value) {
    if (songElement) {
      songElement.volume = value / 100;
      songElement.muted = false;
      setMuted(false);
      setVolume(value / 100);
    }
    onSoundStateChangeDebounced();
  }

  function handleMuteUnmuteClick() {
    if (songElement) {
      if (muted) {
        songElement.muted = false;
        setMuted(false);
        if (volume === 0) {
          setVolume(1);
        }
      } else {
        songElement.muted = true;
        setMuted(true);
      }
    }
    onSoundStateChangeDebounced();
  }

  function handleToggleBgSongLoop() {
    const currentLoopState = songElement.loop;
    
    if (currentLoopState) {
      songElement.loop = false;
      setIsLoop(false);
    } else {
      songElement.loop = true;
      setIsLoop(true);
    }
    onSoundStateChangeDebounced();
  }

  useEffect(() => {
    const onPlay = () => {
      setIsPlaying(true);
    };

    const onPause = () => {
      setIsPlaying(false);
    };

    const onVolumeChange = () => {
      setMuted(songElement.muted);
      setVolume(songElement.volume);
    };

    songElement?.addEventListener('play', onPlay);
    songElement?.addEventListener('pause', onPause);
    songElement?.addEventListener('volumechange', onVolumeChange);

    return () => {
      songElement?.removeEventListener('play', onPlay);
      songElement?.removeEventListener('pause', onPause);
      songElement?.removeEventListener('volumechange', onVolumeChange);
    };
  }, [songElement]);

  return (
    <Container
      absolute={absolute}
      background={background}
      compact={compact}
      dark={dark}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="btn-container" onClick={handlePlayPauseClick}>
        <i className={`fa ${isPlaying ? 'fa-pause' : 'fa-play'} play-pause-btn`}></i>
      </div>

      <div className="btn-container" onClick={handleMuteUnmuteClick}>
        <i
          className={`fa ${(muted || volume === 0)
            ? 'fa-volume-mute'
            : volume > 0.6
              ? 'fa-volume-up'
              : volume > 0.3
                ? 'fa-volume-down'
                : 'fa-volume-off'
            } volume-btn`}
        ></i>
      </div>

      <Slider
        className="volume-slider"
        max={100}
        step={1}
        value={muted ? 0 : volume * 100}
        onChange={handleVolumeChange}
        {...sliderStyle}
      />

      <div className="form-check-container">
        <Form>
          <Form.Check 
            type="switch"
            id="bgSongLoop"
            label="Loop"
            value={isLoop}
            onChange={handleToggleBgSongLoop}
          />
        </Form>
      </div>
    </Container>
  );
};
