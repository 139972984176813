import api from './api' 
 
export const isAuthenticated = () => {
  const user = getAuthenticatedUser();
  return !!user;
}

export const logout = () =>{
  
  localStorage.removeItem('auth-user')
  localStorage.removeItem('auth-token')
  localStorage.removeItem('goauth2_token')
  localStorage.removeItem('account')
  localStorage.removeItem('clientLogo')
  
}

export const token_decode = (code) => {
  if(!code){
    return false
  }
  try{
    let data = atob(code);

    return JSON.parse(data);
  }catch(err){
    return false
  }
}

export const getAuthenticatedUser = () => {
  const encodedUser = localStorage.getItem('auth-user')
  const token = localStorage.getItem('auth-token')

  if (token) {
    api.interceptors.request.use((config) => {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    })
  }

  return token_decode(encodedUser)
}

export const saveAuthenticatedUser = (user) => {
  let token_auth = Buffer.from(JSON.stringify(user)).toString('base64')
  localStorage.setItem('auth-user', token_auth)
}
