import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 36px;

  p {
    font-size: 24px;
    font-weight: 200;
    margin-bottom: 0;
  }
`;

export const Button = styled.button`
  width: 150px;
  height: 40px;

  font-size: 14px;
  border-radius: .3rem;
  border: 0;
  /* background-color: #d82e4c;
  color: #fff; */
  transition: 0.3s;

  /* &:hover {
    background-color: #952539;
    color: #fff;
  } */
`;