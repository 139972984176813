import React, { useEffect, useState, useRef, useMemo } from 'react';
import styles from './customizeImages.module.css';
import api from '../../services/api'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useAuth } from '../../context/Auth'

import BackgroundItem from '../BackgroundItem/BackgroundItem'
import UploadDropzone from '../UploadDropzone/UploadDropzone'
import { getAuthenticatedUser } from '../../services/auth';
import { useClientLogo } from '../../hooks/useClientLogo';
import { useTranslation } from 'react-i18next';

function CustomizeImages(props) {
    const { t } = useTranslation();
    const user = getAuthenticatedUser();
    const { modules } = useAuth();

    const configs = {
        background: {
            title: t(`components.CustomizeImages.titleBackground`),
            tooltip: t(`components.CustomizeImages.tooltipAddBackground`),
            typeActive: 'backgroundActive',
            typeUrl: 'backgroundUrl',
            typeSwitch: 'switchBackground',
            typeCanUseCustom: modules.custom_background,
            typeIsRequired: false,
            imagesUrl: '/backgrounds',
            imagesDefault: [
                { _id: '1', url: 'https://vp2uploads.s3.amazonaws.com/4weblive/undefined/backgrounds/1deaddf28e0b162f9f98e4cfe40ec792-msoa1lygpxolykjblyls.png' },
                { _id: '2', url: 'https://vp2uploads.s3.amazonaws.com/4weblive/1/backgrounds/7ae6b21231ed774c018c39950883ecee-bg-4.jpeg' },
                { _id: '3', url: 'https://vp2uploads.s3.amazonaws.com/4weblive/undefined/backgrounds/95fe9426e6ee05160fff18c0aaa3707c-fundo3.jpg' },
                { _id: '4', url: 'https://vp2uploads.s3.amazonaws.com/4weblive/1/backgrounds/6cd1ceba08e2a0af89d1f2d3a2af5404-bg-1.jpeg' }
            ]
        },
        logo: {
            title: t(`components.CustomizeImages.titleLogo`),
            tooltip: t(`components.CustomizeImages.tooltipAddLogo`),
            typeActive: 'logoActive',
            typeUrl: 'logoUrl',
            typeSwitch: 'switchLogo',
            typeCanUseCustom: modules.custom_logo,
            typeIsRequired: modules.logo_required,
            imagesUrl: '/logos',
            imagesDefault: [
                { _id: '1', url: 'https://vp2uploads.s3.amazonaws.com/4weblive/undefined/backgrounds/5cb67df3a241b4a21b7a11a86e0a6d47-4web%20-%20white.png' },
                { _id: '2', url: 'https://vp2uploads.s3.amazonaws.com/4weblive/undefined/backgrounds/99ed33442b25d4a97ce7155f306695b0-4web%20-%20black.png' },
                { _id: '3', url: 'https://vp2uploads.s3.amazonaws.com/4weblive/undefined/backgrounds/ff7f8c42c882adde5c2966b5c5d10054-4web%20-%20all%20white.png' },
                { _id: '4', url: 'https://vp2uploads.s3.amazonaws.com/4weblive/undefined/backgrounds/38a597b70d75b9bc14b3e63da8e16818-4web%20-%2080%25.png' },
                { _id: '5', url: 'https://vp2uploads.s3.amazonaws.com/4weblive/undefined/backgrounds/a0b59990a06e42754c560baa6cb7d8db-normal_grande_transparente.png' }
            ]
        },
        overlay: {
            title: t(`components.CustomizeImages.titleOverlay`),
            tooltip: t(`components.CustomizeImages.tooltipAddOverlay`),
            typeActive: 'overlayActive',
            typeUrl: 'overlayUrl',
            typeSwitch: 'switchOverlay',
            typeCanUseCustom: modules.custom_overlay,
            typeIsRequired: false,
            imagesUrl: '/overlays',
            imagesDefault: [
                { _id: '1', url: 'https://vp2uploads.s3.amazonaws.com/4weblive/undefined/backgrounds/93326ea84f53026aa36f192eb3f30f32-Default%20overlay%20-%204web.live%20%28Light%29.png' },
                { _id: '2', url: 'https://vp2uploads.s3.amazonaws.com/4weblive/undefined/backgrounds/972efa74b9411b28572a64c9b61a2475-Default%20overlay%20-%204web.live%20%28Dark%29.png' },
            ]
        }
    }

    const {
        title,
        tooltip,
        typeActive,
        typeUrl,
        typeSwitch,
        typeCanUseCustom,
        typeIsRequired,
        imagesUrl,
        imagesDefault
    } = configs[props.type];
    
    const isExternalClient = user.client_type !== '4web'
    const canUseCustomImage = isExternalClient || typeCanUseCustom
    const canHideImage = isExternalClient || !typeIsRequired
     
    const [clientImages, setClientImages] = useState([])
    const [defaultImages, setDefaultImages] = useState([])

    const images = useMemo(() =>  [...clientImages, ...defaultImages], [clientImages, defaultImages]);

    const [uploading, setUploading] = useState(false)
    const [switchImage, setSwitchImage] = useState(localStorage.getItem(typeSwitch) ? (localStorage.getItem(typeSwitch) === 'true') : true)
    const [imageActive, setImageActive] = useState(localStorage.getItem(typeActive))
    const { clientLogo } = useClientLogo();

    const ps = useRef()

    async function getBackgrounds(scroll) {
        if (scroll) {
            scrollTop()
            setUploading(false)
        }

        let data = {
            client_id: props.cid,
            event_id: props.eid,
            room_id: props.room
        }

        let bgClient = []

        if (canUseCustomImage) {
            const response = await api.get(imagesUrl, { params: data });
            if (Array.isArray(response.data)) {
                bgClient = response.data;
            }
        }

        setClientImages(bgClient)
    }

    const uploadingBg = () => {
        scrollTop()
        setUploading(true)
    }

    const scrollTop = () => {
        const curr = ps.current
        if (curr) {
            curr.scrollTop = 0
        }
    }

    const handleSwitchImage = () => {
        const newSwitchValue = !switchImage
        
        if (!canHideImage && !newSwitchValue) {
            props.onForbidden(`${props.type}_required`);
            return;
        }

        setSwitchImage(newSwitchValue)
        localStorage.setItem(typeSwitch, newSwitchValue)
        props.defineImage()
    }

    const defineImageActive = (id, url) => {
        if(imageActive === id){
            if (!canHideImage) {
                return;
            }

            setImageActive(null)
            localStorage.removeItem(typeActive)
            localStorage.removeItem(typeUrl)
            return false
        }

        setImageActive(id)
        localStorage.setItem(typeActive, id)
        localStorage.setItem(typeUrl, url)
    }

    const handleRequestUpload = () => {
        if (!canUseCustomImage) {
            props.onForbidden(`custom_${props.type}`);
        }
        return canUseCustomImage
    }

    useEffect(() => {
        if(props.type === 'logo'){
            let logoActive = localStorage.getItem('logoActive')
            let logoUrl = localStorage.getItem('logoUrl')

            if(!logoActive && !logoUrl){
                localStorage.setItem('logoActive', '1')
                localStorage.setItem('logoUrl', 'https://vp2uploads.s3.amazonaws.com/4weblive/undefined/backgrounds/5cb67df3a241b4a21b7a11a86e0a6d47-4web%20-%20white.png')
            }
        }

        getBackgrounds()
    }, [canUseCustomImage])

    useEffect(() => {
        let bgDefault = imagesDefault;
        
        if (props.type === 'logo' && clientLogo?.url) {
            bgDefault = [{ _id: '1', url: clientLogo?.url }];
        }

        setDefaultImages(bgDefault)
    }, [clientLogo?.url])

    useEffect(() => {
        const hasDependeciesLoaded =
            canUseCustomImage !== undefined && images.length > 0;

        if (!hasDependeciesLoaded || canUseCustomImage) {
            return;
        }

        const imageActive = localStorage.getItem(typeActive);
        const validImage = images.find((image) => image._id === imageActive);

        if (!validImage) {
            defineImageActive(images[0]._id, images[0].url);
            props.defineImage();
        }
    }, [images, canUseCustomImage]);

    useEffect(() => {
        const hasDependeciesLoaded = canHideImage !== undefined;

        if (!hasDependeciesLoaded || canHideImage) {
            return;
        }

        const switchActive = localStorage.getItem(typeSwitch) === 'true';

        if (!switchActive) {
            setSwitchImage(true)
            localStorage.setItem(typeSwitch, 'true')
            props.defineImage()
        }
    }, [images, canHideImage]);

    return (
        <React.StrictMode>
            <div className={`iq-card ${styles.container}`} style={{ boxShadow: "none" }}>
                <div id="localMedia">
                    <div className={styles.bg_card}>
                        <div className={styles.bg_card_header}>
                            <div>
                                <label className={`${styles.switch}`}>
                                    <input type="checkbox" onChange={handleSwitchImage} checked={switchImage} />
                                    <span className={`${styles.slider} ${styles.round}`}></span>
                                </label>

                                {!canHideImage && <i className="fa fa-lock"></i>}
                            </div>

                            <p>{title}</p>

                            <OverlayTrigger placement={props.type === 'background' ? 'bottom' : 'top'} overlay={<Tooltip>{tooltip}</Tooltip>}>
                                <i className="fas fa-question-circle"></i>
                            </OverlayTrigger>
                        </div>
                        <div className={styles.bg_card_body}>
                            <PerfectScrollbar className={styles.bg_scrollbar} containerRef={el => (ps.current = el)}>
                                <div className={styles.bg_list}>

                                    {uploading ? (
                                        <BackgroundItem imageType={props.type} key={0} src={''} type="uploading" />
                                    ) : ('')}

                                    {images.map((bg) => (
                                        <BackgroundItem imageType={props.type} key={bg._id} active={imageActive === bg._id ? true : false} bg_id={bg._id} bg_key={bg.key} src={bg.url} type="uploaded" onUpdate={() => { getBackgrounds(false) }} defineImage={() => { defineImageActive(bg._id, bg.url); props.defineImage() }} />
                                    ))}

                                </div>
                            </PerfectScrollbar>

                            <div className={styles.bg_upload}>
                                {!uploading && (
                                    <UploadDropzone 
                                        type={props.type} 
                                        cid={props.cid} 
                                        eid={props.eid} 
                                        room={props.room} 
                                        onDrop={() => { uploadingBg() }} 
                                        onUpload={() => { getBackgrounds(true) }} 
                                        onRequestUpload={handleRequestUpload} 
                                        minHeight={"66px"} 
                                        showLock={!canUseCustomImage}
                                    />
                                )}
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </React.StrictMode>
    )
}

export default CustomizeImages;